// BayService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import { BayRequest, BayResponse, ChangeStatusBayResponse, DeleteBayResponse, GetBaysResponse } from './interface';

export class BayProfile extends BaseService {
  async createBay(req: BayRequest) {
    return this.post<BayResponse, BayRequest>(`${this.apiUrl}/api/bays`, req);
  }

  async getBays(params: PaginationParams) {
    return this.get<GetBaysResponse>(`${this.apiUrl}/api/bays/paged`, params);
  }

  async updateBay(id: number, req: BayRequest) {
    return this.put<BayResponse, BayRequest>(`${this.apiUrl}/api/bays/${id}`, req);
  }

  async deleteBay(id: number) {
    return this.delete<DeleteBayResponse>(`${this.apiUrl}/api/bays/${id}`);
  }

  async changeStatusBay(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusBayResponse, ChangeStatusRequest>(`${this.apiUrl}/api/bays/${active}/${id}`, req);
  }
}
