import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Business} from '../../assets/SideBarIcons/business.svg';
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import BusinessModalComponent from "./BusinessModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface BusinessComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  statusModal: boolean;
  statusText: string;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  scrapItemGroupsData: any;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  priceListData: any;
  taxProceduresData: any;
  clearSearch: () => void;
}

const BusinessComponent: React.FC <BusinessComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,
  statusText,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  loadingSearch,
  scrapItemGroupsData,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  priceListData,
  taxProceduresData,
  clearSearch
}) => {
  const { t } = useTranslation();

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addBusiness": (enableEdit&&openForm) ? "updateBusiness" : t('Businessses')}
            icon={<Business height={28} width={28} />}
            btnText={openForm ? "back" : "create"}
            btnType={openForm ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_BUSINESS_CREATE")}
            // btnType="create"
          />

          {openForm ? 
            <BusinessModalComponent
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSecField={handleSecField}
              showSecField={showSecField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              handleSubmit={handleSubmit}
              formData={formData}
              scrapItemGroupsData={scrapItemGroupsData.data?.content || []}
              taxProceduresData={taxProceduresData.data?.content || []}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
              loadNext={loadNext}
              loadPrev={loadPrev}
              priceListData={priceListData.data?.content || []}
              handleDropdownSearch={handleDropdownSearch}
              {...{loadingDropdownSearch}}
            />
              :  <Box>
              {loading ? <Loader /> :
                <BackendPaginationTable
                  columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                  data={data.data?.content || []}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  tableData={data?.data || {}}
                  showCheckbox={false}
                  showActions={true}
                  enableSorting={true}
                  showHeader={true}
                  showSearch={true}
                  showFilter={true}
                  showCreateButton={true}
                  actions={actions1}
                  handleChangePage={handleChangePage}
                  handleChangeRows={handleChangeRows}
                  onChangeStatus={onChangeStatus}
                  rowClick={checkPrivileges("ROLE_BUSINESS_UPDATE")}
                  showRowView={showRowView}
                  loadingSearch={loadingSearch}
                  headerComponent={
                  <Grid container spacing={2}>  
                    <Grid item xs={12} md={7} lg={5}>
                      <StyledInput
                        fullWidth
                        placeholder={t("title")}
                        handleSearchClick={handleSearchClick}
                        handleChange={handleInputChange}
                        value={search}
                        clearSearch={clearSearch}
                      />
                    </Grid>
                  </Grid>
                  }
                />
              }
            </Box>
              }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title="Delete Business"
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    Are you sure you want to Delete?
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
        {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

        </Box>

    )
}

export default BusinessComponent;