import { FC, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';

interface StyledInputProps {
  placeholder?: string;
  handleSearchClick?: (searchTerm: string) => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  value?: string;
  clearSearch?: () => void;
}

const StyledInput: FC<StyledInputProps> = ({
  placeholder = 'Search...',
  handleSearchClick,
  handleChange,
  value,
  fullWidth = false,
  clearSearch
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<any>(value);

  const internalHandleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    handleChange?.(event);
  };

  const handleIconClick = () => handleSearchClick?.(searchTerm);

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearchClick?.(searchTerm);
    }
  };

  return (
    <Grid className='flex items-center'>
      <OutlinedInput
        className={`font-Saira italic font-[500] text-[14px] ${fullWidth ? 'w-[100%]' : 'w-[340px]'}
          overflow-hidden pr-0 h-[38px] rounded-[8px] bg-white me-[4px]`}
        sx={{
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#343434 !important', borderWidth: '2px !important' },
          '& input:-webkit-autofill': { WebkitBoxShadow: '0 0 0 30px white inset !important' },
        }}
        id="styled-input"
        type="text"
        // endAdornment={
        //   <InputAdornment
        //     className="bg-unselected-color  hover:bg-selected-color  rounded-[10px] overflow-hidden px-4 py-5 text-center cursor-pointer"
        //     position="end"
        //     onClick={handleIconClick}
        //   >
        //     <SearchIcon className="text-primary-color hover:mt-2 opacity-70 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" />
        //   </InputAdornment>
        // }
        startAdornment = {
          <InputAdornment position="start">
            <SearchIcon className='text-primary-color size-5' />
          </InputAdornment>
        }
        placeholder={t(placeholder)}
        onChange={internalHandleChange}
        onKeyDown={handleKeyPress}
        value={value}
      />
      <Grid item xs={12} className="text-left flex items-center gap-2 mt-0 pt-0">
        <Button 
          variant="contained"
          disabled={false}
          onClick={handleIconClick}
          sx={{ boxShadow: 'none'}}
          className={`text-white text-ellipsis line-clamp-1 overflow-hidden font-Saira font-[600] text-[14px] bg-primary-color h-[35px] px-8 py-1 ms-[4px]`}
        >{t('submit')}</Button>
        {value && <Button 
          variant="contained"
          onClick={clearSearch}
          sx={{ boxShadow: 'none' }}
          className={`text-primary-color text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] bg-[#d8d1e0] h-[35px] px-8 py-1`}
        >{t('clear')}</Button>}
      </Grid>
    </Grid>
  );
};

export default StyledInput;
