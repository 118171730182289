import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import StyledInputArea from 'common/TextArea/TextArea';
import COUNTRIES from '../../common/Others/Countries';
import { useTranslation } from 'react-i18next';

interface StationModalComponentProps {
    handleSecField: () => void;
    showSecField: boolean;
    primaryLanguage: string;
    secondaryLanguage: string;
    openModal: () => void;
    closeModal: () => void;
    handleChange: (field: string, value: any) => void;
    handleSubmit: () => void;
    formData: any;
    priceListData: any;
    businessGroupData: any;
    openForm: boolean;
    errorMessages: { [key: string]: string };
    enableEdit?: boolean;
    isBtnEnabled: boolean;
}

// const PlateNumberType = [
//     { value: 1, label: 'Standard Plate Number' },
//     { value: 2, label: 'Commercial Plate Number' },
//     { value: 3, label: 'Government Plate Number' },
//     { value: 4, label: 'Vintage/Classic Plate Number' },
// ];

// const stationCategory = [
//     { value: 10, label: 'Inspection Station' },
//     { value: 20, label: 'Repair Station' },
//     { value: 30, label: 'Maintenance Station' },
//     { value: 40, label: 'Valet Station' },
//     { value: 50, label: 'Storage Station' },
//     { value: 60, label: 'Specialized Service Station' },
// ];
const StationModalComponent: React.FC<StationModalComponentProps> = ({
    showSecField,
    handleSecField,
    primaryLanguage,
    secondaryLanguage,
    closeModal,
    handleChange,
    handleSubmit,
    formData,
    priceListData,
    businessGroupData,
    openForm,
    enableEdit,
    errorMessages,
    isBtnEnabled
}) => {

    const priceListOptions = priceListData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));

    const businessGroupOptions = businessGroupData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));

    const countryOptions = COUNTRIES.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))

    const handleScroll = () => {
    };
    const handleSearch = () => {
    }

    const { t } = useTranslation();

    // const [isOpen, setIsOpen] = useState(false);

    return (
        <div>
            <SimpleModal
                visable={openForm}
                // title={enableEdit ? "updateStations" : "addStations"}
                isButtonVisable={true}
                isVisableBtn={isBtnEnabled}
                btnclosetext="Back"
                buttonText={enableEdit ? "update" : "submit"}
                attBtnNotshow={false}
                notVisableBackbtn={true}
                showPortList={handleSubmit} // Assuming this is the Submit button
                modalControle={closeModal}
                // attributeChangesMessage="Attribute changes message"
                // showPort={true}
                formSubmit={handleSubmit}
                content={
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3} style={{marginTop:"-1px"}}  >
                            <DynamicSelect
                                // defaultValue={enableEdit ? stationCategoryOptions.find((option: any) => option.value === formData?.stationCategoryId) : null}
                                defaultValue={enableEdit ? stationCategory.find((option: any) => option.value === formData?.stationCategoryId):null}
                                value={stationCategory.find((option: any) => option.value === formData?.stationCategoryId)}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                // fullWidth={false}
                                isSearchable={true}
                                name="stationCategoryId"
                                options={stationCategory}
                                onScroll={handleScroll}
                                onInputChange={handleSearch}
                                // isMulti
                                isRequired={true}
                                onChange={(e) => handleChange('stationCategoryId', e)}
                                error={errorMessages?.stationCategoryIdError}
                                label="stationCategory"
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="stationCode"
                                placeholder="Write branch code here"
                                handleChange={(e) => handleChange('stationCode', e.target.value)}
                                required={true}
                                error={errorMessages?.stationCode}
                                value={formData?.stationCode || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                label={`${!primaryLanguage ? t('primary') : ''} ${t('stationName')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                                search='language'
                                handleSecField={handleSecField}
                                placeholder="Write your name here"
                                handleChange={(e) => handleChange('name', e.target.value)}
                                required={true}
                                fullWidth
                                error={errorMessages?.name}
                                value={formData?.name || ''}
                            />
                            {showSecField &&
                                <Grid item xs={12} sm={12} md={12} lg={12} >
                                    <div style={{ position: 'relative' }}>
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: -8,
                                                left: 0,
                                                zIndex: 1,
                                                display: showSecField ? 'flex' : 'none',
                                                backgroundColor: "white",
                                                height: '5rem',
                                                width: '100%',
                                                justifyContent: 'center',
                                                borderRadius: '8px',
                                                boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                                            }}
                                        >
                                            <div className='w-full mx-2 mt-1'>
                                                <StyledField
                                                    label={`${!secondaryLanguage ? t('secondary') : ''} ${t('stationName')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                                                    placeholder="Write your secondaryTitle here"
                                                    handleChange={(e) => handleChange('secondaryName', e.target.value)}
                                                    required={false}
                                                    fullWidth
                                                    // error={errorMessages?.secondaryName}
                                                    value={formData?.secondaryName || ''}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                type="number"
                                fullWidth={true}
                                label="numberOfTerminal"
                                placeholder="Write number of terminal here"
                                handleChange={(e) => handleChange('numberOfTerminal', e.target.value)}
                                required={false}
                                // error={errorMessages?.numberOfTerminal}
                                value={formData?.numberOfTerminal || ''}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "-1px" }}  >
                            <DynamicSelect
                                // defaultValue={PlateNumberType[formData?.plateNumberType]}
                                defaultValue={enableEdit ? PlateNumberType.find((option: any) => option.value === formData?.plateNumberType) : null}
                                value={PlateNumberType.find((option: any) => option.value === formData?.plateNumberType)}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                // fullWidth={false}
                                isSearchable={true}
                                name="color"
                                options={PlateNumberType}
                                onScroll={handleScroll}
                                onInputChange={handleSearch}
                                // isMulti
                                isRequired={false}
                                onChange={(e) => handleChange('plateNumberType', e)}
                                // error={errorMessages?.plateNumberTypeError}
                                label="plateNumberType"
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                type="number"
                                fullWidth={true}
                                label="startBalanceDefaultAmount"
                                placeholder="Write start balance default amount here"
                                handleChange={(e) => handleChange('startBalanceDefaultAmount', e.target.value)}
                                required={false}
                                // error={errorMessages?.startBalanceDefaultAmount}
                                value={formData?.startBalanceDefaultAmount || ''}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "-1px" }}  >
                            <DynamicSelect
                                // defaultValue={enableEdit ? priceListOptions.find((option: any) => option.value === formData?.priceListId) : null}
                                defaultValue={enableEdit ? priceListOptions.find((option: any) => option.value === formData?.priceListId) : null}
                                value={priceListOptions.find((option: any) => option.value === formData?.priceListId)}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                // fullWidth={false}
                                isSearchable={true}
                                name="priceListId"
                                options={priceListOptions}
                                onScroll={handleScroll}
                                onInputChange={handleSearch}
                                // isMulti
                                isRequired={false}
                                onChange={(e) => handleChange('priceListId', e)}
                                // error={errorMessages?.priceListIdError}
                                label="priceList"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginTop: "-1px" }}  >
                            <DynamicSelect
                                // defaultValue={enableEdit ? businessGroupOptions.find((option: any) => option.value === formData?.businessGroupId) : null}
                                defaultValue={enableEdit ? businessGroupOptions.find((option: any) => option.value === formData?.businessGroupId) : null}
                                value={businessGroupOptions.find((option: any) => option.value === formData?.businessGroupId)}
                                isDisabled={false}
                                isLoading={false}
                                isClearable={true}
                                isRtl={false}
                                // fullWidth={false}
                                isSearchable={true}
                                name="businessGroupId"
                                options={businessGroupOptions}
                                onScroll={handleScroll}
                                onInputChange={handleSearch}
                                // isMulti
                                isRequired={false}
                                onChange={(e) => handleChange('businessGroupId', e)}
                                // error={errorMessages?.businessGroupIdError}
                                label="businessGroup"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="taxNumber"
                                placeholder="Write tax number here"
                                handleChange={(e) => handleChange('taxNumber', e.target.value)}
                                required={false}
                                // error={errorMessages?.taxNumber}
                                value={formData?.taxNumber || ''}
                            />
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="timeZone"
                                placeholder="Write time zone here"
                                handleChange={(e) => handleChange('timeZone', e.target.value)}
                                required={false}
                                // error={errorMessages?.timeZone}
                                value={formData?.timeZone || ''}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="phoneNumber1"
                                placeholder="Write phoneNumber1 here"
                                handleChange={(e) => handleChange('phoneNumber1', e.target.value)}
                                required={false}
                                error={errorMessages?.phoneNumber1}
                                value={formData?.phoneNumber1 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="phoneNumber2"
                                placeholder="Write phoneNumber2 here"
                                handleChange={(e) => handleChange('phoneNumber2', e.target.value)}
                                required={false}
                                error={errorMessages?.phoneNumber2}
                                value={formData?.phoneNumber2 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                type="email"
                                fullWidth={true}
                                label="email"
                                placeholder="Write email here"
                                handleChange={(e) => handleChange('email', e.target.value)}
                                required={false}
                                error={errorMessages?.email}
                                value={formData?.email || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="website"
                                placeholder="Write website here"
                                handleChange={(e) => handleChange('website', e.target.value)}
                                required={false}
                                // error={errorMessages?.website}
                                value={formData?.website || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="googleListingAddress"
                                placeholder="Write googleListingAddress here"
                                handleChange={(e) => handleChange('googleListingAddress', e.target.value)}
                                required={false}
                                // error={errorMessages?.googleListingAddress}
                                value={formData?.googleListingAddress || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledInputArea
                                fullWidth
                                label="description"
                                // placeholder="Write your Description here"
                                handleChange={(e) => handleChange('description', e.target.value)}
                                rowsNo={1}
                                required={false}
                                // error={errorMessages?.description}
                                value={formData?.description || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledInputArea
                                fullWidth={true}
                                label="extraNotes"
                                // placeholder="Write extra notes here"
                                rowsNo={1}
                                handleChange={(e) => handleChange('extraNotes', e.target.value)}
                                required={false}
                                // error={errorMessages?.extraNotes}
                                value={formData?.extraNotes || ''}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-center`} >
                            <CommonCheckbox
                                label='Check Tyre'
                                onChange={(e) => handleChange('checkTyre', e.target.checked)}
                                color="success"
                                required={false}
                                // error={errorMessages?.checkTier}
                                checked={formData?.checkTyre}
                            />
                        </Grid>
                        <Grid container spacing={2} className='my-[0px] mx-0'>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='px-4'>
                                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>Address</h1>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="postalCode"
                                    placeholder="Write postal code here"
                                    handleChange={(e) => handleChange('postalCode', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.postalCode}
                                    value={formData?.postalCode || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="buildingNumber"
                                    placeholder="Write building number here"
                                    handleChange={(e) => handleChange('buildingNumber', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.buildingNumber}
                                    value={formData?.buildingNumber || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="street"
                                    placeholder="Write street here"
                                    handleChange={(e) => handleChange('street', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.street}
                                    value={formData?.street || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="location"
                                    placeholder="Write location here"
                                    handleChange={(e) => handleChange('location', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.location}
                                    value={formData?.location || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="region"
                                    placeholder="Write region here"
                                    handleChange={(e) => handleChange('region', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.region}
                                    value={formData?.region || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="city"
                                    placeholder="Write city here"
                                    handleChange={(e) => handleChange('city', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.city}
                                    value={formData?.city || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <DynamicSelect
                                    defaultValue={enableEdit ? countryOptions.find((option: any) => option.value === formData?.country) : null}
                                    value={countryOptions.find((option: any) => option.value === formData?.country)}
                                    isDisabled={false}
                                    isLoading={false}
                                    isClearable={true}
                                    isRtl={false}
                                    // fullWidth={false}
                                    isSearchable={true}
                                    name="country"
                                    options={countryOptions}
                                    onScroll={handleScroll}
                                    onInputChange={handleSearch}
                                    // isMulti
                                    isRequired={false}
                                    onChange={(e) => handleChange('country', e)}
                                    // error={errorMessages?.country}
                                    label="country"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="address"
                                    placeholder="Write address here"
                                    handleChange={(e) => handleChange('address', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.address}
                                    value={formData?.address || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    type="number"
                                    fullWidth={true}
                                    label="latitude"
                                    placeholder="Write latitude here"
                                    handleChange={(e) => handleChange('latitude', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.latitude}
                                    value={formData?.latitude || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}  >
                                <StyledField
                                    type="number"
                                    fullWidth={true}
                                    label="longitude"
                                    placeholder="Write longitude here"
                                    handleChange={(e) => handleChange('longitude', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.longitude}
                                    value={formData?.longitude || ''}
                                />
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledInputArea
                                fullWidth={true}
                                label="secondaryDescription"
                                rowsNo={1}
                                // placeholder="Write your Secondary Description here"
                                handleChange={(e) => handleChange('secondaryDescription', e.target.value)}
                                required={false}
                                // error={errorMessages?.secondaryDescription}
                                value={formData?.secondaryDescription || ''}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="district"
                                placeholder="Write district here"
                                handleChange={(e) => handleChange('district', e.target.value)}
                                required={false}
                                // error={errorMessages?.district}
                                value={formData?.district || ''}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="landmark"
                                placeholder="Write landmark here"
                                handleChange={(e) => handleChange('landmark', e.target.value)}
                                required={false}
                                // error={errorMessages?.landmark}
                                value={formData?.landmark || ''}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                            <StyledField
                                fullWidth={true}
                                label="language"
                                placeholder="Write language here"
                                handleChange={(e) => handleChange('language', e.target.value)}
                                required={false}
                                // error={errorMessages?.language}
                                value={formData?.language || ''}
                            />
                        </Grid> */}
                        {/* <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`} >
                            <CommonCheckbox
                                label='applicableToAllBusinessGroup'
                                onChange={(e) => handleChange('applicableToAllBusinessGroup', e.target.checked)}
                                color="success"
                                required={false}
                                // error={errorMessages?.applicableToAllBusinessGroup}
                                checked={formData?.applicableToAllBusinessGroup}
                            />
                        </Grid> */}
                        <Grid container spacing={2} className='my-[0px] mx-0'>
                            <Grid item xs={12} sm={12} md={12} lg={12} className='px-4'>
                                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>Others</h1>
                            </Grid>
                            <Grid item xs={12} md={8} lg={6}>
                                <StyledInputArea
                                    fullWidth={true}
                                    label="Terms & Conditions"
                                    rowsNo={3}
                                    handleChange={(e) => handleChange('termAndCondition', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.termAndCondition}
                                    value={formData?.termAndCondition || ''}
                                />
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}  >
                                <StyledField
                                    fullWidth={true}
                                    label="Image Url"
                                    handleChange={(e) => handleChange('imgUrl', e.target.value)}
                                    required={false}
                                    // error={errorMessages?.imgUrl}
                                    value={formData?.imgUrl || ''}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                }
            />
        </div>
    );
};

export default StationModalComponent;