// MakeProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { MakeProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { ChangeStatusMakeResponse, DeleteMakeResponse, MakeRequest, MakeResponse } from './interface';
import { useCallback } from 'react';

type UseMakeServiceProps = {
  onSuccess: (data: MakeResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateMakeServiceProps = {
  onSuccess: (data: MakeResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteMakeServiceProps = {
  onSuccess: (data: DeleteMakeResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusMakeServiceProps = {
  onSuccess: (data: ChangeStatusMakeResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useMakeService = ({ onError, onSuccess }: UseMakeServiceProps) => {
  const {
    mutateAsync: createMake,
    isPending: isCreateMakeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-makes-request'],
    mutationFn: (req: MakeRequest) => MakeProfileService.createMake(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateMake = useCallback(
    async (makeData: MakeRequest) => {
      try {
        createMake(makeData);
      } catch (err) {
        console.error(err);
      }
    },
    [createMake]
  );

  return {
    onCreateMake,
    isCreateMakeLoading,
    data,
    error,
    isError,
  };
};

export const useGetMakeService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-makes-data'],
      queryFn: async ({ pageParam }) => {
        return MakeProfileService.getMake({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetMakeDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-makes-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return MakeProfileService.getMakeDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateMakeService = ({ id, onError, onSuccess }: UpdateMakeServiceProps) => {
  const {
    mutateAsync: updateMake,
    isPending: isUpdateMakeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-makes-request'],
    mutationFn: (req: MakeRequest) => MakeProfileService.updateMake(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateMake = useCallback(
    async (req: MakeRequest) => {
      try {
        updateMake(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateMake]
  );

  return {
    onUpdateMake,
    isUpdateMakeLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteMakeService = ({ id, onError, onSuccess }: DeleteMakeServiceProps) => {
  const {
    mutateAsync: deleteMake,
    isPending: isDeleteMakeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-make-request'],
    mutationFn: () => MakeProfileService.deleteMake(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteMake = useCallback(async () => {
    try {
      deleteMake();
    } catch (err) {
      console.error(err);
    }
  }, [deleteMake]);

  return {
    onDeleteMake,
    isDeleteMakeLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusMakeService = ({ active, id, onError, onSuccess }: ChangeStatusMakeServiceProps) => {
  const {
    mutateAsync: changeStatusMake,
    isPending: isChangeStatusMakeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-make-request'],
    mutationFn: (req: ChangeStatusRequest) => MakeProfileService.changeStatusMake(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusMake = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusMake(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusMake]
  );

  return {
    onChangeStatusMake,
    isChangeStatusMakeLoading,
    data,
    error,
    isError,
  };
};
