// DropdownMenu.tsx

import React from 'react';
import { Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

interface DropdownMenuProps {
  anchorEl: HTMLElement | null;
  menuItems?: { label: string; onClick: () => void }[];
  langItems?: { label: string; onClick: () => void }[];
  onClose: () => void;
}

const StyledMenu = styled(Menu)({
  '& .MuiMenu-paper': {
    backgroundColor: 'white',
    color:"black",
    borderRadius: '5px',
    marginTop: '20px',
  },
});

const StyledMenuItem = styled(MenuItem)({
  '&:hover': {
    backgroundColor: '#4C0055',
  },
});

const DropdownMenu: React.FC<DropdownMenuProps> = ({ anchorEl, menuItems,langItems, onClose }) => {
  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {menuItems?.map((menuItem, index) => (
        <StyledMenuItem className=' hover:text-white font-Saira font-[500]' key={index} onClick={() => {menuItem.onClick(); onClose();}}>
          {menuItem.label}
        </StyledMenuItem>
      ))}
      {langItems?.map((langItem, index) => (
        <StyledMenuItem className=' hover:text-white font-Saira font-[500]'  key={index} onClick={() => {langItem.onClick(); onClose();}}>
          {langItem.label}
        </StyledMenuItem>
      ))}
    </StyledMenu>
  );
};

export default DropdownMenu;
// Dropdown.tsx



// import React from 'react';
// import Popover from '@mui/material/Popover';
// import Typography from '@mui/material/Typography';

// interface DropdownProps {
//   anchorEl: HTMLElement | null;
//   onClose: () => void;
//   menuItems: { label: string; onClick: () => void }[];
// }

// const Dropdown: React.FC<DropdownProps> = ({ anchorEl, onClose, menuItems }) => (
//   <Popover
//     anchorEl={anchorEl}
//     open={Boolean(anchorEl)}
//     onClose={onClose}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'left',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'left',
//     }}
//     disableRestoreFocus
//   >
//     {menuItems.map((item, index) => (
//       <Typography key={index} sx={{ p: 1 }} onClick={() => { onClose(); item.onClick(); }}>
//         {item.label}
//       </Typography>
//     ))}
//   </Popover>
// );

// export default Dropdown;
