// InspectionFormProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { InspectionFormProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusInspectionFormResponse,
  DeleteInspectionFormResponse,
  InspectionFormRequest,
  InspectionFormResponse,
} from './interface';
import { useCallback } from 'react';

type UseInspectionFormServiceProps = {
  onSuccess: (data: InspectionFormResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateInspectionFormServiceProps = {
  onSuccess: (data: InspectionFormResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteInspectionFormServiceProps = {
  onSuccess: (data: DeleteInspectionFormResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusInspectionFormServiceProps = {
  onSuccess: (data: ChangeStatusInspectionFormResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useInspectionFormService = ({ onError, onSuccess }: UseInspectionFormServiceProps) => {
  const {
    mutateAsync: createInspectionForm,
    isPending: isCreateInspectionFormLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-inspectionforms-request'],
    mutationFn: (req: InspectionFormRequest) => InspectionFormProfileService.createInspectionForm(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateInspectionForm = useCallback(
    async (inspectionFormData: InspectionFormRequest) => {
      try {
        createInspectionForm(inspectionFormData);
      } catch (err) {
        console.error(err);
      }
    },
    [createInspectionForm]
  );

  return {
    onCreateInspectionForm,
    isCreateInspectionFormLoading,
    data,
    error,
    isError,
  };
};

export const useGetInspectionFormService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-data'],
      queryFn: async ({ pageParam }) => {
        return InspectionFormProfileService.getInspectionForm({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetInspectionFormDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return InspectionFormProfileService.getInspectionFormDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateInspectionFormService = ({ id, onError, onSuccess }: UpdateInspectionFormServiceProps) => {
  const {
    mutateAsync: updateInspectionForm,
    isPending: isUpdateInspectionFormLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-inspectionforms-request'],
    mutationFn: (req: InspectionFormRequest) => InspectionFormProfileService.updateInspectionForm(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateInspectionForm = useCallback(
    async (req: InspectionFormRequest) => {
      try {
        updateInspectionForm(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateInspectionForm]
  );

  return {
    onUpdateInspectionForm,
    isUpdateInspectionFormLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteInspectionFormService = ({ id, onError, onSuccess }: DeleteInspectionFormServiceProps) => {
  const {
    mutateAsync: deleteInspectionForm,
    isPending: isDeleteInspectionFormLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-inspectionform-request'],
    mutationFn: () => InspectionFormProfileService.deleteInspectionForm(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteInspectionForm = useCallback(async () => {
    try {
      deleteInspectionForm();
    } catch (err) {
      console.error(err);
    }
  }, [deleteInspectionForm]);

  return {
    onDeleteInspectionForm,
    isDeleteInspectionFormLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusInspectionFormService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusInspectionFormServiceProps) => {
  const {
    mutateAsync: changeStatusInspectionForm,
    isPending: isChangeStatusInspectionFormLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-inspectionform-request'],
    mutationFn: (req: ChangeStatusRequest) => InspectionFormProfileService.changeStatusInspectionForm(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusInspectionForm = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusInspectionForm(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusInspectionForm]
  );

  return {
    onChangeStatusInspectionForm,
    isChangeStatusInspectionFormLoading,
    data,
    error,
    isError,
  };
};
