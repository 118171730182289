import React, { useState, useMemo } from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { FormControl, ListItemIcon, Select } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import Loader from 'layout/Loader';
import StyledField from 'common/TextField/TexField1';
// import DynamicSelect from 'common/Select/Select';

interface Column {
  id: string;
  label: string;
  numeric: boolean;
}

interface PriceListServicesTableProps {
  columns: Column[];
  data: any[];
  showActions:boolean;
  enableSorting: boolean;
  showHeader: boolean;
  showSearch: boolean;
  showFilter: boolean;
  showCreateButton: boolean;
  tableData? :any;
  actions: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  headerComponent?:React.ReactNode,
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  pageNo:number;
  pageSize:number;
  rowClick?: boolean;
  showRowView?: (row: any) => void;
  assignUnassignSingleEntity?: (isChecked: boolean, row: any) => void;
  loadingSearch?: boolean;
  priceListFormData: any;
  // taxProceduresData: any;
  handleChangePriceList: (field: string, value: any) => void;
  priceListErrorMessages: any;
  handleBlurPriceList: (row: any) => void;
  assignPrivilege: boolean;
  unassignPrivilege: boolean;
}

const PriceListServicesTable: React.FC<PriceListServicesTableProps> = ({
  columns,
  data,
  showActions,
  enableSorting,
  showHeader,
  showSearch,
  actions,
  headerComponent,
  handleChangePage,
  handleChangeRows,
  tableData,
  pageSize,
  rowClick,
  showRowView,
  assignUnassignSingleEntity,
  loadingSearch,
  priceListFormData,
  handleChangePriceList,
  priceListErrorMessages,
  handleBlurPriceList,
  assignPrivilege,
  unassignPrivilege,
}) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<string>('');
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(pageSize);
  const [selectedRow, setSelectedRow] = useState({});
  const [rowNo, setRowNo] = useState<number>(-1);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const {t} = useTranslation();
  // const taxProcedureOptions = taxProceduresData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));

  const handleClickmenu = (event: React.MouseEvent<HTMLElement>, row: any) => { 
    setSelectedRow(row)
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, index: number, row: any) => {
    const target = event.target as HTMLInputElement;
    const selectedIndex = selected.indexOf(index);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, index);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    assignUnassignSingleEntity?.(target.checked, row);
  };
  
 const handleChangePage1 = (action:string) => {
    if (tableData) {
      if (action === "first") {
        if (tableData?.pageable?.pageNumber > 0) {
         handleChangePage(0);
        }
      } else if (action === "prev") {
        if (tableData.pageable && tableData?.pageable?.pageNumber > 0) {
          handleChangePage(tableData?.pageable?.pageNumber - 1);
        }
      } else if (action === "next") {
        if (!tableData?.last) {
          handleChangePage(tableData?.pageable?.pageNumber + 1);
        }
      } else if (action === "last") {
        if (!tableData?.last) {
          handleChangePage(tableData?.totalPages-1);
        }
      }
    }
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    handleChangeRows(parseInt(event.target.value))
    setPage(0);
  };


  const isSelected = (index: number) => selected.indexOf(index) !== -1;
  // const emptyRows = Math.max(0, rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage));

  const stableSort = (array: any[], comparator: (a: any, b: any) => number) => {
    const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getComparator = (order: 'asc' | 'desc', orderBy: string) => {
    return order === 'desc'
      ? (a: any, b: any) => descendingComparator(a, b, orderBy)
      : (a: any, b: any) => -descendingComparator(a, b, orderBy);
  };

  const descendingComparator = (a: any, b: any, orderBy: string) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const visibleRows = useMemo(
    () => {
      if (enableSorting) {
        return stableSort(data, getComparator(order, orderBy)).slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage,
        );
      } else {
        return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
      }
    },
    [data, enableSorting, order, orderBy, page, rowsPerPage],
  );
  const selectRowNo = (rowIndex: number) => {
    setRowNo(rowIndex);
  }

  
  return (
    <Box className='w-[100%] '>
      <Paper className='w-[100%] mb-[2]  bg-table-bg-color !important ' sx={{ boxShadow: 'none' ,}}>

        {showHeader && showSearch ? 
        <div 
          style={{minHeight:"56px", display:"flex", alignItems:"center"}}
        >{headerComponent}</div> :null }
        {loadingSearch ? <Loader /> :
          <>
            <TableContainer  sx={{borderRadius:"10px", marginTop:"10px"}}>
              <Table className='min-w-[750]' size='small'
                sx={{
                  borderCollapse: 'separate',
                  borderSpacing: '0 0.5rem',
                  '& th, & td': {
                    borderTop: 'none',
                    borderBottom: 'none',
                  },
                }}
              >
                <TableHead
                sx={{ backgroundColor: '#fff' }}
                >
                  <TableRow>
                    <TableCell padding="checkbox" className='border-[0] rounded-l-[8px]'></TableCell>
                    {showActions && columns.map((column) => (
                      <TableCell 
                        key={column.id}
                        align={'left'}
                        padding={column.id === 'actions' ? 'normal' : 'normal'}
                        sortDirection={orderBy === column.id ? order : false}
                        sx={{ '&: last-child': { borderRadius: '0 8px 8px 0' } }}
                        className={`font-Saira font-[400] text-[14px] border-[0]`}
                      >
                        {column.id !== 'actions' && (
                          <TableSortLabel
                          className='font-Saira font-[600] text-[14px] '
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={() => handleRequestSort(column.id)}
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        )}
                        {column.id === 'actions' && (
                          <TableSortLabel
                          className='font-Saira font-[600] text-[14px] text-black'
                          >
                            {t(column.label)}
                          </TableSortLabel>
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                {visibleRows.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      onChange={() => {selectRowNo(index)}}
                      role="checkbox"
                      aria-checked={isSelected(page * rowsPerPage + index)}
                      tabIndex={-1}
                      key={index}
                      selected={isSelected(page * rowsPerPage + index)}
                      sx={{ backgroundColor: '#fff', cursor: 'pointer'  }}
                    >
                        <TableCell padding="checkbox" className='rounded-l-[8px] border-[0]'>
                          <Checkbox
                            onClick={(event) => {
                              if (!row.linked && (index === rowNo || rowNo === -1)) {
                                handleClick(event, page * rowsPerPage + index, row);
                              } else if(row.linked) {
                                handleClick(event, page * rowsPerPage + index, row);
                              }
                            }}
                            className='text-primary-color'
                            checked={row.linked ? true : false}
                            inputProps={{
                              'aria-labelledby': `enhanced-table-checkbox-${index}`,
                            }}
                            disabled={(assignPrivilege && !row.linked) ? false 
                              : (unassignPrivilege && row.linked) ? false
                              : true
                            }
                            sx={{
                              opacity: 1,
                              '&.Mui-disabled': {
                                opacity: 0.3
                              } 
                            }}
                          />
                        </TableCell>
                      
                      {showActions && columns.map((column) => (
                        <TableCell
                          onClick={() => {(rowClick && column.id !== 'active' && column.id !== 'actions') ? showRowView?.(row) : ''}}
                          sx={{ '&: last-child': { borderRadius: '0 8px 8px 0' } }}
                          className={`font-Saira font-[400] text-[14px] border-[0]`}
                          key={column.id}
                          component={column.id === 'actions' ? 'div' : undefined}
                          padding={column.id === 'actions' ? 'none' : 'normal'}
                          align={'left'}
                        >
                          {column.id === 'actions' ? (
                            <Box >
                              <IconButton
                              aria-label="more"
                              id="long-button"
                              aria-controls={open ? 'long-menu' : undefined}
                              aria-expanded={open ? 'true' : undefined}
                              aria-haspopup="true"
                              onClick={(event) => handleClickmenu(event, row)}
                              >
                              <MoreHorizIcon/>
                            </IconButton>
                            <Menu
                            slotProps={{
                              root:{
                                sx:{
                                  zIndex:0
                                }
                              }
                            }}
                                id="long-menu"
                                disableScrollLock
                                MenuListProps={{
                                  'aria-labelledby': 'long-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                  elevation: 0,
                                  sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 2px rgba(0,0,0,0.05))',
                                    // mt: 1.5,
                                    '& .MuiAvatar-root': {
                                      width: 32,
                                      height: 32,
                                      // ml: -0.5,
                                      // mr: 1,
                                    },
                                    '&::before': {
                                      content: '""',
                                      display: 'block',
                                      position: 'absolute',
                                      top: 0,
                                      left: 14,
                                      width: 10,
                                      height: 10,
                                      bgcolor: 'background.paper',
                                      transform: 'translateY(-50%) rotate(45deg)',
                                      zIndex: 0,
                                    },
                                  },
                                }}
                              >
                                {actions.map((option) => (
                                  <div onClick={handleClose}>
                                  <MenuItem className='font-Saira font-[400] text-[14]' key={option.label}  onClick={()=>option.onClick(selectedRow)}>
                                      <ListItemIcon>
                                      {option.icon}
                                    </ListItemIcon>
                                    {t(option.label)}
                                  </MenuItem>
                                  </div>
                                ))}
                              </Menu>
                            </Box>
                          ) : (
                            column.id === 'price' ?
                              <StyledField
                                type='number'
                                placeholder="Price"
                                displayPlaceholder={true}
                                handleChange={(e) => handleChangePriceList('price', e.target.value)}
                                handleBlur={() => handleBlurPriceList(row)}
                                required={true}
                                customWidth={true}
                                error={rowNo === index ? priceListErrorMessages?.priceError : null}
                                value={row?.plPrice ? (priceListFormData.price && rowNo === index ? priceListFormData.price : row.plPrice) : (rowNo === index ? priceListFormData.price : null)}
                              />
                            : row[column.id]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  )})}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{height: 33  * emptyRows }}>
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={columns.length + (showCheckbox ? 1 : 0)} />
                    </TableRow>
                  )} */}
                </TableBody>
              </Table>
            </TableContainer>
            
            <div
                style={{
                  padding: "0 20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span  className="hidden-xs mr-2 font-Saira font-[400] text-[14px]">
                {t("rowsPerPage")}
                </span>
                <span style={{ marginRight: 10, minWidth: 60 }}>
                    <FormControl className='m-1 min-w-[70]'  size="small">
                    <Select
                    className='font-Saira font-[500] text-[14px] h-[25px] mt-[2px]'
                        value={tableData && tableData?.pageable?.pageSize || pageSize}
                        // value={rowsPerPageOptions[2]}
                        onChange={(event) => handleChangeRowsPerPage(event as React.ChangeEvent<HTMLInputElement>)}
                        // style={{height:"30px"}}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                    {rowsPerPageOptions.map((option) => (
                        <MenuItem className='font-Saira font-[500] text-[14px]' key={option} value={option}>
                            {option}
                        </MenuItem>
                        ))}
                    </Select>
                    </FormControl>
                </span>
                <span style={{ flex: 1 }}></span>

                <span className='mr-2 whitespace-nowrap max-w-[300] font-Saira font-[400] text-[14px]'>
                {t("page")}
                  <strong className='font-Saira ml-2 font-[700] text-[15px]' >{tableData && tableData.pageable ? tableData.pageable.pageNumber + 1 : 1}</strong>{" "}
                  <span className="visible-responsive">
                  </span>
                </span>
                <span  className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
                
                {t("outOf")}

                  <strong className='ml-2 font-Saira font-[700] text-[15px]'>{tableData && tableData.totalPages ? tableData.totalPages : 1}</strong>
                </span>
                <span className="hidden-responsive font-Saira mr-2 font-[400] text-[14px]">
                {t("totalRecords")}
                  {/* )}{" "}  */}
                  <strong className='ml-2 font-Saira font-[700] text-[15px]'>{tableData && tableData.totalElements ? tableData.totalElements : 0}</strong>
                </span>
                <span className='mr-2'>
                  <FirstPageIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("first")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
                <span className='mr-2'>
                  <NavigateBeforeIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("prev")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.first
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
                <span className='mr-2'>
                  <NavigateNextIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("next")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
                <span className='mr-2'>
                  <LastPageIcon
                    fontSize="small"
                    onClick={() => handleChangePage1("last")}
                    style={{ 
                      color: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "#1F2020" : "#B8C0C6",
                      cursor: (
                        tableData?.content?.length && tableData?.content?.length !== 0 && !tableData.last
                      ) ? "pointer" : "text",
                    }}
                  />
                </span>
            </div>
          </>
        }
      </Paper>
    </Box>
  );
};

export default PriceListServicesTable;
const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 500];
