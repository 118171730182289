import StyledField from 'common/TextField/TexField1';
import { Button, Grid } from '@mui/material';
import Filter from "common/Select/SelectMultiFilter";
import { useTranslation } from 'react-i18next';

interface ClientGroupClientSearchComponentProps {
  handleChangeAssignFilter: (event: any) => void;
  handleChangeSearchAssign: (field: string, value: any) => void;
  handleSubmitSearchAssign: () => void;
  searchAssignFormData: any;
  searchAssignErrorMessages:{ [key: string]: string };
  filterAssign: string[];
  clearSearchAssign: () => void;
  searchAssign: any;
}

const ClientGroupClientSearchHeader = ({
  handleChangeAssignFilter,
  handleChangeSearchAssign,
  handleSubmitSearchAssign,
  searchAssignFormData,
  searchAssignErrorMessages,
  filterAssign,
  clearSearchAssign,
  searchAssign,
} : ClientGroupClientSearchComponentProps) => {
  const { t } = useTranslation();
  const filterOptions = ["name", "userName"];
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSubmitSearchAssign();
    }
  };

  return (
    <Grid container spacing={1} className='mt-1'>
    <Grid item xs={12} sm={6} md={3} lg={2}>
      <Filter 
        label="Filter By"
        filterOptions={filterOptions}
        handleChangeFilter={handleChangeAssignFilter}
        filter={filterAssign}
      />
    </Grid>
    {filterAssign.map((fieldName) => (
      <>
        <Grid item key={fieldName} xs={12} sm={6} md={3} lg={2}>
          <StyledField
            fullWidth={true}
            placeholder={fieldName === 'userName' ? t('email') : t(fieldName)}
            displayPlaceholder={true}
            handleChange={(e) => handleChangeSearchAssign(fieldName, e.target.value)}
            required={true}
            error={searchAssignErrorMessages[fieldName]}
            value={searchAssignFormData?.[fieldName] || ''}
            handleKeyDown={handleKeyPress}
          />
        </Grid>
      </>
    ))}
    <Grid item xs={12} sm={6} md={2} className="text-left flex items-center gap-2 mt-0 pt-0">
      <Button 
        variant="contained"
        disabled={filterAssign.length ? false : true}
        onClick={handleSubmitSearchAssign}
        sx={{ boxShadow: 'none' }}
        className={`text-white text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] ${filterAssign.length > 0 ? 'bg-primary-color' : 'bg-disabled-color'} h-[35px] px-8 py-1`}
      >{t('submit')}</Button>
      {filterAssign.length && Object.keys(searchAssign).length || filterAssign.length > 1 ?
        <Button 
        variant="contained"
        onClick={clearSearchAssign}
        sx={{ boxShadow: 'none' }}
        className={`text-primary-color text-ellipsis overflow-hidden font-Saira font-[600] text-[14px] bg-[#d8d1e0] h-[35px] px-8 py-1`}
      >{t('clear')}</Button>: null}
    </Grid>
  </Grid>
  )
}

export default ClientGroupClientSearchHeader