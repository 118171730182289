// ModelProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ModelProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignServiceRequest,
  ChangeStatusModelResponse,
  DeleteModelResponse,
  ModelRequest,
  ModelResponse,
} from './interface';
import { useCallback } from 'react';

type UseModelServiceProps = {
  onSuccess: (data: ModelResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateModelServiceProps = {
  onSuccess: (data: ModelResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteModelServiceProps = {
  onSuccess: (data: DeleteModelResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusModelServiceProps = {
  onSuccess: (data: ChangeStatusModelResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useModelService = ({ onError, onSuccess }: UseModelServiceProps) => {
  const {
    mutateAsync: createModel,
    isPending: isCreateModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-models-request'],
    mutationFn: (req: ModelRequest) => ModelProfileService.createModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateModel = useCallback(
    async (modelData: ModelRequest) => {
      try {
        createModel(modelData);
      } catch (err) {
        console.error(err);
      }
    },
    [createModel]
  );

  return {
    onCreateModel,
    isCreateModelLoading,
    data,
    error,
    isError,
  };
};

export const useGetModelService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-models-data'],
      queryFn: async ({ pageParam }) => {
        return ModelProfileService.getModel({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateModelService = ({ id, onError, onSuccess }: UpdateModelServiceProps) => {
  const {
    mutateAsync: updateModel,
    isPending: isUpdateModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-models-request'],
    mutationFn: (req: ModelRequest) => ModelProfileService.updateModel(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateModel = useCallback(
    async (req: ModelRequest) => {
      try {
        updateModel(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateModel]
  );

  return {
    onUpdateModel,
    isUpdateModelLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteModelService = ({ id, onError, onSuccess }: DeleteModelServiceProps) => {
  const {
    mutateAsync: deleteModel,
    isPending: isDeleteModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-model-request'],
    mutationFn: () => ModelProfileService.deleteModel(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteModel = useCallback(async () => {
    try {
      deleteModel();
    } catch (err) {
      console.error(err);
    }
  }, [deleteModel]);

  return {
    onDeleteModel,
    isDeleteModelLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusModelService = ({ active, id, onError, onSuccess }: ChangeStatusModelServiceProps) => {
  const {
    mutateAsync: changeStatusModel,
    isPending: isChangeStatusModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-model-request'],
    mutationFn: (req: ChangeStatusRequest) => ModelProfileService.changeStatusModel(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusModel = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusModel(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusModel]
  );

  return {
    onChangeStatusModel,
    isChangeStatusModelLoading,
    data,
    error,
    isError,
  };
};

export const useGetModelServiceService = (initialParams: PageSize, modelId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-models-services-data'],
      queryFn: async ({ pageParam }) => {
        return ModelProfileService.getModelService({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          modelId: modelId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignServiceService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignService,
    isPending: isAssignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-services-request'],
    mutationFn: (req: AssignUnassignServiceRequest) => ModelProfileService.assignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignService = useCallback(
    async (assignServiceData: AssignUnassignServiceRequest) => {
      try {
        assignService(assignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignService]
  );

  return {
    onAssignService,
    isAssignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignServiceService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignService,
    isPending: isUnassignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-services-request'],
    mutationFn: (req: AssignUnassignServiceRequest) => ModelProfileService.unassignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignService = useCallback(
    async (unassignServiceData: AssignUnassignServiceRequest) => {
      try {
        unassignService(unassignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignService]
  );

  return {
    onUnassignService,
    isUnassignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllServiceService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllServices,
    isPending: isAssignAllServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-model-services-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ModelProfileService.assignAllServices(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllServices = useCallback(
    async (assignAllServiceData: AssignUnassignAllRequest) => {
      try {
        assignAllServices(assignAllServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllServices]
  );

  return {
    onAssignAllServices,
    isAssignAllServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllServiceService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllServices,
    isPending: isUnassignAllServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-services-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ModelProfileService.unassignAllServices(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllServices = useCallback(
    async (unassignAllServiceData: AssignUnassignAllRequest) => {
      try {
        unassignAllServices(unassignAllServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllServices]
  );

  return {
    onUnassignAllServices,
    isUnassignAllServiceLoading,
    data,
    error,
    isError,
  };
};
