import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import StoreComponent from 'components/Store';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useChangeStatusStoreService, useStoreService, useDeleteStoreService, useGetStoreService, useUpdateStoreService } from 'subModule/src/services/store/useStore';
import { useGetStoreItemService, useAssignItemService, useUnassignItemService, useAssignAllItemService, useUnassignAllItemService } from 'subModule/src/services/store/useStore';
import { StoreRequest } from 'subModule/src/services/store/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
// import { useGetStationDropdownService } from '../../subModule/src/services/station/useStation';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { SelectChangeEvent } from '@mui/material';

interface FormData {
  title: string,
  secondaryTitle: string,
  storeNumber: string,
  // stationId: number,
  // stationIdError?: string,
  id: number,
  active: boolean
}
interface SearchAssignFormData {
  [key: string]: string;
}

const requiredFields = ['title', 'storeNumber'];

export default function Store() {
  const [showSecField, setShowSecField] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [rowView, setRowView] = useState(false);
  const [storeId, setStoreId] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [search, setSearch] = useState('');
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
  // const [data] = useState(rows);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    secondaryTitle: '',
    id: 0,
    storeNumber: '',
    // stationId: 0,
    active: false
  });
  const [searchAssignFormData, setSearchAssignFormData] = useState<SearchAssignFormData>({
    title: '',
    itemCode: '',
  });
  const [filterAssign, setFilterAssign] = useState<string[]>(['title']);
  const [searchAssignErrorMessages, setSearchAssignErrorMessages] = useState<Partial<SearchAssignFormData>>({});
  const [searchMultiAssign, setSearchMultiAssign] = useState<any>({});

  const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetStoreService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetStoreService({ pageNumber: pageNo, size: pageSize });

  // const { data: stationsData, isLoading: stationsIsLoading, error: stationsError, refetch: stationsRefetch } = useGetStationDropdownService({ pageNumber: pageNo, size: pageSize });

  const { data: itemAssignData, isLoading: itemAssignIsLoading, error: itemAssignError, refetch: itemAssignRefetch } = useGetStoreItemService({ pageNumber: pageNo, size: pageSize, ...searchMultiAssign }, storeId);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;

  let allItemsAssigned: unknown = itemAssignData?.pages[0].data?.content.map(item => item.linked).every(linked => linked === true);

  useEffect(() => {
    if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
    if(tab === 0 && enableEdit) {
      setIsBtnEnabled(false);
    }
    if (!itemAssignIsLoading && !itemAssignError && storeId && storeId !== 0 && tab === 1) {
      const loadData = async() => {
        setLoadingSearchAssign(true);
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
    }
  }, [pageNo, pageSize, refetch, isLoading, error,
    //  stationsIsLoading, stationsError,
      storeId, tab, tableData, openForm]);

  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchTerm, loadingSearch]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if (searchMultiAssign && loadingSearchAssign && tab === 1) {
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchMultiAssign]);

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1)
    setPageSize(pageSize)

  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize)
    setPageNo(1)
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    if (rowView) {
      setRowView(false);
      setStoreId(0);
      setPageNo(1);
      setTab(0);
      setSearchMultiAssign({});
    } else {
      setIsBtnEnabled(true);
      setOpenForm(!openForm);
      openModalSettings();
      setSearch('');
      setShowSecField(false)
      setSearchTerm('');
    }
  };
  const openModalSettings = () => {
    setEnableEdit(false);
    setErrorMessages({})
    setFormData({
      title: '',
      secondaryTitle: '',
      id: 0,
      storeNumber: '',
      // stationId: 0,
      active: false
    });
    // if (!openForm && !enableEdit) {
    //   stationsRefetch();
    // }
  }

  const handleSecField = () => {
    setShowSecField((prevValue) => !prevValue);
  }


  const handleChange = (field: string, value: any) => {
    const val = field === "stationId" ? (value ? parseInt(value.value) || 1 : 0) : value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.title?.trim()) {
      errors.title = 'Title is required.';
    }
    if (!data.storeNumber?.trim()) {
      errors.storeNumber = 'Store number is required.';
    }
    // if (!data.stationId) {
    //   errors.stationIdError = 'Station is required.';
    // }

    return errors;
  };

  const { onCreateStore } = useStoreService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('store') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('store')} />, { variant: 'error' });
      }
    },
    onError: (err) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateStore } = useUpdateStoreService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (!rowView) {
          setEnableEdit(false);
          setOpenForm(false);
          refetch();
        }
        if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Store') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('store')} />, { variant: 'error' });
      }
    },
    onError: (err) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
          setLoading(true);
      const storeData: StoreRequest = {
        ...formData,
        title: formData.title,
        secondaryTitle: formData.secondaryTitle,
        storeNumber: formData.storeNumber,
        // stationId: formData.stationId,
      };

      if (enableEdit) {
        onUpdateStore(
          storeData
        );
      } else {
        onCreateStore(storeData);
      }
    }
  };

  const onEdit = (row:any) => {
    setEnableEdit(true);
    setIsBtnEnabled(false);
    setFormData(row);
    setPrevFormData(row);
    setOpenForm(true);
    setSearch('');
    setSearchTerm('');
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteStore } = useDeleteStoreService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('Store') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false)
    setLoading(true);
    onDeleteStore()
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setSearch('');
    setSearchTerm('');
    if(searchTerm) { handleSearch(''); }
  }
  const handleSearch = (search: string) => {
    if(!rowView) {
      setLoadingSearch(true);
      setSearchTerm(search);
      setPageNo(1);
      setPageSize(pageSize);
    } else {
      setLoadingSearchAssign(true);
      setSearchMultiAssign(search);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };

  const handleSearchClick = () => {
    if(!rowView) {
      const trimmedSearch = search.trim();
      if (trimmedSearch !== '') {
        handleSearch(trimmedSearch);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!rowView) {
      setSearch(event.target.value.trim());
      if (!event.target.value.trim()) handleSearch('');
    }
  };
  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setLoading(true)
    setStatusModal(false);
    onChangeStatusStore({});
  }
  const { onChangeStatusStore } = useChangeStatusStoreService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('store') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err: any) => {
      console.error("===", err)
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = (row: any) => {
    setEnableEdit(true);
    setOpenForm(false);
    setFormData(row);
    setPrevFormData(row);
    setErrorMessages({});
    // stationsRefetch();
    setRowView(true);
    setStoreId(row.id);
    setSelectedRow(row);
    setSearch('');
    setSearchTerm('');
    clearSearchAssign();
  }
  const handleChangeTabs = (tabId: number) => {
    setSearchMultiAssign({});
    if (tabId === 0) {
      setTab(0);
      setEnableEdit(true);
      setOpenForm(false);
      setFormData(selectedRow);
      setErrorMessages({});
      // stationsRefetch();
      setIsBtnEnabled(false);
    } else if (tabId === 1) {
      setTab(1);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
      clearSearchAssign();
    }
  }
  const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
    setLoadingSearchAssign(true);
    if (isChecked) {
      if (tab === 1) {
        onAssignItem({ storeId: storeId, itemId: row.id });
      }
    } else {
      if (tab === 1) {
        onUnassignItem({ storeId: storeId, itemId: row.id });
      }
    }
  }
  const assignUnassignAllEntities = (isChecked: boolean) => {
    setLoadingSearchAssign(true);
    if (isChecked) {
      if (tab === 1) {
        onAssignAllItems({ storeId: storeId });
      }
    } else {
      if (tab === 1) {
        onUnassignAllItems({ storeId: storeId });
      }
    }
  }
  const { onAssignItem } = useAssignItemService({
    onSuccess: async(msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        if (itemAssignData?.pages[0].data.content.length === itemAssignData?.pages[0].data.content.filter(item => item.linked).length) {
          allItemsAssigned = true;
        }
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('item') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignItem } = useUnassignItemService({
    onSuccess: async(data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if (itemAssignData?.pages[0].data.content.length !== itemAssignData?.pages[0].data.content.filter(item => item.linked).length) {
          allItemsAssigned = false;
        }
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('item') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllItems } = useAssignAllItemService({
    onSuccess: async(msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        allItemsAssigned = true;
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('items') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })
  const { onUnassignAllItems } = useUnassignAllItemService({
    onSuccess: async(data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        allItemsAssigned = false;
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('items') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await itemAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const clearSearchAssign = () => {
    setFilterAssign(['title']);
    setSearchAssignFormData({
      title: '',
      itemCode: '',
    });
    setSearchAssignErrorMessages({});
    if(Object.keys(searchMultiAssign).length) {
      setLoadingSearchAssign(true);
      setSearchMultiAssign({});
    }
  }
  const handleChangeAssignFilter = (event: SelectChangeEvent<typeof filterAssign>) => {
    const { target: { value }, } = event;
    setFilterAssign(typeof value === 'string' ? value.split(',') : value,);
    setSearchAssignFormData(prevFormData => 
      Object.fromEntries(Object.keys(prevFormData).map(key => 
        [key, value.includes(key) ? prevFormData[key] : '']
      ))
    );
    if(!value.length && Object.keys(searchMultiAssign).length) {
      clearSearchAssign();
    }
  };
  const handleChangeSearchAssign = (field: string, value: any) => {
    const val = value;

    setSearchAssignFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (filterAssign.includes(field) && !val) {
      setSearchAssignErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setSearchAssignErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const validateSearchAssignFormData = (data: SearchAssignFormData) => {
    const errors: Partial<SearchAssignFormData> = {};

    filterAssign.forEach(fieldName => {
      if (!data[fieldName]?.trim()) { 
        errors[fieldName] = 'Required';
      }
    });

    return errors;
  };
  const handleSubmitSearchAssign = () => {
    const errors = validateSearchAssignFormData(searchAssignFormData);
    setSearchAssignErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoadingSearchAssign(true);
      const filteredSearch: Record<string, string> = {};

      Object.entries(searchAssignFormData).forEach(([key, value]) => {
          if (value) {
            filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
          }
      });
      setSearchMultiAssign(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_STORE_UPDATE') 
      ? (checkPrivileges('ROLE_STORE_ARCHIVE') 
          ? actions 
          : actions.filter(action => action.label !== 'delete')) 
      : (checkPrivileges('ROLE_STORE_ARCHIVE') 
          ? actions.filter(action => action.label !== 'edit') 
          : []);
  }

  return (
    <Box>
      <StoreComponent
        openModal={openModal}
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        closeModal={openModal}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        rowView={rowView}
        handleChangeTabs={handleChangeTabs}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allItemsAssigned={allItemsAssigned}
        searchAssignFormData={searchAssignFormData}
        handleChangeAssignFilter={handleChangeAssignFilter}
        handleChangeSearchAssign={handleChangeSearchAssign}
        handleSubmitSearchAssign={handleSubmitSearchAssign}
        searchAssignErrorMessages={searchAssignErrorMessages}
        clearSearchAssign={clearSearchAssign}
        clearSearch={clearSearch}
        columns={checkPrivileges("ROLE_STORE_ACTIVE") ? [
          { id: 'title', label: 'Store Name', numeric: false },
          { id: 'storeNumber', label: 'storeNumber', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'title', label: 'Store Name', numeric: false },
          { id: 'storeNumber', label: 'storeNumber', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        // stationsData={stationsData?.pages[0] || {}}
        itemAssignData={itemAssignData?.pages[0] || {}}
        // tableDataMake={tableDataMake?.pages[0] || {}}
        {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText, search, isBtnEnabled, loading, loadingSearch, loadingSearchAssign, filterAssign, searchMultiAssign, tab }}
        errorMessages={errorMessages}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}