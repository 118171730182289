// import React from 'react';
// import Box from '@mui/material/Box';
import LoginModal from './LoginModal';

interface LoginProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  formData: any;
  openForm: boolean;
  isVisableAddBtn:boolean;
  errorMessages: any,
}

const LoginComponent: React.FC<LoginProps> = ({ 
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  handleKeyDown,
  isVisableAddBtn,
  errorMessages
}) => {

      
  return (
    <>
         <LoginModal
         isVisableAddBtn={isVisableAddBtn}
         openModal={openModal}
         closeModal={closeModal}
         handleChange={handleChange}
         handleSubmit={handleSubmit}
         handleKeyDown={handleKeyDown}
         formData={formData}
         openForm={openForm}
         errorMessages={errorMessages}
       />
    </>
  );
};

export default LoginComponent;
