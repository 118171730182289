import { Routes, Route, Navigate } from 'react-router-dom';
import Users from 'pages/Users/index';
import Roles from 'pages/Roles/Index';
import UserGroup from 'pages/UserGroup';
import Business from 'pages/Business';
import Client from 'pages/Client/Index';
import Make from 'pages/Make';
import Model from 'pages/Model';
import ModelEngine from 'pages/ModelEngine';
import VehicleType from 'pages/VehicleType';
import Vehicle from 'pages/Vehicle';
import Station from 'pages/Station';
import BankAccount from 'pages/BankAccount';
import BankDesposit from 'pages/BankDeposit';
import Items from 'pages/Items/Index';
import Packages from 'pages/Packages/Index';
import ItemCategory from 'pages/ItemCatagories/Index';
import Bay from 'pages/Bay';
import ClientGroup from 'pages/ClientGroup';
import ItemGroup from 'pages/ItemGroup';
import ServiceGroup from 'pages/ServiceGroup';
import InspectionForm from 'pages/InspectionForm';
// import InspectionFormItem from 'pages/InspectionFormItem';
import PriceList from 'pages/PriceList';
import Store from 'pages/Store';
import Service from 'pages/Service/Index';
import BusinessGroup from 'pages/BusinessGroup/Index';
import Units from 'pages/Units/Index';
import Warehouse from 'pages/Warehouse';
import Vendor from 'pages/Vendor';
import StationGroup from 'pages/StationGroup/Index';
import PackageGroup from 'pages/PackageGroup';
import Manufacturer from 'pages/Manufacturer/Index';
import TaxProcedure from 'pages/TaxProcedure/Index';
import AccountSettings from 'pages/AccountSettings';
import Login from 'pages/Login/Index';
import JobCard from 'pages/JobCard/Index';
import WorkOrder from 'pages/WorkOrder/index';
import Invoice from 'pages/Invoice/Index';
import Error404 from 'pages/404/Index';
import Payment from 'pages/Payment/Index';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import Statuses from 'pages/Statuses/Index';
import VehicleStatus from 'pages/VehicleStatus/Index';
import PaymentMode from 'pages/PaymentMode/Index';

const AppRoutes = () => {

  return (
    <Routes>
      <Route path="*" element={<Error404/>} />
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<Login />} />
      <Route path="/users" element={checkPrivileges("ROLE_USER_VIEW") && <Users />} />
      <Route path="/business" element={checkPrivileges("ROLE_BUSINESS_VIEW") && <Business/>} />
      <Route path="/roles" element={checkPrivileges("ROLE_ROLE_VIEW") && <Roles />} />
      <Route path="/user-groups" element={checkPrivileges("ROLE_USERGROUP_VIEW") && <UserGroup/>} />
      <Route path="/client" element={checkPrivileges("ROLE_CLIENT_VIEW") && <Client />} />
      <Route path="/make" element={checkPrivileges("ROLE_MAKE_VIEW") && <Make />} />
      <Route path="/model" element={checkPrivileges("ROLE_MODEL_VIEW") && <Model />} />
      <Route path="/model-engine" element={checkPrivileges("ROLE_MODELENGINE_VIEW") && <ModelEngine />} />
      <Route path="/vehicle-type" element={checkPrivileges("ROLE_VEHICLETYPE_VIEW") && <VehicleType/>} />
      <Route path="/vehicle" element={checkPrivileges("ROLE_VEHICLE_VIEW") && <Vehicle/>} />
      <Route path="/station" element={checkPrivileges("ROLE_STATION_VIEW") && <Station/>} />
      <Route path="/bank-account" element={checkPrivileges("ROLE_BANKACCOUNT_VIEW") && <BankAccount/>} />
      <Route path="/bank-desposit" element={checkPrivileges("ROLE_BANKDEPOSIT_VIEW") && <BankDesposit/>} />
      <Route path="/parts" element={checkPrivileges("ROLE_ITEM_VIEW") && <Items/>} />
      <Route path="/packages" element={checkPrivileges("ROLE_PACKAGE_VIEW") && <Packages/>} />
      <Route path="/part-category" element={checkPrivileges("ROLE_ITEMCATEGORY_VIEW") && <ItemCategory/>} />
      <Route path="/bay" element={checkPrivileges("ROLE_BAY_VIEW") && <Bay/>} />
      <Route path="/client-group" element={checkPrivileges("ROLE_CLIENTGROUP_VIEW") && <ClientGroup/>} />
      <Route path="/part-group" element={checkPrivileges("ROLE_ITEMGROUP_VIEW") && <ItemGroup />} />
      <Route path="/service-group" element={checkPrivileges("ROLE_SERVICEGROUP_VIEW") && <ServiceGroup/>} />
      <Route path="/inspection-form" element={checkPrivileges("ROLE_INSPECTIONFORM_VIEW") && <InspectionForm/>} />
      {/* <Route path="/inspection-form-item" element={<InspectionFormItem/>} /> */}
      <Route path="/price-list" element={checkPrivileges("ROLE_PRICELIST_VIEW") && <PriceList/>} />
      <Route path="/store" element={checkPrivileges("ROLE_STORE_VIEW") && <Store/>} />
      <Route path="/service" element={checkPrivileges("ROLE_SERVICE_VIEW") && <Service/>} />
      <Route path="/business-group" element={checkPrivileges("ROLE_BUSINESSGROUP_VIEW") && <BusinessGroup/>} />
      <Route path="/units" element={checkPrivileges("ROLE_UNIT_VIEW") && <Units/>} />
      <Route path="/warehouse" element={checkPrivileges("ROLE_WAREHOUSE_VIEW") && <Warehouse/>} />
      <Route path="/vendor" element={checkPrivileges("ROLE_VENDOR_VIEW") && <Vendor/>} />
      <Route path="/stationgroup" element={checkPrivileges("ROLE_STATIONGROUP_VIEW") && <StationGroup/>} />
      <Route path="/package-group" element={checkPrivileges("ROLE_PACKAGEGROUP_VIEW") && <PackageGroup/>} />
      <Route path="/manufacturer" element={checkPrivileges("ROLE_MANUFACTURER_VIEW") && <Manufacturer/>} />
      <Route path="/taxprocedure" element={checkPrivileges("ROLE_TAXPROCEDURE_VIEW") && <TaxProcedure/>} />
      <Route path="/account-settings" element={<AccountSettings/>} />
      <Route path="/job-card" element={checkPrivileges("ROLE_JOBCARD_VIEW") && <JobCard/>} />
      <Route path="/work-order" element={checkPrivileges("ROLE_WORKORDER_VIEW") && <WorkOrder/>} />
      <Route path="/invoices" element={checkPrivileges("ROLE_INVOICE_VIEW") && <Invoice/>} />
      <Route path="/payments" element={checkPrivileges("ROLE_PAYMENT_VIEW") && <Payment/>} />
      <Route path="/statuses" element={checkPrivileges("ROLE_BUSINESSSTATUS_VIEW") && <Statuses/>} />
      <Route path="/vehicle-statuses" element={checkPrivileges("ROLE_BUSINESSSTATUS_VIEW") && <VehicleStatus/>} />
      <Route path="/payment-modes" element={checkPrivileges("ROLE_BUSINESSSTATUS_VIEW") && <PaymentMode/>} />
    </Routes>
  );
};

export default AppRoutes;
