import ButtonNew from 'common/Button/Button';
import StyledField from 'common/TextField/TexField1';
import {  Grid, useMediaQuery  } from '@mui/material';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import { ReactComponent as Applogo } from "assets/Navbar/sianty-logo-01.svg";
import { useTranslation } from 'react-i18next';
// import { ReactComponent as User } from "../../assets/Login/user.svg";
// import { ReactComponent as Password } from "../../assets/Login/password.svg";
// import { ReactComponent as BackGround } from "../../assets/Login/LoginBg.jpg";
// import {ReactComponent as userLogin} from '../../assets/Login/userLogin.svg'


// import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
interface InventoryComponentModalProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  formData: any;
  openForm: boolean;
  isVisableAddBtn:boolean;
  errorMessages:{ [key: string]: string };
}

const InventoryComponentModal: React.FC<InventoryComponentModalProps> = ({
  handleChange,
  errorMessages,
  handleSubmit,
  handleKeyDown,
  formData
}) => {

  const {t} = useTranslation();
  const isScreen600pxOrSmaller = useMediaQuery('(max-width:600px)');
  
  //------------------------------- For select   ------------------------------


  return (
    <div className={`bg-custom-background bg-cover bg-center min-h-screen flex  items-center pt-10 justify-end ${isScreen600pxOrSmaller ? 'pl-6' : ''}` }>
      <Grid container justifyContent="flex-end" alignItems="center">
        <Grid item xs={12} sm={8} md={6} lg={4} >
          <Grid container direction="column" alignItems="center" spacing={4} rowSpacing={1} className={`rounded-[8px] ${isScreen600pxOrSmaller ? '' : 'w-fit'} bg-signbg-color`}>
            <Grid item xs={12} marginTop={3} paddingRight={4} >
              <Applogo width={230} height={84} />
            </Grid>
            <Grid item xs={12} className='px-8 pt-2'>
              <StyledField
                label="email"
                icon="user"
                // placeholder="Write your email here"
                handleChange={(e) => handleChange('email', e.target.value)}
                required={true}
                height="h-[39.2px]"
                handleKeyDown={handleKeyDown}
                error={errorMessages?.email}
                value={formData.email}
              />
            </Grid>
            <Grid item xs={12} className='px-8 pt-0'>
              <StyledField
                label="Password"
                icon="lock"
                search="showPassword"
                height="h-[39px]"
                type="password"
                placeholder="Write your password here"
                handleChange={(e) => handleChange('password', e.target.value)}
                required={true}
                handleKeyDown={handleKeyDown}
                error={errorMessages?.password}
                value={formData.password}
              />
            </Grid>
            <Grid item xs={12} className='flex self-start items-start ml-1 pt-0 mt-[-10px]'>
                    <CommonCheckbox
                    label='rememberMe'
                    onChange={(e) => handleChange('taxable', e.target.checked)}
                    color="success"
                    required={false}
                    // error={errorMessages?.haveExpiryError}
                    checked={formData?.taxable}
                  />
                </Grid>
                <Grid item xs={12} className='flex self-start items-start justify-center ml-2 pt-0'>
                <h1 className=' font-Saira italic text-[12px]'> <a className='text-[14px] font-[600] text-primary-color' href="forgot-password">{t('forgot password?')}</a></h1>
                </Grid>
            <Grid item xs={12} marginTop={2}  marginBottom={7} marginRight={5}>
              <ButtonNew
                onClick={handleSubmit}
                type="login"
                disabled={false}
                text="Log In"
                hasIcon
              />
            </Grid>
            {/* <Grid item xs={12} className='flex-1 self-start pt-0 my-8'>
            <h1 className='mr-4 font-Saira font-[500] italic text-[13px]'>{t('forNewUsers')}</h1>
            <a className=' font-Saira font-[700] text-secondary-color text-[18px]' target='_Blank' href="https://enroutech.com/contact-us/">{t('techSupport')}</a>
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default InventoryComponentModal;
