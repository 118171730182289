import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Inspection  } from "assets/SideBarIcons/inspection.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import InspectionFormModalComponent from "./InspectionFormModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import InspectionFormItem from "pages/InspectionFormItem";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface InspectionFormComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  statusModal: boolean;
  statusText: string;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  showRowView: (row: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  tab: number;
  inspectionFormId: number;
  clearSearch: () => void;
}

const InspectionFormComponent: React.FC <InspectionFormComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,
  statusText,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  isBtnEnabled,
  loading,
  loadingSearch,
  showRowView,
  rowView,
  handleChangeTabs,
  inspectionFormId,
  clearSearch
}) => {
  const { t } = useTranslation();

  const tabs = [
    { 
      id: 0, title: `edit`, child: <InspectionFormModalComponent
      handleSecField={handleSecField}
      showSecField={showSecField}
      secondaryLanguage={secondaryLanguage}
      primaryLanguage={primaryLanguage}
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
      />
    },
    { id: 1, title: `${t('inspectionFormItem')}`, child: <InspectionFormItem inspectionFormId={inspectionFormId} />},
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_INSPECTIONFORM_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_INSPECTIONFORMITEM_VIEW');
      default:
        return false;
    }
  });

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={!enableEdit && openForm ? "addInspectionForm" : enableEdit && openForm ? "editInspectionForm" : t('inspectionForms')}
            icon={<Inspection height={28} width={28} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_INSPECTIONFORM_CREATE")}
            // btnType="create"
          />

          {openForm ? 
            <InspectionFormModalComponent
            handleSecField={handleSecField}
            showSecField={showSecField}
            secondaryLanguage={secondaryLanguage}
            primaryLanguage={primaryLanguage}
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
            />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
            : <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={disableTabs.length === tabs.length ? false : true}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={
                      <Grid container spacing={2}>  
                        <Grid item xs={12} md={7} lg={5}>
                          <StyledInput
                            fullWidth
                            placeholder={t("title")}
                            handleSearchClick={handleSearchClick}
                            handleChange={handleInputChange}
                            value={search}
                            clearSearch={clearSearch}
                          />
                        </Grid>
                      </Grid>
                      }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title="Delete Inspection Form"
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    Are you sure you want to Delete?
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
        {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

        </Box>

    )
}

export default InspectionFormComponent;