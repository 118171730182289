import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import PaymentComponent from 'components/Payment/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { usePaymentService, useDeletePaymentService, useGetPaymentService, useUpdatePaymentService, useGetPaymentCreditService, useGetPaymentInvoiceService } from 'subModule/src/services/payment/usePayments';
import { PaymentsRequest } from 'subModule/src/services/payment/interface';
import { useGetClientsDropdownService } from 'subModule/src/services/client/useClient';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { SelectChangeEvent } from '@mui/material';
import { useGetStatusesService } from 'subModule/src/services/statuses/useStatuses';

interface FormData {
    id: number;
    clientId: number;
    clientIdError?: string;
    contractNo: string;
    useCredit: boolean;
    paymentMode: number;
    paymentModeError?: string;
    amount: number;
    amountError?: string;
    referenceNo: string;
    invoices: number[];
    adjustmentType: number;
    adjustmentTypeError?: string;
    remarks: string;
    paymentDate: string;
    recipientDate: string;
    active: boolean;
}

interface SearchFormData {
  [key: string]: string;
}

const requiredFields = ['title'];

export default function Payment() {
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  // const [data] = useState(rows);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [clientsPageParams, setClientsPageParams] = useState<any>({ number: 1, size: 50 });
  const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
  const [dropdown, setDropdown] = useState<string>('clientId');
  const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [filter, setFilter] = useState<string[]>(['paymentNo']);
  const [search, setSearch] = useState<any>({});
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [searchInvoice, setSearchInvoice] = useState('');
  const [searchTermInvoice, setSearchTermInvoice] = useState<any>(null);
  const [loadingSearchInvoice, setLoadingSearchInvoice] = useState<boolean>(false);
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    clientId: 0,
    contractNo: '',
    useCredit: false,
    paymentMode: 0,
    amount: 0,
    referenceNo: '',
    invoices: [],
    adjustmentType: 591,
    remarks: '',
    paymentDate: '',
    recipientDate: '',
    active: true,
  });
  const [searchFormData, setSearchFormData] = useState<SearchFormData>({
    paymentNo: '',
    referenceNo: '',
  });

  const { data: tableData, isLoading, error, refetch } = useGetPaymentService({ pageNumber: pageNo, size: pageSize, ...search });

  const { data: clientsData, isLoading: clientsIsLoading, error: clientsError, refetch: clientsRefetch } = useGetClientsDropdownService({ pageNumber: clientsPageParams.number, size: clientsPageParams.size, name: dropdownSearchTerm });

  const { data: paymentsCreditData, isLoading: paymentsCreditIsLoading, error: paymentsCreditError, refetch: paymentsCreditRefetch } = useGetPaymentCreditService(formData.clientId);

  const { data: paymentsInvoiceData, isLoading: paymentsInvoiceIsLoading, error: paymentsInvoiceError, refetch: paymentsInvoiceRefetch } = searchTermInvoice ? useGetPaymentInvoiceService({ pageNumber: pageNo, size: pageSize, invoiceNo: searchTermInvoice }, formData.clientId) : useGetPaymentInvoiceService({ pageNumber: pageNo, size: pageSize }, formData.clientId);

  const { data: paymentModesData, isLoading: paymentModesIsLoading, error: paymentModesError, refetch: paymentModesRefetch } = useGetStatusesService({ pageNumber: 1, size: 50 }, 7);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();


  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) {
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm, paymentModesIsLoading, paymentModesError]);
  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    if (!paymentsInvoiceIsLoading && !paymentsCreditError && openForm && formData.useCredit === true && formData.clientId !== 0) {
      paymentsCreditRefetch();
    }
  }, [formData.useCredit, formData.clientId]);

  useEffect(() => {
    if (!paymentsCreditIsLoading && !paymentsInvoiceError && openForm && formData.adjustmentType === 592 && formData.clientId !== 0) {
      const loadData = async() => {
        setLoadingSearchInvoice(true);
        await paymentsInvoiceRefetch();
        setLoadingSearchInvoice(false);
      }
      loadData();
    }
  }, [formData.adjustmentType, formData.clientId]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if ((dropdownSearchTerm || dropdownSearchTerm === '') && openForm && !initialRender) {
        if(dropdown === 'clientId') {
          setLoadingDropdownSearch(true);
          setClientsPageParams({ number: 1, size: 50 });
          await clientsRefetch();
          setLoadingDropdownSearch(false);
        }
      }
    }, 500)
  return () => clearTimeout(getData)
  }, [dropdownSearchTerm, clientsIsLoading, clientsError, openForm]);

  useEffect(() => {
    if(clientsPageParams && openForm && dropdown === 'clientId') {
      clientsRefetch();
    }
  }, [clientsPageParams]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (search && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 300)
    return () => clearTimeout(getData)
  }, [search]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if (searchTermInvoice !== null && loadingSearchInvoice && openForm && formData.adjustmentType === 592 && formData.clientId !== 0) {
        await paymentsInvoiceRefetch();
        setLoadingSearchInvoice(false);
      }
    }, 300);
    return () => clearTimeout(getData)
  }, [searchTermInvoice, loadingSearchInvoice]);

  const handleDropdownSearch = (field:string, search: string) => {
    if(field === 'clientId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('clientId');
    }
  }
  const loadNext = (field: string, scroll: any) => {
    if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
      if(field === 'clientId') {
        if(!(clientsData?.pages[0]?.data as any).last) {
          setDropdown('clientId');
          setClientsPageParams({ number: clientsPageParams.number + 1, size: clientsPageParams.size });
        }
      }
    }
  }
  const loadPrev = (field: string, scroll: any) => {
    if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
        if(field === 'clientId') {
            if(!(clientsData?.pages[0]?.data as any).first) {
            setDropdown('clientId');
            setClientsPageParams({ number: clientsPageParams.number - 1, size: clientsPageParams.size });
            }
        }
    }
  }

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1)
    setPageSize(pageSize)
  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize)
    setPageNo(1)
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setIsBtnEnabled(true);
    setEnableEdit(false);
    setErrorMessages({});
    setInitialRender(true);
    setDropdown('clientId');
    setDropdownSearchTerm('');
    setSearchInvoice('');
    setSearchTermInvoice('');
    clearSearchInvoice();
    setFormData({
        id: 0,
        clientId: 0,
        contractNo: '',
        useCredit: false,
        paymentMode: 0,
        amount: 0,
        referenceNo: '',
        invoices: [],
        adjustmentType: 591,
        remarks: '',
        paymentDate: '',
        recipientDate: '',
        active: true,
    });
    if (!openForm && !enableEdit) {
      clientsRefetch();
      setClientsPageParams({ number: 1, size: 50 });
      clearSearch();
      paymentModesRefetch();
    }
  };

  const handleChange = (field: string, value: any) => {
    const val = (field === "clientId" || field === "paymentMode" || field === "adjustmentType") ? 
            (value ? parseInt(value.value) || 0 : 0)
        : (field === "paymentDate" || field === "recipientDate") ?
            new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-')
        : (field === "useCredit") ? value ? Boolean(value) : false 
        : (field === "amount") ? 
            (value >= 0 ? value : 0)
        : value;

    if(field === 'clientId') { clearSearchInvoice(); }
    if(field === 'adjustmentType' && val === 591) {
      setSearchInvoice('');
      setSearchTermInvoice('');
      setFormData({ ...formData, invoices: [] });
    }
    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
    if (enableEdit) {
      setIsBtnEnabled(true);
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.clientId) {
      errors.clientIdError = 'Client is required.';
    }
    if (!data.paymentMode) {
      errors.paymentModeError = 'Payment Mode is required.';
    }
    if (!data.amount) {
      errors.amountError = 'Amount is required.';
    }
    if (!data.referenceNo?.trim()) {
      errors.referenceNo = 'Reference Number is required.';
    }
    if (!data.adjustmentType) {
      errors.adjustmentTypeError = 'Adjustment Type is required.';
    }
    if (!data.paymentDate || data.paymentDate === "Invalid Date") {
      errors.paymentDate = 'Payment Date is required.';
    }
    if (!data.recipientDate || data.recipientDate === "Invalid Date") {
      errors.recipientDate = 'Recipent Date is required.';
    }
    if(data.invoices?.length === 0 && data.adjustmentType === 592) {
      errors.adjustmentTypeError = 'Select at least one invoice or change type';
    }

    return errors;
  };

  const clearSearchInvoice = () => {
    setSearchInvoice('');
    setSearchTermInvoice('');
    if(searchTermInvoice) { handleSearch(''); }
  }
  const handleSearch = (search: string) => {
    setLoadingSearchInvoice(true);
    setSearchTermInvoice(search);
    setPageNo(1);
    setPageSize(pageSize);
  };

  const handleSearchClick = () => {
    const trimmedSearch = searchInvoice.trim();
    if (trimmedSearch !== '') {
      handleSearch(trimmedSearch);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInvoice(event.target.value.trim());
    if (!event.target.value.trim()) handleSearch('');
  };
  const clearSearch = () => {
    setFilter(['paymentNo']);
    setSearchFormData({
      paymentNo: '',
      referenceNo: '',
    });
    setSearchErrorMessages({});
    if(Object.keys(search).length) {
      setLoadingSearch(true);
      setSearch({});
    }
  } 
  const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
    const { target: { value }, } = event;
    setFilter(typeof value === 'string' ? value.split(',') : value,);
    setSearchFormData(prevFormData => 
      Object.fromEntries(Object.keys(prevFormData).map(key => 
        [key, value.includes(key) ? prevFormData[key] : '']
      ))
    );
    if(!value.length && Object.keys(search).length) {
      clearSearch();
    }
  };
  const handleChangeSearch = (field: string, value: any) => {
    const val = value;

    setSearchFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (filter.includes(field) && !val) {
      setSearchErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setSearchErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const validateSearchFormData = (data: SearchFormData) => {
    const errors: Partial<SearchFormData> = {};

    filter.forEach(fieldName => {
      if (!data[fieldName]) { 
        errors[fieldName] = 'Required';
      }
    });

    return errors;
  };
  const handleSubmitSearch = () => {
    const errors = validateSearchFormData(searchFormData);
    setSearchErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoadingSearch(true);
      const filteredSearch: Record<string, string> = {};

      Object.entries(searchFormData).forEach(([key, value]) => {
          if (value) {
            filteredSearch[key] = value?.trim();
          }
      });

      setSearch(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };

  const { onCreatePayment } = usePaymentService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      setOpenForm(false);
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize

    },
    onError: (err) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdatePayment } = useUpdatePaymentService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      setEnableEdit(false);
      setOpenForm(false);
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('Payment') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err) => {
      console.error('Login error======', err, enableEdit);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const paymentData: PaymentsRequest = {
        ...formData,
        clientId: formData.clientId,
        contractNo: formData.contractNo,
        useCredit: formData.useCredit,
        paymentMode: formData.paymentMode,
        amount: formData.amount,
        referenceNo: formData.referenceNo,
        invoices: formData.invoices,
        adjustmentType: formData.adjustmentType,
        remarks: formData.remarks,
        paymentDate: formData.paymentDate,
        recipientDate: formData.recipientDate,
      };

      if (enableEdit) {
        onUpdatePayment(paymentData);
      } else {
        onCreatePayment(paymentData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    setFormData(row);
    setPrevFormData(row);
    setOpenForm(true);
    setIsBtnEnabled(false);
    setClientsPageParams({ number: 1, size: 50 });
    setDropdown('clientId');
    setDropdownSearchTerm('');
    setInitialRender(true);
    clientsRefetch();
    clearSearch();
    clearSearchInvoice();
    paymentModesRefetch();
    if(formData?.adjustmentType === 592 && formData?.clientId !== 0) { paymentsInvoiceRefetch(); }
    if(formData?.clientId !== 0 && formData?.useCredit) { paymentsCreditRefetch(); }
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeletePayment } = useDeletePaymentService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('Payment') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false);
    setLoading(true);
    onDeletePayment();
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const showRowView = (row: any) => {
    onEdit(row);
  }
  
  const assignUnassignInvoices = (isChecked: boolean, row: any) => {
    if (isChecked) {
      if (!formData.invoices.includes(row.id)) { setFormData({ ...formData, invoices: [...formData.invoices, row.id] }); }
    } else {
      const updatedInvoices = formData.invoices.filter(id => id !== row.id);
      setFormData({ ...formData, invoices: updatedInvoices });
    }
  };
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_PAYMENT_UPDATE')
      ? (checkPrivileges('ROLE_PAYMENT_ARCHIVE')
        ? actions
        : actions.filter(action => action.label !== 'delete'))
      : (checkPrivileges('ROLE_PAYMENT_ARCHIVE')
        ? actions.filter(action => action.label !== 'edit')
        : []);
  }

  return (
    <Box>
      <PaymentComponent
        openModal={openModal}
        closeModal={openModal}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        showRowView={showRowView}
        handleDropdownSearch={handleDropdownSearch}
        loadNext={loadNext}
        loadPrev={loadPrev}
        clientsData={clientsData?.pages[0] || {}}
        paymentsCreditAmount={paymentsCreditData?.data}
        paymentsInvoiceData={paymentsInvoiceData?.pages[0] || {}}
        assignUnassignInvoices={assignUnassignInvoices}
        clearSearchInvoice={clearSearchInvoice}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        paymentModesData={paymentModesData?.pages[0] || {}}
        columns={[
          { id: 'paymentNo', label: 'Payment Number', numeric: false },
          { id: 'referenceNo', label: 'Reference Number', numeric: false },
          { id: 'paymentModeTitle', label: 'Payment Mode', numeric: false },
          { id: 'clientName', label: 'clientName', numeric: false },
          { id: 'amount', label: 'amount', numeric: false },
          { id: 'paymentDate', label: 'Payment Date', numeric: false },
          { id: 'adjustmentType', label: 'Adjustment Type', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        searchFormData={searchFormData}
        handleChangeFilter={handleChangeFilter}
        handleChangeSearch={handleChangeSearch}
        handleSubmitSearch={handleSubmitSearch}
        searchErrorMessages={searchErrorMessages}
        clearSearch={clearSearch}
        {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, isBtnEnabled, loading, loadingDropdownSearch, loadingSearch, filter, search, searchInvoice, loadingSearchInvoice }}
        errorMessages={errorMessages}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}