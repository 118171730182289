import { ReactComponent as NothingHere } from "assets/Login/Nothing_Here.svg"
import { ReactComponent as Rectangle0 } from "assets/Login/Rectangle0.svg"
import { ReactComponent as Rectangle1 } from "assets/Login/Rectangle-1.svg"
import { ReactComponent as Rectangle2 } from "assets/Login/Rectangle-2.svg"
import { ReactComponent as Rectangle3 } from "assets/Login/Rectangle-3.svg"
import { ReactComponent as Rectangle4 } from "assets/Login/Rectangle-4.svg"
import { Typography, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

export default function Error404() {

    const { t } = useTranslation();

    return (
        <>
            <div className="pt-20">
                <Grid container rowGap={2} >
                    <Grid xs={12} className="flex items-center justify-center">
                        <NothingHere />
                    </Grid>
                    <Grid xs={12} className="flex items-center justify-center">
                        <Typography className="font-Saira text-[24px] text-[#D6DCE8] leading-[18px] font-[700]">
                            {t('404') + ' ' + t('notFound')}
                        </Typography>
                    </Grid>
                    <Grid xs={12} className="flex items-center justify-center">
                        <Rectangle0 />
                    </Grid>
                    <Grid xs={12} className="flex items-center justify-center">
                        <Rectangle1 />
                    </Grid>
                    <Grid xs={12} className="flex items-center justify-center">
                        <Rectangle2 />
                    </Grid>
                    <Grid xs={12} className="flex items-center justify-center">
                        <Rectangle3 />
                    </Grid>
                    <Grid xs={12} className="flex items-center justify-center">
                        <Rectangle4 />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}