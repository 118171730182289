// StationService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignBankAccountRequest,
  AssignUnassignBayRequest,
  AssignUnassignResponse,
  AssignUnassignStoreRequest,
  AssignUnassignUserRequest,
  AssignUnassignWarehouseRequest,
  ChangeStatusStationResponse,
  DeleteStationResponse,
  GetStationBankAccountResponse,
  GetStationBayResponse,
  GetStationDropdownResponse,
  GetStationResponse,
  GetStationStoreResponse,
  GetStationUserResponse,
  GetStationWarehouseResponse,
  StationRequest,
  StationResponse,
} from './interface';

export class StationProfile extends BaseService {
  async createStation(req: StationRequest) {
    return this.post<StationResponse, StationRequest>(`${this.apiUrl}/api/stations`, req);
  }

  async getStation(params: PaginationParams) {
    return this.get<GetStationResponse>(`${this.apiUrl}/api/stations/paged`, params);
  }

  async getStationDropdown(params: PaginationParams) {
    return this.get<GetStationDropdownResponse>(`${this.apiUrl}/api/stations/get`, params);
  }

  async updateStation(id: number, req: StationRequest) {
    return this.put<StationResponse, StationRequest>(`${this.apiUrl}/api/stations/${id}`, req);
  }

  async deleteStation(id: number) {
    return this.delete<DeleteStationResponse>(`${this.apiUrl}/api/stations/${id}`);
  }

  async changeStatusStation(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusStationResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/stations/${active}/${id}`,
      req
    );
  }

  async getStationStore(params: PaginationParams & { stationId: number }) {
    return this.get<GetStationStoreResponse>(`${this.apiUrl}/api/stores/bystationid`, params);
  }

  async assignStore(req: AssignUnassignStoreRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignStoreRequest>(
      `${this.apiUrl}/api/stations/store/assign`,
      req
    );
  }

  async unassignStore(req: AssignUnassignStoreRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignStoreRequest>(
      `${this.apiUrl}/api/stations/store/unassign`,
      req
    );
  }

  async assignAllStores(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/store/assignall`,
      req
    );
  }

  async unassignAllStores(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/store/unassignall`,
      req
    );
  }

  async getStationWarehouse(params: PaginationParams & { stationId: number }) {
    return this.get<GetStationWarehouseResponse>(`${this.apiUrl}/api/warehouses/bystationid`, params);
  }

  async assignWarehouse(req: AssignUnassignWarehouseRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignWarehouseRequest>(
      `${this.apiUrl}/api/stations/warehouse/assign`,
      req
    );
  }

  async unassignWarehouse(req: AssignUnassignWarehouseRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignWarehouseRequest>(
      `${this.apiUrl}/api/stations/warehouse/unassign`,
      req
    );
  }

  async assignAllWarehouses(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/warehouse/assignall`,
      req
    );
  }

  async unassignAllWarehouses(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/warehouse/unassignall`,
      req
    );
  }

  async getStationUser(params: PaginationParams & { stationId: number }) {
    return this.get<GetStationUserResponse>(`${this.apiUrl}/api/users/bystationid`, params);
  }

  async assignUser(req: AssignUnassignUserRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignUserRequest>(`${this.apiUrl}/api/stations/user/assign`, req);
  }

  async unassignUser(req: AssignUnassignUserRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignUserRequest>(
      `${this.apiUrl}/api/stations/user/unassign`,
      req
    );
  }

  async assignAllUsers(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/user/assignall`,
      req
    );
  }

  async unassignAllUsers(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/user/unassignall`,
      req
    );
  }

  async getStationBay(params: PaginationParams & { stationId: number }) {
    return this.get<GetStationBayResponse>(`${this.apiUrl}/api/bays/bystationid`, params);
  }

  async assignBay(req: AssignUnassignBayRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignBayRequest>(`${this.apiUrl}/api/stations/bay/assign`, req);
  }

  async unassignBay(req: AssignUnassignBayRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignBayRequest>(`${this.apiUrl}/api/stations/bay/unassign`, req);
  }

  async assignAllBays(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/bay/assignall`,
      req
    );
  }

  async unassignAllBays(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/bay/unassignall`,
      req
    );
  }

  async getStationBankAccount(params: PaginationParams & { stationId: number }) {
    return this.get<GetStationBankAccountResponse>(`${this.apiUrl}/api/bankaccounts/bystationid`, params);
  }

  async assignBankAccount(req: AssignUnassignBankAccountRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignBankAccountRequest>(
      `${this.apiUrl}/api/stations/bankaccount/assign`,
      req
    );
  }

  async unassignBankAccount(req: AssignUnassignBankAccountRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignBankAccountRequest>(
      `${this.apiUrl}/api/stations/bankaccount/unassign`,
      req
    );
  }

  async assignAllBankAccounts(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/bankaccount/assignall`,
      req
    );
  }

  async unassignAllBankAccounts(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stations/bankaccount/unassignall`,
      req
    );
  }
}
