// PackagesProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { PackagesProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignPriceListRequest,
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignModelRequest,
  AssignUnassignResponse,
  AssignUnassignServiceRequest,
  AssignUnassignStationRequest,
  ChangeStatusPackagesResponse,
  DeletePackagesResponse,
  PackagesRequest,
  PackagesResponse,
  UnassignPriceListRequest,
} from './interface';
import { useCallback } from 'react';

type UsePackagesServiceProps = {
  onSuccess: (data: PackagesResponse) => void;
  onError: (err: unknown) => void;
};
type UpdatePackagesServiceProps = {
  onSuccess: (data: PackagesResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeletePackagesServiceProps = {
  onSuccess: (data: DeletePackagesResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusPackagesServiceProps = {
  onSuccess: (data: ChangeStatusPackagesResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const usePackagesService = ({ onError, onSuccess }: UsePackagesServiceProps) => {
  const {
    mutateAsync: createPackages,
    isPending: isCreatePackagesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-packages-request'],
    mutationFn: (req: PackagesRequest) => PackagesProfileService.createPackages(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreatePackages = useCallback(
    async (PackagesData: PackagesRequest) => {
      try {
        createPackages(PackagesData);
      } catch (err) {
        console.error(err);
      }
    },
    [createPackages]
  );

  return {
    onCreatePackages,
    isCreatePackagesLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackagesService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Packages-data'],
      queryFn: async ({ pageParam }) => {
        return PackagesProfileService.getPackages({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdatePackagesService = ({ id, onError, onSuccess }: UpdatePackagesServiceProps) => {
  const {
    mutateAsync: updatePackages,
    isPending: isUpdatePackagesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-packages-request'],
    mutationFn: (req: PackagesRequest) => PackagesProfileService.updatePackages(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePackages = useCallback(
    async (req: PackagesRequest) => {
      try {
        updatePackages(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePackages]
  );

  return {
    onUpdatePackages,
    isUpdatePackagesLoading,
    data,
    error,
    isError,
  };
};

export const useDeletePackagesService = ({ id, onError, onSuccess }: DeletePackagesServiceProps) => {
  const {
    mutateAsync: deletePackages,
    isPending: isDeletePackagesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-packages-request'],
    mutationFn: () => PackagesProfileService.deletePackages(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeletePackages = useCallback(async () => {
    try {
      deletePackages();
    } catch (err) {
      console.error(err);
    }
  }, [deletePackages]);

  return {
    onDeletePackages,
    isDeletePackagesLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusPackagesService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusPackagesServiceProps) => {
  const {
    mutateAsync: changeStatusPackages,
    isPending: isChangeStatusPackagesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-packages-request'],
    mutationFn: (req: ChangeStatusRequest) => PackagesProfileService.changeStatusPackages(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusPackages = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusPackages(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusPackages]
  );

  return {
    onChangeStatusPackages,
    isChangeStatusPackagesLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackageServiceService = (initialParams: PageSize, packageId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-packages-services-data'],
      queryFn: async ({ pageParam }) => {
        return PackagesProfileService.getPackageService({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          packageId: packageId,
          title: initialParams.title,
          serviceCode: initialParams.serviceCode,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignServiceService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignService,
    isPending: isAssignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-services-request'],
    mutationFn: (req: AssignUnassignServiceRequest) => PackagesProfileService.assignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignService = useCallback(
    async (assignServiceData: AssignUnassignServiceRequest) => {
      try {
        assignService(assignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignService]
  );

  return {
    onAssignService,
    isAssignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignServiceService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignService,
    isPending: isUnassignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-services-request'],
    mutationFn: (req: AssignUnassignServiceRequest) => PackagesProfileService.unassignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignService = useCallback(
    async (unassignServiceData: AssignUnassignServiceRequest) => {
      try {
        unassignService(unassignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignService]
  );

  return {
    onUnassignService,
    isUnassignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllServiceService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllServices,
    isPending: isAssignAllServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-services-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.assignAllServices(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllServices = useCallback(
    async (assignAllServiceData: AssignUnassignAllRequest) => {
      try {
        assignAllServices(assignAllServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllServices]
  );

  return {
    onAssignAllServices,
    isAssignAllServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllServiceService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllServices,
    isPending: isUnassignAllServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-services-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.unassignAllServices(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllServices = useCallback(
    async (unassignAllServiceData: AssignUnassignAllRequest) => {
      try {
        unassignAllServices(unassignAllServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllServices]
  );

  return {
    onUnassignAllServices,
    isUnassignAllServiceLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackageItemService = (initialParams: PageSize, packageId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-packages-items-data'],
      queryFn: async ({ pageParam }) => {
        return PackagesProfileService.getPackageItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
          packageId: packageId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignItemService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignItem,
    isPending: isAssignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => PackagesProfileService.assignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignItem = useCallback(
    async (assignItemData: AssignUnassignItemRequest) => {
      try {
        assignItem(assignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignItem]
  );

  return {
    onAssignItem,
    isAssignItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignItemService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignItem,
    isPending: isUnassignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => PackagesProfileService.unassignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignItem = useCallback(
    async (unassignItemData: AssignUnassignItemRequest) => {
      try {
        unassignItem(unassignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignItem]
  );

  return {
    onUnassignItem,
    isUnassignItemLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllItemService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllItems,
    isPending: isAssignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.assignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllItems = useCallback(
    async (assignAllItemData: AssignUnassignAllRequest) => {
      try {
        assignAllItems(assignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllItems]
  );

  return {
    onAssignAllItems,
    isAssignAllItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllItemService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllItems,
    isPending: isUnassignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.unassignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllItems = useCallback(
    async (unassignAllItemData: AssignUnassignAllRequest) => {
      try {
        unassignAllItems(unassignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllItems]
  );

  return {
    onUnassignAllItems,
    isUnassignAllItemLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackageStationService = (initialParams: PageSize, packageId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-packages-stations-data'],
      queryFn: async ({ pageParam }) => {
        return PackagesProfileService.getPackageStation({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          packageId: packageId,
          name: initialParams.name,
          phone: initialParams.phone,
          email: initialParams.email,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignStationService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignStation,
    isPending: isAssignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => PackagesProfileService.assignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignStation = useCallback(
    async (assignStationData: AssignUnassignStationRequest) => {
      try {
        assignStation(assignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignStation]
  );

  return {
    onAssignStation,
    isAssignStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignStationService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignStation,
    isPending: isUnassignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => PackagesProfileService.unassignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignStation = useCallback(
    async (unassignStationData: AssignUnassignStationRequest) => {
      try {
        unassignStation(unassignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignStation]
  );

  return {
    onUnassignStation,
    isUnassignStationLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllStationService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllStations,
    isPending: isAssignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.assignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllStations = useCallback(
    async (assignAllStationData: AssignUnassignAllRequest) => {
      try {
        assignAllStations(assignAllStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllStations]
  );

  return {
    onAssignAllStations,
    isAssignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllStationService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllStations,
    isPending: isUnassignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.unassignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllStations = useCallback(
    async (unassignAllStationData: AssignUnassignAllRequest) => {
      try {
        unassignAllStations(unassignAllStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllStations]
  );

  return {
    onUnassignAllStations,
    isUnassignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackageModelService = (initialParams: PageSize, packageId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-packages-models-data'],
      queryFn: async ({ pageParam }) => {
        return PackagesProfileService.getPackageModel({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
          packageId: packageId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignModelService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignModel,
    isPending: isAssignModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-models-request'],
    mutationFn: (req: AssignUnassignModelRequest) => PackagesProfileService.assignModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignModel = useCallback(
    async (assignModelData: AssignUnassignModelRequest) => {
      try {
        assignModel(assignModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignModel]
  );

  return {
    onAssignModel,
    isAssignModelLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignModelService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignModel,
    isPending: isUnassignModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-models-request'],
    mutationFn: (req: AssignUnassignModelRequest) => PackagesProfileService.unassignModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignModel = useCallback(
    async (unassignModelData: AssignUnassignModelRequest) => {
      try {
        unassignModel(unassignModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignModel]
  );

  return {
    onUnassignModel,
    isUnassignModelLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllModelService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllModels,
    isPending: isAssignAllModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-models-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.assignAllModels(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllModels = useCallback(
    async (assignAllModelData: AssignUnassignAllRequest) => {
      try {
        assignAllModels(assignAllModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllModels]
  );

  return {
    onAssignAllModels,
    isAssignAllModelLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllModelService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllModels,
    isPending: isUnassignAllModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-models-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackagesProfileService.unassignAllModels(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllModels = useCallback(
    async (unassignAllModelData: AssignUnassignAllRequest) => {
      try {
        unassignAllModels(unassignAllModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllModels]
  );

  return {
    onUnassignAllModels,
    isUnassignAllModelLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackagePriceListService = (initialParams: PageSize, packageId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-packages-pricelists-data'],
      queryFn: async ({ pageParam }) => {
        return PackagesProfileService.getPackagePriceList({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          packageId: packageId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignPackagePriceListService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignPriceList,
    isPending: isAssignPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-package-pricelists-request'],
    mutationFn: (req: AssignPriceListRequest) => PackagesProfileService.assignPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignPriceList = useCallback(
    async (assignPriceListData: AssignPriceListRequest) => {
      try {
        assignPriceList(assignPriceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignPriceList]
  );

  return {
    onAssignPriceList,
    isAssignPriceListLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignPackagePriceListService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignPriceList,
    isPending: isUnassignPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-packages-pricelist-request'],
    mutationFn: (req: UnassignPriceListRequest) => PackagesProfileService.unassignPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignPriceList = useCallback(
    async (unassignPriceListData: UnassignPriceListRequest) => {
      try {
        unassignPriceList(unassignPriceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignPriceList]
  );

  return {
    onUnassignPriceList,
    isUnassignPriceListLoading,
    data,
    error,
    isError,
  };
};
