import { BaseService } from '../../core/BaseService';
import { ExportRequest, ImportRequest, ImportResponse } from './interface';

export class CommonProfile extends BaseService {
  async importEntity(req: ImportRequest) {
    const headers = { 'Content-Type': 'multipart/form-data' };

    const formData = new FormData();

    Object.entries(req).forEach(([key, value]) => {
      if (value !== null) {
        formData.append(key, value);
      }
    });

    return this.post<ImportResponse, FormData>(`${this.apiUrl}/api/${req.typeName}/upload`, formData, { headers });
  }

  async exportEntity(req: ExportRequest) {
    return this.get<Blob>(`${this.apiUrl}/api/${req.typeName}/export/sample`, undefined, {
      responseType: 'blob',
      // headers: {
      //   Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      //   'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // },
    });
  }
}
