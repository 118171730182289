// StationGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { StationGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignStationRequest,
  ChangeStatusStationGroupResponse,
  DeleteStationGroupResponse,
  StationGroupRequest,
  StationGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UseStationGroupServiceProps = {
  onSuccess: (data: StationGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateStationGroupServiceProps = {
  onSuccess: (data: StationGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteStationGroupServiceProps = {
  onSuccess: (data: DeleteStationGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusStationGroupServiceProps = {
  onSuccess: (data: ChangeStatusStationGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useStationGroupService = ({ onError, onSuccess }: UseStationGroupServiceProps) => {
  const {
    mutateAsync: createStationGroup,
    isPending: isCreateStationGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-stationGroup-request'],
    mutationFn: (req: StationGroupRequest) => StationGroupProfileService.createStationGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateStationGroup = useCallback(
    async (StationGroupData: StationGroupRequest) => {
      try {
        createStationGroup(StationGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createStationGroup]
  );

  return {
    onCreateStationGroup,
    isCreateStationGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stationGroup-data'],
      queryFn: async ({ pageParam }) => {
        return StationGroupProfileService.getStationGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateStationGroupService = ({ id, onError, onSuccess }: UpdateStationGroupServiceProps) => {
  const {
    mutateAsync: updateStationGroup,
    isPending: isUpdateStationGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-stationGroup-request'],
    mutationFn: (req: StationGroupRequest) => StationGroupProfileService.updateStationGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateStationGroup = useCallback(
    async (req: StationGroupRequest) => {
      try {
        updateStationGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateStationGroup]
  );

  return {
    onUpdateStationGroup,
    isUpdateStationGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteStationGroupService = ({ id, onError, onSuccess }: DeleteStationGroupServiceProps) => {
  const {
    mutateAsync: deleteStationGroup,
    isPending: isDeleteStationGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-stationGroup-request'],
    mutationFn: () => StationGroupProfileService.deleteStationGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteStationGroup = useCallback(async () => {
    try {
      deleteStationGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deleteStationGroup]);

  return {
    onDeleteStationGroup,
    isDeleteStationGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusStationGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusStationGroupServiceProps) => {
  const {
    mutateAsync: changeStatusStationGroup,
    isPending: isChangeStatusStationGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-stationGroup-request'],
    mutationFn: (req: ChangeStatusRequest) => StationGroupProfileService.changeStatusStationGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusStationGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusStationGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusStationGroup]
  );

  return {
    onChangeStatusStationGroup,
    isChangeStatusStationGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationGroupStationService = (initialParams: PageSize, stationGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stationgroups-station-data'],
      queryFn: async ({ pageParam }) => {
        return StationGroupProfileService.getStationGroupStation({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          stationGroupId: stationGroupId,
          name: initialParams.name,
          phone: initialParams.phone,
          email: initialParams.email,
          stationCode: initialParams.stationCode,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignStationService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignStation,
    isPending: isAssignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-station-request'],
    mutationFn: (req: AssignUnassignStationRequest) => StationGroupProfileService.assignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignStation = useCallback(
    async (assignStationData: AssignUnassignStationRequest) => {
      try {
        assignStation(assignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignStation]
  );

  return {
    onAssignStation,
    isAssignStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignStationService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignStation,
    isPending: isUnassignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => StationGroupProfileService.unassignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignStation = useCallback(
    async (unassignStationData: AssignUnassignStationRequest) => {
      try {
        unassignStation(unassignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignStation]
  );

  return {
    onUnassignStation,
    isUnassignStationLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllStationService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllStations,
    isPending: isAssignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationGroupProfileService.assignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllStations = useCallback(
    async (assignAllStationsData: AssignUnassignAllRequest) => {
      try {
        assignAllStations(assignAllStationsData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllStations]
  );

  return {
    onAssignAllStations,
    isAssignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllStationsService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllStations,
    isPending: isUnassignAllStationsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationGroupProfileService.unassignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllStations = useCallback(
    async (unassignAllStationsData: AssignUnassignAllRequest) => {
      try {
        unassignAllStations(unassignAllStationsData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllStations]
  );

  return {
    onUnassignAllStations,
    isUnassignAllStationsLoading,
    data,
    error,
    isError,
  };
};
