// StationProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { StationProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignBankAccountRequest,
  AssignUnassignBayRequest,
  AssignUnassignResponse,
  AssignUnassignStoreRequest,
  AssignUnassignUserRequest,
  AssignUnassignWarehouseRequest,
  ChangeStatusStationResponse,
  DeleteStationResponse,
  StationRequest,
  StationResponse,
} from './interface';
import { useCallback } from 'react';

type UseStationServiceProps = {
  onSuccess: (data: StationResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateStationServiceProps = {
  onSuccess: (data: StationResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteStationServiceProps = {
  onSuccess: (data: DeleteStationResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusStationServiceProps = {
  onSuccess: (data: ChangeStatusStationResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useStationService = ({ onError, onSuccess }: UseStationServiceProps) => {
  const {
    mutateAsync: createStation,
    isPending: isCreateStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-stations-request'],
    mutationFn: (req: StationRequest) => StationProfileService.createStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateStation = useCallback(
    async (stationData: StationRequest) => {
      try {
        createStation(stationData);
      } catch (err) {
        console.error(err);
      }
    },
    [createStation]
  );

  return {
    onCreateStation,
    isCreateStationLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stations-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStation({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetStationDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stationsdropdown-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStationDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateStationService = ({ id, onError, onSuccess }: UpdateStationServiceProps) => {
  const {
    mutateAsync: updateStation,
    isPending: isUpdateStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-stations-request'],
    mutationFn: (req: StationRequest) => StationProfileService.updateStation(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateStation = useCallback(
    async (req: StationRequest) => {
      try {
        updateStation(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateStation]
  );

  return {
    onUpdateStation,
    isUpdateStationLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteStationService = ({ id, onError, onSuccess }: DeleteStationServiceProps) => {
  const {
    mutateAsync: deleteStation,
    isPending: isDeleteStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-station-request'],
    mutationFn: () => StationProfileService.deleteStation(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteStation = useCallback(async () => {
    try {
      deleteStation();
    } catch (err) {
      console.error(err);
    }
  }, [deleteStation]);

  return {
    onDeleteStation,
    isDeleteStationLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusStationService = ({ active, id, onError, onSuccess }: ChangeStatusStationServiceProps) => {
  const {
    mutateAsync: changeStatusStation,
    isPending: isChangeStatusStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-station-request'],
    mutationFn: (req: ChangeStatusRequest) => StationProfileService.changeStatusStation(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusStation = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusStation(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusStation]
  );

  return {
    onChangeStatusStation,
    isChangeStatusStationLoading,
    data,
    error,
    isError,
  };
};

//Assign & Unassign hooks
export const useGetStationStoreService = (initialParams: PageSize, stationId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stations-stores-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStationStore({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          stationId: stationId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignStoreService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignStore,
    isPending: isAssignStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-stores-request'],
    mutationFn: (req: AssignUnassignStoreRequest) => StationProfileService.assignStore(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignStore = useCallback(
    async (assignStoreData: AssignUnassignStoreRequest) => {
      try {
        assignStore(assignStoreData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignStore]
  );

  return {
    onAssignStore,
    isAssignStoreLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignStoreService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignStore,
    isPending: isUnassignStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-stores-request'],
    mutationFn: (req: AssignUnassignStoreRequest) => StationProfileService.unassignStore(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignStore = useCallback(
    async (unassignStoreData: AssignUnassignStoreRequest) => {
      try {
        unassignStore(unassignStoreData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignStore]
  );

  return {
    onUnassignStore,
    isUnassignStoreLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllStoreService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllStores,
    isPending: isAssignAllStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-stores-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.assignAllStores(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllStores = useCallback(
    async (assignAllStoreData: AssignUnassignAllRequest) => {
      try {
        assignAllStores(assignAllStoreData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllStores]
  );

  return {
    onAssignAllStores,
    isAssignAllStoreLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllStoreService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllStores,
    isPending: isUnassignAllStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-stores-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.unassignAllStores(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllStores = useCallback(
    async (unassignAllStoreData: AssignUnassignAllRequest) => {
      try {
        unassignAllStores(unassignAllStoreData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllStores]
  );

  return {
    onUnassignAllStores,
    isUnassignAllStoreLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationWarehouseService = (initialParams: PageSize, stationId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stations-warehouses-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStationWarehouse({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          stationId: stationId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignWarehouseService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignWarehouse,
    isPending: isAssignWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-warehouses-request'],
    mutationFn: (req: AssignUnassignWarehouseRequest) => StationProfileService.assignWarehouse(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignWarehouse = useCallback(
    async (assignWarehouseData: AssignUnassignWarehouseRequest) => {
      try {
        assignWarehouse(assignWarehouseData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignWarehouse]
  );

  return {
    onAssignWarehouse,
    isAssignWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignWarehouseService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignWarehouse,
    isPending: isUnassignWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-warehouses-request'],
    mutationFn: (req: AssignUnassignWarehouseRequest) => StationProfileService.unassignWarehouse(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignWarehouse = useCallback(
    async (unassignWarehouseData: AssignUnassignWarehouseRequest) => {
      try {
        unassignWarehouse(unassignWarehouseData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignWarehouse]
  );

  return {
    onUnassignWarehouse,
    isUnassignWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllWarehouseService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllWarehouses,
    isPending: isAssignAllWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-warehouses-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.assignAllWarehouses(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllWarehouses = useCallback(
    async (assignAllWarehouseData: AssignUnassignAllRequest) => {
      try {
        assignAllWarehouses(assignAllWarehouseData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllWarehouses]
  );

  return {
    onAssignAllWarehouses,
    isAssignAllWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllWarehouseService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllWarehouses,
    isPending: isUnassignAllWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-warehouses-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.unassignAllWarehouses(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllWarehouses = useCallback(
    async (unassignAllWarehouseData: AssignUnassignAllRequest) => {
      try {
        unassignAllWarehouses(unassignAllWarehouseData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllWarehouses]
  );

  return {
    onUnassignAllWarehouses,
    isUnassignAllWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationUserService = (initialParams: PageSize, stationId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stations-users-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStationUser({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          stationId: stationId,
          name: initialParams.name,
          userName: initialParams.userName,
          type: initialParams.type,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignUserService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignUser,
    isPending: isAssignUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-users-request'],
    mutationFn: (req: AssignUnassignUserRequest) => StationProfileService.assignUser(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignUser = useCallback(
    async (assignUserData: AssignUnassignUserRequest) => {
      try {
        assignUser(assignUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignUser]
  );

  return {
    onAssignUser,
    isAssignUserLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignUserService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignUser,
    isPending: isUnassignUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-users-request'],
    mutationFn: (req: AssignUnassignUserRequest) => StationProfileService.unassignUser(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignUser = useCallback(
    async (unassignUserData: AssignUnassignUserRequest) => {
      try {
        unassignUser(unassignUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignUser]
  );

  return {
    onUnassignUser,
    isUnassignUserLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllUserService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllUsers,
    isPending: isAssignAllUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-users-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.assignAllUsers(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllUsers = useCallback(
    async (assignAllUserData: AssignUnassignAllRequest) => {
      try {
        assignAllUsers(assignAllUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllUsers]
  );

  return {
    onAssignAllUsers,
    isAssignAllUserLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllUserService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllUsers,
    isPending: isUnassignAllUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-users-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.unassignAllUsers(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllUsers = useCallback(
    async (unassignAllUserData: AssignUnassignAllRequest) => {
      try {
        unassignAllUsers(unassignAllUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllUsers]
  );

  return {
    onUnassignAllUsers,
    isUnassignAllUserLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationBayService = (initialParams: PageSize, stationId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stations-bays-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStationBay({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
          stationId: stationId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignBayService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignBay,
    isPending: isAssignBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-bays-request'],
    mutationFn: (req: AssignUnassignBayRequest) => StationProfileService.assignBay(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignBay = useCallback(
    async (assignBayData: AssignUnassignBayRequest) => {
      try {
        assignBay(assignBayData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignBay]
  );

  return {
    onAssignBay,
    isAssignBayLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignBayService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignBay,
    isPending: isUnassignBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-bays-request'],
    mutationFn: (req: AssignUnassignBayRequest) => StationProfileService.unassignBay(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignBay = useCallback(
    async (unassignBayData: AssignUnassignBayRequest) => {
      try {
        unassignBay(unassignBayData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignBay]
  );

  return {
    onUnassignBay,
    isUnassignBayLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllBayService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllBays,
    isPending: isAssignAllBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-bays-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.assignAllBays(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllBays = useCallback(
    async (assignAllBayData: AssignUnassignAllRequest) => {
      try {
        assignAllBays(assignAllBayData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllBays]
  );

  return {
    onAssignAllBays,
    isAssignAllBayLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllBayService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllBays,
    isPending: isUnassignAllBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-bays-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.unassignAllBays(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllBays = useCallback(
    async (unassignAllBayData: AssignUnassignAllRequest) => {
      try {
        unassignAllBays(unassignAllBayData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllBays]
  );

  return {
    onUnassignAllBays,
    isUnassignAllBayLoading,
    data,
    error,
    isError,
  };
};

export const useGetStationBankAccountService = (initialParams: PageSize, stationId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stations-bankaccounts-data'],
      queryFn: async ({ pageParam }) => {
        return StationProfileService.getStationBankAccount({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          stationId: stationId,
          accountTitle: initialParams.accountTitle,
          bankName: initialParams.bankName,
          accountNumber: initialParams.accountNumber,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignBankAccountService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignBankAccount,
    isPending: isAssignBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-bankaccounts-request'],
    mutationFn: (req: AssignUnassignBankAccountRequest) => StationProfileService.assignBankAccount(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignBankAccount = useCallback(
    async (assignBankAccountData: AssignUnassignBankAccountRequest) => {
      try {
        assignBankAccount(assignBankAccountData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignBankAccount]
  );

  return {
    onAssignBankAccount,
    isAssignBankAccountLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignBankAccountService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignBankAccount,
    isPending: isUnassignBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-bankaccounts-request'],
    mutationFn: (req: AssignUnassignBankAccountRequest) => StationProfileService.unassignBankAccount(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignBankAccount = useCallback(
    async (unassignBankAccountData: AssignUnassignBankAccountRequest) => {
      try {
        unassignBankAccount(unassignBankAccountData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignBankAccount]
  );

  return {
    onUnassignBankAccount,
    isUnassignBankAccountLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllBankAccountService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllBankAccounts,
    isPending: isAssignAllBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-bankaccounts-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.assignAllBankAccounts(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllBankAccounts = useCallback(
    async (assignAllBankAccountData: AssignUnassignAllRequest) => {
      try {
        assignAllBankAccounts(assignAllBankAccountData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllBankAccounts]
  );

  return {
    onAssignAllBankAccounts,
    isAssignAllBankAccountLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllBankAccountService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllBankAccounts,
    isPending: isUnassignAllBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-bankaccounts-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StationProfileService.unassignAllBankAccounts(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllBankAccounts = useCallback(
    async (unassignAllBankAccountData: AssignUnassignAllRequest) => {
      try {
        unassignAllBankAccounts(unassignAllBankAccountData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllBankAccounts]
  );

  return {
    onUnassignAllBankAccounts,
    isUnassignAllBankAccountLoading,
    data,
    error,
    isError,
  };
};
