// PriceListProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { PriceListProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignItemRequest,
  AssignPackageRequest,
  AssignServiceRequest,
  AssignUnassignResponse,
  ChangeStatusPriceListResponse,
  DeletePriceListResponse,
  PriceListRequest,
  PriceListResponse,
  UnassignItemRequest,
  UnassignPackageRequest,
  UnassignServiceRequest,
} from './interface';
import { useCallback } from 'react';

type UsePriceListServiceProps = {
  onSuccess: (data: PriceListResponse) => void;
  onError: (err: unknown) => void;
};
type UpdatePriceListServiceProps = {
  onSuccess: (data: PriceListResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeletePriceListServiceProps = {
  onSuccess: (data: DeletePriceListResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusPriceListServiceProps = {
  onSuccess: (data: ChangeStatusPriceListResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};

export const usePriceListService = ({ onError, onSuccess }: UsePriceListServiceProps) => {
  const {
    mutateAsync: createPriceList,
    isPending: isCreatePriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-pricelists-request'],
    mutationFn: (req: PriceListRequest) => PriceListProfileService.createPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreatePriceList = useCallback(
    async (priceListData: PriceListRequest) => {
      try {
        createPriceList(priceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [createPriceList]
  );

  return {
    onCreatePriceList,
    isCreatePriceListLoading,
    data,
    error,
    isError,
  };
};

export const useGetPriceListService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-pricelists-data'],
      queryFn: async ({ pageParam }) => {
        return PriceListProfileService.getPriceList({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetPriceListDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-pricelists-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return PriceListProfileService.getPriceListDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdatePriceListService = ({ id, onError, onSuccess }: UpdatePriceListServiceProps) => {
  const {
    mutateAsync: updatePriceList,
    isPending: isUpdatePriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-pricelists-request'],
    mutationFn: (req: PriceListRequest) => PriceListProfileService.updatePriceList(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePriceList = useCallback(
    async (req: PriceListRequest) => {
      try {
        updatePriceList(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePriceList]
  );

  return {
    onUpdatePriceList,
    isUpdatePriceListLoading,
    data,
    error,
    isError,
  };
};

export const useDeletePriceListService = ({ id, onError, onSuccess }: DeletePriceListServiceProps) => {
  const {
    mutateAsync: deletePriceList,
    isPending: isDeletePriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-pricelist-request'],
    mutationFn: () => PriceListProfileService.deletePriceList(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeletePriceList = useCallback(async () => {
    try {
      deletePriceList();
    } catch (err) {
      console.error(err);
    }
  }, [deletePriceList]);

  return {
    onDeletePriceList,
    isDeletePriceListLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusPriceListService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusPriceListServiceProps) => {
  const {
    mutateAsync: changeStatusPriceList,
    isPending: isChangeStatusPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-pricelist-request'],
    mutationFn: (req: ChangeStatusRequest) => PriceListProfileService.changeStatusPriceList(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusPriceList = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusPriceList(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusPriceList]
  );

  return {
    onChangeStatusPriceList,
    isChangeStatusPriceListLoading,
    data,
    error,
    isError,
  };
};

export const useGetPriceListPackageService = (initialParams: PageSize, pricelistId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-pricelists-packages-data'],
      queryFn: async ({ pageParam }) => {
        return PriceListProfileService.getPriceListPackage({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          pricelistId: pricelistId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignPriceListPackageService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignPackage,
    isPending: isAssignPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-pricelist-packages-request'],
    mutationFn: (req: AssignPackageRequest) => PriceListProfileService.assignPackage(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignPackage = useCallback(
    async (assignPackageData: AssignPackageRequest) => {
      try {
        assignPackage(assignPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignPackage]
  );

  return {
    onAssignPackage,
    isAssignPackageLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignPriceListPackageService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignPackage,
    isPending: isUnassignPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-pricelist-packages-request'],
    mutationFn: (req: UnassignPackageRequest) => PriceListProfileService.unassignPackage(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignPackage = useCallback(
    async (unassignPackageData: UnassignPackageRequest) => {
      try {
        unassignPackage(unassignPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignPackage]
  );

  return {
    onUnassignPackage,
    isUnassignPackageLoading,
    data,
    error,
    isError,
  };
};

export const useGetPriceListServiceService = (initialParams: PageSize, pricelistId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-pricelists-services-data'],
      queryFn: async ({ pageParam }) => {
        return PriceListProfileService.getPriceListService({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          pricelistId: pricelistId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignPriceListServiceService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignService,
    isPending: isAssignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-pricelist-services-request'],
    mutationFn: (req: AssignServiceRequest) => PriceListProfileService.assignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignService = useCallback(
    async (assignServiceData: AssignServiceRequest) => {
      try {
        assignService(assignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignService]
  );

  return {
    onAssignService,
    isAssignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignPriceListServiceService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignService,
    isPending: isUnassignServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-pricelist-services-request'],
    mutationFn: (req: UnassignServiceRequest) => PriceListProfileService.unassignService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignService = useCallback(
    async (unassignServiceData: UnassignServiceRequest) => {
      try {
        unassignService(unassignServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignService]
  );

  return {
    onUnassignService,
    isUnassignServiceLoading,
    data,
    error,
    isError,
  };
};

export const useGetPriceListItemService = (initialParams: PageSize, pricelistId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-pricelists-items-data'],
      queryFn: async ({ pageParam }) => {
        return PriceListProfileService.getPriceListItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          pricelistId: pricelistId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignPriceListItemService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignItem,
    isPending: isAssignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-pricelist-items-request'],
    mutationFn: (req: AssignItemRequest) => PriceListProfileService.assignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignItem = useCallback(
    async (assignItemData: AssignItemRequest) => {
      try {
        assignItem(assignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignItem]
  );

  return {
    onAssignItem,
    isAssignItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignPriceListItemService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignItem,
    isPending: isUnassignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-pricelist-items-request'],
    mutationFn: (req: UnassignItemRequest) => PriceListProfileService.unassignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignItem = useCallback(
    async (unassignItemData: UnassignItemRequest) => {
      try {
        unassignItem(unassignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignItem]
  );

  return {
    onUnassignItem,
    isUnassignItemLoading,
    data,
    error,
    isError,
  };
};

export const useUpdatePriceListItemService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: updatePriceListItem,
    isPending: isUpdatePriceListItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-pricelist-items-request'],
    mutationFn: (req: AssignItemRequest) => PriceListProfileService.updatePriceListItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePriceListItem = useCallback(
    async (UpdatePriceListItemData: AssignItemRequest) => {
      try {
        updatePriceListItem(UpdatePriceListItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePriceListItem]
  );

  return {
    onUpdatePriceListItem,
    isUpdatePriceListItemLoading,
    data,
    error,
    isError,
  };
};

export const useUpdatePriceListServiceService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: updatePriceListService,
    isPending: isUpdatePriceListServiceLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-pricelist-services-request'],
    mutationFn: (req: AssignServiceRequest) => PriceListProfileService.updatePriceListService(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePriceListService = useCallback(
    async (UpdatePriceListServiceData: AssignServiceRequest) => {
      try {
        updatePriceListService(UpdatePriceListServiceData);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePriceListService]
  );

  return {
    onUpdatePriceListService,
    isUpdatePriceListServiceLoading,
    data,
    error,
    isError,
  };
};

export const useUpdatePriceListPackageService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: updatePriceListPackage,
    isPending: isUpdatePriceListPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-pricelist-packages-request'],
    mutationFn: (req: AssignPackageRequest) => PriceListProfileService.updatePriceListPackage(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePriceListPackage = useCallback(
    async (UpdatePriceListPackageData: AssignPackageRequest) => {
      try {
        updatePriceListPackage(UpdatePriceListPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePriceListPackage]
  );

  return {
    onUpdatePriceListPackage,
    isUpdatePriceListPackageLoading,
    data,
    error,
    isError,
  };
};
