import { BaseService } from '../../core/BaseService';
import { LoginRequest, LoginResponse, LogoutRequest, LogoutResponse } from './interface';

export class UserProfile extends BaseService {
  async signIn(req: LoginRequest) {
    return this.post<LoginResponse, LoginRequest>(`${this.apiUrl}/api/auth/signin`, req);
  }

  async signOut(req: LogoutRequest) {
    return this.post<LogoutResponse, LogoutRequest>(`${this.apiUrl}/api/auth/signout`, req);
  }
}
