import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import MakeComponent from 'components/Make';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMakeService, useDeleteMakeService, useGetMakeService, useUpdateMakeService, useChangeStatusMakeService } from 'subModule/src/services/make/useMake';
import { MakeRequest } from 'subModule/src/services/make/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  default: boolean,
  active: boolean
}

const requiredFields = ['title'];

export default function Make() {
  const [showMakeSecField, setShowMakeSecField] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  // const [data] = useState(rows);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [formData, setFormData] = useState<FormData>({
    title: '',
    secondaryTitle: '',
    id: 0,
    default: false,
    active: true
  });

  const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetMakeService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetMakeService({ pageNumber: pageNo, size: pageSize });
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();


  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;


  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm]);
  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchTerm, loadingSearch]);

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1)
    setPageSize(pageSize)
  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize)
    setPageNo(1)
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setIsBtnEnabled(true);
    setEnableEdit(false);
    setErrorMessages({});
    setSearch('');
    setSearchTerm('');
    setShowMakeSecField(false)
    setFormData({
      title: '',
      secondaryTitle: '',
      id: 0,
      default: false,
      active: true
    });
  };

  const handleSecField = () => {
    setShowMakeSecField((prevValue) => !prevValue);
  }

  const handleChange = (field: string, value: any) => {
    const val = (field === "default") ? value ? Boolean(value) : false : value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
    if (enableEdit) {
      setIsBtnEnabled(true);
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.title?.trim()) {
      errors.title = 'Title is required.';
    }

    return errors;
  };

  const { onCreateMake } = useMakeService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      setOpenForm(false);
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('make') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize

    },
    onError: (err) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateMake } = useUpdateMakeService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      setEnableEdit(false);
      setOpenForm(false);
      if (response.success) {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('make') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err) => {
      console.error('Login error======', err, enableEdit);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const makeData: MakeRequest = {
        ...formData,
        title: formData.title,
        secondaryTitle: formData.secondaryTitle,
        default: formData.default
      };

      if (enableEdit) {
        onUpdateMake(
          makeData
        );
      } else {
        onCreateMake(makeData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    setFormData(row);
    setPrevFormData(row);
    setOpenForm(true);
    setIsBtnEnabled(false);
    setSearch('');
    setSearchTerm('');
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteMake } = useDeleteMakeService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('make') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false);
    setLoading(true);
    onDeleteMake();
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setSearch('');
    setSearchTerm('');
    if(searchTerm) { handleSearch(''); }
  }
  const handleSearch = (search: string) => {
    setLoadingSearch(true);
    setSearchTerm(search);
    setPageNo(1);
    setPageSize(pageSize);
  };

  const handleSearchClick = () => {
    const trimmedSearch = search.trim();
    if (trimmedSearch !== '') {
      handleSearch(trimmedSearch);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim());
    if (!event.target.value.trim()) handleSearch('');
  };

  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setStatusModal(false);
    setLoading(true);
    onChangeStatusMake({});
  }
  const { onChangeStatusMake } = useChangeStatusMakeService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('make') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = (row: any) => {
    onEdit(row);
  }
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_MAKE_UPDATE')
      ? (checkPrivileges('ROLE_MAKE_ARCHIVE')
        ? actions
        : actions.filter(action => action.label !== 'delete'))
      : (checkPrivileges('ROLE_MAKE_ARCHIVE')
        ? actions.filter(action => action.label !== 'edit')
        : []);
  }

  return (
    <Box>
      <MakeComponent
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        openModal={openModal}
        closeModal={openModal}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        clearSearch={clearSearch}
        columns={checkPrivileges("ROLE_MAKE_ACTIVE") ? [
          { id: 'title', label: 'title', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'title', label: 'title', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        {...{ openForm, openDeleteModal, enableEdit, showMakeSecField, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, search, loadingSearch }}
        errorMessages={errorMessages}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}