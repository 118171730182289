// ModelEngineProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ModelEngineProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusModelEngineResponse,
  DeleteModelEngineResponse,
  ModelEngineRequest,
  ModelEngineResponse,
} from './interface';
import { useCallback } from 'react';

type UseModelEngineServiceProps = {
  onSuccess: (data: ModelEngineResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateModelEngineServiceProps = {
  onSuccess: (data: ModelEngineResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteModelEngineServiceProps = {
  onSuccess: (data: DeleteModelEngineResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusModelEngineServiceProps = {
  onSuccess: (data: ChangeStatusModelEngineResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useModelEngineService = ({ onError, onSuccess }: UseModelEngineServiceProps) => {
  const {
    mutateAsync: createModelEngine,
    isPending: isCreateModelEngineLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-modelengines-request'],
    mutationFn: (req: ModelEngineRequest) => ModelEngineProfileService.createModelEngine(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateModelEngine = useCallback(
    async (modelEngineData: ModelEngineRequest) => {
      try {
        createModelEngine(modelEngineData);
      } catch (err) {
        console.error(err);
      }
    },
    [createModelEngine]
  );

  return {
    onCreateModelEngine,
    isCreateModelEngineLoading,
    data,
    error,
    isError,
  };
};

export const useGetModelEngineService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-modelengines-data'],
      queryFn: async ({ pageParam }) => {
        return ModelEngineProfileService.getModelEngine({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateModelEngineService = ({ id, onError, onSuccess }: UpdateModelEngineServiceProps) => {
  const {
    mutateAsync: updateModelEngine,
    isPending: isUpdateModelEngineLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-modelengines-request'],
    mutationFn: (req: ModelEngineRequest) => ModelEngineProfileService.updateModelEngine(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateModelEngine = useCallback(
    async (req: ModelEngineRequest) => {
      try {
        updateModelEngine(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateModelEngine]
  );

  return {
    onUpdateModelEngine,
    isUpdateModelEngineLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteModelEngineService = ({ id, onError, onSuccess }: DeleteModelEngineServiceProps) => {
  const {
    mutateAsync: deleteModelEngine,
    isPending: isDeleteModelEngineLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-modelengine-request'],
    mutationFn: () => ModelEngineProfileService.deleteModelEngine(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteModelEngine = useCallback(async () => {
    try {
      deleteModelEngine();
    } catch (err) {
      console.error(err);
    }
  }, [deleteModelEngine]);

  return {
    onDeleteModelEngine,
    isDeleteModelEngineLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusModelEngineService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusModelEngineServiceProps) => {
  const {
    mutateAsync: changeStatusModelEngine,
    isPending: isChangeStatusModelEngineLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-modelEngine-request'],
    mutationFn: (req: ChangeStatusRequest) => ModelEngineProfileService.changeStatusModelEngine(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusModelEngine = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusModelEngine(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusModelEngine]
  );

  return {
    onChangeStatusModelEngine,
    isChangeStatusModelEngineLoading,
    data,
    error,
    isError,
  };
};

export const useGetModelsByMakeService = (initialParams: PageSize, id: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-models-by-make-data'],
      queryFn: async ({ pageParam }) => {
        return ModelEngineProfileService.getModelsByMake(
          {
            page: initialParams.pageNumber ?? pageParam,
            size: initialParams.size,
            title: initialParams.title,
          },
          id
        );
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
