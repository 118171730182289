import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Bank  } from "assets/SideBarIcons/bank.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import BankAccountModalComponent from "./BankAccountModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import Loader from "layout/Loader";
import BankAccountSearchHeader from "./BankAccountSearchHeader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface BankAccountComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  search: string;
  // handleSearchClick: () => void;
  // handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  handleSecField: (field: string) => void;
  showSecAccountTitleField: boolean;
  showSecBankNameField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  formData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  statusModal: boolean;
  statusText: string;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearch: () => void;
  loadingSearch: boolean;
}

const BankAccountComponent: React.FC <BankAccountComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  showSecAccountTitleField,
  showSecBankNameField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  errorMessages,
  actions1,
  openDeleteModal,
  statusModal,
  statusText,
  handleDelete,
  cancelDelete,
  enableEdit,
  // handleSearchClick,
  // handleInputChange,
  searchFormData,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  filter,
  clearSearch,
  search,
  handleChangePage,
  handleChangeRows,
  loadingSearch,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  pageSize,
  showRowView,
  isBtnEnabled,
  loading
}) => {
  const { t } = useTranslation();
 

    return(

        <Box>
            <ComponentHeaderTwo
              showBtn={true}
              showSaveBtn={openForm}
              onClick={openModal}
              onSubmit={handleSubmit}
              heading={(openForm && !enableEdit) ? "addBankAccount" : (enableEdit && openForm) ? "editBankAccount" : t('bankAccounts')}
              icon={<Bank height={28} width={28} />}
              btnText={openForm ? "back" : "create"}
              btnType={openForm ? "back" : "create"}
              isBtnEnabled={isBtnEnabled}
              showCreateBtn={checkPrivileges("ROLE_BANKACCOUNT_CREATE")}
              // btnType="create"
            />

            {openForm ? 
            <BankAccountModalComponent
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              formData={formData}
              handleSecField={handleSecField}
              showSecAccountTitleField={showSecAccountTitleField}
              showSecBankNameField={showSecBankNameField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
            />
           : <Box>
              {loading ? <Loader /> :
                <BackendPaginationTable
                  columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                  data={data.data?.content || []}
                  pageNo={pageNo}
                  pageSize={pageSize}
                  tableData={data?.data || {}}
                  showCheckbox={false}
                  showActions={true}
                  enableSorting={true}
                  showHeader={true}
                  showSearch={true}
                  showFilter={true}
                  showCreateButton={true}
                  actions={actions1}
                  handleChangePage={handleChangePage}
                  handleChangeRows={handleChangeRows}
                  onChangeStatus={onChangeStatus}
                  rowClick={checkPrivileges("ROLE_BANKACCOUNT_UPDATE")}
                  showRowView={showRowView}
                  loadingSearch={loadingSearch}
                  headerComponent={
                    <BankAccountSearchHeader 
                    searchFormData={searchFormData}
                    handleChangeFilter={handleChangeFilter}
                    handleChangeSearch={handleChangeSearch}
                    handleSubmitSearch={handleSubmitSearch}
                    searchErrorMessages={searchErrorMessages}
                    clearSearch={clearSearch}
                    {...{filter, search}}
                  />
                    }
                />
              }
            </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title="Delete Bank Account"
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    Are you sure you want to Delete?
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
        {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

        </Box>

    )
}

export default BankAccountComponent;