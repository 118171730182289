import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Statuses } from "assets/SideBarIcons/status.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import VehicleStatusModalComponent from "./VehicleStatusModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface VehicleStatusComponentProps {
  openModal: () => void;
  primaryLanguage: string;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  formData: any;
  openForm: boolean;
  columns: any[];
  data: any;
  errorMessages: any,
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  showRowView: (row: any) => void;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  clearSearch: () => void;
}

const VehicleStatusComponent: React.FC<VehicleStatusComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  primaryLanguage,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  loadingSearch,
  pageNo,
  pageSize,
  showRowView,
  isBtnEnabled,
  loading,
  clearSearch,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={!enableEdit && openForm ? "addVehicleStatus" : enableEdit && openForm ? "editVehicleStatus" : t('VehicleStatuses')}
        icon={<Statuses height={28} width={28} />}
        btnText={openForm ? "back" : "create"}
        btnType={openForm ? "back" : "create"}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_BUSINESSSTATUS_CREATE")}
      // btnType="create"
      />

      {openForm ?
        <VehicleStatusModalComponent
          primaryLanguage={primaryLanguage}
          enableEdit={enableEdit}
          openModal={openModal}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          formData={formData}
          openForm={openForm}
          errorMessages={errorMessages}
          isBtnEnabled={isBtnEnabled}
        />
        : <Box>
          {loading ? <Loader /> :
            <BackendPaginationTable
              columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
              data={data.data?.content || []}
              pageNo={pageNo}
              pageSize={pageSize}
              tableData={data?.data || {}}
              showCheckbox={false}
              showActions={true}
              enableSorting={true}
              showHeader={true}
              showSearch={true}
              showFilter={true}
              showCreateButton={true}
              actions={actions1}
              handleChangePage={handleChangePage}
              handleChangeRows={handleChangeRows}
              rowClick={checkPrivileges("ROLE_BUSINESSSTATUS_UPDATE")}
              showRowView={showRowView}
              loadingSearch={loadingSearch}
              headerComponent={
                <Grid container spacing={2}>
                  <Grid item xs={12} md={7} lg={5}>
                    <StyledInput
                      fullWidth
                      placeholder={t("name")}
                      handleSearchClick={handleSearchClick}
                      handleChange={handleInputChange}
                      value={search}
                      clearSearch={clearSearch}
                    />
                  </Grid>
                </Grid>
              }
            />
          }
        </Box>
      }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title="Delete Vehicle Status"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  Are you sure you want to Delete?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}
    </Box>

  )
}

export default VehicleStatusComponent;