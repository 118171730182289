import React from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';

interface cardSummaryProps {
    data: any;
}

const CardSummary: React.FC<cardSummaryProps> = ({data}) => {
    const { t } = useTranslation();

    return (
        <Grid container className="mt-4 flex justify-end">
            <Grid item xs={12} sm={8} md={6} lg={3} xl={3} className="bg-[#F65F3D] rounded-xl p-2">
                <Grid item xs={12} className=" border-b flex items-center justify-between border-white">
                    <Typography className="font-Saira text-white text-start font-[400] text-[12px] leading-[35px]" >
                        {t("subTotal")}
                    </Typography>
                    <Typography className="font-Saira text-white text-start font-[400] text-[12px] leading-[35px]" >
                        {data?.subtotalAmount}
                    </Typography>
                </Grid>
                <Grid item xs={12} className=" border-b flex items-center justify-between border-white">
                    <Typography className="font-Saira text-white text-start font-[400] text-[12px] leading-[35px]" >
                        {t("tax")}
                    </Typography>
                    <Typography className="font-Saira text-white text-start font-[400] text-[12px] leading-[35px]" >
                        {data?.taxAmount}
                    </Typography>
                </Grid>
                <Grid item xs={12} className=" border-b flex items-center justify-between border-white">
                    <Typography className="font-Saira text-white italic text-start font-[500] text-[12px] leading-[35px]" >
                        {t("subTotalWithTax")}
                    </Typography>
                    <Typography className="font-Saira text-white italic text-start font-[500] text-[12px] leading-[35px]" >
                        {parseFloat(data?.subtotalAmount) + parseFloat(data?.taxAmount)}
                    </Typography>
                </Grid>
                <Grid item xs={12} className=" border-b flex items-center justify-between border-white">
                    <Typography className="font-Saira text-white italic text-start font-[500] text-[12px] leading-[35px]" >
                        {t("Discount")}
                    </Typography>
                    <Typography className="font-Saira text-white italic text-start font-[500] text-[12px] leading-[35px]" >
                        {data?.discount}
                    </Typography>
                </Grid>
                <Grid item xs={12} className=" flex items-center justify-between">
                    <Typography className="font-Saira text-primary-color text-start font-[600] text-[18px] leading-[35px]" >
                        {t("total")}
                    </Typography>
                    <Typography className="font-Saira text-white text-start font-[600] text-[14px] leading-[35px]" >
                        {data?.totalAmount}
                    </Typography>
                </Grid>

            </Grid>
        </Grid>
    );
};

export default CardSummary;