import React from "react";
import ButtonNew from "common/Button/Button"
// import ButtonNew from '../../common/Button/Button'
import { useTranslation } from "react-i18next";

interface DynamicDiv {
  heading: string;
  value: string;
}

interface ComponentHeaderTwoProps {
  showBtn?: boolean;
  onClick?: () => void;
  onSubmit?: () => void;
  heading?: string;
  subHeading1?: string;
  subHeading2?: string;
  icon?: React.ReactNode;
  mainImg?: React.ReactNode;
  dynamicDivs?: DynamicDiv[];
  showDetails?:boolean;
  btnText?: string;
  btnType?: string;
  showSaveBtn?: boolean;
  enableEdit?: boolean;
  isBtnEnabled?: boolean;
  showCreateBtn?: boolean;
}

const ComponentHeaderTwo: React.FC<ComponentHeaderTwoProps> = ({
  showBtn = false,
  onClick = () => {},
  onSubmit = () => {},
  heading,
  icon,
  mainImg,
  subHeading1,
  subHeading2,
  dynamicDivs = [],
  showDetails,
  btnText,
  btnType,
  showSaveBtn,
  enableEdit,
  isBtnEnabled,
  showCreateBtn
}) => {
  const {t} = useTranslation();
  // const { i18n } = useTranslation();

    return (
    <div
    className={`flex bg-primary-color justify-between items-center rounded-lg py-1 px-5 text-white `}
      style={{
        minHeight: showDetails ? "100px" : "72px",
      }}
    >
      <div style={{ display: "flex" }}>
        <span style={{ marginRight: "20px" }}>{mainImg}</span>
        <div style={{ display:showDetails ? '': "flex" }}>
          <div style={{ display: "flex" }}>
            <span className="invert" style={{ marginRight: "4px" }}>{icon}</span>
            <h1 className="mt-1 font-Saira font-bold text-lg ml-1 line-clamp-1" >{heading && t(heading)}</h1>
          </div>
          <h1 className="float-left font-Saira font-bold text-sm mt-1	">{subHeading1 && t(subHeading1)}</h1>
          <br />
          <h1 className="float-left font-Saira font-semibold text-sm italic	">{subHeading2 && t(subHeading2)}</h1>
        </div>
      </div>

      <div style={{ display: "flex" }}>
        {dynamicDivs.map((div, index) => (
          <div key={index} className="border-r border-white border-solid p-[6px]" >
            <h1 className="text-white font-Saira font-[500] text-sm">{div.heading}</h1>
            <h1 className="text-white font-Saira font-bold text-[16px]">{div.value}</h1>
          </div>
        ))}
      </div>
<div className="flex pl-3">
          
     
      {showBtn && (
        <div style={{marginLeft:"10px"}}>
          {showCreateBtn && btnType === 'create' ? 
              <ButtonNew
                onClick={onClick}
                type={btnType}
                disabled={false}
                text={btnText}
                hasIcon
              ></ButtonNew> 
            : !showCreateBtn && btnType === 'create' ? null
            : <ButtonNew
                onClick={onClick}
                type={btnType}
                disabled={false}
                text={btnText}
                hasIcon
              ></ButtonNew>
          }
        </div>
      )}
       {showSaveBtn && (
      <div style={{marginLeft:"10px"}}>
          <ButtonNew
            onClick={onSubmit}
            type={!isBtnEnabled ? "disabled" : "create"}
            disabled={!isBtnEnabled}
            text={enableEdit ? "update" : "save"}
            hasIcon
          ></ButtonNew>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComponentHeaderTwo;
