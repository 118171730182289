// ItemCategoryService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusItemCategoryResponse,
  DeleteItemCategoryResponse,
  GetItemCategoryResponse,
  ItemCategoryRequest,
  ItemCategoryResponse,
} from './interface';

export class ItemCategoryProfile extends BaseService {
  async createItemCategory(req: ItemCategoryRequest) {
    return this.post<ItemCategoryResponse, ItemCategoryRequest>(`${this.apiUrl}/api/itemcategories`, req);
  }

  async getItemCategory(params: PaginationParams) {
    return this.get<GetItemCategoryResponse>(`${this.apiUrl}/api/itemcategories/paged`, params);
  }

  async updateItemCategory(id: number, req: ItemCategoryRequest) {
    return this.put<ItemCategoryResponse, ItemCategoryRequest>(`${this.apiUrl}/api/itemcategories/${id}`, req);
  }

  async deleteItemCategory(id: number) {
    return this.delete<DeleteItemCategoryResponse>(`${this.apiUrl}/api/itemcategories/${id}`);
  }

  async changeStatusItemCategory(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusItemCategoryResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/itemcategories/${active}/${id}`,
      req
    );
  }
}
