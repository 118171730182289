// UnitsProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { UnitsProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { ChangeStatusUnitsResponse, DeleteUnitsResponse, UnitsRequest, UnitsResponse } from './interface';
import { useCallback } from 'react';

type UseUnitsServiceProps = {
  onSuccess: (data: UnitsResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateUnitsServiceProps = {
  onSuccess: (data: UnitsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteUnitsServiceProps = {
  onSuccess: (data: DeleteUnitsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusUnitsServiceProps = {
  onSuccess: (data: ChangeStatusUnitsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useUnitsService = ({ onError, onSuccess }: UseUnitsServiceProps) => {
  const {
    mutateAsync: createUnits,
    isPending: isCreateUnitsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-Units-request'],
    mutationFn: (req: UnitsRequest) => UnitsProfileService.createUnits(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateUnits = useCallback(
    async (UnitsData: UnitsRequest) => {
      try {
        createUnits(UnitsData);
      } catch (err) {
        console.error(err);
      }
    },
    [createUnits]
  );

  return {
    onCreateUnits,
    isCreateUnitsLoading,
    data,
    error,
    isError,
  };
};

export const useGetUnitsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Units-data'],
      queryFn: async ({ pageParam }) => {
        return UnitsProfileService.getUnits({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          unitTitle: initialParams.unitTitle,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetUnitsDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Units-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return UnitsProfileService.getUnitsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateUnitsService = ({ id, onError, onSuccess }: UpdateUnitsServiceProps) => {
  const {
    mutateAsync: updateUnits,
    isPending: isUpdateUnitsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-Units-request'],
    mutationFn: (req: UnitsRequest) => UnitsProfileService.updateUnits(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateUnits = useCallback(
    async (req: UnitsRequest) => {
      try {
        updateUnits(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateUnits]
  );

  return {
    onUpdateUnits,
    isUpdateUnitsLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteUnitsService = ({ id, onError, onSuccess }: DeleteUnitsServiceProps) => {
  const {
    mutateAsync: deleteUnits,
    isPending: isDeleteUnitsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-Units-request'],
    mutationFn: () => UnitsProfileService.deleteUnits(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteUnits = useCallback(async () => {
    try {
      deleteUnits();
    } catch (err) {
      console.error(err);
    }
  }, [deleteUnits]);

  return {
    onDeleteUnits,
    isDeleteUnitsLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusUnitsService = ({ active, id, onError, onSuccess }: ChangeStatusUnitsServiceProps) => {
  const {
    mutateAsync: changeStatusUnits,
    isPending: isChangeStatusUnitsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-units-request'],
    mutationFn: (req: ChangeStatusRequest) => UnitsProfileService.changeStatusUnits(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusUnits = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusUnits(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusUnits]
  );

  return {
    onChangeStatusUnits,
    isChangeStatusUnitsLoading,
    data,
    error,
    isError,
  };
};
