// BankAccountProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { BankAccountProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  BankAccountRequest,
  BankAccountResponse,
  ChangeStatusBankAccountResponse,
  DeleteBankAccountResponse,
} from './interface';
import { useCallback } from 'react';

type UseBankAccountServiceProps = {
  onSuccess: (data: BankAccountResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateBankAccountServiceProps = {
  onSuccess: (data: BankAccountResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteBankAccountServiceProps = {
  onSuccess: (data: DeleteBankAccountResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusBankAccountServiceProps = {
  onSuccess: (data: ChangeStatusBankAccountResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useBankAccountService = ({ onError, onSuccess }: UseBankAccountServiceProps) => {
  const {
    mutateAsync: createBankAccount,
    isPending: isCreateBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-bankaccount-request'],
    mutationFn: (req: BankAccountRequest) => BankAccountProfileService.createBankAccount(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateBankAccount = useCallback(
    async (bankAccountData: BankAccountRequest) => {
      try {
        createBankAccount(bankAccountData);
      } catch (err) {
        console.error(err);
      }
    },
    [createBankAccount]
  );

  return {
    onCreateBankAccount,
    isCreateBankAccountLoading,
    data,
    error,
    isError,
  };
};

export const useGetBankAccountsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-bankaccounts-data'],
      queryFn: async ({ pageParam }) => {
        return BankAccountProfileService.getBankAccounts({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          bankName: initialParams.bankName,
          accountNumber: initialParams.accountNumber,
          accountTitle: initialParams.accountTitle,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetBankAccountsDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-bankaccounts-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return BankAccountProfileService.getBankAccountsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          bankName: initialParams.bankName,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateBankAccountService = ({ id, onError, onSuccess }: UpdateBankAccountServiceProps) => {
  const {
    mutateAsync: updateBankAccount,
    isPending: isUpdateBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-bankaccount-request'],
    mutationFn: (req: BankAccountRequest) => BankAccountProfileService.updateBankAccount(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateBankAccount = useCallback(
    async (req: BankAccountRequest) => {
      try {
        updateBankAccount(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateBankAccount]
  );

  return {
    onUpdateBankAccount,
    isUpdateBankAccountLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteBankAccountService = ({ id, onError, onSuccess }: DeleteBankAccountServiceProps) => {
  const {
    mutateAsync: deleteBankAccount,
    isPending: isDeleteBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-bankaccount-request'],
    mutationFn: () => BankAccountProfileService.deleteBankAccount(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteBankAccount = useCallback(async () => {
    try {
      deleteBankAccount();
    } catch (err) {
      console.error(err);
    }
  }, [deleteBankAccount]);

  return {
    onDeleteBankAccount,
    isDeleteBankAccountLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusBankAccountService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusBankAccountServiceProps) => {
  const {
    mutateAsync: changeStatusBankAccount,
    isPending: isChangeStatusBankAccountLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-bankaccount-request'],
    mutationFn: (req: ChangeStatusRequest) => BankAccountProfileService.changeStatusBankAccount(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusBankAccount = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusBankAccount(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusBankAccount]
  );

  return {
    onChangeStatusBankAccount,
    isChangeStatusBankAccountLoading,
    data,
    error,
    isError,
  };
};
