// InvoiceProfileService.tsx
import { useInfiniteQuery } from '@tanstack/react-query';
import { InvoiceProfileService } from '../../core/services';
import { PageSize } from '../../type';

export const useGetInvoiceService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-invoices-data'],
      queryFn: async ({ pageParam }) => {
        return InvoiceProfileService.getInvoice({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          invoiceNo: initialParams.invoiceNo,
          stationId: initialParams.stationId,
          vehiclePlate: initialParams.vehiclePlate,
          vehicleVin: initialParams.vehicleVin,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
