import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';

interface UpdatePasswordComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleUpdatePassword: (field: string, value: any) => void;
  handleSubmitUpdatePassword: () => void;
  updatePasswordFormData: any;
  openForm: boolean;
  updatePasswordErrorMessages:{ [key: string]: string };
  enableEdit?:boolean;
}

const UpdatePasswordComponent: React.FC<UpdatePasswordComponentProps> = ({
  closeModal,
  handleUpdatePassword,
  handleSubmitUpdatePassword,
  updatePasswordFormData,
  openForm,
  updatePasswordErrorMessages,
}) => {

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={"updatePassword"}
        isButtonVisable={true}
        isVisableBtn={true}
        btnclosetext="Back"
        buttonText="submit"
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmitUpdatePassword} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmitUpdatePassword}
        content={
          <Grid container spacing={2}>
            <Grid item  xs={12} sm={6} md={4} lg={3} className='relative'>
                <StyledField
                  type="password"
                  label="newPassword"
                  handleChange={(e) => handleUpdatePassword('newPassword', e.target.value)}
                  required={true}
                  fullWidth
                  error={updatePasswordErrorMessages?.newPassword}
                  value={updatePasswordFormData?.newPassword || ''}
                  search="showPassword"
                />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3} className='relative'>
                <StyledField
                  type="password"
                  label="confirmNewPassword"
                  handleChange={(e) => handleUpdatePassword('confirmNewPassword', e.target.value)}
                  required={true}
                  fullWidth
                  error={updatePasswordErrorMessages?.confirmNewPassword}
                  value={updatePasswordFormData?.confirmNewPassword || ''}
                  search="showPassword"
                />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default UpdatePasswordComponent;