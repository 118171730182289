import { Box,Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Client  } from "assets/SideBarIcons/customer.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ClientModal from "./ClientModal";
import FormDialog from "common/Modal/Modal";
import ClientSearchHeader from "./ClientSearchHeader";
import { useTranslation } from 'react-i18next';
import Loader from "layout/Loader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import UpdatePasswordComponent from "./UpdatePassword";
interface ClientComponentProps{
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any, section: 'user' | 'account') => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText: string;
  formData: any;
  // rolesData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  // isVisableAddBtn:boolean;
  errorMessages: any,
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearch: () => void;
  search: any;
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean,
  pageNo:number;
  pageSize:number;
  isBtnEnabled: boolean;
  handleSecField: (field: string) => void;
  showSecAccountField: boolean;
  showSecUserField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  loadingSearch: boolean;
  loading: boolean;
  showRowView: (row: any) => void;
  // loadNext: (field: string, scroll: any) => void;
  // loadPrev: (field: string, scroll: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  handleUpdatePassword: (field: string, value: any) => void;
  updatePasswordFormData: any;
  updatePasswordErrorMessages: any;
  handleSubmitUpdatePassword: () => void;
}
const ClientComponent : React.FC<ClientComponentProps>=({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  // rolesData,
  openForm,
  columns,
  data,
  // isVisableAddBtn,
  showSecAccountField,
  showSecUserField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  errorMessages,
  searchFormData,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  filter,
  clearSearch,
  search,
  actions1,
  loadingSearch,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,
  statusText,
  pageSize,
  isBtnEnabled,
  loading,
  showRowView,
  // loadNext,
  // loadPrev,
  rowView,
  handleChangeTabs,
  handleUpdatePassword,
  updatePasswordFormData,
  updatePasswordErrorMessages,
  handleSubmitUpdatePassword
}) => {
  const { t } = useTranslation();
  const tabs = [
    { 
      id: 0, title: `edit`, child: <ClientModal
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleSecField={handleSecField}
        showSecAccountField={showSecAccountField}
        showSecUserField={showSecUserField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        formData={formData}
        // rolesData={rolesData.data?.content || []}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
        // loadNext={loadNext}
        // loadPrev={loadPrev}
      />
    },
    { id: 1, title: `updatePassword`, child: <UpdatePasswordComponent 
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleUpdatePassword={handleUpdatePassword}
        handleSubmitUpdatePassword={handleSubmitUpdatePassword}
        updatePasswordFormData={updatePasswordFormData}
        openForm={true}
        updatePasswordErrorMessages={updatePasswordErrorMessages}
      />
    },
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_CLIENT_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_CHANGE_PASSWORD');
      default:
        return false;
    }
  });

    return(
        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            enableEdit={enableEdit}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addClient" : (enableEdit && openForm || rowView) ? "editClient" : t('Clients')}
            icon={<Client height={28} width={28} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_CLIENT_CREATE")}
            // btnType="create"
          />

        {openForm ? 
          <ClientModal
            // isVisableAddBtn={isVisableAddBtn}
            enableEdit={enableEdit}
            openModal={openModal}
            closeModal={closeModal}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleSecField={handleSecField}
            showSecAccountField={showSecAccountField}
            showSecUserField={showSecUserField}
            secondaryLanguage={secondaryLanguage}
            primaryLanguage={primaryLanguage}
            formData={formData}
            // rolesData={rolesData.data?.content || []}
            openForm={openForm}
            errorMessages={errorMessages}
            isBtnEnabled={isBtnEnabled}
            // loadNext={loadNext}
            // loadPrev={loadPrev}
          />
          : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
          : <Box>
            {loading ? <Loader /> :
              <BackendPaginationTable
                columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                data={data.data?.content || []}
                // data={tableData?.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={data?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={actions1}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={checkPrivileges("ROLE_CLIENT_UPDATE")}
                showRowView={showRowView}
                loadingSearch={loadingSearch}
                headerComponent={
                  <ClientSearchHeader 
                    searchFormData={searchFormData}
                    handleChangeFilter={handleChangeFilter}
                    handleChangeSearch={handleChangeSearch}
                    handleSubmitSearch={handleSubmitSearch}
                    searchErrorMessages={searchErrorMessages}
                    clearSearch={clearSearch}
                    {...{filter, search}}
                  />
                }
              />
            }
          </Box>
        }

  {openDeleteModal && 
        <FormDialog
            open={openDeleteModal}
            onClose={cancelDelete}
            title="Delete User"
            // icon={<div><Home/></div>}
            content={  
                <Grid container >
              <Grid item  xs={12} sm={12} md={12} lg={12}  >
              <Typography>
                Are you sure you want to Delete? 
              </Typography>
              <Typography style={{fontWeight:"600"}}>
                {formData.name || ''}
              </Typography>
              </Grid>
              </Grid>
              }
            saveFunction={handleDelete}
            submitButtonText="Delete"
          />}

       {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }


    </Box>
    )
}
export default ClientComponent;