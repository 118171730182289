import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as User} from '../../assets/SideBarIcons/users.svg';
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import UserModalComponent from "./UserModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import Loader from "layout/Loader";
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import UpdatePasswordComponent from "./UpdatePassword";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import UserSearchHeader from "./UserSearchHeader";

interface UserComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any, section: 'user' | 'business') => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText: string;
  formData: any;
  openForm: boolean;
  handleSecField: (field: string) => void;
  showSecUsersField:boolean;
  showSecBusinessField:boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  columns: any[]; 
  data: any;
  rolesData: any;
  errorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  isBtnEnabled: boolean;
  loading: boolean;
  loadingSearch: boolean;
  showRowView: (row: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  handleUpdatePassword: (field: string, value: any) => void;
  updatePasswordFormData: any;
  updatePasswordErrorMessages: any;
  handleSubmitUpdatePassword: () => void;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  scrapItemGroupsData: any;
  taxProceduresData: any;
  priceListData: any;
  userTypesOption: any;
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearch: () => void;
  search: any;
}

const UserComponent: React.FC <UserComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  showSecUsersField,
  showSecBusinessField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  data,
  rolesData,
  errorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,
  statusText,
  pageSize,
  isBtnEnabled,
  loadingSearch,
  loading,
  showRowView,
  rowView,
  handleChangeTabs,
  handleUpdatePassword,
  updatePasswordFormData,
  updatePasswordErrorMessages,
  handleSubmitUpdatePassword,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  scrapItemGroupsData,
  taxProceduresData,
  priceListData,
  userTypesOption,
  searchFormData,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  filter,
  clearSearch,
  search,
}) => {
  const { t } = useTranslation();

  const tabs = [
    { 
      id: 0, title: `edit`, child: <UserModalComponent
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleSecField={handleSecField}
        showSecUsersField={showSecUsersField}
        showSecBusinessField={showSecBusinessField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        rolesData={rolesData.data?.content || []}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
        loadNext={loadNext}
        loadPrev={loadPrev}
        handleDropdownSearch={handleDropdownSearch}
        loadingDropdownSearch={loadingDropdownSearch}
        scrapItemGroupsData={scrapItemGroupsData.data?.content || []}
        taxProceduresData={taxProceduresData.data?.content || []}
        priceListData={priceListData.data?.content || []}
        {...{userTypesOption}}
      />
    },
    { id: 1, title: `updatePassword`, child: <UpdatePasswordComponent 
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleUpdatePassword={handleUpdatePassword}
        handleSubmitUpdatePassword={handleSubmitUpdatePassword}
        updatePasswordFormData={updatePasswordFormData}
        openForm={true}
        updatePasswordErrorMessages={updatePasswordErrorMessages}
      />
    },
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_USER_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_CHANGE_PASSWORD');
      default:
        return false;
    }
  });

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addUser" : (enableEdit && openForm || rowView) ? "editUser" : t('users')}
            icon={<User height={28} width={28} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            // btnType="create"
            showCreateBtn={checkPrivileges("ROLE_USER_CREATE")}
          />

          {openForm ? 
            <UserModalComponent
              enableEdit={enableEdit}
              openModal={openModal}
              closeModal={closeModal}
              handleChange={handleChange}
              handleSecField={handleSecField}
              showSecUsersField={showSecUsersField}
              showSecBusinessField={showSecBusinessField}
              secondaryLanguage={secondaryLanguage}
              primaryLanguage={primaryLanguage}
              handleSubmit={handleSubmit}
              formData={formData}
              rolesData={rolesData.data?.content || []}
              openForm={openForm}
              errorMessages={errorMessages}
              isBtnEnabled={isBtnEnabled}
              loadNext={loadNext}
              loadPrev={loadPrev}
              handleDropdownSearch={handleDropdownSearch}
              loadingDropdownSearch={loadingDropdownSearch}
              scrapItemGroupsData={scrapItemGroupsData.data?.content || []}
              taxProceduresData={taxProceduresData.data?.content || []}
              priceListData={priceListData.data?.content || []}
              {...{userTypesOption}}
            />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
              : <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={disableTabs.length === tabs.length ? false : true}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={
                      <UserSearchHeader 
                        searchFormData={searchFormData}
                        handleChangeFilter={handleChangeFilter}
                        handleChangeSearch={handleChangeSearch}
                        handleSubmitSearch={handleSubmitSearch}
                        searchErrorMessages={searchErrorMessages}
                        clearSearch={clearSearch}
                        {...{filter, search, userTypesOption}}
                      />
                    }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title="Delete User"
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    Are you sure you want to Delete?
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}

          {statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }



        </Box>

    )
}

export default UserComponent;