const currency: { title: string; value: string; symbol: string }[] = [
  { "title": "AED - United Arab Emirates Dirham (د.إ)", "value": "aed", "symbol": "AED" },
  { "title": "AFN - Afghan Afghani (؋)", "value": "afn", "symbol": "AFN" },
  { "title": "ALL - Albanian Lek (Lek)", "value": "all", "symbol": "ALL" },
  { "title": "AMD - Armenian Dram (֏)", "value": "amd", "symbol": "AMD" },
  { "title": "ANG - Netherlands Antillean Guilder (ƒ)", "value": "ang", "symbol": "ANG" },
  { "title": "AOA - Angolan Kwanza (Kz)", "value": "aoa", "symbol": "AOA" },
  { "title": "ARS - Argentine Peso ($)", "value": "ars", "symbol": "ARS" },
  { "title": "AUD - Australian Dollar ($)", "value": "aud", "symbol": "AUD" },
  { "title": "AWG - Aruban Florin (ƒ)", "value": "awg", "symbol": "AWG" },
  { "title": "AZN - Azerbaijani Manat (₼)", "value": "azn", "symbol": "AZN" },
  { "title": "BAM - Bosnia-Herzegovina Convertible Mark (KM)", "value": "bam", "symbol": "BAM" },
  { "title": "BBD - Barbadian Dollar ($)", "value": "bbd", "symbol": "BBD" },
  { "title": "BDT - Bangladeshi Taka (৳)", "value": "bdt", "symbol": "BDT" },
  { "title": "BGN - Bulgarian Lev (лв)", "value": "bgn", "symbol": "BGN" },
  { "title": "BHD - Bahraini Dinar (ب.د)", "value": "bhd", "symbol": "BHD" },
  { "title": "BIF - Burundian Franc (FBu)", "value": "bif", "symbol": "BIF" },
  { "title": "BMD - Bermudian Dollar ($)", "value": "bmd", "symbol": "BMD" },
  { "title": "BND - Brunei Dollar ($)", "value": "bnd", "symbol": "BND" },
  { "title": "BOB - Bolivian Boliviano (Bs.)", "value": "bob", "symbol": "BOB" },
  { "title": "BRL - Brazilian Real (R$)", "value": "brl", "symbol": "BRL" },
  { "title": "BSD - Bahamian Dollar ($)", "value": "bsd", "symbol": "BSD" },
  { "title": "BTN - Bhutanese Ngultrum (Nu.)", "value": "btn", "symbol": "BTN" },
  { "title": "BWP - Botswanan Pula (P)", "value": "bwp", "symbol": "BWP" },
  { "title": "BYN - Belarusian Ruble (Br)", "value": "byn", "symbol": "BYN" },
  { "title": "BZD - Belize Dollar (BZ$)", "value": "bzd", "symbol": "BZD" },
  { "title": "CAD - Canadian Dollar ($)", "value": "cad", "symbol": "CAD" },
  { "title": "CDF - Congolese Franc (FC)", "value": "cdf", "symbol": "CDF" },
  { "title": "CHF - Swiss Franc (Fr.)", "value": "chf", "symbol": "CHF" },
  { "title": "CLP - Chilean Peso ($)", "value": "clp", "symbol": "CLP" },
  { "title": "CNY - Chinese Yuan (¥)", "value": "cny", "symbol": "CNY" },
  { "title": "COP - Colombian Peso ($)", "value": "cop", "symbol": "COP" },
  { "title": "CRC - Costa Rican Colón (₡)", "value": "crc", "symbol": "CRC" },
  { "title": "CUP - Cuban Peso (₱)", "value": "cup", "symbol": "CUP" },
  { "title": "CVE - Cape Verdean Escudo (Esc)", "value": "cve", "symbol": "CVE" },
  { "title": "CZK - Czech Republic Koruna (Kč)", "value": "czk", "symbol": "CZK" },
  { "title": "DJF - Djiboutian Franc (Fdj)", "value": "djf", "symbol": "DJF" },
  { "title": "DKK - Danish Krone (kr)", "value": "dkk", "symbol": "DKK" },
  { "title": "DOP - Dominican Peso (RD$)", "value": "dop", "symbol": "DOP" },
  { "title": "DZD - Algerian Dinar (د.ج)", "value": "dzd", "symbol": "DZD" },
  { "title": "EGP - Egyptian Pound (£)", "value": "egp", "symbol": "EGP" },
  { "title": "ERN - Eritrean Nakfa (Nfk)", "value": "ern", "symbol": "ERN" },
  { "title": "ETB - Ethiopian Birr (Br)", "value": "etb", "symbol": "ETB" },
  { "title": "EUR - Euro (€)", "value": "eur", "symbol": "EUR" },
  { "title": "FJD - Fijian Dollar ($)", "value": "fjd", "symbol": "FJD" },
  { "title": "FKP - Falkland Islands Pound (£)", "value": "fkp", "symbol": "FKP" },
  { "title": "GBP - British Pound Sterling (£)", "value": "gbp", "symbol": "GBP" },
  { "title": "GEL - Georgian Lari (₾)", "value": "gel", "symbol": "GEL" },
  { "title": "GGP - Guernsey Pound (£)", "value": "ggp", "symbol": "GGP" },
  { "title": "GHS - Ghanaian Cedi (₵)", "value": "ghs", "symbol": "GHS" },
  { "title": "GIP - Gibraltar Pound (£)", "value": "gip", "symbol": "GIP" },
  { "title": "GMD - Gambian Dalasi (D)", "value": "gmd", "symbol": "GMD" },
  { "title": "GNF - Guinean Franc (FG)", "value": "gnf", "symbol": "GNF" },
  { "title": "GTQ - Guatemalan Quetzal (Q)", "value": "gtq", "symbol": "GTQ" },
  { "title": "GYD - Guyanaese Dollar ($)", "value": "gyd", "symbol": "GYD" },
  { "title": "HKD - Hong Kong Dollar (HK$)", "value": "hkd", "symbol": "HKD" },
  { "title": "HNL - Honduran Lempira (L)", "value": "hnl", "symbol": "HNL" },
  { "title": "HRK - Croatian Kuna (kn)", "value": "hrk", "symbol": "HRK" },
  { "title": "HTG - Haitian Gourde (G)", "value": "htg", "symbol": "HTG" },
  { "title": "HUF - Hungarian Forint (Ft)", "value": "huf", "symbol": "HUF" },
  { "title": "IDR - Indonesian Rupiah (Rp)", "value": "idr", "symbol": "IDR" },
  { "title": "ILS - Israeli New Sheqel (₪)", "value": "ils", "symbol": "ILS" },
  { "title": "IMP - Isle of Man Pound (£)", "value": "imp", "symbol": "IMP" },
  { "title": "INR - Indian Rupee (₹)", "value": "inr", "symbol": "INR" },
  { "title": "IQD - Iraqi Dinar (د.ع)", "value": "iqd", "symbol": "IQD" },
  { "title": "IRR - Iranian Rial (﷼)", "value": "irr", "symbol": "IRR" },
  { "title": "ISK - Icelandic Króna (kr)", "value": "isk", "symbol": "ISK" },
  { "title": "JEP - Jersey Pound (£)", "value": "jep", "symbol": "JEP" },
  { "title": "JMD - Jamaican Dollar (J$)", "value": "jmd", "symbol": "JMD" },
  { "title": "JOD - Jordanian Dinar (د.ا)", "value": "jod", "symbol": "JOD" },
  { "title": "JPY - Japanese Yen (¥)", "value": "jpy", "symbol": "JPY" },
  { "title": "KES - Kenyan Shilling (KSh)", "value": "kes", "symbol": "KES" },
  { "title": "KGS - Kyrgystani Som (сом)", "value": "kgs", "symbol": "KGS" },
  { "title": "KHR - Cambodian Riel (៛)", "value": "khr", "symbol": "KHR" },
  { "title": "KID - Kiribati Dollar ($)", "value": "kid", "symbol": "KID" },
  { "title": "KMF - Comorian Franc (CF)", "value": "kmf", "symbol": "KMF" },
  { "title": "KPW - North Korean Won (₩)", "value": "kpw", "symbol": "KPW" },
  { "title": "KRW - South Korean Won (₩)", "value": "krw", "symbol": "KRW" },
  { "title": "KWD - Kuwaiti Dinar (د.ك)", "value": "kwd", "symbol": "KWD" },
  { "title": "KYD - Cayman Islands Dollar ($)", "value": "kyd", "symbol": "KYD" },
  { "title": "KZT - Kazakhstani Tenge (₸)", "value": "kzt", "symbol": "KZT" },
  { "title": "LAK - Laotian Kip (₭)", "value": "lak", "symbol": "LAK" },
  { "title": "LBP - Lebanese Pound (ل.ل)", "value": "lbp", "symbol": "LBP" },
  { "title": "LKR - Sri Lankan Rupee (Rs)", "value": "lkr", "symbol": "LKR" },
  { "title": "LRD - Liberian Dollar ($)", "value": "lrd", "symbol": "LRD" },
  { "title": "LSL - Lesotho Loti (L)", "value": "lsl", "symbol": "LSL" },
  { "title": "LYD - Libyan Dinar (ل.د)", "value": "lyd", "symbol": "LYD" },
  { "title": "MAD - Moroccan Dirham (د.م.)", "value": "mad", "symbol": "MAD" },
  { "title": "MDL - Moldovan Leu (L)", "value": "mdl", "symbol": "MDL" },
  { "title": "MGA - Malagasy Ariary (Ar)", "value": "mga", "symbol": "MGA" },
  { "title": "MKD - Macedonian Denar (ден)", "value": "mkd", "symbol": "MKD" },
  { "title": "MMK - Myanmar Kyat (K)", "value": "mmk", "symbol": "MMK" },
  { "title": "MNT - Mongolian Tugrik (₮)", "value": "mnt", "symbol": "MNT" },
  { "title": "MOP - Macanese Pataca (MOP$)", "value": "mop", "symbol": "MOP" },
  { "title": "MRU - Mauritanian Ouguiya (MRU)", "value": "mru", "symbol": "MRU" },
  { "title": "MUR - Mauritian Rupee (₨)", "value": "mur", "symbol": "MUR" },
  { "title": "MVR - Maldivian Rufiyaa (Rf)", "value": "mvr", "symbol": "MVR" },
  { "title": "MWK - Malawian Kwacha (MK)", "value": "mwk", "symbol": "MWK" },
  { "title": "MXN - Mexican Peso ($)", "value": "mxn", "symbol": "MXN" },
  { "title": "MYR - Malaysian Ringgit (RM)", "value": "myr", "symbol": "MYR" },
  { "title": "MZN - Mozambican Metical (MT)", "value": "mzn", "symbol": "MZN" },
  { "title": "NAD - Namibian Dollar ($)", "value": "nad", "symbol": "NAD" },
  { "title": "NGN - Nigerian Naira (₦)", "value": "ngn", "symbol": "NGN" },
  { "title": "NIO - Nicaraguan Córdoba (C$)", "value": "nio", "symbol": "NIO" },
  { "title": "NOK - Norwegian Krone (kr)", "value": "nok", "symbol": "NOK" },
  { "title": "NPR - Nepalese Rupee (₨)", "value": "npr", "symbol": "NPR" },
  { "title": "NZD - New Zealand Dollar ($)", "value": "nzd", "symbol": "NZD" },
  { "title": "OMR - Omani Rial (ر.ع.)", "value": "omr", "symbol": "OMR" },
  { "title": "PAB - Panamanian Balboa (B/.)", "value": "pab", "symbol": "PAB" },
  { "title": "PEN - Peruvian Nuevo Sol (S/.)", "value": "pen", "symbol": "PEN" },
  { "title": "PGK - Papua New Guinean Kina (K)", "value": "pgk", "symbol": "PGK" },
  { "title": "PHP - Philippine Peso (₱)", "value": "php", "symbol": "PHP" },
  { "title": "PKR - Pakistani Rupee (₨)", "value": "pkr", "symbol": "PKR" },
  { "title": "PLN - Polish Zloty (zł)", "value": "pln", "symbol": "PLN" },
  { "title": "PYG - Paraguayan Guarani (₲)", "value": "pyg", "symbol": "PYG" },
  { "title": "QAR - Qatari Rial (ر.ق)", "value": "qar", "symbol": "QAR" },
  { "title": "RON - Romanian Leu (lei)", "value": "ron", "symbol": "RON" },
  { "title": "RSD - Serbian Dinar (дин.)", "value": "rsd", "symbol": "RSD" },
  { "title": "RUB - Russian Ruble (₽)", "value": "rub", "symbol": "RUB" },
  { "title": "RWF - Rwandan Franc (R₣)", "value": "rwf", "symbol": "RWF" },
  { "title": "SAR - Saudi Riyal (ر.س)", "value": "sar", "symbol": "SAR" },
  { "title": "SBD - Solomon Islands Dollar ($)", "value": "sbd", "symbol": "SBD" },
  { "title": "SCR - Seychellois Rupee (₨)", "value": "scr", "symbol": "SCR" },
  { "title": "SDG - Sudanese Pound (ج.س.)", "value": "sdg", "symbol": "SDG" },
  { "title": "SEK - Swedish Krona (kr)", "value": "sek", "symbol": "SEK" },
  { "title": "SGD - Singapore Dollar ($)", "value": "sgd", "symbol": "SGD" },
  { "title": "SHP - Saint Helena Pound (£)", "value": "shp", "symbol": "SHP" },
  { "title": "SLL - Sierra Leonean Leone (Le)", "value": "sll", "symbol": "SLL" },
  { "title": "SOS - Somali Shilling (Sh.So.)", "value": "sos", "symbol": "SOS" },
  { "title": "SRD - Surinamese Dollar ($)", "value": "srd", "symbol": "SRD" },
  { "title": "SSP - South Sudanese Pound (ج.س.)", "value": "ssp", "symbol": "SSP" },
  { "title": "STN - São Tomé and Príncipe Dobra (Db)", "value": "stn", "symbol": "STN" },
  { "title": "SVC - Salvadoran Colón (₡)", "value": "svc", "symbol": "SVC" },
  { "title": "SYP - Syrian Pound (£S)", "value": "syp", "symbol": "SYP" },
  { "title": "SZL - Eswatini Lilangeni (L)", "value": "szl", "symbol": "SZL" },
  { "title": "THB - Thai Baht (฿)", "value": "thb", "symbol": "THB" },
  { "title": "TJS - Tajikistani Somoni (ЅМ)", "value": "tjs", "symbol": "TJS" },
  { "title": "TMT - Turkmenistani Manat (T)", "value": "tmt", "symbol": "TMT" },
  { "title": "TND - Tunisian Dinar (د.ت)", "value": "tnd", "symbol": "TND" },
  { "title": "TOP - Tongan Paʻanga (T$)", "value": "top", "symbol": "TOP" },
  { "title": "TRY - Turkish Lira (₺)", "value": "try", "symbol": "TRY" },
  { "title": "TTD - Trinidad and Tobago Dollar ($)", "value": "ttd", "symbol": "TTD" },
  { "title": "TVD - Tuvaluan Dollar ($)", "value": "tvd", "symbol": "TVD" },
  { "title": "TWD - New Taiwan Dollar (NT$)", "value": "twd", "symbol": "TWD" },
  { "title": "TZS - Tanzanian Shilling (Sh.TZ)", "value": "tzs", "symbol": "TZS" },
  { "title": "UAH - Ukrainian Hryvnia (₴)", "value": "uah", "symbol": "UAH" },
  { "title": "UGX - Ugandan Shilling (Sh.U)", "value": "ugx", "symbol": "UGX" },
  { "title": "USD - United States Dollar ($)", "value": "usd", "symbol": "USD" },
  { "title": "UYU - Uruguayan Peso ($)", "value": "uyu", "symbol": "UYU" },
  { "title": "UZS - Uzbekistani Som (so'm)", "value": "uzs", "symbol": "UZS" },
  { "title": "VES - Venezuelan Bolívar (Bs)", "value": "ves", "symbol": "VES" },
  { "title": "VND - Vietnamese Dong (₫)", "value": "vnd", "symbol": "VND" },
  { "title": "VUV - Vanuatu Vatu (VT)", "value": "vuv", "symbol": "VUV" },
  { "title": "WST - Samoan Tala (T)", "value": "wst", "symbol": "WST" },
  { "title": "XAF - Central African CFA Franc (FCFA)", "value": "xaf", "symbol": "XAF" },
  { "title": "XCD - East Caribbean Dollar ($)", "value": "xcd", "symbol": "XCD" },
  { "title": "XDR - Special Drawing Rights (SDR)", "value": "xdr", "symbol": "XDR" },
  { "title": "XOF - West African CFA franc (CFA)", "value": "xof", "symbol": "XOF" },
  { "title": "XPF - CFP Franc (F)", "value": "xpf", "symbol": "XPF" },
  { "title": "YER - Yemeni Rial (﷼)", "value": "yer", "symbol": "YER" },
  { "title": "ZAR - South African Rand (R)", "value": "zar", "symbol": "ZAR" },
  { "title": "ZMW - Zambian Kwacha (ZK)", "value": "zmw", "symbol": "ZMW" },
  { "title": "ZWL - Zimbabwean Dollar ($)", "value": "zwl", "symbol": "ZWL" }
];
  export default currency;