// UserGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { UserGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignUserRequest,
  ChangeStatusUserGroupResponse,
  DeleteUserGroupResponse,
  UserGroupRequest,
  UserGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UseUserGroupServiceProps = {
  onSuccess: (data: UserGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateUserGroupServiceProps = {
  onSuccess: (data: UserGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteUserGroupServiceProps = {
  onSuccess: (data: DeleteUserGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusUserGroupServiceProps = {
  onSuccess: (data: ChangeStatusUserGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useUserGroupService = ({ onError, onSuccess }: UseUserGroupServiceProps) => {
  const {
    mutateAsync: createUserGroup,
    isPending: isCreateUserGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-usergroups-request'],
    mutationFn: (req: UserGroupRequest) => UserGroupProfileService.createUserGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateUserGroup = useCallback(
    async (userGroupData: UserGroupRequest) => {
      try {
        createUserGroup(userGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createUserGroup]
  );

  return {
    onCreateUserGroup,
    isCreateUserGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetUserGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-usergroups-data'],
      queryFn: async ({ pageParam }) => {
        return UserGroupProfileService.getUserGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateUserGroupService = ({ id, onError, onSuccess }: UpdateUserGroupServiceProps) => {
  const {
    mutateAsync: updateUserGroup,
    isPending: isUpdateUserGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-usergroups-request'],
    mutationFn: (req: UserGroupRequest) => UserGroupProfileService.updateUserGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateUserGroup = useCallback(
    async (req: UserGroupRequest) => {
      try {
        updateUserGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateUserGroup]
  );

  return {
    onUpdateUserGroup,
    isUpdateUserGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteUserGroupService = ({ id, onError, onSuccess }: DeleteUserGroupServiceProps) => {
  const {
    mutateAsync: deleteUserGroup,
    isPending: isDeleteUserGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-usergroup-request'],
    mutationFn: () => UserGroupProfileService.deleteUserGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteUserGroup = useCallback(async () => {
    try {
      deleteUserGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deleteUserGroup]);

  return {
    onDeleteUserGroup,
    isDeleteUserGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusUserGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusUserGroupServiceProps) => {
  const {
    mutateAsync: changeStatusUserGroup,
    isPending: isChangeStatusUserGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-userGroup-request'],
    mutationFn: (req: ChangeStatusRequest) => UserGroupProfileService.changeStatusUserGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusUserGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusUserGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusUserGroup]
  );

  return {
    onChangeStatusUserGroup,
    isChangeStatusUserGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetUserGroupUserService = (initialParams: PageSize, userGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-usergroups-users-data'],
      queryFn: async ({ pageParam }) => {
        return UserGroupProfileService.getUserGroupUser({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          userGroupId: userGroupId,
          name: initialParams.name,
          userName: initialParams.userName,
          type: initialParams.type,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignUserService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignUser,
    isPending: isAssignUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-users-request'],
    mutationFn: (req: AssignUnassignUserRequest) => UserGroupProfileService.assignUser(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignUser = useCallback(
    async (assignUserData: AssignUnassignUserRequest) => {
      try {
        assignUser(assignUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignUser]
  );

  return {
    onAssignUser,
    isAssignUserLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignUserService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignUser,
    isPending: isUnassignUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-users-request'],
    mutationFn: (req: AssignUnassignUserRequest) => UserGroupProfileService.unassignUser(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignUser = useCallback(
    async (unassignUserData: AssignUnassignUserRequest) => {
      try {
        unassignUser(unassignUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignUser]
  );

  return {
    onUnassignUser,
    isUnassignUserLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllUserService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllUsers,
    isPending: isAssignAllUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-users-request'],
    mutationFn: (req: AssignUnassignAllRequest) => UserGroupProfileService.assignAllUsers(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllUsers = useCallback(
    async (assignAllUserData: AssignUnassignAllRequest) => {
      try {
        assignAllUsers(assignAllUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllUsers]
  );

  return {
    onAssignAllUsers,
    isAssignAllUserLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllUserService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllUsers,
    isPending: isUnassignAllUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-users-request'],
    mutationFn: (req: AssignUnassignAllRequest) => UserGroupProfileService.unassignAllUsers(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllUsers = useCallback(
    async (unassignAllUserData: AssignUnassignAllRequest) => {
      try {
        unassignAllUsers(unassignAllUserData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllUsers]
  );

  return {
    onUnassignAllUsers,
    isUnassignAllUserLoading,
    data,
    error,
    isError,
  };
};
