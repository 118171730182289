import React, { FC, useState, FocusEvent } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { IconButton, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as Group } from '../../assets/Modal/Group.svg';
import userIcon from 'assets/Login/loginUser.svg';
import lock from 'assets/Login/lock.svg';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface StyledFieldProps {
  label?: string;
  value?: string | number;
  handleSecField?: () => void;
  placeholder?: string;
  displayPlaceholder?: boolean;
  customWidth?:boolean;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  required?: boolean;
  fullWidth?: boolean;
  error?: string;
  search?: string;
  icon?: string;
  type?: string;
  handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  handleBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  isDisabled?: boolean;
  height?:string;
  readOnly?: boolean;
  onClick?: () => void;
}

const StyledInput: FC<StyledFieldProps> = ({
  value,
  label,
  type,
  placeholder,
  displayPlaceholder,
  customWidth,
  handleChange,
  required,
  handleSecField,
  fullWidth,
  search,
  icon,
  error,
  handleKeyDown,
  isDisabled,
  height,
  handleBlur,
  readOnly,
  onClick
}) => {
  const { t, i18n } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const isArabic = i18n.language === 'ar';
  const isFieldDisabledStyles: React.CSSProperties = isDisabled ? { pointerEvents: 'none', opacity: 0.7 } : {};

  return (
    <div style={isFieldDisabledStyles} className='block'>
      
        <Typography
          className="font-Saira font-[600] text-[14px] text-txt-color line-clamp-1"
          style={{ textAlign: isArabic ? 'right' : 'left' }}
          color={error&&!isDisabled ? 'red !important' : 'textPrimary'}
        >
          {label && t(label)}
          {required && label ? ' *' : ''}
        </Typography>
      <OutlinedInput
        className={`font-Saira italic font-[500] text-[14px] ${
          fullWidth ? 'w-[100%]' : customWidth ? 'w-[166px]' : 'w-[340px]'
        } overflow-hidden pr-0 ${height || 'h-[38px]'}  rounded-[8px]  bg-white`}
        sx={{
          border: !label ? (error ? '1px solid red' : 'none') : 'none',
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#343434',
            borderWidth: '1px',
          },
          '& input:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 30px white inset !important',
          },
        }}
        endAdornment={
          search ? (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                className={search === 'search' || search === 'language' ? 'bg-unselected-color h-10 rounded-[10px] w-10 flex text-center' : ''}
                tabIndex={-1}
                onClick={
                  search === 'showPassword'
                    ? handleTogglePasswordVisibility
                    : search === 'language'
                    ? handleSecField
                    : undefined
                }
              >
                {search === 'search' ? (
                  <SearchIcon className="text-primary-color" />
                ) : search === 'showPassword' && value ? (
                  showPassword ? (
                    <VisibilityOffIcon className="text-primary-color absolute right-[10px]" />
                  ) : (
                    <VisibilityIcon className="text-primary-color absolute right-[10px]" />
                  )
                ) : search === 'language' ? (
                      <Group/>
                ) : null}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        startAdornment={
          icon ? (
            <InputAdornment position="start">
              <IconButton
                aria-label="toggle password visibility"
                tabIndex={-1}
                className="flex text-center m-0 p-0"
              >
                {icon === 'user' ? (
                  <img src={userIcon} alt="User Icon" height={15} width={15} className="text-primary-color" />
                ) : icon === 'lock' ? (
                  <img src={lock} alt="Lock Icon" height={12} width={12} className="text-primary-color" />
                ) : null}
              </IconButton>
            </InputAdornment>
          ) : null
        }
        id="styled-input"
        type={type === 'password' && search === 'showPassword' ? (showPassword ? 'text' : 'password') : type}
        value={value || ''}
        required={required}
        placeholder={displayPlaceholder ? t(placeholder || '') : ''}
        onChange={handleChange}
        onKeyDown={handleKeyDown} // Add the onKeyDown event handler here
        onBlur={handleBlur}
        disabled={isDisabled} // Disable the input based on the isDisabled prop
        readOnly={readOnly}
        onClick={onClick}
      />
      {!isDisabled && (
        <>
          <HelperText className="font-Saira italic font-[500] text-[12px] text-red-400" error={Boolean(error)}>{error ? t(error) : null}</HelperText>
          <span style={{ display: 'block', height: '10px' }}></span>
        </>
      )}
    </div>
  );
};

const HelperText = styled('p')<{ error?: boolean }>`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  color: ${(props) => (props.error ? 'red' : '')};
  text-align: left;
  position: absolute;
`;

export default StyledInput;
