import { Box } from "@mui/material"
import { useTranslation } from 'react-i18next';
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import { ReactComponent as Accounts } from '../../assets/SideBarIcons/accounts.svg';
import ChangePasswordModal from "./ChangePasswordModalModal";
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import BusinessSettingsModalComponent from "./BusinessSettingsModal";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import Loader from "layout/Loader";

interface AccountSettingsComponentProps {
    handleChangePassword: (field: string, value: any) => void;
    handleSubmitChangePassword: () => void;
    changePasswordFormData: any;
    changePasswordErrorMessages: any;
    isBtnEnabled: boolean;
    handleChangeTabs: (tabId: number) => void;
    showSecField: boolean;
    loggedInUserBusinessData: any;
    scrapItemGroupsData: any;
    priceListData: any;
    taxProceduresData: any;
    handleDropdownSearch: (field: string, search: string) => void;
    loadNext: (field: string, scroll: any) => void;
    loadPrev: (field: string, scroll: any) => void;
    handleSecField: () => void;
    handleChangeBusiness: (field: string, value: any) => void;
    handleSubmitBusiness: () => void;
    loadingDropdownSearch: boolean;
    errorMessages: any,
    enableEdit: boolean,
    loading: boolean;
}

const AccountSettingsComponent: React.FC<AccountSettingsComponentProps> = ({
    handleChangePassword,
    handleSubmitChangePassword,
    changePasswordFormData,
    changePasswordErrorMessages,
    isBtnEnabled,
    handleChangeTabs,
    showSecField,
    loggedInUserBusinessData,
    scrapItemGroupsData,
    priceListData,
    taxProceduresData,
    handleDropdownSearch,
    loadNext,
    loadPrev,
    handleSecField,
    handleChangeBusiness,
    handleSubmitBusiness,
    loadingDropdownSearch,
    errorMessages,
    enableEdit,
    loading
}) => {


    const { t } = useTranslation();


    const tabs = [
        {
            id: 0, title: `updatePassword`, child: <ChangePasswordModal
            isBtnEnabled={isBtnEnabled}
            handleChangePassword={handleChangePassword}
            handleSubmitChangePassword={handleSubmitChangePassword}
            changePasswordFormData={changePasswordFormData}
            openForm={true}
            changePasswordErrorMessages={changePasswordErrorMessages}
            />
        },
        {
            id: 1, title: `businessSettings`, child: loading ? <Loader /> : <BusinessSettingsModalComponent
                enableEdit={enableEdit}
                handleChange={handleChangeBusiness}
                handleSecField={handleSecField}
                showSecField={showSecField}
                handleSubmit={handleSubmitBusiness}
                formData={loggedInUserBusinessData}
                scrapItemGroupsData={scrapItemGroupsData.data?.content || []}
                taxProceduresData={taxProceduresData.data?.content || []}
                errorMessages={errorMessages}
                isBtnEnabled={isBtnEnabled}
                loadNext={loadNext}
                loadPrev={loadPrev}
                priceListData={priceListData.data?.content || []}
                handleDropdownSearch={handleDropdownSearch}
                {...{loadingDropdownSearch}}
            />
        },
    ];
    const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
        switch (tabId) {
          case 0:
            return false;
          case 1:
            return !checkPrivileges('ROLE_BUSINESS_UPDATE');
          default:
            return false;
        }
      });

    return (
        <Box>
            <ComponentHeaderTwo
                showBtn={false}
                showSaveBtn={false}
                // onClick={openModal}
                // onSubmit={handleSubmit}
                heading={t('accountSettings')}
                icon={<Accounts height={28} width={28} />}
                btnText="back"
                btnType="back"
                isBtnEnabled={isBtnEnabled}
            />
            <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
        </Box>
    )
}
export default AccountSettingsComponent;