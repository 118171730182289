import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import BankDepositComponent from 'components/BankDeposit/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useBankDepositService, useChangeStatusBankDepositService, useDeleteBankDepositService, useGetBankDepositsService, useUpdateBankDepositService } from 'subModule/src/services/bankdeposit/useBankDeposit';
import { BankDepositRequest } from 'subModule/src/services/bankdeposit/interface';
import { useGetStationDropdownService } from 'subModule/src/services/station/useStation';
import { useGetBankAccountsDropdownService } from 'subModule/src/services/bankaccount/useBankAccount';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
    active: boolean,
    id:number,
    number:number,
    numberError?: string,
    bankAccountId: number,
    bankAccountIdError?: string,
    stationId: number,
    stationIdError?: string,
    amount:number,
    amountError?: string,
    date: string,
    receiptNumber: string
}

  const requiredFields = ['number', 'amount', 'stationId', 'bankAccountId', 'receiptNumber','date'];

  export default function BankDesposit () {
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [formData, setFormData] = useState<FormData>({
      active: false,
      id:0,
      number: 0,
      bankAccountId: 0,
      stationId: 0,
      amount: 0,
      date: '',
      receiptNumber: ''
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetBankDepositsService({pageNumber: pageNo, size: pageSize, receiptNumber: searchTerm}) : useGetBankDepositsService({pageNumber: pageNo, size: pageSize});
    
    const { data: stationData, isLoading: stationIsLoading,error: stationError,  refetch:stationRefetch } = useGetStationDropdownService({pageNumber: 1, size: 50});
    
    const { data: bankAccountData, isLoading: bankaccountIsLoading, error: bankAccountError,  refetch:bankAccountRefetch } = useGetBankAccountsDropdownService({pageNumber: 1, size: 50});
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    useEffect(() => {
      if (!isLoading && !error && !openForm&&!loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
    }, [pageNo, pageSize, refetch, isLoading, error,bankaccountIsLoading,stationIsLoading,bankAccountError,stationError, tableData,openForm]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000)
      return () => clearTimeout(getData)
    }, [searchTerm,loadingSearch]);
    
    const handleChangePage = (pageNo : number) => { 
      setLoading(true); 
      setPageNo(pageNo+1)
      setPageSize(pageSize)

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize)
      setPageNo(1)
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setEnableEdit(false);
        setErrorMessages({});
        setSearch('');
        setSearchTerm('');
        setFormData({
          active: false,
          id:0,
          number: 0,
          bankAccountId: 0,
          stationId: 0,
          amount: 0,
          date: '',
          receiptNumber: ''
        });
        if(!openForm && !enableEdit) {
          bankAccountRefetch();
          stationRefetch();
        }
      };

      const handleChange = (field: string, value: any) => {
        const  val = (field === "bankAccountId" || field === "stationId") ? (value ? parseInt(value.value) || 0 : 0) 
          : (field === "amount") ? 
            (value >= 0 ? value : 0)
          : (field === "date") ?
            new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }).split('/').reverse().join('-')
          : value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
        if(enableEdit) {
          setIsBtnEnabled(true);
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.number) {
          errors.numberError = 'Account Number is required.';
        }
        if (!data.bankAccountId) {
            errors.bankAccountIdError = 'Bank Account is required.';
        }
        if (!data.stationId) {
            errors.stationIdError = 'Station is required.';
        }
        if (!data.amount) {
            errors.amountError = 'Amount is required.';
        }
        if (!data.receiptNumber.trim()) {
          errors.receiptNumber = 'Receipt Number is required.';
        }
        if (!data.date || data.date === "Invalid Date") {
          errors.date = 'Date is required.';
        }
    
        return errors;
      };

      const { onCreateBankDeposit,  } = useBankDepositService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankDeposit') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})

      const {onUpdateBankDeposit } = useUpdateBankDepositService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          setEnableEdit(false);
          setOpenForm(false);
          if(response.success) {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('bankDeposit') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
        },
        onError: (err: any) => {
          console.error("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);

        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const bankDepositData: BankDepositRequest = {
            ...formData, 
            active: formData.active,
            number: formData.number,
            bankAccountId: formData.bankAccountId,
            stationId: formData.stationId,
            amount: formData.amount,
            date: formData.date,
            receiptNumber: formData.receiptNumber
          };
     
          if (enableEdit) {
            onUpdateBankDeposit(
                bankDepositData
            );
          } else {
            onCreateBankDeposit(bankDepositData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
        stationRefetch();
        bankAccountRefetch();
        setIsBtnEnabled(false);
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteBankDeposit } = useDeleteBankDepositService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('bankDeposit') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err: any) => {
          console.error("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteBankDeposit();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusBankDeposit({});
      }
      const { onChangeStatusBankDeposit } = useChangeStatusBankDepositService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('bankDeposit') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        onEdit(row);
      }
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_BANKDEPOSIT_UPDATE') 
          ? (checkPrivileges('ROLE_BANKDEPOSIT_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_BANKDEPOSIT_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <BankDepositComponent
                openModal={openModal}
                closeModal={openModal}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_BANKDEPOSIT_ACTIVE") ? [
                    { id: 'receiptNumber', label: 'receiptNumber', numeric: false },
                    { id: 'bankName', label: 'bankAccount', numeric: false },
                    { id: 'number', label: 'accountNumber', numeric: false },
                    { id: 'date', label: 'date', numeric: false },
                    { id: 'amount', label: 'amount', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'receiptNumber', label: 'receiptNumber', numeric: false },
                  { id: 'bankName', label: 'bankAccount', numeric: false },
                  { id: 'number', label: 'accountNumber', numeric: false },
                  { id: 'date', label: 'date', numeric: false },
                  { id: 'amount', label: 'amount', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                bankAccountData={bankAccountData?.pages[0] || {}}
                stationData={stationData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, search, loadingSearch }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }