import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import BusinessComponent from 'components/Business/index';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import { useBusinessService, useChangeStatusBusinessService, useDeleteBusinessService, useGetBusinessService, useUpdateBusinessService } from 'subModule/src/services/business/useBusiness';
import { BusinessRequest } from 'subModule/src/services/business/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useGetItemGroupDropdownService } from 'subModule/src/services/itemgroup/useItemGroup';
import { useGetPriceListDropdownService } from 'subModule/src/services/pricelist/usePriceList';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useGetTaxProcedureDropdownService } from 'subModule/src/services/taxProcedure/useTaxProcedure';

interface FormData {
  id: number,
  title: string,
  secondaryTitle: string,
  primaryLanguage: string,
  secondaryLanguage: string,
  whiteLabel: boolean,
  whiteLabelError?: string,
  scrapItemGroupId: number,
  scrapItemGroupIdError?: string,
  active: boolean,
  priceListId: number,
  currency: string,
  priceListIdError?: string,
  taxProcedureId: number,
}

const requiredFields = ['title', "priceListId", 'scrapItemGroupId'];

export default function Business() {
  const [showSecField, setShowSecField] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  // const [prevFormData, setPrevFormData] = useState<object>({});
  const [scrapGroupItemsPageParams, setScrapGroupItemsPageParams] = useState<any>({ number: 1, size: 50 });
  const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
  const [dropdown, setDropdown] = useState<string>('scrapItemGroupId');
  const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  // const [data] = useState(rows);
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    title: '',
    secondaryTitle: '',
    primaryLanguage: '',
    secondaryLanguage: '',
    whiteLabel: false,
    scrapItemGroupId: 0,
    active: true,
    currency: '',
    priceListId: 0,
    taxProcedureId: 0,
  });

  const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetBusinessService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetBusinessService({ pageNumber: pageNo, size: pageSize });

  const { data: scrapItemGroupsData, isLoading: scrapItemGroupsIsLoading, error: scrapItemGroupsError, refetch: scrapItemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: scrapGroupItemsPageParams.number, size: scrapGroupItemsPageParams.size, title: dropdownSearchTerm });

  const { data: priceListData, isLoading: priceListIsLoading, error: priceListError, refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 50 });

  const { data: taxProceduresData, isLoading: taxProceduresIsLoading, error: taxProceduresError, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 50 });

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;

  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) {
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm, priceListIsLoading, priceListError, taxProceduresIsLoading, taxProceduresError]);

  // useEffect(() => {
  //   if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
  //     setIsBtnEnabled(true);
  //   } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
  //     setIsBtnEnabled(false);
  //   }
  // }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchTerm, loadingSearch]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if ((dropdownSearchTerm || dropdownSearchTerm === '') && !scrapItemGroupsIsLoading && !scrapItemGroupsError && openForm && !initialRender) {
        if (dropdown === 'scrapItemGroupId') {
          setLoadingDropdownSearch(true);
          setScrapGroupItemsPageParams({ number: 1, size: 50 });
          await scrapItemGroupsRefetch();
          setLoadingDropdownSearch(false);
        }
      }
    }, 500)
    return () => clearTimeout(getData)
  }, [dropdownSearchTerm, scrapItemGroupsIsLoading, scrapItemGroupsError, openForm]);

  useEffect(() => {
    if (scrapGroupItemsPageParams && openForm && dropdown === 'scrapItemGroupId') {
      scrapItemGroupsRefetch();
    }
  }, [scrapGroupItemsPageParams])

  const handleDropdownSearch = (field: string, search: string) => {
    if (field === 'scrapItemGroupId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('scrapItemGroupId');
    }
  }
  const loadNext = (field: string, scroll: any) => {
    if (scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
      if (field === 'scrapItemGroupId') {
        if (!(scrapItemGroupsData?.pages[0]?.data as any).last) {
          setDropdown('scrapItemGroupId');
          setScrapGroupItemsPageParams({ number: scrapGroupItemsPageParams.number + 1, size: scrapGroupItemsPageParams.size });
        }
      }
    }
  }
  const loadPrev = (field: string, scroll: any) => {
    if (scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
      if (field === 'scrapItemGroupId') {
        if (!(scrapItemGroupsData?.pages[0]?.data as any).first) {
          setDropdown('scrapItemGroupId');
          setScrapGroupItemsPageParams({ number: scrapGroupItemsPageParams.number - 1, size: scrapGroupItemsPageParams.size });
        }
      }
    }
  }

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize);
  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize);
    setPageNo(1);
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setIsBtnEnabled(true);
    setEnableEdit(false);
    setErrorMessages({});
    setDropdown('scrapItemGroupId');
    setDropdownSearchTerm('');
    setShowSecField(false)
    setInitialRender(true);
    setFormData({
      id: 0,
      title: '',
      secondaryTitle: '',
      primaryLanguage: '',
      secondaryLanguage: '',
      whiteLabel: false,
      scrapItemGroupId: 0,
      active: true,
      currency: '',
      priceListId: 0,
      taxProcedureId: 0,
    });
    if (!openForm && !enableEdit) {
      scrapItemGroupsRefetch();
      priceListRefetch();
      taxProceduresRefetch();
      setScrapGroupItemsPageParams({ number: 1, size: 50 });
      setSearch('');
      setSearchTerm('');
    }
  };

  const handleSecField = () => {
    setShowSecField((prevValue) => !prevValue);
  }

  const handleChange = (field: string, value: any) => {
    const val = (field === "whiteLabel") ? value ? Boolean(value) : false
      : (field === "priceListId" || field === "scrapItemGroupId" || field === "taxProcedureId") ? (value ? parseInt(value.value) || 0 : 0) 
      : (field === "currency" || field === "primaryLanguage" || field === "secondaryLanguage" ) ?
        (value ? value.value || '' : '')
        : value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.title?.trim()) {
      errors.title = 'Title is required.';
    }
    if (!data.priceListId) {
      errors.priceListIdError = 'Default Price list is required.';
    }
    if (!data.scrapItemGroupId) {
      errors.scrapItemGroupIdError = 'Scrap Group Part is required.';
    }

    return errors;
  };

  const { onCreateBusiness, } = useBusinessService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('business') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateBusiness } = useUpdateBusinessService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        setEnableEdit(false);
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('business') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const businessData: BusinessRequest = {
        ...formData,
        title: formData.title,
        secondaryTitle: formData.secondaryTitle,
        primaryLanguage: formData.primaryLanguage,
        secondaryLanguage: formData.secondaryLanguage,
        whiteLabel: formData.whiteLabel,
        scrapItemGroupId: formData.scrapItemGroupId,
        priceListId: formData.priceListId,
        currency: formData.currency,
        taxProcedureId: formData.taxProcedureId,
      };

      if (enableEdit) {
        onUpdateBusiness(
          businessData
        );
      } else {
        onCreateBusiness(businessData);
      }
    }
  };

  // const onEdit = (row: any) => {
  //   setEnableEdit(true)
  //   setPrevFormData(row);
  //   setIsBtnEnabled(false);
  //   setFormData(row)
  //   setOpenForm(true);
  //   scrapItemGroupsRefetch();
  //   priceListRefetch();
  //   taxProceduresRefetch();
  //   setScrapGroupItemsPageParams({ number: 1, size: 50 });
  //   setInitialRender(true);
  //   setDropdownSearchTerm('');
  //   setSearch('');
  //   setSearchTerm('');
  // }

  // const onDelete = (row: any) => {
  //   setFormData(row)
  //   setDeleteModal(true)
  // }

  const { onDeleteBusiness } = useDeleteBusinessService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('business') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false)
    setLoading(true);
    onDeleteBusiness()
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setSearch('');
    setSearchTerm('');
    if(searchTerm) { handleSearch(''); }
  }
  const handleSearch = (search: string) => {
    setLoadingSearch(true);
    setSearchTerm(search);
    setPageNo(1);
    setPageSize(pageSize);
  };

  const handleSearchClick = () => {
    const trimmedSearch = search.trim();
    if (trimmedSearch !== '') {
      handleSearch(trimmedSearch);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim());
    if (!event.target.value.trim()) handleSearch('');
  };
  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setStatusModal(false);
    setLoading(true);
    onChangeStatusBusiness({});
  }
  const { onChangeStatusBusiness } = useChangeStatusBusinessService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('business') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err) => {
      console.error("===", err)
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = () => {
    // onEdit(row);
  }
  // const adjustActions = (actions: any[]) => {
  //   return checkPrivileges('ROLE_BUSINESS_UPDATE')
  //     ? (checkPrivileges('ROLE_BUSINESS_ARCHIVE')
  //       ? actions
  //       : actions.filter(action => action.label !== 'delete'))
  //     : (checkPrivileges('ROLE_BUSINESS_ARCHIVE')
  //       ? actions.filter(action => action.label !== 'edit')
  //       : []);
  // }

  return (
    <Box>
      <BusinessComponent
        openModal={openModal}
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        closeModal={openModal}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        loadNext={loadNext}
        loadPrev={loadPrev}
        handleDropdownSearch={handleDropdownSearch}
        taxProceduresData={taxProceduresData?.pages[0] || {}}
        clearSearch={clearSearch}
        columns={checkPrivileges("ROLE_BUSINESS_ACTIVE") ? [
          { id: 'title', label: 'title', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'title', label: 'title', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        scrapItemGroupsData={scrapItemGroupsData?.pages[0] || {}}
        {...{ openForm, openDeleteModal, enableEdit, pageNo, showSecField, pageSize, statusModal, statusText, search, isBtnEnabled, loading, loadingSearch, loadingDropdownSearch }}
        errorMessages={errorMessages}
        // actions1={adjustActions([
        //   { label: "edit", onClick: onEdit, icon: <EditIcon /> },
        //   { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        // ])}
        actions1={[]}
        priceListData={priceListData?.pages[0] || {}}

      />
    </Box>
  )
}