// VehicleTypeProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { VehicleTypeProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusVehicleTypeResponse,
  DeleteVehicleTypeResponse,
  VehicleTypeRequest,
  VehicleTypeResponse,
} from './interface';
import { useCallback } from 'react';

type UseVehicleTypeServiceProps = {
  onSuccess: (data: VehicleTypeResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateVehicleTypeServiceProps = {
  onSuccess: (data: VehicleTypeResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteVehicleTypeServiceProps = {
  onSuccess: (data: DeleteVehicleTypeResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusVehicleTypeServiceProps = {
  onSuccess: (data: ChangeStatusVehicleTypeResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useVehicleTypeService = ({ onError, onSuccess }: UseVehicleTypeServiceProps) => {
  const {
    mutateAsync: createVehicleType,
    isPending: isCreateVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-vehicletypes-request'],
    mutationFn: (req: VehicleTypeRequest) => VehicleTypeProfileService.createVehicleType(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateVehicleType = useCallback(
    async (vehicleTypeData: VehicleTypeRequest) => {
      try {
        createVehicleType(vehicleTypeData);
      } catch (err) {
        console.error(err);
      }
    },
    [createVehicleType]
  );

  return {
    onCreateVehicleType,
    isCreateVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useGetVehicleTypeService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-vehicletypes-data'],
      queryFn: async ({ pageParam }) => {
        return VehicleTypeProfileService.getVehicleType({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetVehicleTypeDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-vehicletypes-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return VehicleTypeProfileService.getVehicleTypeDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateVehicleTypeService = ({ id, onError, onSuccess }: UpdateVehicleTypeServiceProps) => {
  const {
    mutateAsync: updateVehicleType,
    isPending: isUpdateVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-vehicletypes-request'],
    mutationFn: (req: VehicleTypeRequest) => VehicleTypeProfileService.updateVehicleType(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateVehicleType = useCallback(
    async (req: VehicleTypeRequest) => {
      try {
        updateVehicleType(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateVehicleType]
  );

  return {
    onUpdateVehicleType,
    isUpdateVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteVehicleTypeService = ({ id, onError, onSuccess }: DeleteVehicleTypeServiceProps) => {
  const {
    mutateAsync: deleteVehicleType,
    isPending: isDeleteVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-vehicletype-request'],
    mutationFn: () => VehicleTypeProfileService.deleteVehicleType(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteVehicleType = useCallback(async () => {
    try {
      deleteVehicleType();
    } catch (err) {
      console.error(err);
    }
  }, [deleteVehicleType]);

  return {
    onDeleteVehicleType,
    isDeleteVehicleTypeLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusVehicleTypeService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusVehicleTypeServiceProps) => {
  const {
    mutateAsync: changeStatusVehicleType,
    isPending: isChangeStatusVehicleTypeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-vehicleType-request'],
    mutationFn: (req: ChangeStatusRequest) => VehicleTypeProfileService.changeStatusVehicleType(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusVehicleType = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusVehicleType(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusVehicleType]
  );

  return {
    onChangeStatusVehicleType,
    isChangeStatusVehicleTypeLoading,
    data,
    error,
    isError,
  };
};
