import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ItemsComponent from 'components/Items/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useItemsService,useDeleteItemsService,useGetItemsService,useUpdateItemsService,useChangeStatusItemsService, useGetItemPriceListService, useAssignItemPriceListService, useUnassignItemPriceListService } from 'subModule/src/services/items/useItems';
import { useGetItemUnitService, useAssignUnitService, useUnassignUnitService } from 'subModule/src/services/items/useItems';
import { useGetItemModelService, useAssignModelService, useUnassignModelService, useAssignAllModelService, useUnassignAllModelService } from 'subModule/src/services/items/useItems';
import { useGetItemVendorService, useAssignVendorService, useUnassignVendorService, useAssignAllVendorService, useUnassignAllVendorService } from 'subModule/src/services/items/useItems';
import { useGetManufacturerDropdownService } from 'subModule/src/services/manufacturer/useManufacturer';
import { useGetDefaultTaxProcedureService, useGetTaxProcedureDropdownService } from 'subModule/src/services/taxProcedure/useTaxProcedure';
import { AssignPriceListRequest, AssignUnitRequest, ItemsRequest } from 'subModule/src/services/items/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useGetUnitsDropdownService } from 'subModule/src/services/units/useUnits';
import { useGetItemsDropdownService } from 'subModule/src/services/items/useItems';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  itemCode: string,
  partNo: string,
  sortOrder: number;
  taxProcedureId: number,
  taxProcedureIdError?: string,
  manufacturer: number,
  baseUnitID: number,
  price: number,
  priceError?: string,
  priceWithTax?: number,
  scrapItemId: number,
  scrapItemIdError: string,
  chargedName: string,
  secondaryChargedName: string,
  chargedValue: string,
  chargedPer: number,
  chargedPerError: string,
  stockReferanceNumber: string,
  minQuantity: number,
  minQuantityError?: string,
  maxQuantity: number,
  maxQuantityError?: string,
  itemCost: number,
  baseItemQuantityType: number,
  itemSupplierNumber: string,
  warranty: string,
  itemWarrantyServiceId: number,
  shippingMethod: number,
  minPrice: number,
  sellBelowCost: boolean,
  extraNotes: string,
  itemPictureUrl: string,
  salesMarkupPercentage: number,
  haveScrapItem: boolean,
  applyExtraServiceCharge: boolean,
  default: boolean,
  taxable: boolean,
  itemEveryMile: number,
  itemEveryPeriodType: number,
  active: boolean
}
interface PriceListFormData {
  itemId: number;
  price: number;
  priceError?: string;
  pricelistId: number;
}

interface UnitFormData {
  itemId: number;
  unitId: number;
  baseUnit: boolean;
  baseUnitError?: string;
  baseUnitId: number;
  baseUnitIdError?: string;
  conversionMultiplier: number;
  conversionMultiplierError?: string;
}

  let requiredFields = ['title','itemCode','scrapItemId','chargedName','secondaryChargedName','chargedValue','chargedPer', 'taxProcedureId', 'price'];

  const priceListRequiredFields = ['price'];

  let unitRequiredFields = [""];
  
  export default function Items () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [rowView, setRowView] = useState(false);
    const [itemId, setItemId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    const [searchAssign, setSearchAssign] = useState('');
    const [searchTermAssign, setSearchTermAssign] = useState<any>(null);
    const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        itemCode: '',
        partNo: '',
        sortOrder: 0,
        taxProcedureId: 0,
        baseUnitID: 0,
        manufacturer: 0,
        price: 0,
        priceWithTax: 0,
        scrapItemId: 0,
        scrapItemIdError: '',
        chargedName: '',
        secondaryChargedName: '',
        chargedValue: '',
        chargedPer: 0,
        chargedPerError: '',
        stockReferanceNumber: '',
        minQuantity: 0,
        maxQuantity: 0,
        itemCost: 0,
        baseItemQuantityType: 0,
        itemSupplierNumber: '',
        warranty: '',
        itemWarrantyServiceId: 0,
        shippingMethod: 0,
        minPrice: 0,
        sellBelowCost: false,
        extraNotes: '',
        itemPictureUrl: '',
        salesMarkupPercentage: 0,
        haveScrapItem: false,
        applyExtraServiceCharge: false,
        default: false,
        taxable: false,
        itemEveryMile: 0,
        itemEveryPeriodType: 0,
        active: false
    });
    const [priceListFormData, setPriceListFormData] = useState<PriceListFormData>({
      itemId: 0,
      price: 0,
      pricelistId: 0,
    });
    const [unitFormData, setUnitFormData] = useState<UnitFormData>({
      itemId: 0,
      unitId: 0,
      baseUnit: false,
      baseUnitId: 0,
      conversionMultiplier: 0,
    });
    const loggedInUserData = useSelector(selectUserInfo);
    const scrapItemGroupId = loggedInUserData?.scrapItemGroupId;
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    const loggedInUserCurrency = loggedInUserData?.currency;
    const defaultPriceList = loggedInUserData?.priceListTitle;
    const defaultPriceListId = loggedInUserData?.priceListId;

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetItemsService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetItemsService({ pageNumber: pageNo, size: pageSize });
    
    const { data: unitAssignData, isLoading: unitAssignIsLoading, error: unitAssignError, refetch: unitAssignRefetch } = searchTermAssign ? useGetItemUnitService({pageNumber: pageNo, size: pageSize, unitTitle: searchTermAssign}, itemId) : useGetItemUnitService({pageNumber: pageNo, size: pageSize}, itemId);

    const { data: modelAssignData, isLoading: modelAssignIsLoading, error: modelAssignError, refetch: modelAssignRefetch } = searchTermAssign ? useGetItemModelService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, itemId):useGetItemModelService({pageNumber: pageNo, size: pageSize}, itemId)

    const { data: vendorAssignData, isLoading: vendorAssignIsLoading, error: vendorAssignError, refetch: vendorAssignRefetch } = searchTermAssign ? useGetItemVendorService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, itemId) : useGetItemVendorService({pageNumber: pageNo, size: pageSize}, itemId);

    const { data: priceListAssignData, isLoading: priceListAssignIsLoading, error: priceListAssignError, refetch: priceListAssignRefetch } = searchTermAssign ? useGetItemPriceListService({pageNumber: pageNo, size: pageSize, title: searchTermAssign}, itemId) : useGetItemPriceListService({pageNumber: pageNo, size: pageSize}, itemId);

    const { data: manufacturersData, isLoading: manufacturersIsLoading, error: manufacturersError, refetch: manufacturersRefetch } = useGetManufacturerDropdownService({ pageNumber: 1, size: 50 });

    const { data: taxProceduresData, isLoading: taxProceduresIsLoading, error: taxProceduresError, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 50 });

    const { data: unitsData, isLoading: unitsIsLoading, error: unitsError, refetch: unitsRefetch } = useGetUnitsDropdownService({ pageNumber: 1, size: 50 });

    const { data: scrapItemsData, refetch: scrapItemsRefetch } = scrapItemGroupId ? useGetItemsDropdownService({pageNumber: 1, size: 50}, scrapItemGroupId) : useGetItemsDropdownService({ pageNumber: 1, size: 50 });

    const { data: defaultTaxProceduresData, refetch: defaultTaxProceduresRefetch } = useGetDefaultTaxProcedureService(2);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    let allModelsAssigned:unknown = modelAssignData?.pages[0].data?.content.map(model => model.linked).every(linked => linked === true);
    let allVendorsAssigned:unknown = vendorAssignData?.pages[0].data?.content.map(vendor => vendor.linked).every(linked => linked === true);

    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { 
        refetch();
      }
      if (tableData) {
        setLoading(false);
        if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }

      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!unitAssignIsLoading && !unitAssignError && itemId && itemId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await unitAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!modelAssignIsLoading && !modelAssignError && itemId && itemId !== 0 && tab === 2) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await modelAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!vendorAssignIsLoading && !vendorAssignError && itemId && itemId !== 0 && tab === 3) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await vendorAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
      if(!priceListAssignIsLoading && !priceListAssignError && itemId && itemId !== 0 && tab === 4) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await priceListAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, manufacturersIsLoading, manufacturersError, taxProceduresIsLoading, taxProceduresError, unitsIsLoading, unitsError,itemId, tab, tableData, openForm]);
    
    useEffect(() => {
      if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);
    
    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTermAssign !== null && loadingSearchAssign && tab === 1) {
          await unitAssignRefetch();
          setLoadingSearchAssign(false);
        }
        if (searchTermAssign !== null && loadingSearchAssign && tab === 2) {
          await modelAssignRefetch();
          setLoadingSearchAssign(false);
        }
        if (searchTermAssign !== null && loadingSearchAssign && tab === 3) {
          await vendorAssignRefetch();
          setLoadingSearchAssign(false);
        }
        if (searchTermAssign !== null && loadingSearchAssign && tab === 4) {
          await priceListAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTermAssign, loadingSearchAssign]);
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo+1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const [priceListErrorMessages, setPriceListErrorMessages] = useState<Partial<PriceListFormData>>({});

    const [unitErrorMessages, setUnitErrorMessages] = useState<Partial<UnitFormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setItemId(0);
        setPageNo(1);
        setTab(0);
        setSearchAssign('');
        setSearchTermAssign('');
      } 
      else {
        setIsBtnEnabled(true);
        setShowSecField(false)
        setOpenForm(!openForm);
        openModalSettings();
        if(!openForm && !enableEdit) {
          manufacturersRefetch();
          taxProceduresRefetch();
          scrapItemsRefetch();
          unitsRefetch();
          defaultTaxProceduresRefetch();
          setSearch('');
          setSearchTerm('');
        }
      }
    };
      const openModalSettings = () => {
        setEnableEdit(false);
        setErrorMessages({});
        setFormData({
            title: '',
            secondaryTitle: '',
            id: 0,
            itemCode: '',
            partNo: '',
            sortOrder: 0,
            taxProcedureId: 0,
            manufacturer: 0,
            baseUnitID: 0,
            price: 0,
            priceWithTax: 0,
            scrapItemId: 0,
            scrapItemIdError:'',
            chargedName: '',
            secondaryChargedName: '',
            chargedValue: '',
            chargedPer: 0,
            chargedPerError: '',
            stockReferanceNumber: '',
            minQuantity: 0,
            maxQuantity: 0,
            itemCost: 0,
            baseItemQuantityType: 0,
            itemSupplierNumber: '',
            warranty: '',
            itemWarrantyServiceId: 0,
            shippingMethod: 0,
            minPrice: 0,
            sellBelowCost: false,
            extraNotes: '',
            itemPictureUrl: '',
            salesMarkupPercentage: 0,
            haveScrapItem: false,
            applyExtraServiceCharge: false,
            default: false,
            taxable: false,
            itemEveryMile: 0,
            itemEveryPeriodType: 0,
            active: false
        });
      }

      const handleSecField = () => {
        setShowSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const val = field === "applyExtraServiceCharge" || field === "taxable" || field === "haveScrapItem" || field === "isDefault" || field === "sellBelowCost"
          ? value ? Boolean(value) : false
            : field === "taxProcedureId" || field === "baseUnitID" || field === "scrapItemId" || field === "shippingMethod" || field === "manufacturer" || field === "chargedPer" || field === "baseItemQuantityType"
          ? value ? parseInt(value.value) || 0 : 0
            : field === "sortOrder"
          ? value && value > 0 && value < 101 ? 
            parseInt(value) : 0
          : field === "itemWarrantyServiceId" || field === "minQuantity" || field === "maxQuantity" ? 
            value ? parseInt(value) : 0
          : field === "price" || field === "minPrice" || field === "itemCost" ?
            value >= 0 ? value : 0
          : field === "itemEveryPeriodType" ? value ? value >=0 ? value : 0 : 0
          : field === "salesMarkupPercentage" || field === "itemEveryMile" ? 
            value ? parseFloat(value) : 0
          : value;
    
        setFormData((prevData) => ({
            ...prevData,
            [field]: val,
        }));
    
        if (field === "taxable") {
          if (val) {
            requiredFields = [ "title", "itemCode", "scrapItemId", "chargedName", "secondaryChargedName", "chargedValue", "chargedPer", "manufacturer", "taxProcedureId", "baseUnitID", "price"];
            if(openForm && !enableEdit) {
              setFormData((prevData) => ({
                ...prevData,
                taxProcedureId: (defaultTaxProceduresData as any)?.data?.id,
              }));
            }
          } else {
            requiredFields = [ "title", "itemCode", "scrapItemId", "chargedName", "secondaryChargedName", "chargedValue", "chargedPer", "manufacturer", "baseUnitID", "price"];
            setFormData((prevData) => ({
              ...prevData,
              taxProcedureId: 0,
            }));
          }
        }
        if (field === "haveScrapItem") {
          if (val) {
            requiredFields = [ "title", "itemCode", "scrapItemId", "chargedName", "secondaryChargedName", "chargedValue", "chargedPer", "manufacturer", "taxProcedureId", "baseUnitID", "price"];
          } else {
            requiredFields = [ "title", "itemCode", "chargedName", "secondaryChargedName", "chargedValue", "chargedPer", "manufacturer", "taxProcedureId", "baseUnitID", "price"];
            setFormData((prevData) => ({
              ...prevData,
              scrapItemId: 0,
            }));
          }
        }
            if (requiredFields.includes(field) && !val) {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    [field]: "This field is required.",
                }));
            } else {
                setErrorMessages((prevErrors) => ({
                    ...prevErrors,
                    [field]: undefined,
                }));
            }
    };
    
      
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = 'Title is required.';
        }
        if (!data.itemCode) {
          errors.itemCode = 'Part Code is required.';
        }
        if(data.taxable && !data.taxProcedureId) {
          errors.taxProcedureIdError = 'Tax Procedure is required.';
        }
        if(!data.chargedName && formData.applyExtraServiceCharge === true) {
          errors.chargedName = 'Charged Name is required.';
        }
        if(!data.secondaryChargedName && formData.applyExtraServiceCharge === true) {
          errors.secondaryChargedName = 'Secondary Charged Name is required.';
        }
        if(!data.chargedValue && formData.applyExtraServiceCharge === true) {
          errors.chargedValue = 'Charged Value is required.';
        }
        if(!data.chargedPer && formData.applyExtraServiceCharge === true) {
          errors.chargedPerError = 'Charged Per is required.';
        }
        if(data.haveScrapItem && !data.scrapItemId) {
          errors.scrapItemIdError = 'Scrap Part is required.';
        }
        if (!data.price) {
          errors.priceError = 'Price is required.';
        }
        if (data.minQuantity > data.maxQuantity) {
          errors.minQuantityError = 'Min Quantity must be less than Max Quantity.';
          errors.maxQuantityError = 'Max Quantity must be greater than Max Quantity.';
        }
    
        return errors;
      };

      const { onCreateItems } = useItemsService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('items') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('itemCode')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateItems } = useUpdateItemsService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('items') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('itemCode')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id :formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const ItemsData: ItemsRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            id: formData.id,
            itemCode: formData.itemCode,
            partNo: formData.partNo,
            sortOrder: formData.sortOrder,
            taxProcedureId: formData.taxProcedureId,
            baseUnitID: formData.baseUnitID,
            manufacturer: formData.manufacturer,
            price: formData.price,
            scrapItemId: formData.scrapItemId,
            chargedName: formData.chargedName,
            secondaryChargedName: formData.secondaryChargedName,
            chargedValue: formData.chargedValue,
            chargedPer: formData.chargedPer,
            stockReferanceNumber: formData.stockReferanceNumber,
            minQuantity: formData.minQuantity,
            maxQuantity: formData.maxQuantity,
            itemCost: formData.itemCost,
            baseItemQuantityType: formData.baseItemQuantityType,
            itemSupplierNumber: formData.itemSupplierNumber,
            warranty: formData.warranty,
            itemWarrantyServiceId: formData.itemWarrantyServiceId,
            shippingMethod: formData.shippingMethod,
            minPrice: formData.minPrice,
            sellBelowCost: formData.sellBelowCost,
            extraNotes: formData.extraNotes,
            itemPictureUrl: formData.itemPictureUrl,
            salesMarkupPercentage: formData.salesMarkupPercentage,
            haveScrapItem: formData.haveScrapItem,
            applyExtraServiceCharge: formData.applyExtraServiceCharge,
            default: formData.default,
            taxable: formData.taxable,
            itemEveryMile: formData.itemEveryMile,
            itemEveryPeriodType: formData.itemEveryPeriodType,
            active: formData.active
          };
     
          if (enableEdit) {
            onUpdateItems(
                ItemsData
            );
          } else {
            onCreateItems(ItemsData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setIsBtnEnabled(false);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        manufacturersRefetch();
        taxProceduresRefetch();
        scrapItemsRefetch();
        unitsRefetch();
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row);
        setDeleteModal(true);
      }

      const { onDeleteItems } = useDeleteItemsService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('items') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteItems();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        if(!rowView) {
          setSearch('');
          setSearchTerm('');
          if(searchTerm) { handleSearch(''); }
        } else {
          setSearchAssign('');
          setSearchTermAssign('');
          if(searchTermAssign) { handleSearch(''); }
        }
      }
      const handleSearch = (search: string) => {
        if(!rowView) {
          setLoadingSearch(true);
          setSearchTerm(search);
          setPageNo(1);
          setPageSize(pageSize);
        } else {
          setLoadingSearchAssign(true);
          setSearchTermAssign(search);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
    
      const handleSearchClick = () => {
        if(!rowView) {
          const trimmedSearch = search.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        } else {
          const trimmedSearch = searchAssign.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!rowView) {
          setSearch(event.target.value?.trim());
          if (!event.target.value?.trim()) handleSearch('');
        } else {
          setSearchAssign(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        }
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusItems({});
      }
      const { onChangeStatusItems } = useChangeStatusItemsService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('items') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        manufacturersRefetch();
        taxProceduresRefetch();
        scrapItemsRefetch();
        unitsRefetch();
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setItemId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangePriceList = (field: string, value: any) => {
        const  val = field === "price" ? (value >= 0 ? value : 0) : value;
        setPriceListFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (priceListRequiredFields.includes(field) && !val) {
          setPriceListErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setPriceListErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validatePriceListFormData = (data: PriceListFormData) => {
        const errors: Partial<PriceListFormData> = {};

        if (!data.price) {
          errors.priceError = 'Required';
        }
    
        return errors;
      };
      const handleChangeUnit = (field: string, value: any) => {
        const val = field === "baseUnit" ? value ? Boolean(value) : false
          : field === "baseUnitId" ? value ? parseInt(value.value) || 0 : 0
          : field === "conversionMultiplier" ? value ? value >=0 ? value : 0 : 0
          : value;
        setUnitFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
        if (field === "baseUnit") {
          if (val) {
            unitRequiredFields = [""];
          } else {
            unitRequiredFields = ["baseUnitId", "conversionMultiplier"];
          }
        }
      
        if (unitRequiredFields.includes(field) && !val) {
          setUnitErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setUnitErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateUnitFormData = (data: UnitFormData) => {
        const errors: Partial<UnitFormData> = {};

        if(!data.baseUnit && !data.baseUnitId) {
          errors.baseUnitIdError = 'Required.';
        }
        if(!data.baseUnit && !data.conversionMultiplier) {
          errors.conversionMultiplierError = 'Required.';
        }
    
        return errors;
      };
      const handleChangeTabs = (tabId: number) => {
        setSearchAssign('');
        setSearchTermAssign('');
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
          manufacturersRefetch();
          taxProceduresRefetch();
          scrapItemsRefetch();
          unitsRefetch();
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          unitsRefetch();
          setErrorMessages({});
          setUnitErrorMessages({});
          setUnitFormData({
            itemId: 0,
            unitId: 0,
            baseUnit: false,
            baseUnitId: 0,
            conversionMultiplier: 0,
          });
        } else if(tabId === 2) {
          setTab(2);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        } else if(tabId === 3) {
          setTab(3);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
        } else if(tabId === 4) {
          setTab(4);
          setPageNo(1);
          setEnableEdit(false);
          setPriceListErrorMessages({});
          setPriceListFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        if(isChecked) {
          if(tab === 1) {
            const errors = validateUnitFormData(unitFormData);
            setUnitErrorMessages(errors);
            
            if (Object.keys(errors).length === 0) {
              setLoadingSearchAssign(true);
              const ItemsUnitData: AssignUnitRequest = {
                ...unitFormData, 
                unitId: row.id,
                itemId: itemId,
                baseUnit: unitFormData.baseUnit,
                baseUnitId: unitFormData.baseUnitId,
                conversionMultiplier: +unitFormData.conversionMultiplier,
              };
     
              onAssignUnit(ItemsUnitData);
            }
          } else if(tab === 2) {
            setLoadingSearchAssign(true);
            onAssignModel({ itemId: itemId, modelId: row.id });
          } else if(tab === 3) {
            setLoadingSearchAssign(true);
            onAssignVendor({ itemId: itemId, vendorId: row.id });
          } else if(tab === 4) {
            const errors = validatePriceListFormData(priceListFormData);
            setPriceListErrorMessages(errors);
            
            if (Object.keys(errors).length === 0) {
              setLoadingSearchAssign(true);
              const ItemsPriceListData: AssignPriceListRequest = {
                ...priceListFormData, 
                pricelistId: row.id,
                price: +priceListFormData.price,
                itemId: itemId,
              };
     
              onAssignPriceList(ItemsPriceListData);
            }
          }
        } else {
          if(tab === 1) {
            setLoadingSearchAssign(true);
            onUnassignUnit({ itemId: itemId, unitId: row.id });
          } else if(tab === 2) {
            setLoadingSearchAssign(true);
            onUnassignModel({ itemId: itemId, modelId: row.id });
          } else if(tab === 3) {
            setLoadingSearchAssign(true);
            onUnassignVendor({ itemId: itemId, vendorId: row.id });
          } else if(tab === 4) {
            setLoadingSearchAssign(true);
            onUnassignPriceList({ itemId: itemId, pricelistId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 2) {
            onAssignAllModels({itemId: itemId});
          } else if(tab === 3) {
            onAssignAllVendors({itemId: itemId});
          }
        } else {
          if(tab === 2) {
            onUnassignAllModels({itemId: itemId});
          } else if(tab === 3) {
            onUnassignAllVendors({itemId: itemId});
          }
        }
      }
      const { onAssignUnit } = useAssignUnitService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await unitAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('unit') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await unitAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('baseUnitId')} />, { variant: 'error' });
          }
          setUnitFormData({
            itemId: 0,
            unitId: 0,
            baseUnit: false,
            baseUnitId: 0,
            conversionMultiplier: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setUnitFormData({
            itemId: 0,
            unitId: 0,
            baseUnit: false,
            baseUnitId: 0,
            conversionMultiplier: 0,
          });
      }})
      const { onUnassignUnit } = useUnassignUnitService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            await unitAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('unit') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await unitAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setUnitFormData({
            itemId: 0,
            unitId: 0,
            baseUnit: false,
            baseUnitId: 0,
            conversionMultiplier: 0,
          });
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setUnitFormData({
            itemId: 0,
            unitId: 0,
            baseUnit: false,
            baseUnitId: 0,
            conversionMultiplier: 0,
          });
        },
      });
      const { onAssignModel } = useAssignModelService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(modelAssignData?.pages[0].data.content.length === modelAssignData?.pages[0].data.content.filter(model => model.linked).length) {
              allModelsAssigned = true;
            }
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('model') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignModel } = useUnassignModelService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(modelAssignData?.pages[0].data.content.length !== modelAssignData?.pages[0].data.content.filter(model => model.linked).length) {
              allModelsAssigned = false;
            }
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('model') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllModels } = useAssignAllModelService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allModelsAssigned = true;
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('models') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllModels } = useUnassignAllModelService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allModelsAssigned = false;
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('models') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await modelAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignVendor } = useAssignVendorService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(vendorAssignData?.pages[0].data.content.length === vendorAssignData?.pages[0].data.content.filter(vendor => vendor.linked).length) {
              allVendorsAssigned = true;
            }
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vendor') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          vendorAssignRefetch();
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignVendor } = useUnassignVendorService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(vendorAssignData?.pages[0].data.content.length !== vendorAssignData?.pages[0].data.content.filter(vendor => vendor.linked).length) {
              allVendorsAssigned = false;
            }
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vendor') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllVendors } = useAssignAllVendorService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allVendorsAssigned = true;
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('vendors') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllVendors } = useUnassignAllVendorService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allVendorsAssigned = false;
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('vendors') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await vendorAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignPriceList } = useAssignItemPriceListService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('priceList') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setPriceListFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setPriceListFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
      }})
      const { onUnassignPriceList } = useUnassignItemPriceListService({
        onSuccess: async(msg) => {
          if(msg.success) {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('priceList') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await priceListAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          priceListAssignRefetch();
          setPriceListFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setPriceListFormData({
            itemId: 0,
            price: 0,
            pricelistId: 0,
          });
        },
      });
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_ITEM_UPDATE') 
          ? (checkPrivileges('ROLE_ITEM_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_ITEM_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ItemsComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                manufacturersData={manufacturersData?.pages[0] || {}}
                taxProceduresData={taxProceduresData?.pages[0] || {}}
                scrapItemsData={scrapItemsData?.pages[0] || {}}
                unitsData={unitsData?.pages[0] || {}}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allModelsAssigned={allModelsAssigned}
                allVendorsAssigned={allVendorsAssigned}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                clearSearch={clearSearch}
                refetch={refetch}
                columns={checkPrivileges("ROLE_ITEM_ACTIVE") ? [
                    { id: 'title', label: 'name', numeric: false },
                    { id: 'itemCode', label: 'itemCode', numeric: false },
                    { id: 'itemCost', label: 'itemCost', numeric: false },
                    { id: 'partNo', label: 'partNo', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'name', numeric: false },
                  { id: 'itemCode', label: 'itemCode', numeric: false },
                  { id: 'itemCost', label: 'itemCost', numeric: false },
                  { id: 'partNo', label: 'partNo', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                unitAssignData={unitAssignData?.pages[0] || {}}
                modelAssignData={modelAssignData?.pages[0] || {}}
                vendorAssignData={vendorAssignData?.pages[0] || {}}
                priceListAssignData={priceListAssignData?.pages[0] || {}}
                priceListFormData={priceListFormData}
                handleChangePriceList={handleChangePriceList}
                priceListErrorMessages={priceListErrorMessages}
                unitFormData={unitFormData}
                handleChangeUnit={handleChangeUnit}
                unitErrorMessages={unitErrorMessages}
                {...{ openForm, openDeleteModal, showSecField,enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, handleSearch, search, loadingSearch, searchAssign, loadingSearchAssign, defaultPriceList, loggedInUserCurrency, defaultPriceListId}}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }