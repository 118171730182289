// VehicleService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusVehicleResponse,
  DeleteVehicleResponse,
  GetModelEnginesByModelResponse,
  GetModelsByMakeResponse,
  GetVehicleResponse,
  VehicleRequest,
  VehicleResponse,
} from './interface';

export class VehicleProfile extends BaseService {
  async createVehicle(req: VehicleRequest) {
    return this.post<VehicleResponse, VehicleRequest>(`${this.apiUrl}/api/vehicles`, req);
  }

  async getVehicle(params: PaginationParams) {
    return this.get<GetVehicleResponse>(`${this.apiUrl}/api/vehicles/paged`, params);
  }

  async updateVehicle(id: number, req: VehicleRequest) {
    return this.put<VehicleResponse, VehicleRequest>(`${this.apiUrl}/api/vehicles/${id}`, req);
  }

  async deleteVehicle(id: number) {
    return this.delete<DeleteVehicleResponse>(`${this.apiUrl}/api/vehicles/${id}`);
  }

  async changeStatusVehicle(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusVehicleResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/vehicles/${active}/${id}`,
      req
    );
  }

  async getModelsByMake(params: PaginationParams, id: number) {
    return this.get<GetModelsByMakeResponse>(`${this.apiUrl}/api/models/bymakeid/${id}`, params);
  }

  async getModelEnginesByModel(params: PaginationParams, id: number) {
    return this.get<GetModelEnginesByModelResponse>(`${this.apiUrl}/api/modelengines/bymodelid/${id}`, params);
  }
}
