// UserModuleProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { UserModuleProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangeStatusUserResponse,
  DeleteUserResponse,
  UpdatePasswordRequest,
  UserRequest,
  UserResponse,
} from './interface';
import { useCallback } from 'react';

type UseUserServiceProps = {
  onSuccess: (data: UserResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateUserServiceProps = {
  onSuccess: (data: UserResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteUserServiceProps = {
  onSuccess: (data: DeleteUserResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusUserServiceProps = {
  onSuccess: (data: ChangeStatusUserResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type ChangePasswordServiceProps = {
  onSuccess: (data: ChangePasswordResponse) => void;
  onError: (err: unknown) => void;
};

export const useUserService = ({ onError, onSuccess }: UseUserServiceProps) => {
  const {
    mutateAsync: createUser,
    isPending: isCreateUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-users-request'],
    mutationFn: (req: UserRequest) => UserModuleProfileService.createUser(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateUser = useCallback(
    async (userData: UserRequest) => {
      try {
        createUser(userData);
      } catch (err) {
        console.error(err);
      }
    },
    [createUser]
  );

  return {
    onCreateUser,
    isCreateUserLoading,
    data,
    error,
    isError,
  };
};

export const useGetUserService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-users-data'],
      queryFn: async ({ pageParam }) => {
        return UserModuleProfileService.getUser({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
          userName: initialParams.userName,
          type: initialParams.type,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateUserService = ({ id, onError, onSuccess }: UpdateUserServiceProps) => {
  const {
    mutateAsync: updateUser,
    isPending: isUpdateUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-users-request'],
    mutationFn: (req: UserRequest) => UserModuleProfileService.updateUser(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateUser = useCallback(
    async (req: UserRequest) => {
      try {
        updateUser(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateUser]
  );

  return {
    onUpdateUser,
    isUpdateUserLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteUserService = ({ id, onError, onSuccess }: DeleteUserServiceProps) => {
  const {
    mutateAsync: deleteUser,
    isPending: isDeleteUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-user-request'],
    mutationFn: () => UserModuleProfileService.deleteUser(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteUser = useCallback(async () => {
    try {
      deleteUser();
    } catch (err) {
      console.error(err);
    }
  }, [deleteUser]);

  return {
    onDeleteUser,
    isDeleteUserLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusUserService = ({ active, id, onError, onSuccess }: ChangeStatusUserServiceProps) => {
  const {
    mutateAsync: changeStatusUser,
    isPending: isChangeStatusUserLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-user-request'],
    mutationFn: (req: ChangeStatusRequest) => UserModuleProfileService.changeStatusUser(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusUser = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusUser(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusUser]
  );

  return {
    onChangeStatusUser,
    isChangeStatusUserLoading,
    data,
    error,
    isError,
  };
};

export const useChangePasswordService = ({ onError, onSuccess }: ChangePasswordServiceProps) => {
  const {
    mutateAsync: changePassword,
    isPending: isChangePasswordLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-password-request'],
    mutationFn: (req: ChangePasswordRequest) => UserModuleProfileService.changePassword(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangePassword = useCallback(
    async (req: ChangePasswordRequest) => {
      try {
        changePassword(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changePassword]
  );

  return {
    onChangePassword,
    isChangePasswordLoading,
    data,
    error,
    isError,
  };
};

export const useUpdatePasswordService = ({ onError, onSuccess }: ChangePasswordServiceProps) => {
  const {
    mutateAsync: updatePassword,
    isPending: isUpdatePasswordLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-password-request'],
    mutationFn: (req: UpdatePasswordRequest) => UserModuleProfileService.updatePassword(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePassword = useCallback(
    async (req: UpdatePasswordRequest) => {
      try {
        updatePassword(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePassword]
  );

  return {
    onUpdatePassword,
    isUpdatePasswordLoading,
    data,
    error,
    isError,
  };
};
