import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import ClientGroupComponent from 'components/ClientGroup/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ClientGroupRequest } from 'subModule/src/services/clientgroup/interface';
import { useClientGroupService,useChangeStatusClientGroupService,useDeleteClientGroupService,useGetClientGroupService,useUpdateClientGroupService, useGetClientGroupClientService, useAssignClientService, useUnassignClientService, useAssignAllClientService, useUnassignAllClientService } from 'subModule/src/services/clientgroup/useClientGroup';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { SelectChangeEvent } from '@mui/material';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  active: boolean
}

interface SearchAssignFormData {
  [key: string]: string;
}

  const requiredFields = ['title'];

  export default function ClientGroup () {
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [search, setSearch] = useState('');
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [rowView, setRowView] = useState(false);
    const [clientGroupId, setClientGroupId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
  const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
    const [formData, setFormData] = useState<FormData>({
      title: '',
      secondaryTitle: '',
      id: 0,
      active: true
    });

    const [searchAssignFormData, setSearchAssignFormData] = useState<SearchAssignFormData>({
      name: '',
      userName: ''
    });
    const [filterAssign, setFilterAssign] = useState<string[]>(['name']);
    const [searchAssignErrorMessages, setSearchAssignErrorMessages] = useState<Partial<SearchAssignFormData>>({});
    const [searchMultiAssign, setSearchMultiAssign] = useState<any>({});

    // const { data: tableData,  isLoading, error,  refetch } = useGetClientGroupService({page: pageNo, size: pageSize});
    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetClientGroupService({pageNumber : pageNo,size: pageSize,title:searchTerm}) : useGetClientGroupService({pageNumber : pageNo,size: pageSize})
    
    const { data: clientAssignData, isLoading: clientAssignIsLoading, error: clientAssignError, refetch: clientAssignRefetch } = useGetClientGroupClientService({ pageNumber: pageNo, size: pageSize, ...searchMultiAssign }, clientGroupId);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    let allClientsAssigned:unknown = clientAssignData?.pages[0].data?.content.map(client => client.linked).every(linked => linked === true);

    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!clientAssignIsLoading && !clientAssignError && clientGroupId && clientGroupId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingSearchAssign(true);
          await clientAssignRefetch();
          setLoadingSearchAssign(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, clientGroupId, tab, tableData, openForm]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
         await refetch();
          setLoadingSearch(false);
        }
      }, 1000)
      return () => clearTimeout(getData)
    }, [searchTerm,loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchMultiAssign && loadingSearchAssign && tab === 1) {
          await clientAssignRefetch();
          setLoadingSearchAssign(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchMultiAssign]);
    
    const handleChangePage = (pageNo : number) =>{  
      setLoading(true);
      setPageNo(pageNo+1);
      setPageSize(pageSize)

    }
    const handleChangeRows = (pageSize : number) =>{
      setLoading(true);
      setPageSize(pageSize)
      setPageNo(1)
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setClientGroupId(0);
        setPageNo(1);
        setTab(0);
        setSearchMultiAssign({});
      } else {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        openModalSettings();
      }
    };
    const openModalSettings = () => {
      setEnableEdit(false);
      setErrorMessages({})
      setSearch("");
      setShowSecField(false)
      setSearchTerm("");
      setFormData({
        title: '',
        secondaryTitle: '',
        id: 0,
        active: true
      });
    }

    const handleSecField=()=>{
      setShowSecField((prevValue) => !prevValue);
    }

      const handleChange = (field: string, value: any) => {
        const  val = value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
        if(enableEdit) {
          setIsBtnEnabled(true);
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = 'Title is required.';
        }
    
        return errors;
      };

      const { onCreateClientGroup } = useClientGroupService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('clientGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const {onUpdateClientGroup } = useUpdateClientGroupService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('clientGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const clientGroupData: ClientGroupRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
          };
     
          if (enableEdit) {
            onUpdateClientGroup(
              clientGroupData
            );
          } else {
            onCreateClientGroup(clientGroupData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setSearch("");
        setSearchTerm("");
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteClientGroup } = useDeleteClientGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('clientGroup') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false)
        setLoading(true);
        onDeleteClientGroup()
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        if(!rowView) {
          setLoadingSearch(true);
          setSearchTerm(search);
          setPageNo(1);
          setPageSize(pageSize);
        } else {
          setLoadingSearchAssign(true);
          setSearchMultiAssign(search);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
    
      const handleSearchClick = () => {
        if(!rowView) {
          const trimmedSearch = search.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        } else {
          const trimmedSearch = searchMultiAssign?.trim();
          if (trimmedSearch !== '') {
            handleSearch(trimmedSearch);
          }
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(!rowView) {
          setSearch(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        } else {
          setSearchMultiAssign(event.target.value.trim());
          if (!event.target.value.trim()) handleSearch('');
        }
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusClientGroup({});
      }
      const { onChangeStatusClientGroup } = useChangeStatusClientGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('clientGroup') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setClientGroupId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
        clearSearchAssign();
      }
      const handleChangeTabs = (tabId: number) => {
        setSearchMultiAssign({});
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          clearSearchAssign();
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignClient({ clientGroupId: clientGroupId, clientId: row.id });
          }
        } else {
          if(tab === 1) {
            onUnassignClient({ clientGroupId: clientGroupId, clientId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingSearchAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignAllClients({clientGroupId: clientGroupId});
          }
        } else {
          if(tab === 1) {
            onUnassignAllClients({clientGroupId: clientGroupId});
          }
        }
      }
      const { onAssignClient } = useAssignClientService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(clientAssignData?.pages[0].data.content.length === clientAssignData?.pages[0].data.content.filter(client => client.linked).length) {
              allClientsAssigned = true;
            }
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('clients') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignClient } = useUnassignClientService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(clientAssignData?.pages[0].data.content.length !== clientAssignData?.pages[0].data.content.filter(client => client.linked).length) {
              allClientsAssigned = false;
            }
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('client') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllClients } = useAssignAllClientService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allClientsAssigned = true;
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + t('clients') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllClients } = useUnassignAllClientService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allClientsAssigned = false;
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + t('clients') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await clientAssignRefetch();
            setLoadingSearchAssign(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const clearSearchAssign = () => {
        setFilterAssign(['name']);
        setSearchAssignFormData({
          name: '',
          userName: ''
        });
        setSearchAssignErrorMessages({});
        if(Object.keys(searchMultiAssign).length) {
          setLoadingSearchAssign(true);
          setSearchMultiAssign({});
        }
      }
      const handleChangeAssignFilter = (event: SelectChangeEvent<typeof filterAssign>) => {
        const { target: { value }, } = event;
        setFilterAssign(typeof value === 'string' ? value.split(',') : value,);
        setSearchAssignFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : '']
          ))
        );
        if(!value.length && Object.keys(searchMultiAssign).length) {
          clearSearchAssign();
        }
      };
      const handleChangeSearchAssign = (field: string, value: any) => {
        const val = value;
    
        setSearchAssignFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filterAssign.includes(field) && !val) {
          setSearchAssignErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchAssignErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchAssignFormData = (data: SearchAssignFormData) => {
        const errors: Partial<SearchAssignFormData> = {};
    
        filterAssign.forEach(fieldName => {
          if (!data[fieldName]?.trim()) { 
            errors[fieldName] = 'Required';
          }
        });
    
        return errors;
      };
      const handleSubmitSearchAssign = () => {
        const errors = validateSearchAssignFormData(searchAssignFormData);
        setSearchAssignErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingSearchAssign(true);
          const filteredSearch: Record<string, string> = {};
    
          Object.entries(searchAssignFormData).forEach(([key, value]) => {
              if (value) {
                filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
              }
          });
          setSearchMultiAssign(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_CLIENTGROUP_UPDATE') 
          ? (checkPrivileges('ROLE_CLIENTGROUP_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_CLIENTGROUP_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <ClientGroupComponent
                openModal={openModal}
                closeModal={openModal}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allClientsAssigned={allClientsAssigned}
                searchAssignFormData={searchAssignFormData}
                handleChangeAssignFilter={handleChangeAssignFilter}
                handleChangeSearchAssign={handleChangeSearchAssign}
                handleSubmitSearchAssign={handleSubmitSearchAssign}
                searchAssignErrorMessages={searchAssignErrorMessages}
                clearSearchAssign={clearSearchAssign}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_CLIENTGROUP_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                clientAssignData={clientAssignData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, search,loadingSearch,showSecField, loadingSearchAssign, filterAssign, searchMultiAssign, tab }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }