// import { useState } from 'react';
import './App.css';
import Layout from 'layout/index';
import { BrowserRouter as Router } from 'react-router-dom';
import { I18nextProvider, useTranslation } from 'react-i18next';
// import i18n1 from './i18/i18';
import { i18n1 } from './i18/i18';

import {
  // useQuery,
  // useMutation,
  // useQueryClient, 
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { Provider } from 'react-redux';
import store from './app/store';
import Alert from 'common/Alert/Alert';

function App() {
  // const [language, setLanguage] = useState('en'); // State to manage language selection
  // const [drawerAnchor, setDrawerAnchor] = useState<'left' | 'right'>('left'); 
  const queryClient = new QueryClient()
  const { i18n } = useTranslation();

  const handleLanguageSelect = (lang: string) => {
    // setLanguage(lang);
    i18n.changeLanguage(lang);
    // if (lang === 'ur' || lang === 'ar') {
    //   setDrawerAnchor('right');
    // } else {
    //   setDrawerAnchor('left');
    // }
  };

  return (
    <div className={`App bg-background-color`} 
    // dir={language === 'ur' || language === 'ar' ? "rtl" : "ltr"}
    >
      <Alert>
       <Provider store={store}>
       <QueryClientProvider client={queryClient}>
          <I18nextProvider i18n={i18n1}>
            <Router>
              <Layout
               handleLanguageSelect={handleLanguageSelect} 
              // drawerAnchor={drawerAnchor}
               />
            </Router>
          </I18nextProvider>
      </QueryClientProvider>
      </Provider>
      </Alert>
    </div>
  );
}

export default App;