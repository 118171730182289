// ClientGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ClientGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignClientRequest,
  AssignUnassignResponse,
  ChangeStatusClientGroupResponse,
  ClientGroupRequest,
  ClientGroupResponse,
  DeleteClientGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UseClientGroupServiceProps = {
  onSuccess: (data: ClientGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateClientGroupServiceProps = {
  onSuccess: (data: ClientGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteClientGroupServiceProps = {
  onSuccess: (data: DeleteClientGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusClientGroupServiceProps = {
  onSuccess: (data: ChangeStatusClientGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useClientGroupService = ({ onError, onSuccess }: UseClientGroupServiceProps) => {
  const {
    mutateAsync: createClientGroup,
    isPending: isCreateClientGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-clientgroups-request'],
    mutationFn: (req: ClientGroupRequest) => ClientGroupProfileService.createClientGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateClientGroup = useCallback(
    async (clientGroupData: ClientGroupRequest) => {
      try {
        createClientGroup(clientGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createClientGroup]
  );

  return {
    onCreateClientGroup,
    isCreateClientGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetClientGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-data'],
      queryFn: async ({ pageParam }) => {
        return ClientGroupProfileService.getClientGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateClientGroupService = ({ id, onError, onSuccess }: UpdateClientGroupServiceProps) => {
  const {
    mutateAsync: updateClientGroup,
    isPending: isUpdateClientGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-clientgroups-request'],
    mutationFn: (req: ClientGroupRequest) => ClientGroupProfileService.updateClientGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateClientGroup = useCallback(
    async (req: ClientGroupRequest) => {
      try {
        updateClientGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateClientGroup]
  );

  return {
    onUpdateClientGroup,
    isUpdateClientGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteClientGroupService = ({ id, onError, onSuccess }: DeleteClientGroupServiceProps) => {
  const {
    mutateAsync: deleteClientGroup,
    isPending: isDeleteClientGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-clientgroup-request'],
    mutationFn: () => ClientGroupProfileService.deleteClientGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteClientGroup = useCallback(async () => {
    try {
      deleteClientGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deleteClientGroup]);

  return {
    onDeleteClientGroup,
    isDeleteClientGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusClientGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusClientGroupServiceProps) => {
  const {
    mutateAsync: changeStatusClientGroup,
    isPending: isChangeStatusClientGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-clientGroup-request'],
    mutationFn: (req: ChangeStatusRequest) => ClientGroupProfileService.changeStatusClientGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusClientGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusClientGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusClientGroup]
  );

  return {
    onChangeStatusClientGroup,
    isChangeStatusClientGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetClientGroupClientService = (initialParams: PageSize, clientGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-clientgroups-clients-data'],
      queryFn: async ({ pageParam }) => {
        return ClientGroupProfileService.getClientGroupClient({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          clientGroupId: clientGroupId,
          name: initialParams.name,
          userName: initialParams.userName,
          type: initialParams.type,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignClientService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignClient,
    isPending: isAssignClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-clients-request'],
    mutationFn: (req: AssignUnassignClientRequest) => ClientGroupProfileService.assignClient(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignClient = useCallback(
    async (assignClientData: AssignUnassignClientRequest) => {
      try {
        assignClient(assignClientData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignClient]
  );

  return {
    onAssignClient,
    isAssignClientLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignClientService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignClient,
    isPending: isUnassignClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-clients-request'],
    mutationFn: (req: AssignUnassignClientRequest) => ClientGroupProfileService.unassignClient(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignClient = useCallback(
    async (unassignClientData: AssignUnassignClientRequest) => {
      try {
        unassignClient(unassignClientData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignClient]
  );

  return {
    onUnassignClient,
    isUnassignClientLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllClientService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllClients,
    isPending: isAssignAllClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-clients-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ClientGroupProfileService.assignAllClients(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllClients = useCallback(
    async (assignAllClientData: AssignUnassignAllRequest) => {
      try {
        assignAllClients(assignAllClientData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllClients]
  );

  return {
    onAssignAllClients,
    isAssignAllClientLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllClientService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllClients,
    isPending: isUnassignAllClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-clients-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ClientGroupProfileService.unassignAllClients(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllClients = useCallback(
    async (unassignAllClientData: AssignUnassignAllRequest) => {
      try {
        unassignAllClients(unassignAllClientData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllClients]
  );

  return {
    onUnassignAllClients,
    isUnassignAllClientLoading,
    data,
    error,
    isError,
  };
};
