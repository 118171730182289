import React, { useEffect } from 'react';
import { Checkbox } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Loader from 'layout/Loader';
import { useTranslation } from 'react-i18next';

interface PrivilegeData {
  id: number;
  active: boolean;
  erole: string;
  parentId: number | null;
  linked: boolean;
}

interface Props {
  privilegeData: PrivilegeData[];
  assignUnassignSingleEntity: (isChecked: boolean, row: any, scroll: number) => void;
  loading: boolean;
  scrollPosition: number;
  assignPrivilege?: boolean;
  unassignPrivilege?: boolean;
}

const Privileges: React.FC<Props> = ({ 
  privilegeData, assignUnassignSingleEntity, loading, scrollPosition, assignPrivilege, unassignPrivilege 
}) => {
  const {t} = useTranslation();

  useEffect(() => {
    if(!loading) { window.scrollTo(0, scrollPosition) }
  }, [loading]);

  const handleParentCheckboxChange = (parent: any, isChecked: boolean) => {
    let groups: any[] = [];
    groups = [parent, ...privilegeData
      .filter((item) => item.parentId === parent.id)
      .map((item) => item)
    ];

    groups.map((group) => {
      if((group as any).parentId === null) {
        assignUnassignSingleEntity(!(group as any).linked, group, window.scrollY);
      } else if((group as any).parentId !== null) {
        if(isChecked) {
          assignUnassignSingleEntity(true, group, window.scrollY);
        } else {
          assignUnassignSingleEntity(false, group, window.scrollY);
        }
      }
    });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box>
          {privilegeData
            .filter((item) => item.parentId === null)
            .map((parentItem) => (
              <Accordion key={parentItem.id} defaultExpanded>
                <AccordionSummary 
                    expandIcon={<span><KeyboardArrowDownIcon /></span>} 
                    sx = {{
                        padding: '0 5px',
                        minHeight: '20px !important',
                        '& .MuiAccordionSummary-content': {
                          alignItems: 'center',
                          margin: '3px 0',
                        },
                        '& .Mui-expanded': {
                          margin: '0 !important',
                        }
                    }}
                >
                  <Checkbox
                    checked={parentItem.linked ? true : false}
                    className='text-primary-color'
                    onChange={(event) => handleParentCheckboxChange(parentItem, event.target.checked)}
                    disabled={(assignPrivilege && !parentItem.linked) ? false 
                      : (unassignPrivilege && parentItem.linked) ? false
                      : true
                    }
                    sx={{
                      opacity: 1,
                      '&.Mui-disabled': {
                        opacity: 0.3
                      } 
                    }}
                  />
                  <Typography className='font-Saira font-[600] text-[14px] text-txt-color'>{t(parentItem.erole)}</Typography>
                </AccordionSummary>
                <AccordionDetails className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-[10px]'>
                  {privilegeData
                    .filter((item) => item.parentId === parentItem.id)
                    .map((childItem) => (
                      <Box key={childItem.id} className='flex text-left'>
                        <Checkbox
                          checked={childItem.linked ? true : false}
                          className='text-primary-color pt-[0px]'
                          onClick={(event) => {assignUnassignSingleEntity((event.target as HTMLInputElement).checked, childItem, window.scrollY)}}
                          disabled={(assignPrivilege && !childItem.linked) ? false 
                            : (unassignPrivilege && childItem.linked) ? false
                            : true
                          }
                          sx={{
                            opacity: 1,
                            '&.Mui-disabled': {
                              opacity: 0.3
                            } 
                          }}
                        />
                        <Typography className='font-Saira font-[500] text-[14px] text-txt-color'>{t(childItem.erole)}</Typography>
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
            ))}
        </Box>
      )}
    </>
  );
};

export default Privileges;