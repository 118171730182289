// ClientService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusClientResponse,
  ClientRequest,
  ClientResponse,
  DeleteClientResponse,
  GetClientsDropdownResponse,
  GetClientsResponse,
} from './interface';

export class ClientProfile extends BaseService {
  async createClient(req: ClientRequest) {
    return this.post<ClientResponse, ClientRequest>(`${this.apiUrl}/api/users/client`, req);
  }

  async getClients(params: PaginationParams) {
    return this.get<GetClientsResponse>(`${this.apiUrl}/api/users/client/paged`, params);
  }

  async getClientsDropdown(params: PaginationParams) {
    return this.get<GetClientsDropdownResponse>(`${this.apiUrl}/api/users/client/get`, params);
  }

  async updateClient(id: number, req: ClientRequest) {
    return this.put<ClientResponse, ClientRequest>(`${this.apiUrl}/api/users/client/${id}`, req);
  }

  async deleteClient(id: number) {
    return this.delete<DeleteClientResponse>(`${this.apiUrl}/api/users/client/${id}`);
  }

  async changeStatusClient(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusClientResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/users/client/${active}/${id}`,
      req
    );
  }
}
