// ClientProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ClientProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { ChangeStatusClientResponse, ClientRequest, ClientResponse, DeleteClientResponse } from './interface';
import { useCallback } from 'react';

type UseClientServiceProps = {
  onSuccess: (data: ClientResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateClientServiceProps = {
  onSuccess: (data: ClientResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteClientServiceProps = {
  onSuccess: (data: DeleteClientResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusClientServiceProps = {
  onSuccess: (data: ChangeStatusClientResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useClientService = ({ onError, onSuccess }: UseClientServiceProps) => {
  const {
    mutateAsync: createClient,
    isPending: isCreateClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-client-request'],
    mutationFn: (req: ClientRequest) => ClientProfileService.createClient(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateClient = useCallback(
    async (clientData: ClientRequest) => {
      try {
        createClient(clientData);
      } catch (err) {
        console.error(err);
      }
    },
    [createClient]
  );

  return {
    onCreateClient,
    isCreateClientLoading,
    data,
    error,
    isError,
  };
};

export const useGetClientsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-clients-data'],
      queryFn: async ({ pageParam }) => {
        return ClientProfileService.getClients({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
          userName: initialParams.userName,
          accountTitle: initialParams.accountTitle,
          contractNumber: initialParams.contractNumber,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetClientsDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-clients-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ClientProfileService.getClientsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          name: initialParams.name,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateClientService = ({ id, onError, onSuccess }: UpdateClientServiceProps) => {
  const {
    mutateAsync: updateClient,
    isPending: isUpdateClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-client-request'],
    mutationFn: (req: ClientRequest) => ClientProfileService.updateClient(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateClient = useCallback(
    async (req: ClientRequest) => {
      try {
        updateClient(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateClient]
  );

  return {
    onUpdateClient,
    isUpdateClientLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteClientService = ({ id, onError, onSuccess }: DeleteClientServiceProps) => {
  const {
    mutateAsync: deleteClient,
    isPending: isDeleteClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-client-request'],
    mutationFn: () => ClientProfileService.deleteClient(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteClient = useCallback(async () => {
    try {
      deleteClient();
    } catch (err) {
      console.error(err);
    }
  }, [deleteClient]);

  return {
    onDeleteClient,
    isDeleteClientLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusClientService = ({ active, id, onError, onSuccess }: ChangeStatusClientServiceProps) => {
  const {
    mutateAsync: changeStatusClient,
    isPending: isChangeStatusClientLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-client-request'],
    mutationFn: (req: ChangeStatusRequest) => ClientProfileService.changeStatusClient(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusClient = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusClient(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusClient]
  );

  return {
    onChangeStatusClient,
    isChangeStatusClientLoading,
    data,
    error,
    isError,
  };
};
