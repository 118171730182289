// UnitsService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusUnitsResponse,
  DeleteUnitsResponse,
  GetUnitsDropdownResponse,
  GetUnitsResponse,
  UnitsRequest,
  UnitsResponse,
} from './interface';

export class UnitsProfile extends BaseService {
  async createUnits(req: UnitsRequest) {
    return this.post<UnitsResponse, UnitsRequest>(`${this.apiUrl}/api/units`, req);
  }

  async getUnits(params: PaginationParams) {
    return this.get<GetUnitsResponse>(`${this.apiUrl}/api/units/paged`, params);
  }

  async getUnitsDropdown(params: PaginationParams) {
    return this.get<GetUnitsDropdownResponse>(`${this.apiUrl}/api/units/get`, params);
  }

  async updateUnits(id: number, req: UnitsRequest) {
    return this.put<UnitsResponse, UnitsRequest>(`${this.apiUrl}/api/units/${id}`, req);
  }

  async deleteUnits(id: number) {
    return this.delete<DeleteUnitsResponse>(`${this.apiUrl}/api/units/${id}`);
  }

  async changeStatusUnits(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusUnitsResponse, ChangeStatusRequest>(`${this.apiUrl}/api/units/${active}/${id}`, req);
  }
}
