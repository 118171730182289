import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Model } from "assets/SideBarIcons/car-model.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ModelModalComponent from "./ModelModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import { ReactComponent as Make } from "assets/SideBarIcons/make.svg";
import MakePopupComponent from "./MakePopup";
import Loader from "layout/Loader";
import VerticalTabs from "common/ComponentSidebar/ComponentSideBar";
import { checkPrivileges } from "Helpers/CheckPrivileges";

interface ModelComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete: () => void;
  cancelDelete: () => void;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText: string;
  formData: any;
  openForm: boolean;
  columns: any[];
  data: any;
  makesData: any;
  errorMessages: any,
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?: boolean;
  pageNo: number;
  pageSize: number;
  openMakePopup: boolean;
  onOpenMakePopup: () => void;
  loadingSearch: boolean;
  closeMakePopup: () => void;
  submitMake: () => void;
  makeErrorMessages: any;
  handleChangeMake: (field: string, value: any) => void;
  makeformData: any;
  isBtnEnabled: boolean;
  handleSecField: () => void;
  showModelSecField: boolean;
  showModelMakeSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  loading: boolean;
  showRowView: (row: any) => void;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  searchAssign: string;
  loadingSearchAssign: boolean;
  allServicesAssigned: unknown;
  serviceAssignData: any;
  clearSearch: () => void;
}

const ModelComponent: React.FC<ModelComponentProps> = ({
  openModal,
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  columns,
  data,
  makesData,
  errorMessages,
  actions1,
  showModelSecField,
  showModelMakeSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openDeleteModal,
  loadingSearch,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleSearchClick,
  handleInputChange,
  search,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,
  statusText,
  pageSize,
  openMakePopup,
  onOpenMakePopup,
  closeMakePopup,
  submitMake,
  makeErrorMessages,
  handleChangeMake,
  makeformData,
  isBtnEnabled,
  loading,
  showRowView,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  searchAssign,
  loadingSearchAssign,
  allServicesAssigned,
  serviceAssignData,
  clearSearch
}) => {
  const { t } = useTranslation();
  const serviceColumns = [
    { id: 'title', label: 'title', numeric: false },
    { id: 'serviceCode', label: 'serviceCode', numeric: false },
  ]
  const childTabContent = (columns: any, data: any, tableData: any, allEntitiesAssigned: unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingSearchAssign}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={(
          <Grid container spacing={2}>
            <Grid item xs={12} md={7} lg={5}>
              <StyledInput
                fullWidth
                placeholder={t("title")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={searchAssign}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
  const tabs = [
    {
      id: 0, title: `edit`,
      child: <ModelModalComponent
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSecField={handleSecField}
        showModelSecField={showModelSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        handleSubmit={handleSubmit}
        formData={formData}
        makesData={makesData.data?.content || []}
        openForm={true}
        errorMessages={errorMessages}
        onOpenMakePopup={onOpenMakePopup}
        isBtnEnabled={isBtnEnabled}
        loadNext={loadNext}
        loadPrev={loadPrev}
        handleDropdownSearch={handleDropdownSearch}
        {...{ loadingDropdownSearch }}
      />
    },
    { id: 1, title: `service`, child: childTabContent(serviceColumns, serviceAssignData.data?.content || [], serviceAssignData?.data || {}, allServicesAssigned, 'ROLE_MODEL_SERVICE_ASSIGN', 'ROLE_MODEL_SERVICE_UNASSIGN') },
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_MODEL_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_MODEL_SERVICE_ASSIGN') && !checkPrivileges('ROLE_MODEL_SERVICE_UNASSIGN');
      default:
        return false;
    }
  });

  return (

    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={openForm}
        onClick={openModal}
        onSubmit={handleSubmit}
        heading={(openForm && !enableEdit) ? "addModels" : (enableEdit && openForm) ? "editModels" : t('models')}
        icon={<Model height={28} width={28} />}
        btnText={openForm || rowView ? "back" : "create"}
        btnType={openForm || rowView ? "back" : "create"}
        isBtnEnabled={isBtnEnabled}
        showCreateBtn={checkPrivileges("ROLE_MODEL_CREATE")}
      // btnType="create"
      />

      {openForm ?
        <ModelModalComponent
          enableEdit={enableEdit}
          openModal={openModal}
          closeModal={closeModal}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleSecField={handleSecField}
          showModelSecField={showModelSecField}
          secondaryLanguage={secondaryLanguage}
          primaryLanguage={primaryLanguage}
          formData={formData}
          makesData={makesData.data?.content || []}
          openForm={openForm}
          errorMessages={errorMessages}
          onOpenMakePopup={onOpenMakePopup}
          isBtnEnabled={isBtnEnabled}
          loadNext={loadNext}
          loadPrev={loadPrev}
          handleDropdownSearch={handleDropdownSearch}
          {...{ loadingDropdownSearch }}
        />
        : rowView ?
          <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={disableTabs} />
          : <Box>
            {loading ? <Loader /> :
              <BackendPaginationTable
                columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                data={data.data?.content || []}
                pageNo={pageNo}
                pageSize={pageSize}
                tableData={data?.data || {}}
                showCheckbox={false}
                showActions={true}
                enableSorting={true}
                showHeader={true}
                showSearch={true}
                showFilter={true}
                showCreateButton={true}
                actions={actions1}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                rowClick={disableTabs.length === tabs.length ? false : true}
                showRowView={showRowView}
                loadingSearch={loadingSearch}
                headerComponent={
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={7} lg={5}>
                      <StyledInput
                        fullWidth
                        placeholder={t("title")}
                        handleSearchClick={handleSearchClick}
                        handleChange={handleInputChange}
                        value={search}
                        clearSearch={clearSearch}
                      />
                    </Grid>
                  </Grid>
                }
              />
            }
          </Box>
      }

      {openDeleteModal &&
        <FormDialog
          open={openDeleteModal}
          onClose={cancelDelete}
          title="Delete Model"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  Are you sure you want to Delete?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={handleDelete}
          submitButtonText="Delete"
        />}
      {statusModal &&
        <FormDialog
          open={statusModal}
          onClose={cancelChangeStatus}
          title="Change Status"
          // icon={<div><Home/></div>}
          content={
            <Grid container >
              <Grid item xs={12} sm={12} md={12} lg={12}  >
                <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                </Typography>
              </Grid>
            </Grid>
          }
          saveFunction={submitChangeStatus}
          submitButtonText="OK"
        />
      }
      {openMakePopup &&
        <FormDialog
          open={openMakePopup}
          onClose={closeMakePopup}
          title="addMake"
          icon={<Make height={28} width={28} />}
          content={
            <MakePopupComponent
            handleSecField={handleSecField}
            showModelMakeSecField={showModelMakeSecField}
            secondaryLanguage={secondaryLanguage}
            primaryLanguage={primaryLanguage}
              openModal={onOpenMakePopup}
              closeModal={onOpenMakePopup}
              handleChange={handleChangeMake}
              handleSubmit={submitMake}
              formData={makeformData}
              openForm={openMakePopup}
              errorMessages={makeErrorMessages}
            />
          }
          saveFunction={submitMake}
          submitButtonText="Save"
          createPopup={true}
        />
      }
    </Box>

  )
}

export default ModelComponent;