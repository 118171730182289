import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import { useTranslation } from 'react-i18next';
// import CommonCheckbox from 'common/CheckBox/Checkbox';

interface ModelPopUpComponentProps {
  handleSecField: (field: string) => void;
  showModelEngineModelSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  makesData: any;
  loadingDropdownSearch: boolean;
  handleDropdownSearch: (field: string, search: string) => void;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
}

const ModelPopUpComponent: React.FC<ModelPopUpComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  showModelEngineModelSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  formData,
  openForm,
  errorMessages,
  enableEdit,
  makesData,
  loadingDropdownSearch,
  handleDropdownSearch,
  loadNext,
  loadPrev,
}) => {
  const { t } = useTranslation();
  const makeOptions = makesData.map((item: { id: number, title: string }) => ({
    value: item.id,
    label: item.title
  }));

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={""}
        isButtonVisable={false}
        isVisableBtn={true}
        btnclosetext="Back"
        buttonText={"submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        padding={false}
        entityPopup={true}
        content={
          <Grid container spacing={2} >
            <Grid item xs={12} sm={6}>
              <DynamicSelect
                defaultValue={enableEdit ? makeOptions.find((option: any) => option.value === formData?.makeId) : null}
                value={makeOptions.find((option: any) => option.value === formData?.makeId)}
                isDisabled={false}
                isLoading={loadingDropdownSearch}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="makeId"
                options={makeOptions}
                // onScroll={handleScroll}
                onInputChange={(e) => handleDropdownSearch('makeId', e)}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('makeId', e)}
                error={errorMessages?.makeIdError}
                label="make"
                onMenuScrollToBottom={(e) => { loadNext('makeId', e); }}
                onMenuScrollToTop={(e) => { loadPrev('makeId', e); }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                search='language'
                handleSecField={() => handleSecField("modelTitle")}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth={true}
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
              {showModelEngineModelSecField &&
                      <StyledField
                        label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                        placeholder="Write your secondaryTitle here"
                        handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                        required={false}
                        fullWidth
                        // error={errorMessages?.secondaryTitle}
                        value={formData?.secondaryTitle || ''}
                      />
            }

          </Grid> 

          </Grid>
        }
/>
    </div >
  );
};

export default ModelPopUpComponent;