// BusinessGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { BusinessGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignBusinessRequest,
  AssignUnassignResponse,
  AssignUnassignStationRequest,
  BusinessGroupRequest,
  BusinessGroupResponse,
  ChangeStatusBusinessGroupResponse,
  DeleteBusinessGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UseBusinessGroupServiceProps = {
  onSuccess: (data: BusinessGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateBusinessGroupServiceProps = {
  onSuccess: (data: BusinessGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteBusinessGroupServiceProps = {
  onSuccess: (data: DeleteBusinessGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusBusinessGroupServiceProps = {
  onSuccess: (data: ChangeStatusBusinessGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useBusinessGroupService = ({ onError, onSuccess }: UseBusinessGroupServiceProps) => {
  const {
    mutateAsync: createBusinessGroup,
    isPending: isCreateBusinessGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-BusinessGroup-request'],
    mutationFn: (req: BusinessGroupRequest) => BusinessGroupProfileService.createBusinessGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateBusinessGroup = useCallback(
    async (BusinessGroupData: BusinessGroupRequest) => {
      try {
        createBusinessGroup(BusinessGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createBusinessGroup]
  );

  return {
    onCreateBusinessGroup,
    isCreateBusinessGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetBusinessGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-BusinessGroup-data'],
      queryFn: async ({ pageParam }) => {
        return BusinessGroupProfileService.getBusinessGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetBusinessGroupDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-BusinessGroup-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return BusinessGroupProfileService.getBussinessGroupDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateBusinessGroupService = ({ id, onError, onSuccess }: UpdateBusinessGroupServiceProps) => {
  const {
    mutateAsync: updateBusinessGroup,
    isPending: isUpdateBusinessGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-BusinessGroup-request'],
    mutationFn: (req: BusinessGroupRequest) => BusinessGroupProfileService.updateBusinessGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateBusinessGroup = useCallback(
    async (req: BusinessGroupRequest) => {
      try {
        updateBusinessGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateBusinessGroup]
  );

  return {
    onUpdateBusinessGroup,
    isUpdateBusinessGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteBusinessGroupService = ({ id, onError, onSuccess }: DeleteBusinessGroupServiceProps) => {
  const {
    mutateAsync: deleteBusinessGroup,
    isPending: isDeleteBusinessGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-BusinessGroup-request'],
    mutationFn: () => BusinessGroupProfileService.deleteBusinessGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteBusinessGroup = useCallback(async () => {
    try {
      deleteBusinessGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deleteBusinessGroup]);

  return {
    onDeleteBusinessGroup,
    isDeleteBusinessGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusBusinessGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusBusinessGroupServiceProps) => {
  const {
    mutateAsync: changeStatusBusinessGroup,
    isPending: isChangeStatusBusinessGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-businessGroup-request'],
    mutationFn: (req: ChangeStatusRequest) => BusinessGroupProfileService.changeStatusBusinessGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusBusinessGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusBusinessGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusBusinessGroup]
  );

  return {
    onChangeStatusBusinessGroup,
    isChangeStatusBusinessGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetBusinessGroupStationService = (initialParams: PageSize, businessGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-businessgroups-stations-data'],
      queryFn: async ({ pageParam }) => {
        return BusinessGroupProfileService.getBusinessGroupStation({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          businessGroupId: businessGroupId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignStationService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignStation,
    isPending: isAssignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-businessgroups-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => BusinessGroupProfileService.assignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignStation = useCallback(
    async (assignStationData: AssignUnassignStationRequest) => {
      try {
        assignStation(assignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignStation]
  );

  return {
    onAssignStation,
    isAssignStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignStationService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignStation,
    isPending: isUnassignStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-businessgroups-stations-request'],
    mutationFn: (req: AssignUnassignStationRequest) => BusinessGroupProfileService.unassignStation(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignStation = useCallback(
    async (unassignStationData: AssignUnassignStationRequest) => {
      try {
        unassignStation(unassignStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignStation]
  );

  return {
    onUnassignStation,
    isUnassignStationLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllStationService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllStations,
    isPending: isAssignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-businessgroups-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => BusinessGroupProfileService.assignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllStations = useCallback(
    async (assignAllStationData: AssignUnassignAllRequest) => {
      try {
        assignAllStations(assignAllStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllStations]
  );

  return {
    onAssignAllStations,
    isAssignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllStationService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllStations,
    isPending: isUnassignAllStationLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-businessgroups-stations-request'],
    mutationFn: (req: AssignUnassignAllRequest) => BusinessGroupProfileService.unassignAllStations(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllStations = useCallback(
    async (unassignAllStationData: AssignUnassignAllRequest) => {
      try {
        unassignAllStations(unassignAllStationData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllStations]
  );

  return {
    onUnassignAllStations,
    isUnassignAllStationLoading,
    data,
    error,
    isError,
  };
};

export const useGetBusinessGroupBusinesesService = (initialParams: PageSize, businessGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-businessgroups-businesses-data'],
      queryFn: async ({ pageParam }) => {
        return BusinessGroupProfileService.getBusinessGroupBusiness({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          businessGroupId: businessGroupId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignBusinesesService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignBusiness,
    isPending: isAssignBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-businessgroups-businesses-request'],
    mutationFn: (req: AssignUnassignBusinessRequest) => BusinessGroupProfileService.assignBusiness(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignBusiness = useCallback(
    async (assignBusinessData: AssignUnassignBusinessRequest) => {
      try {
        assignBusiness(assignBusinessData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignBusiness]
  );

  return {
    onAssignBusiness,
    isAssignBusinessLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignBusinesesService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignBusiness,
    isPending: isUnassignBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-businessgroups-businesses-request'],
    mutationFn: (req: AssignUnassignBusinessRequest) => BusinessGroupProfileService.unassignBusiness(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignBusiness = useCallback(
    async (unassignBusinessData: AssignUnassignBusinessRequest) => {
      try {
        unassignBusiness(unassignBusinessData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignBusiness]
  );

  return {
    onUnassignBusiness,
    isUnassignBusinessLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllBusinessService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllBusinesses,
    isPending: isAssignAllBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-businessgroups-businesses-request'],
    mutationFn: (req: AssignUnassignAllRequest) => BusinessGroupProfileService.assignAllBusinesses(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllBusinesses = useCallback(
    async (assignAllBusinessData: AssignUnassignAllRequest) => {
      try {
        assignAllBusinesses(assignAllBusinessData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllBusinesses]
  );

  return {
    onAssignAllBusinesses,
    isAssignAllBusinessLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllBusinesesService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllBusinesses,
    isPending: isUnassignAllBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-businessgroups-businesses-request'],
    mutationFn: (req: AssignUnassignAllRequest) => BusinessGroupProfileService.unassignAllBusinesses(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllBusinesses = useCallback(
    async (unassignAllBusinessData: AssignUnassignAllRequest) => {
      try {
        unassignAllBusinesses(unassignAllBusinessData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllBusinesses]
  );

  return {
    onUnassignAllBusinesses,
    isUnassignAllBusinessLoading,
    data,
    error,
    isError,
  };
};
