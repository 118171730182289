import SimpleModal from 'common/Form/Form';
import { Grid, Typography } from '@mui/material';
import Card from 'common/Cards/CardDetailWithPricing';
import { useTranslation } from 'react-i18next';
import CardSummary from 'common/Cards/CardSummary';

interface InvoiceModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  formData: any;
  openForm: boolean;
}

const InvoiceModalComponent: React.FC<InvoiceModalComponentProps> = ({
  closeModal,
  formData,
  openForm,
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={"invoiceDetails"}
        isButtonVisable={false}
        isVisableBtn={false}
        btnclosetext="Back"
        buttonText={""}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        // showPortList={handleSubmit}
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={() => {}}
        content={
          <Grid container spacing={2}>
            {formData?.invoicePackages?.length === 0 && 
              formData?.invoiceServices?.length === 0 && 
              formData?.invoiceItems?.length === 0 ? 
              <Typography className="font-Saira text-black text-center font-[700] my-3  w-[100%] text-[21px] leading-[33px]">{t("No Details Found!")}</Typography> :
                <>
                                          {/* Package Details */}
                  {formData?.invoicePackages?.length > 0 &&
                      <Grid item xs={12} className="mt-4 p-2 rounded-lg ml-2">
                          <Typography className="font-Saira text-start text-black line-clamp-1 font-[600] mb-2  text-[21px] leading-[33px]" >
                              {t("packageDetails")}
                          </Typography>
                          <Grid item xs={12}>
                              <Card data={formData?.invoicePackages} type="package" />
                          </Grid>
                      </Grid>
                  }
                                          {/* Service Details */}
                  {formData?.invoiceServices?.length > 0 && 
                      <Grid item xs={12} className="mt-4 p-2 rounded-lg ml-2">
                          <Typography className="font-Saira text-start text-black line-clamp-1 font-[600] mb-2  text-[21px] leading-[33px]" >
                              {t("serviceDetails")}
                          </Typography>
                          <Card data={formData?.invoiceServices} type="service" />
                      </Grid>
                  }
                                          {/* Item Details */}
                  {formData?.invoiceItems?.length > 0 &&
                      <Grid item xs={12} className="mt-4 p-2 rounded-lg ml-2">
                          <Typography className="font-Saira text-start text-black line-clamp-1 font-[600] mb-2  text-[21px] leading-[33px]" >
                              {t("itemDetails")}
                          </Typography>
                          <Grid item xs={12}>
                              <Card data={formData?.invoiceItems} type="item" />
                          </Grid>
                      </Grid>
                  }
                  <CardSummary data={formData} />
                </>
            }
          </Grid>
        }
      />
    </div>
  );
};

export default InvoiceModalComponent;