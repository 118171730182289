// StatusesService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { DeleteStatusesResponse, GetStatusesResponse, StatusesRequest, StatusesResponse } from './interface';

export class StatusesProfile extends BaseService {
  async createStatuses(req: StatusesRequest) {
    return this.post<StatusesResponse, StatusesRequest>(`${this.apiUrl}/api/businessstatus`, req);
  }

  async getStatuses(params: PaginationParams & { type: number }) {
    return this.get<GetStatusesResponse>(`${this.apiUrl}/api/businessstatus/paged`, params);
  }

  async updateStatuses(id: number, req: StatusesRequest) {
    return this.put<StatusesResponse, StatusesRequest>(`${this.apiUrl}/api/businessstatus/${id}`, req);
  }

  async deleteStatuses(id: number) {
    return this.delete<DeleteStatusesResponse>(`${this.apiUrl}/api/businessstatus/${id}`);
  }
}
