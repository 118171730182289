import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import PaymentModeComponent from 'components/PaymentMode/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useStatusesService,useDeleteStatusesService, useGetStatusesService, useUpdateStatusesService } from 'subModule/src/services/statuses/useStatuses';
import { StatusesRequest } from 'subModule/src/services/statuses/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  id: number;
  name: string;
  type: number;
  description: string;
  sortOrder: number;
  active: boolean;
}

const requiredFields = ['name'];

export default function PaymentMode() {
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [search, setSearch] = useState('');
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  // const [data] = useState(rows);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    name: '',
    type: 7,
    description: '',
    sortOrder: 0,
    active: true,
  });

  const { data: tableData, isLoading, error, refetch } = searchTerm ? useGetStatusesService({pageNumber: pageNo, size: pageSize, name: searchTerm}, 7) : useGetStatusesService({pageNumber: pageNo, size: pageSize}, 7);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;

  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm]);

  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000)
    return () => clearTimeout(getData)
  }, [searchTerm, loadingSearch]);

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize)

  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize)
    setPageNo(1)
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setIsBtnEnabled(true);
    setEnableEdit(false);
    setErrorMessages({});
    setSearch("");
    setSearchTerm("");
    setFormData({
      id: 0,
      name: '',
      type: 7,
      description: '',
      sortOrder: 0,
      active: true,
    });
  };

  const handleChange = (field: string, value: any) => {
    const val = (field === "sortOrder") ? ((value && value > 0 && value < 101) ? parseInt(value) : 0) : value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};
    if (!data.name?.trim()) {
      errors.name = 'Name is required.';
    }

    return errors;
  };

  const { onCreateStatuses } = useStatusesService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('PaymentMode') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateStatuses } = useUpdateStatusesService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        setEnableEdit(false);
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('PaymentMode') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const PaymentModeData: StatusesRequest = {
        ...formData,
        id: formData.id,
        name: formData.name,
        type: formData.type,
        description: formData.description,
        sortOrder: formData.sortOrder,
        active: formData.active,
      };

      if (enableEdit) {
        onUpdateStatuses(
          PaymentModeData
        );
      } else {
        onCreateStatuses(PaymentModeData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    setFormData(row);
    setPrevFormData(row);
    setOpenForm(true);
    setIsBtnEnabled(false);
    setSearch("");
    setSearchTerm("");
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteStatuses } = useDeleteStatusesService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('PaymentMode') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false);
    setLoading(true);
    onDeleteStatuses();
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setSearch('');
    setSearchTerm('');
    if (searchTerm) { handleSearch(''); }
  }
  const handleSearch = (search: string) => {
    setLoadingSearch(true);
    setSearchTerm(search);
    setPageNo(1);
    setPageSize(pageSize);
  };

  const handleSearchClick = () => {
    const trimmedSearch = search.trim();
    if (trimmedSearch !== '') {
      handleSearch(trimmedSearch);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value.trim());
    if (!event.target.value.trim()) handleSearch('');
  };
  const showRowView = (row: any) => {
    onEdit(row);
  }
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_BUSINESSSTATUS_UPDATE')
      ? (checkPrivileges('ROLE_BUSINESSSTATUS_ARCHIVE')
        ? actions
        : actions.filter(action => action.label !== 'delete'))
      : (checkPrivileges('ROLE_BUSINESSSTATUS_ARCHIVE')
        ? actions.filter(action => action.label !== 'edit')
        : []);
  }

  return (
    <Box>
      <PaymentModeComponent
        openModal={openModal}
        closeModal={openModal}
        primaryLanguage={primaryLanguage}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        showRowView={showRowView}
        clearSearch={clearSearch}
        columns={[
          { id: 'name', label: 'name', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, isBtnEnabled, loading, search, loadingSearch }}
        errorMessages={errorMessages}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}