// ItemsProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ItemsProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignPriceListRequest,
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignModelRequest,
  AssignUnassignResponse,
  AssignUnassignVendorRequest,
  AssignUnitRequest,
  ChangeStatusItemsResponse,
  DeleteItemsResponse,
  ItemsRequest,
  ItemsResponse,
  UnassignPriceListRequest,
  UnassignUnitRequest,
} from './interface';
import { useCallback } from 'react';

type UseItemsServiceProps = {
  onSuccess: (data: ItemsResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateItemsServiceProps = {
  onSuccess: (data: ItemsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteItemsServiceProps = {
  onSuccess: (data: DeleteItemsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusItemsServiceProps = {
  onSuccess: (data: ChangeStatusItemsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useItemsService = ({ onError, onSuccess }: UseItemsServiceProps) => {
  const {
    mutateAsync: createItems,
    isPending: isCreateItemsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-Items-request'],
    mutationFn: (req: ItemsRequest) => ItemsProfileService.createItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateItems = useCallback(
    async (ItemsData: ItemsRequest) => {
      try {
        createItems(ItemsData);
      } catch (err) {
        console.error(err);
      }
    },
    [createItems]
  );

  return {
    onCreateItems,
    isCreateItemsLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Items-data'],
      queryFn: async ({ pageParam }) => {
        return ItemsProfileService.getItems({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetItemsDropdownService = (initialParams: PageSize, itemGroupId?: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-items-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ItemsProfileService.getItemsDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          itemGroupId: itemGroupId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateItemsService = ({ id, onError, onSuccess }: UpdateItemsServiceProps) => {
  const {
    mutateAsync: updateItems,
    isPending: isUpdateItemsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-Items-request'],
    mutationFn: (req: ItemsRequest) => ItemsProfileService.updateItems(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateItems = useCallback(
    async (req: ItemsRequest) => {
      try {
        updateItems(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateItems]
  );

  return {
    onUpdateItems,
    isUpdateItemsLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteItemsService = ({ id, onError, onSuccess }: DeleteItemsServiceProps) => {
  const {
    mutateAsync: deleteItems,
    isPending: isDeleteItemsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-Items-request'],
    mutationFn: () => ItemsProfileService.deleteItems(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteItems = useCallback(async () => {
    try {
      deleteItems();
    } catch (err) {
      console.error(err);
    }
  }, [deleteItems]);

  return {
    onDeleteItems,
    isDeleteItemsLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusItemsService = ({ active, id, onError, onSuccess }: ChangeStatusItemsServiceProps) => {
  const {
    mutateAsync: changeStatusItems,
    isPending: isChangeStatusItemsLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-items-request'],
    mutationFn: (req: ChangeStatusRequest) => ItemsProfileService.changeStatusItems(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusItems = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusItems(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusItems]
  );

  return {
    onChangeStatusItems,
    isChangeStatusItemsLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemUnitService = (initialParams: PageSize, itemId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-items-units-data'],
      queryFn: async ({ pageParam }) => {
        return ItemsProfileService.getItemUnit({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          unitTitle: initialParams.unitTitle,
          itemId: itemId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignUnitService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignUnit,
    isPending: isAssignUnitLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-units-request'],
    mutationFn: (req: AssignUnitRequest) => ItemsProfileService.assignUnit(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignUnit = useCallback(
    async (assignUnitData: AssignUnitRequest) => {
      try {
        assignUnit(assignUnitData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignUnit]
  );

  return {
    onAssignUnit,
    isAssignUnitLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignUnitService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignUnit,
    isPending: isUnassignUnitLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-units-request'],
    mutationFn: (req: UnassignUnitRequest) => ItemsProfileService.unassignUnit(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignUnit = useCallback(
    async (unassignUnitData: UnassignUnitRequest) => {
      try {
        unassignUnit(unassignUnitData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignUnit]
  );

  return {
    onUnassignUnit,
    isUnassignUnitLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemModelService = (initialParams: PageSize, itemId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-items-models-data'],
      queryFn: async ({ pageParam }) => {
        return ItemsProfileService.getItemModel({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          itemId: itemId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignModelService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignModel,
    isPending: isAssignModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-models-request'],
    mutationFn: (req: AssignUnassignModelRequest) => ItemsProfileService.assignModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignModel = useCallback(
    async (assignModelData: AssignUnassignModelRequest) => {
      try {
        assignModel(assignModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignModel]
  );

  return {
    onAssignModel,
    isAssignModelLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignModelService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignModel,
    isPending: isUnassignModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-models-request'],
    mutationFn: (req: AssignUnassignModelRequest) => ItemsProfileService.unassignModel(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignModel = useCallback(
    async (unassignModelData: AssignUnassignModelRequest) => {
      try {
        unassignModel(unassignModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignModel]
  );

  return {
    onUnassignModel,
    isUnassignModelLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllModelService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllModels,
    isPending: isAssignAllModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-models-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ItemsProfileService.assignAllModels(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllModels = useCallback(
    async (assignAllModelData: AssignUnassignAllRequest) => {
      try {
        assignAllModels(assignAllModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllModels]
  );

  return {
    onAssignAllModels,
    isAssignAllModelLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllModelService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllModels,
    isPending: isUnassignAllModelLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-models-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ItemsProfileService.unassignAllModels(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllModels = useCallback(
    async (unassignAllModelData: AssignUnassignAllRequest) => {
      try {
        unassignAllModels(unassignAllModelData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllModels]
  );

  return {
    onUnassignAllModels,
    isUnassignAllModelLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemVendorService = (initialParams: PageSize, itemId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-items-vendors-data'],
      queryFn: async ({ pageParam }) => {
        return ItemsProfileService.getItemVendor({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          itemId: itemId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignVendorService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignVendor,
    isPending: isAssignVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-vendors-request'],
    mutationFn: (req: AssignUnassignVendorRequest) => ItemsProfileService.assignVendor(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignVendor = useCallback(
    async (assignVendorData: AssignUnassignVendorRequest) => {
      try {
        assignVendor(assignVendorData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignVendor]
  );

  return {
    onAssignVendor,
    isAssignVendorLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignVendorService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignVendor,
    isPending: isUnassignVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-vendors-request'],
    mutationFn: (req: AssignUnassignVendorRequest) => ItemsProfileService.unassignVendor(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignVendor = useCallback(
    async (unassignVendorData: AssignUnassignVendorRequest) => {
      try {
        unassignVendor(unassignVendorData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignVendor]
  );

  return {
    onUnassignVendor,
    isUnassignVendorLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllVendorService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllVendors,
    isPending: isAssignAllVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-vendors-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ItemsProfileService.assignAllVendors(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllVendors = useCallback(
    async (assignAllVendorData: AssignUnassignAllRequest) => {
      try {
        assignAllVendors(assignAllVendorData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllVendors]
  );

  return {
    onAssignAllVendors,
    isAssignAllVendorLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllVendorService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllVendors,
    isPending: isUnassignAllVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-vendors-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ItemsProfileService.unassignAllVendors(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllVendors = useCallback(
    async (unassignAllVendorData: AssignUnassignAllRequest) => {
      try {
        unassignAllVendors(unassignAllVendorData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllVendors]
  );

  return {
    onUnassignAllVendors,
    isUnassignAllVendorLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemPriceListService = (initialParams: PageSize, itemId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-items-pricelists-data'],
      queryFn: async ({ pageParam }) => {
        return ItemsProfileService.getItemPriceList({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          itemId: itemId,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignItemPriceListService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignPriceList,
    isPending: isAssignPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-item-pricelists-request'],
    mutationFn: (req: AssignPriceListRequest) => ItemsProfileService.assignPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignPriceList = useCallback(
    async (assignPriceListData: AssignPriceListRequest) => {
      try {
        assignPriceList(assignPriceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignPriceList]
  );

  return {
    onAssignPriceList,
    isAssignPriceListLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignItemPriceListService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignPriceList,
    isPending: isUnassignPriceListLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-items-pricelist-request'],
    mutationFn: (req: UnassignPriceListRequest) => ItemsProfileService.unassignPriceList(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignPriceList = useCallback(
    async (unassignPriceListData: UnassignPriceListRequest) => {
      try {
        unassignPriceList(unassignPriceListData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignPriceList]
  );

  return {
    onUnassignPriceList,
    isUnassignPriceListLoading,
    data,
    error,
    isError,
  };
};
