import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import UserComponent from 'components/Users';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useUserService ,useChangeStatusUserService, useDeleteUserService, useGetUserService , useUpdateUserService, useUpdatePasswordService} from 'subModule/src/services/user/useUser';
import { useGetRoleDropdownService } from 'subModule/src/services/role/useRole';
import { UpdatePasswordRequest, UserRequest } from 'subModule/src/services/user/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useGetItemGroupDropdownService } from 'subModule/src/services/itemgroup/useItemGroup';
import { useGetPriceListDropdownService } from 'subModule/src/services/pricelist/usePriceList';
import { useGetTaxProcedureDropdownService } from 'subModule/src/services/taxProcedure/useTaxProcedure';
import { SelectChangeEvent } from '@mui/material';

interface FormData {
  name: string,
  secondaryName: string,
  customRole?: boolean;
  roleId: number,
  roleIdError?: string,
  type: number,
  typeError?: string,
  username: string,
  email: string,
  password: string,
  confirmNewPassword?: string,
  phone: string,
  address: string,
  id: number,
  active: boolean,
  business: {
    id: number,
    title: string,
    secondaryTitle: string,
    primaryLanguage: string,
    secondaryLanguage: string,
    whiteLabel: boolean,
    whiteLabelError?: string,
    scrapItemGroupId: number,
    scrapItemGroupIdError?: string,
    active: boolean,
    priceListId: number,
    priceListIdError?: string,
    taxProcedureId: number,
    currency: string,
  }
}

interface SearchFormData {
  [key: string]: string | number;
}
interface UpdatePasswordFormData {
  newPassword: string,
  confirmNewPassword: string,
}

  const requiredFields = ['title', 'roleId', 'type', 'username', 'password', 'confirmNewPassword'];

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const phoneRegex = /^[\d+]{5,17}$/;

  const updatePasswordRequiredFields = ['newPassword', 'confirmNewPassword'];

  export default function User () {
    const [showSecUsersField, setShowSecUsersField] = useState<boolean>(false);
    const [showSecBusinessField, setShowSecBusinessField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [rolesPageParams, setRolesPageParams] = useState<any>({ number: 1, size: 50 });
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [rowView, setRowView] = useState(false);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [scrapGroupItemsPageParams, setScrapGroupItemsPageParams] = useState<any>({ number: 1, size: 50 });
    const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
    const [dropdown, setDropdown] = useState<string>('scrapItemGroupId');
    const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
    const [initialRender, setInitialRender] = useState<boolean>(true);
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
      name: '',
      secondaryName: '',
      id: 0,
      customRole: false,
      roleId: 0,
      type: 0,
      username: '',
      email: '',
      password: '',
      confirmNewPassword: '',
      phone: '',
      address: '',
      active: true,
      business: {
        id: 0,
        title: '',
        secondaryTitle: '',
        primaryLanguage: '',
        secondaryLanguage: '',
        whiteLabel: false,
        scrapItemGroupId: 0,
        active: true,
        priceListId: 0,
        currency: '',
        taxProcedureId: 0,
      }
    });
    const [updatePasswordFormData, setUpdatePasswordFormData] = useState<UpdatePasswordFormData>({
      newPassword: '',
      confirmNewPassword: ''
    });
    const [filter, setFilter] = useState<(string)[]>(['name']);
    const [search, setSearch] = useState<any>({});
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({
      name: '', 
      userName: '', 
      type: 0
    });

    const { data: tableData, isLoading, error, refetch } = useGetUserService({ pageNumber: pageNo, size: pageSize, ...search })
    
    const { data: rolesData, isLoading: rolesIsLoading, error: rolesError, refetch: rolesRefetch } = useGetRoleDropdownService({ pageNumber: rolesPageParams.number, size: rolesPageParams.size, title: dropdownSearchTerm });

    const { data: scrapItemGroupsData, isLoading: scrapItemGroupsIsLoading, error: scrapItemGroupsError, refetch: scrapItemGroupsRefetch } = useGetItemGroupDropdownService({ pageNumber: scrapGroupItemsPageParams.number, size: scrapGroupItemsPageParams.size, title: dropdownSearchTerm });

    const { data: priceListData,  isLoading: priceListIsLoading, error: priceListError,  refetch: priceListRefetch } = useGetPriceListDropdownService({ pageNumber: 1, size: 50 });

    const { data: taxProceduresData, isLoading: taxProceduresIsLoading, error: taxProceduresError, refetch: taxProceduresRefetch } = useGetTaxProcedureDropdownService({ pageNumber: 1, size: 50 });
    
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    const loggedInUserType = loggedInUserData.type;
    const userTypesData = [
      { value: 100, label: 'Reseller' },
      { value: 110, label: 'Maintenance Head' },
      { value: 120, label: 'Service Provider' },
      { value: 130, label: 'Station Manager' },
      { value: 140, label: 'Service Manager' },
      { value: 150, label: 'Technician' },
    ];
    const userTypesOption = loggedInUserType === -1 ? 
      userTypesData.filter(option => option.value === 100 || option.value === 110) 
    : loggedInUserType === 100 ? 
      userTypesData.filter(option => option.value === 110) 
    : userTypesData.filter(option => option.value > loggedInUserType);

    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch) {
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData,rowView,loadingSearch, openForm, rolesIsLoading, rolesError, scrapItemGroupsIsLoading, scrapItemGroupsError, scrapItemGroupsIsLoading, scrapItemGroupsError, priceListIsLoading, priceListError, taxProceduresIsLoading, taxProceduresError]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async () => {
        if (search && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 300)
      return () => clearTimeout(getData)
    }, [search]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if ((dropdownSearchTerm || dropdownSearchTerm === '') && openForm && !initialRender) {
          if(dropdown === 'scrapItemGroupId') {
            setLoadingDropdownSearch(true);
            setScrapGroupItemsPageParams({ number: 1, size: 50 });
            await scrapItemGroupsRefetch();
            setLoadingDropdownSearch(false);
          }
        } else if(dropdown === 'roleId') {
          setLoadingDropdownSearch(true);
          setRolesPageParams({ number: 1, size: 50 });
          await rolesRefetch();
          setLoadingDropdownSearch(false);
        }
      }, 500)
    return () => clearTimeout(getData)
    }, [dropdownSearchTerm, openForm]);

    useEffect(() => {
      if(scrapGroupItemsPageParams && openForm && dropdown === 'scrapItemGroupId') {
        scrapItemGroupsRefetch();
      } else if(rolesPageParams && openForm && dropdown === 'roleId') {
        rolesRefetch();
      }
    }, [scrapGroupItemsPageParams, rolesPageParams])

    const handleDropdownSearch = (field:string, search: string) => {
      if(field === 'scrapItemGroupId') {
        setInitialRender(false);
        setDropdownSearchTerm(search);
        setDropdown('scrapItemGroupId');
      } else if(field === 'roleId') {
        setInitialRender(false);
        setDropdownSearchTerm(search);
        setDropdown('roleId');
      }
    }
    const loadNext = (field: string, scroll: any) => {
      if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
        if(field === 'scrapItemGroupId') {
          if(!(scrapItemGroupsData?.pages[0]?.data as any).last) {
            setDropdown('scrapItemGroupId');
            setScrapGroupItemsPageParams({ number: scrapGroupItemsPageParams.number + 1, size: scrapGroupItemsPageParams.size });
          }
        } else if(field === 'roleId') {
          if(!(rolesData?.pages[0]?.data as any).last) {
            setDropdown('roleId');
            setRolesPageParams({ number: rolesPageParams.number + 1, size: rolesPageParams.size });
          }
        }
      }
    }
    const loadPrev = (field: string, scroll: any) => {
      if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
        if(field === 'scrapItemGroupId') {
          if(!(scrapItemGroupsData?.pages[0]?.data as any).first) {
            setDropdown('scrapItemGroupId');
            setScrapGroupItemsPageParams({ number: scrapGroupItemsPageParams.number - 1, size: scrapGroupItemsPageParams.size });
          }
        } else if(field === 'roleId') {
          if(!(rolesData?.pages[0]?.data as any).first) {
            setDropdown('roleId');
            setRolesPageParams({ number: rolesPageParams.number - 1, size: rolesPageParams.size });
          }
        }
      }
    }
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<{ user: Partial<FormData> ; business: Partial<FormData['business']> }>({
      user: {},
      business: {}
    });

    const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});

    const [updatePasswordErrorMessages, setUpdatePasswordErrorMessages] = useState<Partial<UpdatePasswordFormData>>({});

    const openModal = () => {
      if (rowView) {
        setRowView(false);
        setPageNo(1);
        setTab(0);
        setDropdown('scrapItemGroupId');
        setDropdownSearchTerm('');
        setInitialRender(true);
      } else {
        setOpenForm(!openForm);
        openModalSettings();
        setDropdown('scrapItemGroupId');
        setDropdownSearchTerm('');
        setInitialRender(true);
        if(!openForm && !enableEdit) {
          rolesRefetch();
          // scrapItemGroupsRefetch();
          // taxProceduresRefetch();
          // priceListRefetch();
          clearSearch();
        }
      }
    };
    const openModalSettings = () => {
      setEnableEdit(false);
      setIsBtnEnabled(true);
      setErrorMessages({user: {}, business: {}});
      setShowSecUsersField(false)
      setShowSecBusinessField(false)
      setFormData({
        name: '',
        secondaryName: '',
        id: 0,
        customRole: false,
        roleId: 0,
        type: 0,
        username: '',
        email: '',
        password: '',
        confirmNewPassword: '',
        phone: '',
        address: '',
        active: true,
        business: {
          id: 0,
          title: '',
          secondaryTitle: '',
          primaryLanguage: '',
          secondaryLanguage: '',
          whiteLabel: false,
          scrapItemGroupId: 0,
          active: true,
          priceListId: 0,
          taxProcedureId: 0,
          currency: '',
        }
      });
      if(!openForm && !enableEdit) {
        setRolesPageParams({ number: 1, size: 50 });
        setScrapGroupItemsPageParams({ number: 1, size: 50 });
      }
    }

    const handleSecField = (field: string) => {
      if (field === 'user') {
      setShowSecUsersField((prevValue) => !prevValue)
      } else if (field === 'business') {
      setShowSecBusinessField((prevValue) => !prevValue)
      }
    }

      const handleChange = (field: string, value: any, section: 'user' | 'business') => {
        const  val = (field === "roleId" || field === 'type' || field==="priceListId"|| field === "scrapItemGroupId" || field === "taxProcedureId") ? 
          (value ? parseInt(value.value) || 1 : 0) 
          : field === "customRole" ?
            value ? Boolean(value) : false
          : field === "phone" ?
            value.length === 0 ? '' : value
          : (field === "currency" || field === "primaryLanguage" || field === "secondaryLanguage" ) ?
            (value ? value.value || '' : '')
          : value;

          if(field === 'customRole' && !value) {
            setFormData({...formData, roleId: 0});
          }
          if (field === 'type' && val !== 110) {
            setFormData((prevData) => ({
              ...prevData,
              [field]: val,
            }));
          } else {
            section === 'business' ? 
              setFormData((prevData) => ({
                ...prevData,
                [section]: {
                  ...prevData[section],
                  [field]: val,
                }
              }))
            : setFormData((prevData) => ({
                ...prevData,
                [field]: val,
            }));
          }
        
          if (requiredFields.includes(field) && !val) {
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              [section]: {
                ...prevErrors[section],
                [field]: 'This field is required.',
              }
            }));
          } else {
            setErrorMessages((prevErrors) => ({
              ...prevErrors,
              [section]: {
                ...prevErrors[section],
                [field]: undefined,
              }
            }));
          }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errorsUser: Partial<FormData> = {};
        const errorsBusiness: Partial<FormData['business']> = {};

        // Validations for users
        if (data.customRole && !data.roleId) {
          errorsUser.roleIdError = 'Role is required.';
        }
        if (!data.type) {
          errorsUser.typeError = 'Type is required.';
        }
        if (!emailRegex?.test(data.username)) {
          errorsUser.username = 'Please enter valid email address!';
        }
        if (!data.password?.trim() || data.password?.length < 8) {
          errorsUser.password = 'Password should be minimum 8 characters length';
        }
        if (!data.confirmNewPassword?.trim() || data.password !== data.confirmNewPassword) {
          errorsUser.confirmNewPassword = 'Confirm New Password should match New Password';
        }
        if (!data.name?.trim()) {
          errorsUser.name = 'Name is required.';
        }
        if (data.phone?.length > 0 && !phoneRegex?.test(data.phone)) {
          errorsUser.phone = 'Phone length must be 5-17 digits only';
        }

        // Validations for business
        if(data.type === 110 && !data.business?.title?.trim()) {
          errorsBusiness.title = 'Title is required.';
        }
        // if(enableEdit && data.type === 110 && !data.business?.priceListId) {
        //   errorsBusiness.priceListIdError = 'Default Price list is required.';
        // }
        // if(enableEdit && data.type === 110 && !data.business?.scrapItemGroupId) {
        //   errorsBusiness.scrapItemGroupIdError = 'Scrap Group Item is required.';
        // }
    
        return { errorsUser, errorsBusiness };
      };

      const { onCreateUser } = useUserService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('email')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateUser } = useUpdateUserService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if (!rowView) {
              setEnableEdit(false);
              setOpenForm(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status}  text={t('email')} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages({user: { ...errors.errorsUser }, business: { ...errors.errorsBusiness }});

        if (Object.keys(errors.errorsBusiness).length === 0 && Object.keys(errors.errorsUser).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const userData: UserRequest = formData.type === 110 ? {
            ...formData, 
            name: formData.name,
            secondaryName: formData.secondaryName,
            roleId: formData.roleId,
            type: formData.type,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            business: {
              id: formData.business.id,
              title: formData.business.title,
              secondaryTitle: formData.business.secondaryTitle,
              primaryLanguage: formData.business.primaryLanguage,
              secondaryLanguage: formData.business.secondaryLanguage,
              whiteLabel: formData.business.whiteLabel,
              scrapItemGroupId: formData.business.scrapItemGroupId,
              priceListId: formData.business.priceListId,
              taxProcedureId: formData.business.taxProcedureId,
              currency: formData.business.currency,
            }
          } : {
            name: formData.name,
            secondaryName: formData.secondaryName,
            id: formData.id,
            roleId: formData.roleId,
            type: formData.type,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            active: formData.active,
          };
     
          if (enableEdit) {
            onUpdateUser(userData);
          } else {
            onCreateUser(userData);
          }
        } else if(Object.keys(errors.errorsBusiness).length === 0 && Object.keys(errors.errorsUser).length === 2 && enableEdit && 'password' in errors.errorsUser && 'confirmNewPassword' in errors.errorsUser) {
          setIsBtnEnabled(false);
          setLoading(true);
          const userData: UserRequest = formData.type === 110 ? {
            ...formData, 
            name: formData.name,
            secondaryName: formData.secondaryName,
            roleId: formData.roleId,
            type: formData.type,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            business: {
              id: formData.business.id,
              title: formData.business.title,
              secondaryTitle: formData.business.secondaryTitle,
              primaryLanguage: formData.business.primaryLanguage,
              secondaryLanguage: formData.business.secondaryLanguage,
              whiteLabel: formData.business.whiteLabel,
              scrapItemGroupId: formData.business.scrapItemGroupId,
              priceListId: formData.business.priceListId,
              taxProcedureId: formData.business.taxProcedureId,
              currency: formData.business.currency,
            }
          } : {
            name: formData.name,
            secondaryName: formData.secondaryName,
            id: formData.id,
            roleId: formData.roleId,
            type: formData.type,
            username: formData.username,
            email: formData.username,
            password: formData.password,
            phone: formData.phone,
            address: formData.address,
            active: formData.active,
          };
          onUpdateUser(userData);
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setRolesPageParams({ number: 1, size: 50 });
        setScrapGroupItemsPageParams({ number: 1, size: 50 });
        setDropdown('scrapItemGroupId');
        setDropdownSearchTerm('');
        setInitialRender(true);
        clearSearch();
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteUser } = useDeleteUserService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('user') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteUser();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusUser({});
      }
      const { onChangeStatusUser } = useChangeStatusUserService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('user') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setIsBtnEnabled(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({user: {}, business: {}});
        rolesRefetch();
        scrapItemGroupsRefetch();
        taxProceduresRefetch();
        priceListRefetch();
        setRowView(true);
        setSelectedRow(row);
        clearSearch();
      }
      const handleChangeTabs = (tabId: number) => {
        if (tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({user: {}, business: {}});
          rolesRefetch();
          scrapItemGroupsRefetch();
          taxProceduresRefetch();
          priceListRefetch();
          setDropdown('scrapItemGroupId');
          setDropdownSearchTerm('');
          setInitialRender(true);
        } else if (tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setUpdatePasswordErrorMessages({});
          setUpdatePasswordFormData({
            newPassword: '',
            confirmNewPassword: ''
          });
        }
      }
      const handleUpdatePassword = (field: string, value: any) => {
        const  val = value;

        setUpdatePasswordFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (updatePasswordRequiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setUpdatePasswordErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateFormDataUpdatePassword = (data: UpdatePasswordFormData) => {
        const errors: Partial<UpdatePasswordFormData> = {};
        
        if (!data.newPassword?.trim() || data.newPassword?.length < 8) {
          errors.newPassword = 'Password should be minimum 8 characters length';
        }
        
        if (!data.confirmNewPassword?.trim() || data.newPassword !== data.confirmNewPassword) {
          errors.confirmNewPassword = 'Confirm New Password should match New Password';
        }
    
        return errors;
      };
      const handleSubmitUpdatePassword = () => {
        const errors = validateFormDataUpdatePassword(updatePasswordFormData);
        setUpdatePasswordErrorMessages(errors);

        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const updatePasswordData: UpdatePasswordRequest = {
            email: formData.email,
            newPassword: updatePasswordFormData.newPassword,
          };
          onUpdatePassword(updatePasswordData);
        }
      };
      const { onUpdatePassword } = useUpdatePasswordService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            setUpdatePasswordFormData({
              newPassword: '',
              confirmNewPassword: ''
            });
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('password') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const clearSearch = () => {
        setFilter(['name']);
        setSearchFormData({
          name: '', 
          userName: '', 
          type: 0
        });
        setSearchErrorMessages({});
        if(Object.keys(search).length) {
          setLoadingSearch(true);
          setSearch({});
        }
      } 
      const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : (typeof prevFormData[key] === 'string' ? '' : 0)]
          ))
        )
        if(!value.length && Object.keys(search).length) {
          clearSearch();
        }
      };
      const handleChangeSearch = (field: string, value: any) => {
        const val = field === 'type' ? (value ? parseInt(value.value) || 0 : 0) : value;
    
        setSearchFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filter.includes(field) && !val) {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchFormData = (data: SearchFormData) => {
        const errors: Partial<SearchFormData> = {};
    
        filter.forEach(fieldName => {
          if (!data[fieldName]) { 
            errors[fieldName] = 'Required';
          }
        });
    
        return errors;
      };
      const handleSubmitSearch = () => {
        const errors = validateSearchFormData(searchFormData);
        setSearchErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingSearch(true);
          const filteredSearch: Record<string, string | number> = {};

          Object.entries(searchFormData).forEach(([key, value]) => {
            if (value) {
              filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
            }
          });
    
          setSearch(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_USER_UPDATE') 
          ? (checkPrivileges('ROLE_USER_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_USER_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <UserComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                handleUpdatePassword={handleUpdatePassword}
                updatePasswordFormData={updatePasswordFormData}
                updatePasswordErrorMessages={updatePasswordErrorMessages}
                handleSubmitUpdatePassword={handleSubmitUpdatePassword}
                handleDropdownSearch={handleDropdownSearch}
                loadNext={loadNext}
                loadPrev={loadPrev}
                searchFormData={searchFormData}
                handleChangeFilter={handleChangeFilter}
                handleChangeSearch={handleChangeSearch}
                handleSubmitSearch={handleSubmitSearch}
                searchErrorMessages={searchErrorMessages}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_USER_ACTIVE") ? [
                  { id: 'name', label: 'name', numeric: false },
                  { id: 'username', label: 'email', numeric: false },
                  { id: 'type', label: 'type', numeric: false },
                  { id: 'roleTitle', label: 'role', numeric: false },
                  { id: 'active', label: 'status', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'name', label: 'name', numeric: false },
                  { id: 'username', label: 'email', numeric: false },
                  { id: 'type', label: 'type', numeric: false },
                  { id: 'roleTitle', label: 'role', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
                ]}
                data={tableData?.pages[0] || {}}
                rolesData={rolesData?.pages[0] || {}}
                scrapItemGroupsData={scrapItemGroupsData?.pages[0] || {}}
                taxProceduresData={taxProceduresData?.pages[0] || {}}
                priceListData={priceListData?.pages[0] || {}}
                // tableDataMake={tableDataMake?.pages[0] || {}}
                {...{ openForm, openDeleteModal,showSecUsersField,showSecBusinessField, enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, loadingSearch, loadingDropdownSearch, userTypesOption, search, filter }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }