// PaymentProfileService.tsx
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { PaymentProfileService } from '../../core/services';
import { PageSize } from '../../type';
import { DeletePaymentResponse, GetPaymentsCreditResponse, PaymentsRequest, PaymentsResponse } from './interface';
import { useCallback } from 'react';

type UsePaymentServiceProps = {
  onSuccess: (data: PaymentsResponse) => void;
  onError: (err: unknown) => void;
};
type UpdatePaymentServiceProps = {
  onSuccess: (data: PaymentsResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeletePaymentServiceProps = {
  onSuccess: (data: DeletePaymentResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

export const usePaymentService = ({ onError, onSuccess }: UsePaymentServiceProps) => {
  const {
    mutateAsync: createPayment,
    isPending: isCreatePaymentLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-payments-request'],
    mutationFn: (req: PaymentsRequest) => PaymentProfileService.createPayment(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreatePayment = useCallback(
    async (paymentData: PaymentsRequest) => {
      try {
        createPayment(paymentData);
      } catch (err) {
        console.error(err);
      }
    },
    [createPayment]
  );

  return {
    onCreatePayment,
    isCreatePaymentLoading,
    data,
    error,
    isError,
  };
};

export const useGetPaymentService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-payments-data'],
      queryFn: async ({ pageParam }) => {
        return PaymentProfileService.getPayment({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          paymentNo: initialParams.paymentNo,
          referenceNo: initialParams.referenceNo,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdatePaymentService = ({ id, onError, onSuccess }: UpdatePaymentServiceProps) => {
  const {
    mutateAsync: updatePayment,
    isPending: isUpdatePaymentLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-payments-request'],
    mutationFn: (req: PaymentsRequest) => PaymentProfileService.updatePayment(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePayment = useCallback(
    async (req: PaymentsRequest) => {
      try {
        updatePayment(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePayment]
  );

  return {
    onUpdatePayment,
    isUpdatePaymentLoading,
    data,
    error,
    isError,
  };
};

export const useDeletePaymentService = ({ id, onError, onSuccess }: DeletePaymentServiceProps) => {
  const {
    mutateAsync: deletePayment,
    isPending: isDeletePaymentLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-payment-request'],
    mutationFn: () => PaymentProfileService.deletePayment(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeletePayment = useCallback(async () => {
    try {
      deletePayment();
    } catch (err) {
      console.error(err);
    }
  }, [deletePayment]);

  return {
    onDeletePayment,
    isDeletePaymentLoading,
    data,
    error,
    isError,
  };
};

export const useGetPaymentCreditService = (id: number) => {
  const { data, isLoading, error, refetch } = useQuery<GetPaymentsCreditResponse, unknown>({
    queryKey: ['get-payments-credit-data'],
    queryFn: async () => {
      return PaymentProfileService.getPaymentsCredit(id);
    },
    enabled: false,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};

export const useGetPaymentInvoiceService = (initialParams: PageSize, clientId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-payments-invoice-data'],
      queryFn: async ({ pageParam }) => {
        return PaymentProfileService.getPaymentsInvoice({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          clientId: clientId,
          invoiceNo: initialParams.invoiceNo,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
