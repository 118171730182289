import React, { ReactNode } from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ButtonNewProps {
  type?: string;
  disabled?: boolean;
  text?: string;
  hasIcon?: boolean;
  icon?: ReactNode;
  onClick?: () => void;
  children?: React.ReactNode; 
}

const ButtonNew: React.FC<ButtonNewProps> = ({
  type = "create",
  disabled = false,
  text = "Create",
  hasIcon = false,
  icon,
  onClick
}) => {
  const backgroundColor =
    type === "create"
      ? `bg-secondary-color`
      : type === "reject"
      ? `bg-rejected-color`
      : type === "login"
      ? `bg-primary-color`
      : type === "submit"
      ?`bg-secondary-color`
      : type === "back" 
      ? `bg-white`
      : disabled
      ? `bg-disabled-color`
      :`bg-[#fff]`;

  const textColor = type === "back" ? `text-black`: `text-white`;
  const border  =  type === "back" ? `border-1 border-solid border-black`  :`border-none` 
  const varient = type === "back" ? "outlined" : "contained"

  const { t } = useTranslation();

  return (
    <Button
    variant= {varient }// Use "contained" for custom background colors
    onClick={onClick}
    className={`${backgroundColor} text-ellipsis overflow-hidden font-Saira font-[600] text-[16px] ${textColor} ${border} h-[38px] px-8 py-1 transition-none`}
    disabled={disabled}
    >
      {hasIcon && icon}
      {t(text)}
    </Button>
  );
};

export default ButtonNew;
