// VehicleProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { VehicleProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { ChangeStatusVehicleResponse, DeleteVehicleResponse, VehicleRequest, VehicleResponse } from './interface';
import { useCallback } from 'react';

type UseVehicleServiceProps = {
  onSuccess: (data: VehicleResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateVehicleServiceProps = {
  onSuccess: (data: VehicleResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteVehicleServiceProps = {
  onSuccess: (data: DeleteVehicleResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusVehicleServiceProps = {
  onSuccess: (data: ChangeStatusVehicleResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useVehicleService = ({ onError, onSuccess }: UseVehicleServiceProps) => {
  const {
    mutateAsync: createVehicle,
    isPending: isCreateVehicleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-vehicles-request'],
    mutationFn: (req: VehicleRequest) => VehicleProfileService.createVehicle(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateVehicle = useCallback(
    async (vehicleData: VehicleRequest) => {
      try {
        createVehicle(vehicleData);
      } catch (err) {
        console.error(err);
      }
    },
    [createVehicle]
  );

  return {
    onCreateVehicle,
    isCreateVehicleLoading,
    data,
    error,
    isError,
  };
};

export const useGetVehicleService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-vehicles-data'],
      queryFn: async ({ pageParam }) => {
        return VehicleProfileService.getVehicle({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          platenumber: initialParams.platenumber,
          vin: initialParams.vin,
          makeTitle: initialParams.makeTitle,
          modelTitle: initialParams.modelTitle,
          customerName: initialParams.customerName,
          customerPhone: initialParams.customerPhone,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateVehicleService = ({ id, onError, onSuccess }: UpdateVehicleServiceProps) => {
  const {
    mutateAsync: updateVehicle,
    isPending: isUpdateVehicleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-vehicles-request'],
    mutationFn: (req: VehicleRequest) => VehicleProfileService.updateVehicle(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateVehicle = useCallback(
    async (req: VehicleRequest) => {
      try {
        updateVehicle(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateVehicle]
  );

  return {
    onUpdateVehicle,
    isUpdateVehicleLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteVehicleService = ({ id, onError, onSuccess }: DeleteVehicleServiceProps) => {
  const {
    mutateAsync: deleteVehicle,
    isPending: isDeleteVehicleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-vehicle-request'],
    mutationFn: () => VehicleProfileService.deleteVehicle(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteVehicle = useCallback(async () => {
    try {
      deleteVehicle();
    } catch (err) {
      console.error(err);
    }
  }, [deleteVehicle]);

  return {
    onDeleteVehicle,
    isDeleteVehicleLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusVehicleService = ({ active, id, onError, onSuccess }: ChangeStatusVehicleServiceProps) => {
  const {
    mutateAsync: changeStatusVehicle,
    isPending: isChangeStatusVehicleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-vehicle-request'],
    mutationFn: (req: ChangeStatusRequest) => VehicleProfileService.changeStatusVehicle(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusVehicle = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusVehicle(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusVehicle]
  );

  return {
    onChangeStatusVehicle,
    isChangeStatusVehicleLoading,
    data,
    error,
    isError,
  };
};

export const useGetModelsByMakeService = (initialParams: PageSize, id: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-models-by-make-data'],
      queryFn: async ({ pageParam }) => {
        return VehicleProfileService.getModelsByMake(
          {
            page: initialParams.pageNumber ?? pageParam,
            size: initialParams.size,
            title: initialParams.title,
          },
          id
        );
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetModelEnginesByModelService = (initialParams: PageSize, id: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-modelengines-by-model-data'],
      queryFn: async ({ pageParam }) => {
        return VehicleProfileService.getModelEnginesByModel(
          {
            page: initialParams.pageNumber ?? pageParam,
            size: initialParams.size,
            title: initialParams.title,
          },
          id
        );
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};
