// WarehouseProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { WarehouseProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusWarehouseResponse,
  DeleteWarehouseResponse,
  WarehouseRequest,
  WarehouseResponse,
} from './interface';
import { useCallback } from 'react';

type UseWarehouseServiceProps = {
  onSuccess: (data: WarehouseResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateWarehouseServiceProps = {
  onSuccess: (data: WarehouseResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteWarehouseServiceProps = {
  onSuccess: (data: DeleteWarehouseResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusWarehouseServiceProps = {
  onSuccess: (data: ChangeStatusWarehouseResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useWarehouseService = ({ onError, onSuccess }: UseWarehouseServiceProps) => {
  const {
    mutateAsync: createWarehouse,
    isPending: isCreateWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-warehouse-request'],
    mutationFn: (req: WarehouseRequest) => WarehouseProfileService.createWarehouse(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateWarehouse = useCallback(
    async (warehouseData: WarehouseRequest) => {
      try {
        createWarehouse(warehouseData);
      } catch (err) {
        console.error(err);
      }
    },
    [createWarehouse]
  );

  return {
    onCreateWarehouse,
    isCreateWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useGetWarehouseService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-warehouse-data'],
      queryFn: async ({ pageParam }) => {
        // Use the provided initialPageNo and initialPageSize
        return WarehouseProfileService.getWarehouse({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateWarehouseService = ({ id, onError, onSuccess }: UpdateWarehouseServiceProps) => {
  const {
    mutateAsync: updateWarehouse,
    isPending: isUpdateWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-warehouse-request'],
    mutationFn: (req: WarehouseRequest) => WarehouseProfileService.updateWarehouse(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateWarehouse = useCallback(
    async (req: WarehouseRequest) => {
      try {
        updateWarehouse(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateWarehouse]
  );

  return {
    onUpdateWarehouse,
    isUpdateWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteWarehouseService = ({ id, onError, onSuccess }: DeleteWarehouseServiceProps) => {
  const {
    mutateAsync: deleteWarehouse,
    isPending: isDeleteWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-warehouse-request'],
    mutationFn: () => WarehouseProfileService.deleteWarehouse(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteWarehouse = useCallback(async () => {
    try {
      deleteWarehouse();
    } catch (err) {
      console.error(err);
    }
  }, [deleteWarehouse]);

  return {
    onDeleteWarehouse,
    isDeleteWarehouseLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusWarehouseService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusWarehouseServiceProps) => {
  const {
    mutateAsync: changeStatusWarehouse,
    isPending: isChangeStatusWarehouseLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-warehouse-request'],
    mutationFn: (req: ChangeStatusRequest) => WarehouseProfileService.changeStatusWarehouse(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusWarehouse = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusWarehouse(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusWarehouse]
  );

  return {
    onChangeStatusWarehouse,
    isChangeStatusWarehouseLoading,
    data,
    error,
    isError,
  };
};
