import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';

interface ChangePasswordModalProps {
  handleChangePassword: (field: string, value: any) => void;
  handleSubmitChangePassword: () => void;
  changePasswordFormData: any;
  openForm: boolean;
  changePasswordErrorMessages: { [key: string]: string };
  isBtnEnabled: boolean;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  handleChangePassword,
  handleSubmitChangePassword,
  changePasswordFormData,
  openForm,
  changePasswordErrorMessages,
  isBtnEnabled,
}) => {


  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={"changePassword"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText="submit"
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmitChangePassword} // Assuming this is the Submit button
        // modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmitChangePassword}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
              <StyledField
                type="password"
                label="currentPassword"
                handleChange={(e) => handleChangePassword('password', e.target.value)}
                required={true}
                fullWidth
                error={changePasswordErrorMessages?.password}
                value={changePasswordFormData?.password || ''}
                search="showPassword"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
              <StyledField
                type="password"
                label="newPassword"
                handleChange={(e) => handleChangePassword('newPassword', e.target.value)}
                required={true}
                fullWidth
                error={changePasswordErrorMessages?.newPassword}
                value={changePasswordFormData?.newPassword || ''}
                search="showPassword"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} className='relative'>
              <StyledField
                type="password"
                label="confirmNewPassword"
                handleChange={(e) => handleChangePassword('confirmNewPassword', e.target.value)}
                required={true}
                fullWidth
                error={changePasswordErrorMessages?.confirmNewPassword}
                value={changePasswordFormData?.confirmNewPassword || ''}
                search="showPassword"
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default ChangePasswordModal;
