// PackageGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { PackageGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignPackageRequest,
  AssignUnassignResponse,
  ChangeStatusPackageGroupResponse,
  DeletePackageGroupResponse,
  PackageGroupRequest,
  PackageGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UsePackageGroupServiceProps = {
  onSuccess: (data: PackageGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdatePackageGroupServiceProps = {
  onSuccess: (data: PackageGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeletePackageGroupServiceProps = {
  onSuccess: (data: DeletePackageGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusPackageGroupServiceProps = {
  onSuccess: (data: ChangeStatusPackageGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const usePackageGroupService = ({ onError, onSuccess }: UsePackageGroupServiceProps) => {
  const {
    mutateAsync: createPackageGroup,
    isPending: isCreatePackageGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-package-groups-request'],
    mutationFn: (req: PackageGroupRequest) => PackageGroupProfileService.createPackageGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreatePackageGroup = useCallback(
    async (packageGroupData: PackageGroupRequest) => {
      try {
        createPackageGroup(packageGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createPackageGroup]
  );

  return {
    onCreatePackageGroup,
    isCreatePackageGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackageGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-package-groups-data'],
      queryFn: async ({ pageParam }) => {
        return PackageGroupProfileService.getPackageGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdatePackageGroupService = ({ id, onError, onSuccess }: UpdatePackageGroupServiceProps) => {
  const {
    mutateAsync: updatePackageGroup,
    isPending: isUpdatePackageGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-package-groups-request'],
    mutationFn: (req: PackageGroupRequest) => PackageGroupProfileService.updatePackageGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdatePackageGroup = useCallback(
    async (req: PackageGroupRequest) => {
      try {
        updatePackageGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updatePackageGroup]
  );

  return {
    onUpdatePackageGroup,
    isUpdatePackageGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeletePackageGroupService = ({ id, onError, onSuccess }: DeletePackageGroupServiceProps) => {
  const {
    mutateAsync: deletePackageGroup,
    isPending: isDeletePackageGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-package-groups-request'],
    mutationFn: () => PackageGroupProfileService.deletePackageGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeletePackageGroup = useCallback(async () => {
    try {
      deletePackageGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deletePackageGroup]);

  return {
    onDeletePackageGroup,
    isDeletePackageGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusPackageGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusPackageGroupServiceProps) => {
  const {
    mutateAsync: changeStatusPackageGroup,
    isPending: isChangeStatusPackageGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-package-groups-request'],
    mutationFn: (req: ChangeStatusRequest) => PackageGroupProfileService.changeStatusPackageGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusPackageGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusPackageGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusPackageGroup]
  );

  return {
    onChangeStatusPackageGroup,
    isChangeStatusPackageGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetPackageGroupPackageService = (initialParams: PageSize, packageGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-packagegroups-packages-data'],
      queryFn: async ({ pageParam }) => {
        return PackageGroupProfileService.getPackageGroupPackage({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
          packageCode: initialParams.packageCode,
          packageGroupId: packageGroupId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignPackageService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignPackage,
    isPending: isAssignPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-packages-request'],
    mutationFn: (req: AssignUnassignPackageRequest) => PackageGroupProfileService.assignPackage(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignPackage = useCallback(
    async (assignPackageData: AssignUnassignPackageRequest) => {
      try {
        assignPackage(assignPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignPackage]
  );

  return {
    onAssignPackage,
    isAssignPackageLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignPackageService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignPackage,
    isPending: isUnassignPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-packages-request'],
    mutationFn: (req: AssignUnassignPackageRequest) => PackageGroupProfileService.unassignPackage(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignPackage = useCallback(
    async (unassignPackageData: AssignUnassignPackageRequest) => {
      try {
        unassignPackage(unassignPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignPackage]
  );

  return {
    onUnassignPackage,
    isUnassignPackageLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllPackageService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllPackages,
    isPending: isAssignAllPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-packages-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackageGroupProfileService.assignAllPackages(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllPackages = useCallback(
    async (assignAllPackageData: AssignUnassignAllRequest) => {
      try {
        assignAllPackages(assignAllPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllPackages]
  );

  return {
    onAssignAllPackages,
    isAssignAllPackageLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllPackageService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllPackages,
    isPending: isUnassignAllPackageLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-packages-request'],
    mutationFn: (req: AssignUnassignAllRequest) => PackageGroupProfileService.unassignAllPackages(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllPackages = useCallback(
    async (unassignAllPackageData: AssignUnassignAllRequest) => {
      try {
        unassignAllPackages(unassignAllPackageData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllPackages]
  );

  return {
    onUnassignAllPackages,
    isUnassignAllPackageLoading,
    data,
    error,
    isError,
  };
};
