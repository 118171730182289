import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import RoleComponent from 'components/Roles/Index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useRoleService ,useChangeStatusRoleService, useDeleteRoleService, useGetRoleService , useUpdateRoleService, useGetRolePrivilegeService, useAssignPrivilegeService, useUnassignPrivilegeService} from 'subModule/src/services/role/useRole';
import { RoleRequest } from 'subModule/src/services/role/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  description: string,
  id: number,
  active: boolean,
}

  const requiredFields = ['title'];

  export default function Role () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [rowView, setRowView] = useState(false);
    const [roleId, setRoleId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [loadingAssign, setLoadingAssign] = useState<boolean>(false);
    const [scrollPosition, setScrollPosition] = useState<number>(0);
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        description: '',
        id: 0,
        active: true
    });

    const { data: tableData,  isLoading, error,  refetch } =searchTerm? useGetRoleService({pageNumber: pageNo, size: pageSize , title:searchTerm}): useGetRoleService({pageNumber: pageNo, size: pageSize});
    
    const { data: privilegeAssignData, isLoading: privilegeAssignIsLoading, error: privilegeAssignError, refetch: privilegeAssignRefetch } = useGetRolePrivilegeService({pageNumber: 1, size: 1000}, roleId);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
    const primaryLanguage = loggedInUserData?.primaryLanguage;
    const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm &&!loadingSearch) {
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if (!privilegeAssignIsLoading && !privilegeAssignError && roleId && roleId !== 0 && tab === 1) {
        const loadData = async() => {
          setLoadingAssign(true);
          await privilegeAssignRefetch();
          setLoadingAssign(false);
        };
        loadData();
      }
    }, [tableData, pageNo, pageSize, refetch, isLoading, error, roleId, tab, tableData, openForm]);

    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData)|| openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) { 
        await refetch();
        setLoadingSearch(false);
      }
    }, 1000)
    return () => clearTimeout(getData)
    }, [searchTerm, isLoading, error, refetch, loadingSearch]);
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo+1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

      const openModal = () => {
        if(rowView) {
          setRowView(false);
          setRoleId(0);
          setPageNo(1);
          setTab(0);
        } else {
          setOpenForm(!openForm);
          setIsBtnEnabled(true);
          openModalSettings();  
        }
      };
      const openModalSettings = () => {
        setEnableEdit(false);
        setErrorMessages({});
        setSearch('');
        setSearchTerm('');
        setShowSecField(false)
        setFormData({
            title: '',
            secondaryTitle: '',
            description: '',
            id: 0,
            active: true
        });
      }

      const handleSecField = () => {
        setShowSecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title.trim()) {
          errors.title = 'Title is required.';
        }
    
        return errors;
      };

      const { onCreateRole } = useRoleService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('role') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateRole } = useUpdateRoleService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('role') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      });

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const roleData: RoleRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            description: formData.description,
          };
          if (enableEdit && 'privileges' in roleData) {
            delete roleData.privileges;
          }
          if (enableEdit) {
            onUpdateRole(
                roleData
            );
          } else {
            onCreateRole(roleData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setPrevFormData(row);
        setIsBtnEnabled(false);
        setFormData(row);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteRole } = useDeleteRoleService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('role') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteRole();
      }
      const cancelDelete = () => {
        setDeleteModal(false);
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };

      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusRole({});
      }
      const { onChangeStatusRole } = useChangeStatusRoleService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('role') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });
      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setPrevFormData(row);
        setErrorMessages({});
        setRowView(true);
        setRoleId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangeTabs = (tabId: number) => {
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          setScrollPosition(0);
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any, scroll: number) => {
        setScrollPosition(scroll);
        setLoadingAssign(true);
        if(isChecked) {
          if(tab === 1) {
            onAssignPrivilege({ roleId: roleId, privilegeId: row.id });
          }
        } else {
          if(tab === 1) {
            onUnassignPrivilege({ roleId: roleId, privilegeId: row.id });
          }
        }
      }
      const { onAssignPrivilege } = useAssignPrivilegeService({
        onSuccess: async (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            await privilegeAssignRefetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('privilege') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setLoadingAssign(false);
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
          setLoadingAssign(false);
      }})
      const { onUnassignPrivilege } = useUnassignPrivilegeService({
        onSuccess: async (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            await privilegeAssignRefetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('privilege') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
          setLoadingAssign(false);
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          setLoadingAssign(false);
        },
      });
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_ROLE_UPDATE') 
          ? (checkPrivileges('ROLE_ROLE_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_ROLE_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <RoleComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                showRowView={showRowView}
                rowView={rowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_ROLE_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                privilegeAssignData={privilegeAssignData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText, search, isBtnEnabled, loading, loadingSearch, loadingAssign, scrollPosition }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }