type ErrorProps = {
  statusCode: string;
  name: string;
  message: string;
};

export class ProjectError extends Error {
  readonly statusCode: string;

  constructor({ statusCode, name, message }: ErrorProps) {
    super(message);
    this.name = name;
    this.statusCode = statusCode;
  }
}
