// JobCardService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { GetJobCardResponse } from './interface';

export class JobCardProfile extends BaseService {
  async getJobCard(params: PaginationParams) {
    return this.get<GetJobCardResponse>(`${this.apiUrl}/api/jobcards/paged`, params);
  }
}
