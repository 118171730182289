import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import VehicleComponent from 'components/Vehicle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useVehicleService, useDeleteVehicleService, useGetVehicleService, useUpdateVehicleService, useChangeStatusVehicleService, useGetModelEnginesByModelService } from 'subModule/src/services/vehicle/useVehicle';
import { VehicleRequest } from 'subModule/src/services/vehicle/interface';
import { useGetClientsDropdownService } from 'subModule/src/services/client/useClient';
import { useGetMakeDropdownService } from 'subModule/src/services/make/useMake';
import { useGetVehicleTypeDropdownService } from 'subModule/src/services/vehicletype/useVehicleType';
import { useGetModelsByMakeService } from 'subModule/src/services/vehicle/useVehicle';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { checkPrivileges } from 'Helpers/CheckPrivileges';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';

interface FormData {
  id: number,
  userId: number,
  userIdError?: string,
  plateNumber: string,
  secondaryPlateNumber: string,
  vin: string,
  transmission: number,
  makeId: number,
  makeIdError?: string,
  modelId: number,
  modelIdError?: string,
  modelEngineId: number | null,
  // modelEngineIdError?: string,
  fuelType: number,
  year: string,
  color: string,
  dailyMileage: number,
  avgDailyFuelConsumption: number,
  status: number,
  odometer: number,
  fleetCode: string,
  vehicleTypeId: number,
  vehicleTypeIdError?: string,
  seatingCapacity: number,
  ac: boolean,
  registrationStatus: number,
  insuranceStatus: number,
  lastServiceDate: string,
  insuranceExpiryDate: string,
  registrationExpiryDate: string,
  tyreInformation: string,
  vehicleStatus: number,
  notes: string,
  active: boolean
}

interface SearchFormData {
  [key: string]: string;
}

const requiredFields = ['plateNumber', 'userId', 'makeId', 'modelId', 'modelEngineId', 'vehicleTypeId'];

export default function Vehicle() {
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [make, setMake] = useState<number>(0);
  const [model, setModel] = useState<number>(0);
  // const [data] = useState(rows);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [clearModel, setClearModel] = useState<boolean>(false);
  const [clearModelEngine, setClearModelEngine] = useState<boolean>(false);
  const [makesPageParams, setMakesPageParams] = useState<any>({ number: 1, size: 50 });
  const [modelsPageParams, setModelsPageParams] = useState<any>({ number: 1, size: 50 });
  const [modelEnginesPageParams, setModelEnginesPageParams] = useState<any>({ number: 1, size: 50 });
  const [clientsPageParams, setClientsPageParams] = useState<any>({ number: 1, size: 50 });
  const [vehicleTypesPageParams, setVehicleTypesPageParams] = useState<any>({ number: 1, size: 50 });
  const [dropdownSearchTerm, setDropdownSearchTerm] = useState<any>('');
  const [dropdown, setDropdown] = useState<string>('makeId');
  const [loadingDropdownSearch, setLoadingDropdownSearch] = useState<boolean>(false);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [filter, setFilter] = useState<string[]>(['platenumber']);
  const [search, setSearch] = useState<any>({});
  const [formData, setFormData] = useState<FormData>({
    id: 0,
    userId: 0,
    plateNumber: '',
    secondaryPlateNumber: '',
    vin: '',
    transmission: 0,
    makeId: 0,
    modelId: 0,
    modelEngineId: null,
    fuelType: 0,
    year: '',
    color: '',
    dailyMileage: 0,
    avgDailyFuelConsumption: 0,
    status: 0,
    odometer: 0,
    fleetCode: '',
    vehicleTypeId: 0,
    seatingCapacity: 0,
    ac: false,
    registrationStatus: 0,
    insuranceStatus: 0,
    lastServiceDate: '',
    insuranceExpiryDate: '',
    registrationExpiryDate: '',
    tyreInformation: '',
    vehicleStatus: 0,
    notes: '',
    active: true
  });
  const [searchFormData, setSearchFormData] = useState<SearchFormData>({
    platenumber: '',
    vin: '',
    makeTitle: '',
    modelTitle: '',
    customerName: '',
    customerPhone: '',
  });

  const { data: tableData, isLoading, error, refetch } = useGetVehicleService({ pageNumber: pageNo, size: pageSize, ...search });

  const { data: clientsData, isLoading: clientsIsLoading, error: clientsError, refetch: clientsRefetch } = useGetClientsDropdownService({ pageNumber: clientsPageParams.number, size: clientsPageParams.size, name: dropdownSearchTerm });

  const { data: makesData, isLoading: makesIsLoading, error: makesError, refetch: makesRefetch } = useGetMakeDropdownService({ pageNumber: makesPageParams.number, size: makesPageParams.size, title: dropdownSearchTerm });

  const { data: modelsData, isLoading: modelsIsLoading, error: modelsError, refetch: modelsbymakeRefetch } = useGetModelsByMakeService({ pageNumber: modelsPageParams.number, size: modelsPageParams.size, title: dropdownSearchTerm }, make);

  const { data: modelEnginesData, isLoading: modelEnginesIsLoading, error: modelEnginesError, refetch: modelEnginesbymodelRefetch } = useGetModelEnginesByModelService({ pageNumber: modelEnginesPageParams.number, size: modelEnginesPageParams.size, title: dropdownSearchTerm }, model);

  const { data: vehicleTypesData, isLoading: vehicleTypesIsLoading, error: vehicleTypesError, refetch: vehicleTypesRefetch } = useGetVehicleTypeDropdownService({ pageNumber: vehicleTypesPageParams.number, size: vehicleTypesPageParams.size, title: dropdownSearchTerm });

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;

  useEffect(() => {
    if (!isLoading && !error && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
  }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm]);
  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);
  useEffect(() => {
    if(enableEdit && make && openForm) {
      setModelsPageParams({ number: 1, size: 50 });
      modelsbymakeRefetch();
      if(clearModel) { setFormData({ ...formData, modelId: 0 }); }
    } else if(!enableEdit && make && openForm) {
      setModelsPageParams({ number: 1, size: 50 });
      modelsbymakeRefetch();
      if(clearModel) { setFormData({ ...formData, modelId: 0 }); }
    }
  }, [make]);
  useEffect(() => {
    if(enableEdit && model && openForm) {
      setModelEnginesPageParams({ number: 1, size: 50 });
      modelEnginesbymodelRefetch();
      if(clearModelEngine) { setFormData({ ...formData, modelEngineId: null }); }
    } else if(!enableEdit && model && openForm) {
      setModelEnginesPageParams({ number: 1, size: 50 });
      modelEnginesbymodelRefetch();
      if(clearModelEngine) { setFormData({ ...formData, modelEngineId: null }); }
    }
  }, [model]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      if (search && !isLoading && !error && loadingSearch) {
        await refetch();
        setLoadingSearch(false);
      }
    }, 300)
    return () => clearTimeout(getData)
  }, [search]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if ((dropdownSearchTerm || dropdownSearchTerm === '') && openForm && !initialRender) {
        if(dropdown === 'makeId') {
          setLoadingDropdownSearch(true);
          setMakesPageParams({ number: 1, size: 50 });
          await makesRefetch();
          setLoadingDropdownSearch(false);
        } else if(dropdown === 'modelId') {
          setLoadingDropdownSearch(true);
          setModelsPageParams({ number: 1, size: 50 });
          await modelsbymakeRefetch();
          setLoadingDropdownSearch(false);
        } else if(dropdown === 'modelEngineId') {
          setLoadingDropdownSearch(true);
          setModelEnginesPageParams({ number: 1, size: 50 });
          await modelEnginesbymodelRefetch();
          setLoadingDropdownSearch(false);
        } else if(dropdown === 'userId') {
          setLoadingDropdownSearch(true);
          setClientsPageParams({ number: 1, size: 50 });
          await clientsRefetch();
          setLoadingDropdownSearch(false);
        } else if(dropdown === 'vehicleTypeId') {
          setLoadingDropdownSearch(true);
          setVehicleTypesPageParams({ number: 1, size: 50 });
          await vehicleTypesRefetch();
          setLoadingDropdownSearch(false);
        }
      }
    }, 500)
  return () => clearTimeout(getData)
  }, [dropdownSearchTerm, makesIsLoading, makesError, modelsIsLoading, modelsError, modelEnginesIsLoading, modelEnginesError, clientsIsLoading, clientsError, vehicleTypesIsLoading, vehicleTypesError, openForm]);

  useEffect(() => {
    if(makesPageParams && openForm && dropdown === 'makeId') {
      makesRefetch();
    } else if(modelsPageParams && openForm && dropdown === 'modelId') {
      modelsbymakeRefetch();
    } else if(modelEnginesPageParams && openForm && dropdown === 'modelEngineId') {
      modelEnginesbymodelRefetch();
    } else if(clientsPageParams && openForm && dropdown === 'userId') {
      clientsRefetch();
    } else if(vehicleTypesPageParams && openForm && dropdown === 'vehicleTypeId') {
      vehicleTypesRefetch();
    }
  }, [makesPageParams, modelsPageParams, modelEnginesPageParams, clientsPageParams, vehicleTypesPageParams])
  
  const handleDropdownSearch = (field:string, search: string) => {
    if(field === 'makeId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('makeId');
    } else if(field === 'modelId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('modelId');
    } else if(field === 'modelEngineId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('modelEngineId');
    } else if(field === 'userId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('userId');
    } else if(field === 'vehicleTypeId') {
      setInitialRender(false);
      setDropdownSearchTerm(search);
      setDropdown('vehicleTypeId');
    }
  }
  const loadNext = (field: string, scroll: any) => {
    if(scroll.deltaY > 90 || scroll.touches[0].clientY > 90) {
      if(field === 'makeId') {
        if(!(makesData?.pages[0]?.data as any).last) {
          setDropdown('makeId');
          setMakesPageParams({ number: makesPageParams.number + 1, size: makesPageParams.size });
        }
      } else if(field === 'modelId') {
        if(!(modelsData?.pages[0]?.data as any).last) {
          setDropdown('modelId');
          setModelsPageParams({ number: modelsPageParams.number + 1, size: modelsPageParams.size });
        }
      } else if(field === 'modelEngineId') {
        if(!(modelEnginesData?.pages[0]?.data as any).last) {
          setDropdown('modelEngineId');
          setModelEnginesPageParams({ number: modelEnginesPageParams.number + 1, size: modelEnginesPageParams.size });
        }
      } else if(field === 'userId') {
        if(!(clientsData?.pages[0]?.data as any).last) {
          setDropdown('userId');
          setClientsPageParams({ number: clientsPageParams.number + 1, size: clientsPageParams.size });
        }
      } else if(field === 'vehicleTypeId') {
        if(!(vehicleTypesData?.pages[0]?.data as any).last) {
          setDropdown('vehicleTypeId');
          setVehicleTypesPageParams({ number: vehicleTypesPageParams.number + 1, size: vehicleTypesPageParams.size });
        }
      }
    }
  }
  const loadPrev = (field: string, scroll: any) => {
    if(scroll.deltaY < 10 || scroll.touches[0].identifier === 0) {
      if(field === 'makeId') {
        if(!(makesData?.pages[0]?.data as any).first) {
          setDropdown('makeId');
          setMakesPageParams({ number: makesPageParams.number - 1, size: makesPageParams.size });
        }
      } else if(field === 'modelId') {
        if(!(modelsData?.pages[0]?.data as any).first) {
          setDropdown('modelId');
          setModelsPageParams({ number: modelsPageParams.number - 1, size: modelsPageParams.size });
        }
      } else if(field === 'modelEngineId') {
        if(!(modelEnginesData?.pages[0]?.data as any).first) {
          setDropdown('modelEngineId');
          setModelEnginesPageParams({ number: modelEnginesPageParams.number - 1, size: modelEnginesPageParams.size });
        }
      } else if(field === 'userId') {
        if(!(clientsData?.pages[0]?.data as any).first) {
          setDropdown('userId');
          setClientsPageParams({ number: clientsPageParams.number - 1, size: clientsPageParams.size });
        }
      } else if(field === 'vehicleTypeId') {
        if(!(vehicleTypesData?.pages[0]?.data as any).first) {
          setDropdown('vehicleTypeId');
          setVehicleTypesPageParams({ number: vehicleTypesPageParams.number - 1, size: vehicleTypesPageParams.size });
        }
      }
    }
  }

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize);

  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize);
    setPageNo(1);
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});

  const openModal = () => {
    setOpenForm(!openForm);
    setIsBtnEnabled(true);
    setEnableEdit(false);
    setErrorMessages({});
    setDropdown('makeId');
    setDropdownSearchTerm('');
    // setshowSecPlateNum(false);
    setInitialRender(true);
    setClearModel(false);
    setClearModelEngine(false);
    setFormData({
      id: 0,
      userId: 0,
      plateNumber: '',
      secondaryPlateNumber: '',
      vin: '',
      transmission: 0,
      makeId: 0,
      modelId: 0,
      modelEngineId: null,
      fuelType: 0,
      year: '',
      color: '',
      dailyMileage: 0,
      avgDailyFuelConsumption: 0,
      status: 0,
      odometer: 0,
      fleetCode: '',
      vehicleTypeId: 0,
      seatingCapacity: 0,
      ac: false,
      registrationStatus: 0,
      insuranceStatus: 0,
      lastServiceDate: '',
      insuranceExpiryDate: '',
      registrationExpiryDate: '',
      tyreInformation: '',
      vehicleStatus: 0,
      notes: '',
      active: true
    });
    if (!openForm && !enableEdit) {
      clientsRefetch();
      makesRefetch();
      vehicleTypesRefetch();
      setMakesPageParams({ number: 1, size: 50 });
      setModelsPageParams({ number: 1, size: 50 });
      setModelEnginesPageParams({ number: 1, size: 50 });
      setClientsPageParams({ number: 1, size: 50 });
      setVehicleTypesPageParams({ number: 1, size: 50 });
      clearSearch();
    }
  };

  const handleChange = (field: string, value: any) => {
    const val = (field === "userId" || field === "makeId" || field === "modelId" || field === "vehicleTypeId" || field === "status" || field === "vehicleStatus" || field === "registrationStatus" || field === "vehicleStatus" || field === "insuranceStatus" || field === "transmission" || field === "fuelType") ?
      (value ? parseInt(value.value) || 0 : 0)
      : (field === "modelEngineId") ?
        (value ? parseInt(value.value) || null : null)
      : (field === "seatingCapacity") ?
        (value ? parseInt(value) : 0)
        : (field === "dailyMileage") ?
        (value && value >=0 ? value : 0)
        : (field === "odometer" || field === "avgDailyFuelConsumption") ?
          (value && value >=0 ? value : 0)
        : (field === "ac") ? 
          value ? Boolean(value) : false 
          : (field === "lastServiceDate" || field === "insuranceExpiryDate" || field === "registrationExpiryDate") ?
          new Date(value).toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' })?.split('/').reverse().join('-')
          : value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
    if(field === "makeId") {
      setMake(value ? parseInt(value.value) : 0);
      setClearModel(true);
      setClearModelEngine(true);
    } if(field === "modelId") { 
      setModel(value ? parseInt(value.value) : 0);
      setClearModel(false);
      setClearModelEngine(true);
    } if(field === "modelEngineId") {
      setClearModelEngine(false);
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.plateNumber?.trim()) {
      errors.plateNumber = 'Plate Number is required.';
    }
    if (!data.userId) {
      errors.userIdError = 'Client is required.';
    }
    if (!data.makeId) {
      errors.makeIdError = 'Make is required.';
    }
    if (!data.modelId) {
      errors.modelIdError = 'Model is required.';
    }
    // if (!data.modelEngineId) {
    //   errors.modelEngineIdError = 'Model Engine is required.';
    // }
    if (!data.vehicleTypeId) {
      errors.vehicleTypeIdError = 'Vehicle Type is required.';
    }
    if (data.registrationExpiryDate && data.registrationExpiryDate === "Invalid Date") {
      errors.registrationExpiryDate = 'Enter Correct Registration Expiry Date.';
    }
    if (data.insuranceExpiryDate && data.insuranceExpiryDate === "Invalid Date") {
      errors.insuranceExpiryDate = 'Enter Correct Insurance Expiry Date.';
    }

    return errors;
  };

  const { onCreateVehicle } = useVehicleService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vehicle') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('plateNumber')} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateVehicle } = useUpdateVehicleService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        setEnableEdit(false);
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vehicle') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('plateNumber')} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);
    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const vehicleData: VehicleRequest = {
        ...formData,
        userId: formData.userId,
        plateNumber: formData.plateNumber,
        secondaryPlateNumber: formData.secondaryPlateNumber,
        vin: formData.vin,
        transmission: formData.transmission,
        makeId: formData.makeId,
        modelId: formData.modelId,
        modelEngineId: formData.modelEngineId,
        fuelType: formData.fuelType,
        year: formData.year,
        color: formData.color,
        dailyMileage: formData.dailyMileage,
        avgDailyFuelConsumption: formData.avgDailyFuelConsumption,
        status: formData.status,
        odometer: formData.odometer,
        fleetCode: formData.fleetCode,
        vehicleTypeId: formData.vehicleTypeId,
        seatingCapacity: formData.seatingCapacity,
        ac: formData.ac,
        registrationStatus: formData.registrationStatus,
        insuranceStatus: formData.insuranceStatus,
        lastServiceDate: formData.lastServiceDate,
        insuranceExpiryDate: formData.insuranceExpiryDate,
        registrationExpiryDate: formData.registrationExpiryDate,
        tyreInformation: formData.tyreInformation,
        vehicleStatus: formData.vehicleStatus,
        notes: formData.notes,
        active: formData.active
      };

      if (enableEdit) {
        onUpdateVehicle(
          vehicleData
        );
      } else {
        onCreateVehicle(vehicleData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    clearSearch();
    setFormData(row);
    setPrevFormData(row);
    setMake(row.makeId);
    setModel(row.modelId);
    setOpenForm(true);
    clientsRefetch();
    vehicleTypesRefetch();
    makesRefetch();
    setIsBtnEnabled(false);
    setMakesPageParams({ number: 1, size: 50 });
    setModelsPageParams({ number: 1, size: 50 });
    setModelEnginesPageParams({ number: 1, size: 50 });
    setClientsPageParams({ number: 1, size: 50 });
    setVehicleTypesPageParams({ number: 1, size: 50 });
    setDropdown('makeId');
    setDropdownSearchTerm('');
    setInitialRender(true);
    setClearModel(false);
    setClearModelEngine(false);
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteVehicle } = useDeleteVehicleService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('vehicle') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false);
    setLoading(true);
    onDeleteVehicle();
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setFilter(['platenumber']);
    setSearchFormData({
      platenumber: '',
      vin: '',
      makeTitle: '',
      modelTitle: '',
      customerName: '',
      customerPhone: '',
    });
    setSearchErrorMessages({});
    if(Object.keys(search).length) {
      setLoadingSearch(true);
      setSearch({});
    }
  } 
  const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
    const { target: { value }, } = event;
    setFilter(typeof value === 'string' ? value.split(',') : value,);
    setSearchFormData(prevFormData => 
      Object.fromEntries(Object.keys(prevFormData).map(key => 
        [key, value.includes(key) ? prevFormData[key] : '']
      ))
    );
    if(!value.length && Object.keys(search).length) {
      clearSearch();
    }
  };
  const handleChangeSearch = (field: string, value: any) => {
    const val = value;

    setSearchFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (filter.includes(field) && !val) {
      setSearchErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setSearchErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const validateSearchFormData = (data: SearchFormData) => {
    const errors: Partial<SearchFormData> = {};

    filter.forEach(fieldName => {
      if (!data[fieldName]) { 
        errors[fieldName] = 'Required';
      }
    });

    return errors;
  };
  const handleSubmitSearch = () => {
    const errors = validateSearchFormData(searchFormData);
    setSearchErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoadingSearch(true);
      const filteredSearch: Record<string, string> = {};

      Object.entries(searchFormData).forEach(([key, value]) => {
          if (value) {
            filteredSearch[key] = value?.trim();
          }
      });

      setSearch(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };

  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setStatusModal(false);
    setLoading(true);
    onChangeStatusVehicle({});
  }
  const { onChangeStatusVehicle } = useChangeStatusVehicleService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('vehicle') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
    },
    onError: (err) => {
      console.error("===", err)
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = (row: any) => {
    onEdit(row);
  }
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_VEHICLE_UPDATE') 
      ? (checkPrivileges('ROLE_VEHICLE_ARCHIVE') 
          ? actions 
          : actions.filter(action => action.label !== 'delete')) 
      : (checkPrivileges('ROLE_VEHICLE_ARCHIVE') 
          ? actions.filter(action => action.label !== 'edit') 
          : []);
  }

  return (
    <Box>
      <VehicleComponent
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        openModal={openModal}
        closeModal={openModal}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        loadNext={loadNext}
        loadPrev={loadPrev}
        handleDropdownSearch={handleDropdownSearch}
        columns={checkPrivileges("ROLE_VEHICLE_ACTIVE") ? [
          { id: 'plateNumber', label: 'plateNumber', numeric: false },
          { id: 'userName', label: 'clientName', numeric: false },
          { id: 'userPhone', label: 'clientPhone', numeric: false },
          { id: 'makeTitle', label: 'make', numeric: false },
          { id: 'modelTitle', label: 'model', numeric: false },
          { id: 'vin', label: 'VIN', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'plateNumber', label: 'plateNumber', numeric: false },
          { id: 'userName', label: 'clientName', numeric: false },
          { id: 'userPhone', label: 'clientPhone', numeric: false },
          { id: 'makeTitle', label: 'make', numeric: false },
          { id: 'modelTitle', label: 'model', numeric: false },
          { id: 'vin', label: 'VIN', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        clientsData={clientsData?.pages[0] || {}}
        makesData={makesData?.pages[0] || {}}
        modelsData={modelsData?.pages[0] || {}}
        modelEnginesData={modelEnginesData?.pages[0] || {}}
        vehicleTypesData={vehicleTypesData?.pages[0] || {}}
        refetch={refetch}
        {...{ openForm, openDeleteModal, enableEdit, pageNo, pageSize, statusModal, statusText, isBtnEnabled, loading, loadingSearch, loadingDropdownSearch, clearModel, clearModelEngine, filter, search }}
        errorMessages={errorMessages}
        searchFormData={searchFormData}
        handleChangeFilter={handleChangeFilter}
        handleChangeSearch={handleChangeSearch}
        handleSubmitSearch={handleSubmitSearch}
        searchErrorMessages={searchErrorMessages}
        clearSearch={clearSearch}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}