import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import UserGroupComponent from 'components/UserGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useUserGroupService, useChangeStatusUserGroupService, useDeleteUserGroupService, useGetUserGroupService, useUpdateUserGroupService, useGetUserGroupUserService, useAssignUserService, useUnassignUserService, useUnassignAllUserService, useAssignAllUserService } from 'subModule/src/services/usergroup/useUserGroup';
import { UserGroupRequest } from 'subModule/src/services/usergroup/interface';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  active: boolean
}
interface SearchAssignFormData {
  [key: string]: string | number;
}

const requiredFields = ['title'];

export default function UserGroup() {
  const [showSecField, setShowSecField] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [enableEdit, setEnableEdit] = useState(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [statusModal, setStatusModal] = useState(false);
  const [statusText, setStatusText] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<any>(null);
  const [search, setSearch] = useState('');
  const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
  const [prevFormData, setPrevFormData] = useState<object>({});
  const [rowView, setRowView] = useState(false);
  const [userGroupId, setUserGroupId] = useState<number>(0);
  const [tab, setTab] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<any>({});

  // const [data] = useState(rows);
  const [formData, setFormData] = useState<FormData>({
    title: '',
    secondaryTitle: '',
    id: 0,
    active: true
  });
  const [searchAssignFormData, setSearchAssignFormData] = useState<SearchAssignFormData>({
    name: '',
    userName: '',
    type : 0,
  });
  const [filterAssign, setFilterAssign] = useState<string[]>(['name']);
  const [loadingSearchAssign, setLoadingSearchAssign] = useState<boolean>(false);
  const [searchAssignErrorMessages, setSearchAssignErrorMessages] = useState<Partial<SearchAssignFormData>>({});
  const [searchMultiAssign, setSearchMultiAssign] = useState<any>({});

  const { data: tableData, isLoading, error, refetch } =searchTerm? useGetUserGroupService({ pageNumber: pageNo, size: pageSize, title: searchTerm }):useGetUserGroupService({ pageNumber: pageNo, size: pageSize})
  
  const { data: userAssignData, isLoading: userAssignIsLoading, error: userAssignError, refetch: userAssignRefetch } = useGetUserGroupUserService({pageNumber: pageNo, size: pageSize, ...searchMultiAssign}, userGroupId);

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const loggedInUserType = loggedInUserData.type;
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;
  const userTypesData = [
    { value: 100, label: 'Reseller' },
    { value: 110, label: 'Maintenance Head' },
    { value: 120, label: 'Service Provider' },
    { value: 130, label: 'Station Manager' },
    { value: 140, label: 'Service Manager' },
    { value: 150, label: 'Technician' },
  ];
  const userTypesOption = loggedInUserType === -1 ? 
      userTypesData.filter(option => option.value === 100 || option.value === 110) 
    : loggedInUserType === 100 ? 
      userTypesData.filter(option => option.value === 110) 
    : userTypesData.filter(option => option.value > loggedInUserType);

  let allUsersAssigned:unknown = userAssignData?.pages[0].data?.content.map(user => user.linked).every(linked => linked === true);

  useEffect(() => {
    if (!isLoading && !error && !rowView && !openForm && !loadingSearch) { // Only refetch if not already loading or in error state
      refetch();
    }
    if (tableData) {
      setLoading(false);
      if ((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
        enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
      }
    }
    if(tab === 0 && enableEdit) {
      setIsBtnEnabled(false);
    }
    if(!userAssignIsLoading && !userAssignError && userGroupId && userGroupId !== 0 && tab === 1) {
      const loadData = async() => {
        setLoadingSearchAssign(true);
        await userAssignRefetch();
        setLoadingSearchAssign(false);
      }
      loadData();
    }
  }, [pageNo, pageSize, refetch, isLoading, error, userGroupId, tab, tableData, openForm]);

  useEffect(() => {
    if (enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
      setIsBtnEnabled(true);
    } else if (enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
      setIsBtnEnabled(false);
    }
  }, [enableEdit, formData]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if (searchTerm !== null && !isLoading && !error && loadingSearch) {
       await refetch();
        setLoadingSearch(false);
      }
    }, 1000)
    return () => clearTimeout(getData)
  }, [searchTerm,loadingSearch]);

  useEffect(() => {
    const getData = setTimeout(async() => {
      if (searchMultiAssign && loadingSearchAssign && tab === 1) {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
      }
    }, 1000);
    return () => clearTimeout(getData)
  }, [searchMultiAssign]);

  const handleChangePage = (pageNo: number) => {
    setLoading(true);
    setPageNo(pageNo + 1);
    setPageSize(pageSize);
  }
  const handleChangeRows = (pageSize: number) => {
    setLoading(true);
    setPageSize(pageSize);
    setPageNo(1);
  }

  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

  const openModal = () => {
    if(rowView) {
      setRowView(false);
      setUserGroupId(0);
      setPageNo(1);
      setTab(0);
      setSearchMultiAssign({});
    } else {
      setIsBtnEnabled(true);
      setOpenForm(!openForm);
      setSearch('');
      setShowSecField(false)
      setSearchTerm('');
      openModalSettings();
    }
  };
  const openModalSettings = () => {
    setEnableEdit(false);
    setErrorMessages({});
    setSearch('');
    setSearchTerm('');
    setFormData({
      title: '',
      secondaryTitle: '',
      id: 0,
      active: false
    });
  }

  const handleSecField = () => {
    setShowSecField((prevValue) => !prevValue);
  }

  const handleChange = (field: string, value: any) => {
    const val = value;

    setFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (requiredFields.includes(field) && !val) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };


  const validateFormData = (data: FormData) => {
    const errors: Partial<FormData> = {};

    if (!data.title?.trim()) {
      errors.title = 'Title is required.';
    }

    return errors;
  };

  const { onCreateUserGroup, } = useUserGroupService({
    onSuccess: (msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if (response.success) {
        setOpenForm(false);
        refetch();
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('userGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
    }
  })

  const { onUpdateUserGroup } = useUpdateUserGroupService({
    onSuccess: (data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if (response.success) {
        if(!rowView) {
          setOpenForm(false);
          setEnableEdit(false);
          refetch();
        }
        if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('userGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err) => {
      console.error('Login error======', err, enableEdit);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    id: formData.id,
  }
  );

  const handleSubmit = () => {
    const errors = validateFormData(formData);
    setErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setIsBtnEnabled(false);
      setLoading(true);
      const userGroupData: UserGroupRequest = {
        ...formData,
        title: formData.title,
        secondaryTitle: formData.secondaryTitle,
      };

      if (enableEdit) {
        onUpdateUserGroup(
          userGroupData
        );
      } else {
        onCreateUserGroup(userGroupData);
      }
    }
  };

  const onEdit = (row: any) => {
    setEnableEdit(true);
    setIsBtnEnabled(false);
    setFormData(row);
    setPrevFormData(row);
    setOpenForm(true);
    setSearch('');
    setSearchTerm('');
  }

  const onDelete = (row: any) => {
    setFormData(row)
    setDeleteModal(true)
  }

  const { onDeleteUserGroup } = useDeleteUserGroupService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('userGroup') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch(); // This will use the updated pageNo and pageSize
      if (tableData?.pages[0].data.content.length === 1) {
        handleChangePage(pageNo - 1)
      }
    },
    onError: (err) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const handleDelete = () => {
    setDeleteModal(false)
    setLoading(true);
    onDeleteUserGroup()
  }
  const cancelDelete = () => {
    setDeleteModal(false)
  }

  const clearSearch = () => {
    setSearch('');
    setSearchTerm('');
    if(searchTerm) { handleSearch(''); }
  }
  const handleSearch = (search: string) => {
    if(!rowView) {
      setLoadingSearch(true);
      setSearchTerm(search);
      setPageNo(1);
      setPageSize(pageSize);
    } else {
      setLoadingSearchAssign(true);
      setSearchMultiAssign(search);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };

  const handleSearchClick = () => {
    if(!rowView) {
      const trimmedSearch = search.trim();
      if (trimmedSearch !== '') {
        handleSearch(trimmedSearch);
      }
    } else {
      const trimmedSearch = searchMultiAssign?.trim();
      if (trimmedSearch !== '') {
        handleSearch(trimmedSearch);
      }
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if(!rowView) {
      setSearch(event.target.value?.trim());
      if (!event.target.value?.trim()) handleSearch('');
    } else {
      setSearchMultiAssign(event.target.value.trim());
      if (!event.target.value.trim()) handleSearch('');
    }
  };

  const onChangeStatus = (row: any) => {
    row.active ? setStatusText('deactivate') : setStatusText('activate');
    setFormData(row);
    setStatusModal(true);
  }
  const cancelChangeStatus = () => {
    setStatusModal(false);
  }
  const submitChangeStatus = () => {
    setStatusModal(false);
    setLoading(true);
    onChangeStatusUserGroup({});
  }
  const { onChangeStatusUserGroup } = useChangeStatusUserGroupService({
    id: formData.id,
    onSuccess: (msg) => {
      if (msg.success) {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('userGroup') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
      }
      refetch();
    },
    onError: (err: any) => {
      console.log("err====,  ", err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
    active: formData.active ? "inactive" : "active"
  });
  const showRowView = (row: any) => {
    setEnableEdit(true);
    setOpenForm(false);
    setFormData(row);
    setPrevFormData(row);
    setErrorMessages({});
    setRowView(true);
    setUserGroupId(row.id);
    setSelectedRow(row);
    setSearch('');
    setSearchTerm('');
    clearSearchAssign();
  }
  const handleChangeTabs = (tabId: number) => {
    setSearchMultiAssign({});
    if(tabId === 0) {
      setTab(0);
      setEnableEdit(true);
      setOpenForm(false);
      setFormData(selectedRow);
      setErrorMessages({});
      setIsBtnEnabled(false);
    } else if(tabId === 1) {
      setTab(1);
      setPageNo(1);
      setEnableEdit(false);
      setErrorMessages({});
      clearSearchAssign();
    }
  }
  const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
    setLoadingSearchAssign(true);
    if(isChecked) {
      if(tab === 1) {
        onAssignUser({ userGroupId: userGroupId, userId: row.id });
      }
    } else {
      if(tab === 1) {
        onUnassignUser({ userGroupId: userGroupId, userId: row.id });
      }
    }
  }
  const assignUnassignAllEntities = (isChecked: boolean) => {
    setLoadingSearchAssign(true);
    if(isChecked) {
      if(tab === 1) {
        onAssignAllUsers({userGroupId: userGroupId});
      }
    } else {
      if(tab === 1) {
        onUnassignAllUsers({userGroupId: userGroupId});
      }
    }
  }
  const { onAssignUser } = useAssignUserService({
    onSuccess: async(msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if(response.success) {
        if(userAssignData?.pages[0].data.content.length === userAssignData?.pages[0].data.content.filter(user => user.linked).length) {
          allUsersAssigned = true;
        }
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('users') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err:any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
  }})
  const { onUnassignUser } = useUnassignUserService({
    onSuccess: async(data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if(response.success) {
        if(userAssignData?.pages[0].data.content.length !== userAssignData?.pages[0].data.content.filter(user => user.linked).length) {
          allUsersAssigned = false;
        }
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('user') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ",err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });
  const { onAssignAllUsers } = useAssignAllUserService({
    onSuccess: async(msg) => {
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if(response.success) {
        allUsersAssigned = true;
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All')  + ' ' +  t('users') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err:any) => {
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
      if (err instanceof Error) {
        console.error(err.stack);
      }
  }})
  const { onUnassignAllUsers } = useUnassignAllUserService({
    onSuccess: async(data) => {
      const response: any = {};
      Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
      if(response.success) {
        allUsersAssigned = false;
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All')  + ' ' +  t('users') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
      } else {
        await userAssignRefetch();
        setLoadingSearchAssign(false);
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      console.log("err====,  ",err);
      enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
    },
  });

  const clearSearchAssign = () => {
    setFilterAssign(['name']);
    setSearchAssignFormData({
      name: '',
      userName: '',
      type : '',
    });
    setSearchAssignErrorMessages({});
    if(Object.keys(searchMultiAssign).length) {
      setLoadingSearchAssign(true);
      setSearchMultiAssign({});
    }
  }
  const handleChangeAssignFilter = (event: SelectChangeEvent<typeof filterAssign>) => {
    const { target: { value }, } = event;
    setFilterAssign(typeof value === 'string' ? value.split(',') : value,);
    setSearchAssignFormData(prevFormData => 
      Object.fromEntries(Object.keys(prevFormData).map(key => 
        [key, value.includes(key) ? prevFormData[key] : '']
      ))
    );
    if(!value.length && Object.keys(searchMultiAssign).length) {
      clearSearchAssign();
    }
  };
  const handleChangeSearchAssign = (field: string, value: any) => {
    const val = field === 'type' ? (value ? parseInt(value.value) || 0 : 0) : value;

    setSearchAssignFormData((prevData) => ({
      ...prevData,
      [field]: val,
    }));

    if (filterAssign.includes(field) && !val) {
      setSearchAssignErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: 'This field is required.',
      }));
    } else {
      setSearchAssignErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };
  const validateSearchAssignFormData = (data: SearchAssignFormData) => {
    const errors: Partial<SearchAssignFormData> = {};

    filterAssign.forEach(fieldName => {
      if (!data[fieldName]) { 
        errors[fieldName] = 'Required';
      }
    });

    return errors;
  };
  const handleSubmitSearchAssign = () => {
    const errors = validateSearchAssignFormData(searchAssignFormData);
    setSearchAssignErrorMessages(errors);

    if (Object.keys(errors).length === 0) {
      setLoadingSearchAssign(true);
      const filteredSearch: Record<string, string | number> = {};

          Object.entries(searchAssignFormData).forEach(([key, value]) => {
            if (value) {
              filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
            }
          });
      setSearchMultiAssign(filteredSearch);
      setPageNo(1);
      setPageSize(pageSize);
    }
  };
  const adjustActions = (actions: any[]) => {
    return checkPrivileges('ROLE_USERGROUP_UPDATE') 
      ? (checkPrivileges('ROLE_USERGROUP_ARCHIVE') 
          ? actions 
          : actions.filter(action => action.label !== 'delete')) 
      : (checkPrivileges('ROLE_USERGROUP_ARCHIVE') 
          ? actions.filter(action => action.label !== 'edit') 
          : []);
  }

  return (
    <Box>
      <UserGroupComponent
        openModal={openModal}
        closeModal={openModal}
        handleSecField={handleSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        handleSearchClick={handleSearchClick}
        handleInputChange={handleInputChange}
        handleDelete={handleDelete}
        cancelDelete={cancelDelete}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        onChangeStatus={onChangeStatus}
        cancelChangeStatus={cancelChangeStatus}
        submitChangeStatus={submitChangeStatus}
        showRowView={showRowView}
        rowView={rowView}
        handleChangeTabs={handleChangeTabs}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allUsersAssigned={allUsersAssigned}
        columns={checkPrivileges("ROLE_USERGROUP_ACTIVE") ? [
          { id: 'title', label: 'title', numeric: false },
          { id: 'active', label: 'status', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ] : [
          { id: 'title', label: 'title', numeric: false },
          { id: 'actions', label: 'actions', numeric: false },
        ]}
        data={tableData?.pages[0] || {}}
        userAssignData={userAssignData?.pages[0] || {}}
        {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText, search, isBtnEnabled, loading, loadingSearch, loadingSearchAssign, tab, filterAssign, searchMultiAssign, userTypesOption }}
        errorMessages={errorMessages}
        searchAssignFormData={searchAssignFormData}
        handleChangeAssignFilter={handleChangeAssignFilter}
        handleChangeSearchAssign={handleChangeSearchAssign}
        handleSubmitSearchAssign={handleSubmitSearchAssign}
        searchAssignErrorMessages={searchAssignErrorMessages}
        clearSearchAssign={clearSearchAssign}
        clearSearch={clearSearch}
        actions1={adjustActions([
          { label: "edit", onClick: onEdit, icon: <EditIcon /> },
          { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
        ])}
      />
    </Box>
  )
}