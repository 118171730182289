// ManufacturerProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ManufacturerProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusManufacturerResponse,
  DeleteManufacturerResponse,
  ManufacturerRequest,
  ManufacturerResponse,
} from './interface';
import { useCallback } from 'react';

type UseManufacturerServiceProps = {
  onSuccess: (data: ManufacturerResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateManufacturerServiceProps = {
  onSuccess: (data: ManufacturerResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteManufacturerServiceProps = {
  onSuccess: (data: DeleteManufacturerResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusManufacturerServiceProps = {
  onSuccess: (data: ChangeStatusManufacturerResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useManufacturerService = ({ onError, onSuccess }: UseManufacturerServiceProps) => {
  const {
    mutateAsync: createManufacturer,
    isPending: isCreateManufacturerLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-manufacturer-request'],
    mutationFn: (req: ManufacturerRequest) => ManufacturerProfileService.createManufacturer(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateManufacturer = useCallback(
    async (ManufacturerData: ManufacturerRequest) => {
      try {
        createManufacturer(ManufacturerData);
      } catch (err) {
        console.error(err);
      }
    },
    [createManufacturer]
  );

  return {
    onCreateManufacturer,
    isCreateManufacturerLoading,
    data,
    error,
    isError,
  };
};

export const useGetManufacturerService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-manufacturer-data'],
      queryFn: async ({ pageParam }) => {
        return ManufacturerProfileService.getManufacturer({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetManufacturerDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-manufacturer-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ManufacturerProfileService.getManufacturerDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateManufacturerService = ({ id, onError, onSuccess }: UpdateManufacturerServiceProps) => {
  const {
    mutateAsync: updateManufacturer,
    isPending: isUpdateManufacturerLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-manufacturer-request'],
    mutationFn: (req: ManufacturerRequest) => ManufacturerProfileService.updateManufacturer(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateManufacturer = useCallback(
    async (req: ManufacturerRequest) => {
      try {
        updateManufacturer(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateManufacturer]
  );

  return {
    onUpdateManufacturer,
    isUpdateManufacturerLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteManufacturerService = ({ id, onError, onSuccess }: DeleteManufacturerServiceProps) => {
  const {
    mutateAsync: deleteManufacturer,
    isPending: isDeleteManufacturerLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-manufacturer-request'],
    mutationFn: () => ManufacturerProfileService.deleteManufacturer(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteManufacturer = useCallback(async () => {
    try {
      deleteManufacturer();
    } catch (err) {
      console.error(err);
    }
  }, [deleteManufacturer]);

  return {
    onDeleteManufacturer,
    isDeleteManufacturerLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusManufacturerService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusManufacturerServiceProps) => {
  const {
    mutateAsync: changeStatusManufacturer,
    isPending: isChangeStatusManufacturerLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-manufacturer-request'],
    mutationFn: (req: ChangeStatusRequest) => ManufacturerProfileService.changeStatusManufacturer(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusManufacturer = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusManufacturer(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusManufacturer]
  );

  return {
    onChangeStatusManufacturer,
    isChangeStatusManufacturerLoading,
    data,
    error,
    isError,
  };
};
