import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import InspectionFormItemComponent from 'components/InspectionFormItem/index';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { InspectionFormItemRequest } from 'subModule/src/services/inspectionformitem/interface';
import { useInspectionFormItemService,useChangeStatusInspectionFormItemService,useDeleteInspectionFormItemService,useGetInspectionFormItemService,useUpdateInspectionFormItemService } from 'subModule/src/services/inspectionformitem/useInspectionFormItem';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  description: string,
  inspectionFormId: number;
  id: number,
  active: boolean;
}
interface InspectionFormItemProps {
  inspectionFormId: number;
}

  const requiredFields = ['title'];

  export default function InspectionFormItem ({ inspectionFormId } : InspectionFormItemProps) {
    const [showIFISecField, setShowIFISecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    // const [data] = useState(rows);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        description: '',
        inspectionFormId: inspectionFormId,
        id: 0,
        active: true
    });

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetInspectionFormItemService({ pageNumber : pageNo, size: pageSize, title: search }, inspectionFormId) : useGetInspectionFormItemService({ pageNumber: pageNo, size: pageSize }, inspectionFormId);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

  const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;
    
    useEffect(() => {
      const fetchData = setTimeout(async() => {
        if (!isLoading && !error && !openForm && !loadingSearch) {
          await refetch();
        }
        if(tableData) {
          setLoading(false);
          if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
            enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
          }
        }
      }, 300);
      return () => clearTimeout(fetchData)
    }, [pageNo, pageSize, refetch, isLoading, error, tableData]);
    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000)
      return () => clearTimeout(getData)
    }, [searchTerm,loadingSearch]);
    
    const handleChangePage = (pageNo : number) => {
      setLoading(true);
      setPageNo(pageNo+1)
      setPageSize(pageSize)

    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize)
      setPageNo(1)
    }

    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
        setOpenForm(!openForm);
        setIsBtnEnabled(true);
        setEnableEdit(false);
        setShowIFISecField(false);
        setErrorMessages({});
        setSearch("");
        setSearchTerm("");
        setFormData({
            title: '',
            secondaryTitle: '',
            description: '',
            inspectionFormId: inspectionFormId,
            id: 0,
            active: true
        });
      };

      const handleSecField = () => {
        setShowIFISecField((prevValue) => !prevValue);
      }

      const handleChange = (field: string, value: any) => {
        const  val = value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = 'Title is required.';
        }
    
        return errors;
      };

      const { onCreateInspectionFormItem } = useInspectionFormItemService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vehicleInspection') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdateInspectionFormItem } = useUpdateInspectionFormItemService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            setEnableEdit(false);
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('vehicleInspection') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      }
      );

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const inspectionFormItemData: InspectionFormItemRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
            description: formData.description,
            inspectionFormId: inspectionFormId
          };
     
          if (enableEdit) {
            onUpdateInspectionFormItem(
                inspectionFormItemData
            );
          } else {
            onCreateInspectionFormItem(inspectionFormItemData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setIsBtnEnabled(false);
        setSearch("");
        setSearchTerm("");
        // setInspectionFormPageParams({ number: 1, size: 20 })
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeleteInspectionFormItem } = useDeleteInspectionFormItemService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('vehicleInspection') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false);
        setLoading(true);
        onDeleteInspectionFormItem();
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusInspectionFormItem({});
      }

      const { onChangeStatusInspectionFormItem } = useChangeStatusInspectionFormItemService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('inspectionFormItem') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });

      const showRowView = (row: any) => {
        onEdit(row);
      }
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_INSPECTIONFORMITEM_UPDATE') 
          ? (checkPrivileges('ROLE_INSPECTIONFORMITEM_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_INSPECTIONFORMITEM_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <InspectionFormItemComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                showRowView={showRowView}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_INSPECTIONFORMITEM_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit, pageNo, statusModal, statusText, pageSize,search, isBtnEnabled, loading, loadingSearch,showIFISecField }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
            />
        </Box>
    )
  }