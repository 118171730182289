import React from 'react';
// import ReactDOM from 'react-dom/client';
import { createRoot } from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { ThemeProvider } from '@material-tailwind/react';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);
const theme = createTheme({
  components: {
    MuiPopover: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement
      }
    },
    MuiDialog: {
        defaultProps: {
          container: rootElement,
        },
     },

  }
});

root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
    {/* <ThemeProvider> */}
    {/* <App /> */}
    {/* </ThemeProvider> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
