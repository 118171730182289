// WorkOrderService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { GetWorkOrderResponse } from './interface';

export class WorkOrderProfile extends BaseService {
  async getWorkOrder(params: PaginationParams) {
    return this.get<GetWorkOrderResponse>(`${this.apiUrl}/api/workorders/paged`, params);
  }
}
