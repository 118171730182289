// StationGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignResponse,
  AssignUnassignStationRequest,
  ChangeStatusStationGroupResponse,
  DeleteStationGroupResponse,
  GetStationGroupResponse,
  GetStationGroupStationResponse,
  StationGroupRequest,
  StationGroupResponse,
} from './interface';

export class StationGroupProfile extends BaseService {
  async createStationGroup(req: StationGroupRequest) {
    return this.post<StationGroupResponse, StationGroupRequest>(`${this.apiUrl}/api/stationgroups`, req);
  }

  async getStationGroup(params: PaginationParams) {
    return this.get<GetStationGroupResponse>(`${this.apiUrl}/api/stationgroups/paged`, params);
  }

  async updateStationGroup(id: number, req: StationGroupRequest) {
    return this.put<StationGroupResponse, StationGroupRequest>(`${this.apiUrl}/api/stationgroups/${id}`, req);
  }

  async deleteStationGroup(id: number) {
    return this.delete<DeleteStationGroupResponse>(`${this.apiUrl}/api/stationgroups/${id}`);
  }

  async changeStatusStationGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusStationGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/stationgroups/${active}/${id}`,
      req
    );
  }

  async getStationGroupStation(params: PaginationParams & { stationGroupId: number }) {
    return this.get<GetStationGroupStationResponse>(`${this.apiUrl}/api/stations/bystationgroupid`, params);
  }

  async assignStation(req: AssignUnassignStationRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/stationgroups/station/assign`,
      req
    );
  }

  async unassignStation(req: AssignUnassignStationRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignStationRequest>(
      `${this.apiUrl}/api/stationgroups/station/unassign`,
      req
    );
  }

  async assignAllStations(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stationgroups/station/assignall`,
      req
    );
  }

  async unassignAllStations(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/stationgroups/station/unassignall`,
      req
    );
  }
}
