import React from 'react';
import { SnackbarProvider } from 'notistack';
import { closeSnackbar } from 'notistack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

interface AlertProps {
  children: React.ReactNode;
}

function Alert({ children }: AlertProps) {
  return (
    <SnackbarProvider autoHideDuration={5000} anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }} 
      className='font-Saira font-medium text-left py-0 px-3 rounded-md'
      action={(snackbarId) => (
        <button onClick={() => closeSnackbar(snackbarId)}>
          <CloseRoundedIcon sx={{ fontSize: 20, marginRight: '5px' }}  />
        </button>
      )}
      maxSnack={5}>
      {children}
    </SnackbarProvider>
  );
}

export default Alert;