import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import StyledInputArea from 'common/TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import CommonCheckbox from 'common/CheckBox/Checkbox';
import currency from 'common/Others/Currency';
import language from 'common/Others/Language';


interface UserModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any, section: 'user' | 'business') => void;
  handleSubmit: () => void;
  formData: any;
  rolesData: any;
  handleSecField: (field: string) => void;
  showSecUsersField: boolean;
  showSecBusinessField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  scrapItemGroupsData: any;
  taxProceduresData: any;
  priceListData: any;
  userTypesOption: any;
}

const UserModalComponent: React.FC<UserModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  rolesData,
  showSecUsersField,
  showSecBusinessField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled,
  loadNext,
  loadPrev,
  // handleDropdownSearch,
  loadingDropdownSearch,
  // scrapItemGroupsData,
  // taxProceduresData,
  // priceListData,
  userTypesOption
}) => {
  const { t } = useTranslation();
  const roleOptions = rolesData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  // const scrapItemGroupOptions = scrapItemGroupsData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  // const taxProcedureOptions = taxProceduresData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  // const priceListOptions = priceListData.map((item: { id: number, title: string }) => ({ value: item.id, label: item.title }));
  const currencyOptions = currency.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))
  const languageOptions = language.map((item: { value: string, title: string }) => ({ value: item.value, label: item.title }))

  const handleSearch = () => {
    // Handle search logic here
  }


  return (
    <div>
      <SimpleModal
        visable={openForm}
        // title={enableEdit ? "updateUser" : "addUser"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit}
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container rowGap={1}>
            {!enableEdit &&
              <Grid container columnSpacing={2} rowGap={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <DynamicSelect
                    defaultValue={enableEdit ? userTypesOption.find((option: any) => option.value === formData?.type) : null}
                    value={userTypesOption.find((option: any) => option.value === formData?.type)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="type"
                    options={userTypesOption}
                    // onScroll={handleScroll}
                    onInputChange={handleSearch}
                    // isMulti
                    isRequired={true}
                    onChange={(e) => handleChange('type', e, 'user')}
                    error={(errorMessages.user as any).typeError}
                    label="User Type"
                  />
                </Grid>
              </Grid>
            }

            {/* <----------------------User Details-------------------> */}
            <Grid container spacing={2} className='my-[0px]'>
              <Grid item xs={12} sm={12} md={12} lg={12} className='px-4'>
                <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('userDetails')}</h1>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3} >
                <StyledField
                  label={`${!primaryLanguage ? t('primary') : ''} ${t('name')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                  handleChange={(e) => handleChange('name', e.target.value, 'user')}
                  required={true}
                  fullWidth
                  search='language'
                  handleSecField={() => handleSecField('user')}
                  error={(errorMessages.user as any).name}
                  value={formData?.name || ''}
                />
                {showSecUsersField &&
                  <Grid item xs={12} sm={12} md={12} lg={12} >
                    <div style={{ position: 'relative' }}>
                      <div
                        style={{
                          position: 'absolute',
                          top: -8,
                          left: 0,
                          zIndex: 1,
                          display: showSecUsersField ? 'flex' : 'none',
                          backgroundColor: "white",
                          height: '5rem',
                          width: '100%',
                          justifyContent: 'center',
                          borderRadius: '8px',
                          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                        }}
                      >
                        <div className='w-full mx-2 mt-1'>
                          <StyledField
                            label={`${!secondaryLanguage ? t('secondary') : ''} ${t('name')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                            handleChange={(e) => handleChange('secondaryName', e.target.value, 'user')}
                            required={false}
                            fullWidth
                            // error={(errorMessages.user as any).secondaryName}
                            value={formData?.secondaryName || ''}
                          />
                        </div>
                      </div>
                    </div>
                  </Grid>
                }
              </Grid>
              {/* <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  type="email"
                  label="email"
                  placeholder="Write your Email here"
                  handleChange={(e) => handleChange('email', e.target.value, 'user')}
                  required={false}
                  fullWidth
                  error={(errorMessages.user as any).email}
                  value={formData?.email || ''}
                />
              </Grid> */}
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  label="email"
                  placeholder="Write your name here"
                  handleChange={(e) => handleChange('username', e.target.value, 'user')}
                  required={true}
                  fullWidth
                  error={(errorMessages.user as any).username}
                  value={formData?.username || ''}
                />
              </Grid>
              {enableEdit ? null :
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <StyledField
                      type="password"
                      label="password"
                      placeholder="Write your password here"
                      handleChange={(e) => handleChange('password', e.target.value, 'user')}
                      required={true}
                      fullWidth
                      error={(errorMessages.user as any).password}
                      value={formData?.password || ''}
                      search="showPassword"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <StyledField
                      type="password"
                      label="confirmNewPassword"
                      handleChange={(e) => handleChange('confirmNewPassword', e.target.value, 'user')}
                      required={true}
                      fullWidth
                      error={(errorMessages.user as any)?.confirmNewPassword}
                      value={formData?.confirmNewPassword || ''}
                      search="showPassword"
                    />
                  </Grid>
                </>
              }
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledField
                  fullWidth={true}
                  label="phoneNumber"
                  placeholder="Write your Phone No. here"
                  handleChange={(e) => handleChange('phone', e.target.value, 'user')}
                  required={false}
                  error={(errorMessages.user as any).phone}
                  value={formData?.phone || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <StyledInputArea
                  fullWidth={true}
                  label="address"
                  // placeholder="Write your Address here"
                  rowsNo={1}
                  handleChange={(e) => handleChange('address', e.target.value, 'user')}
                  required={false}
                  // error={(errorMessages.user as any).address}
                  value={formData?.address || ''}
                />
              </Grid>
              {!enableEdit && <Grid item xs={12} sm={6} md={4} lg={3}>
                <CommonCheckbox
                  label='Use Custom Role'
                  onChange={(e) => handleChange('customRole', e.target.checked, 'user')}
                  color="success"
                  required={false}
                  // error={(errorMessages.user as any).customRole}
                  checked={formData?.customRole}
                />
              </Grid>}
              <Grid item xs={12} sm={6} md={4} lg={3}  >
                <DynamicSelect
                  defaultValue={enableEdit ? roleOptions.find((option: any) => option.value === formData?.roleId) : null}
                  value={enableEdit ? roleOptions.find((option: any) => option.value === formData?.roleId) : (formData.customRole ? roleOptions.find((option: any) => option.value === formData?.roleId) : 0)}
                  isDisabled={enableEdit ? false : (formData?.customRole ? false : true)}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  // fullWidth={false}
                  isSearchable={true}
                  name="roleId"
                  options={roleOptions}
                  // onScroll={handleScroll}
                  onInputChange={handleSearch}
                  // isMulti
                  isRequired={enableEdit ? true : (formData?.customRole ? true : false)}
                  onChange={(e) => handleChange('roleId', e, 'user')}
                  error={(errorMessages.user as any).roleIdError}
                  label="role"
                  onMenuScrollToBottom={(e) => { loadNext('roleId', e); }}
                  onMenuScrollToTop={(e) => { loadPrev('roleId', e); }}
                />
              </Grid>
            </Grid>

            {/* <----------------------Business Details-------------------> */}
            {formData?.type === 110 &&
              <Grid container spacing={2} className='my-[0px]'>
                <Grid item xs={12} sm={12} md={12} lg={12} className='px-4'>
                  <h1 className='flex font-Saira font-[700] text-[18px] text-primary-color'>{t('Business Details')}</h1>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <StyledField
                    label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                    handleChange={(e) => handleChange('title', e.target.value, 'business')}
                    required={true}
                    fullWidth
                    search='language'
                    handleSecField={() => handleSecField('business')}
                    error={(errorMessages.business as any).title}
                    value={formData?.business?.title || ''}
                  />
                  {showSecBusinessField &&
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                      <div style={{ position: 'relative' }}>
                        <div
                          style={{
                            position: 'absolute',
                            top: -8,
                            left: 0,
                            zIndex: 1,
                            display: showSecBusinessField ? 'flex' : 'none',
                            backgroundColor: "white",
                            height: '5rem',
                            width: '100%',
                            justifyContent: 'center',
                            borderRadius: '8px',
                            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                          }}
                        >
                          <div className='w-full mx-2 mt-1'>
                            <StyledField
                              label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                              handleChange={(e) => handleChange('secondaryTitle', e.target.value, 'business')}
                              required={false}
                              fullWidth
                              // error={(errorMessages.business as any).secondaryTitle}
                              value={formData?.business?.secondaryTitle || ''}
                            />
                          </div>
                        </div>
                      </div>
                    </Grid>
                  }
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3} >
                  <DynamicSelect
                    defaultValue={enableEdit ? languageOptions.find((option: any) => option.value === formData?.business?.primaryLanguage) : null}
                    value={languageOptions.find((option: any) => option.value === formData?.business?.primaryLanguage)}
                    isDisabled={false}
                    isLoading={loadingDropdownSearch}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="primaryLanguage"
                    options={languageOptions}
                    // onInputChange={(e) => handleDropdownSearch('primaryLanguage', e)}
                    // isMulti
                    isRequired={false}
                    onChange={(e) => handleChange('primaryLanguage', e, 'business')}
                    // error={errorMessages?.primaryLanguageError}
                    label="primaryLanguage"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <DynamicSelect
                    defaultValue={enableEdit ? languageOptions.find((option: any) => option.value === formData?.business?.secondaryLanguage) : null}
                    value={languageOptions.find((option: any) => option.value === formData?.business?.secondaryLanguage)}
                    isDisabled={false}
                    isLoading={loadingDropdownSearch}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="secondaryLanguage"
                    options={languageOptions}
                    // onInputChange={(e) => handleDropdownSearch('secondaryLanguage', e)}
                    // isMulti
                    isRequired={false}
                    onChange={(e) => handleChange('secondaryLanguage', e, 'business')}
                    // error={errorMessages?.secondaryLanguageError}
                    label="secondaryLanguage"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}  >
                  <DynamicSelect
                    defaultValue={enableEdit ? currencyOptions.find((option: any) => option.value === formData?.business?.currency) : null}
                    value={currencyOptions.find((option: any) => option.value === formData?.business?.currency)}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={true}
                    isRtl={false}
                    // fullWidth={false}
                    isSearchable={true}
                    name="currency"
                    options={currencyOptions}
                    // onScroll={handleScroll}
                    onInputChange={handleSearch}
                    // isMulti
                    isRequired={false}
                    onChange={(e) => handleChange('currency', e, 'business')}
                    // error={errorMessages?.currency}
                    label="currency"
                  />
                </Grid>
                {/* {enableEdit &&
                  <>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                      <DynamicSelect
                        defaultValue={enableEdit ? scrapItemGroupOptions.find((option: any) => option.value === formData?.business?.scrapItemGroupId) : null}
                        value={scrapItemGroupOptions.find((option: any) => option.value === formData?.business?.scrapItemGroupId)}
                        isDisabled={false}
                        isLoading={loadingDropdownSearch}
                        isClearable={true}
                        isRtl={false}
                        // fullWidth={false}
                        isSearchable={true}
                        name="scrapItemGroupId"
                        options={scrapItemGroupOptions}
                        onInputChange={(e) => handleDropdownSearch('scrapItemGroupId', e)}
                        // isMulti
                        isRequired={true}
                        onChange={(e) => handleChange('scrapItemGroupId', e, 'business')}
                        error={(errorMessages.business as any).scrapItemGroupIdError}
                        label="scrapItemGroup"
                        onMenuScrollToBottom={(e) => { loadNext('scrapItemGroupId', e); }}
                        onMenuScrollToTop={(e) => { loadPrev('scrapItemGroupId', e); }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}  >
                      <DynamicSelect
                        defaultValue={enableEdit ? priceListOptions.find((option: any) => option.value === formData?.business?.priceListId) : null}
                        value={priceListOptions.find((option: any) => option.value === formData?.business?.priceListId)}
                        isDisabled={false}
                        isLoading={loadingDropdownSearch}
                        isClearable={true}
                        isRtl={false}
                        // fullWidth={false}
                        isSearchable={true}
                        name="priceListId"
                        options={priceListOptions}
                        onInputChange={(e) => handleDropdownSearch('priceListId', e)}
                        // isMulti
                        isRequired={true}
                        onChange={(e) => handleChange('priceListId', e, 'business')}
                        error={(errorMessages.business as any).priceListIdError}
                        label="defaultPriceList"
                        onMenuScrollToBottom={(e) => { loadNext('priceListId', e); }}
                        onMenuScrollToTop={(e) => { loadPrev('priceListId', e); }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <DynamicSelect
                        defaultValue={enableEdit ? taxProcedureOptions.find((option: any) => option.value === formData?.business?.taxProcedureId) : null}
                        value={taxProcedureOptions.find((option: any) => option.value === formData?.business?.taxProcedureId)}
                        isDisabled={false}
                        isLoading={false}
                        isClearable={true}
                        isRtl={false}
                        // fullWidth={false}
                        isSearchable={true}
                        name="color"
                        options={taxProcedureOptions}
                        // onScroll={handleScroll}
                        // onInputChange={handleSearch}
                        // isMulti
                        isRequired={false}
                        onChange={(e) => handleChange('taxProcedureId', e, 'business')}
                        // error={(errorMessages.business as any).taxProcedureIdError}
                        label="taxProcedureId"
                      />
                    </Grid>
                  </>
                } */}
              </Grid>
            }
          </Grid>
        }
      />
    </div>
  );
};

export default UserModalComponent;