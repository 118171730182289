// StoreProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { StoreProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignResponse,
  ChangeStatusStoreResponse,
  DeleteStoreResponse,
  StoreRequest,
  StoreResponse,
} from './interface';
import { useCallback } from 'react';

type UseStoreServiceProps = {
  onSuccess: (data: StoreResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateStoreServiceProps = {
  onSuccess: (data: StoreResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteStoreServiceProps = {
  onSuccess: (data: DeleteStoreResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusStoreServiceProps = {
  onSuccess: (data: ChangeStatusStoreResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};
type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useStoreService = ({ onError, onSuccess }: UseStoreServiceProps) => {
  const {
    mutateAsync: createStore,
    isPending: isCreateStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-stores-request'],
    mutationFn: (req: StoreRequest) => StoreProfileService.createStore(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateStore = useCallback(
    async (storeData: StoreRequest) => {
      try {
        createStore(storeData);
      } catch (err) {
        console.error(err);
      }
    },
    [createStore]
  );

  return {
    onCreateStore,
    isCreateStoreLoading,
    data,
    error,
    isError,
  };
};

export const useGetStoreService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stores-data'],
      queryFn: async ({ pageParam }) => {
        return StoreProfileService.getStore({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateStoreService = ({ id, onError, onSuccess }: UpdateStoreServiceProps) => {
  const {
    mutateAsync: updateStore,
    isPending: isUpdateStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-stores-request'],
    mutationFn: (req: StoreRequest) => StoreProfileService.updateStore(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateStore = useCallback(
    async (req: StoreRequest) => {
      try {
        updateStore(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateStore]
  );

  return {
    onUpdateStore,
    isUpdateStoreLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteStoreService = ({ id, onError, onSuccess }: DeleteStoreServiceProps) => {
  const {
    mutateAsync: deleteStore,
    isPending: isDeleteStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-store-request'],
    mutationFn: () => StoreProfileService.deleteStore(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteStore = useCallback(async () => {
    try {
      deleteStore();
    } catch (err) {
      console.error(err);
    }
  }, [deleteStore]);

  return {
    onDeleteStore,
    isDeleteStoreLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusStoreService = ({ active, id, onError, onSuccess }: ChangeStatusStoreServiceProps) => {
  const {
    mutateAsync: changeStatusStore,
    isPending: isChangeStatusStoreLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-stores-request'],
    mutationFn: (req: ChangeStatusRequest) => StoreProfileService.changeStatusStore(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusStore = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusStore(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusStore]
  );

  return {
    onChangeStatusStore,
    isChangeStatusStoreLoading,
    data,
    error,
    isError,
  };
};

export const useGetStoreItemService = (initialParams: PageSize, storeId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-stores-items-data'],
      queryFn: async ({ pageParam }) => {
        return StoreProfileService.getStoreItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          storeId: storeId,
          title: initialParams.title,
          itemCode: initialParams.itemCode,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignItemService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignItem,
    isPending: isAssignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => StoreProfileService.assignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignItem = useCallback(
    async (assignItemData: AssignUnassignItemRequest) => {
      try {
        assignItem(assignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignItem]
  );

  return {
    onAssignItem,
    isAssignItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignItemService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignItem,
    isPending: isUnassignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => StoreProfileService.unassignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignItem = useCallback(
    async (unassignItemData: AssignUnassignItemRequest) => {
      try {
        unassignItem(unassignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignItem]
  );

  return {
    onUnassignItem,
    isUnassignItemLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllItemService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllItems,
    isPending: isAssignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StoreProfileService.assignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllItems = useCallback(
    async (assignAllItemData: AssignUnassignAllRequest) => {
      try {
        assignAllItems(assignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllItems]
  );

  return {
    onAssignAllItems,
    isAssignAllItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllItemService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllItems,
    isPending: isUnassignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => StoreProfileService.unassignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllItems = useCallback(
    async (unassignAllItemData: AssignUnassignAllRequest) => {
      try {
        unassignAllItems(unassignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllItems]
  );

  return {
    onUnassignAllItems,
    isUnassignAllItemLoading,
    data,
    error,
    isError,
  };
};
