import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources: Record<string, Record<string, any>> = {};

const languages = ['en', 'ar', 'ur', 'fr'];

languages.forEach((language) => {
  resources[language] = {
    translation: require(`./locales/${language}.json`),
  };
});

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false,
    },
  });

export const i18n1 = i18n;
