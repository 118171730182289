import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
// import CommonCheckbox from 'common/CheckBox/Checkbox';

interface MakePopupComponentProps {
  handleSecField: () => void;
  showModelMakeSecField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  openForm: boolean;
  errorMessages: { [key: string]: string };
}

const MakePopupComponent: React.FC<MakePopupComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  showModelMakeSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  formData,
  openForm,
  errorMessages
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={""}
        isButtonVisable={false}
        isVisableBtn={true}
        btnclosetext="Back"
        buttonText={"submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        padding={false}
        entityPopup={true}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('title')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                search='language'
                handleSecField={handleSecField}
                placeholder="Write your title here"
                handleChange={(e) => handleChange('title', e.target.value)}
                required={true}
                fullWidth={true}
                error={errorMessages?.title}
                value={formData?.title || ''}
              />
            </Grid>
            {showModelMakeSecField &&
              <Grid item xs={12} md={6}>
                <StyledField
                  label={`${!secondaryLanguage ? t('secondary') : ''} ${t('title')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                  placeholder="Write your secondaryTitle here"
                  handleChange={(e) => handleChange('secondaryTitle', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.secondaryTitle}
                  value={formData?.secondaryTitle || ''}
                />
              </Grid>
            }
            {/* <Grid item xs={12} sm={6} md={4} lg={3} className={`flex items-center justify-start`}>
              <CommonCheckbox
                label="isDefault"
                onChange={(e) => handleChange('isDefault', e.target.checked)}
                color="success"
                required={false}
                // error={errorMessages?.isDefault}
                checked={formData?.isDefault}
              />
            </Grid> */}
          </Grid>
        }
      />
    </div>
  );
};

export default MakePopupComponent;