// StatusesProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { StatusesProfileService } from '../../core/services';
import { PageSize } from '../../type';
import { DeleteStatusesResponse, StatusesRequest, StatusesResponse } from './interface';
import { useCallback } from 'react';

type UseStatusesServiceProps = {
  onSuccess: (data: StatusesResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateStatusesServiceProps = {
  onSuccess: (data: StatusesResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteStatusesServiceProps = {
  onSuccess: (data: DeleteStatusesResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

export const useStatusesService = ({ onError, onSuccess }: UseStatusesServiceProps) => {
  const {
    mutateAsync: createStatuses,
    isPending: isCreateStatusesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-statuses-request'],
    mutationFn: (req: StatusesRequest) => StatusesProfileService.createStatuses(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateStatuses = useCallback(
    async (StatusesData: StatusesRequest) => {
      try {
        createStatuses(StatusesData);
      } catch (err) {
        console.error(err);
      }
    },
    [createStatuses]
  );

  return {
    onCreateStatuses,
    isCreateStatusesLoading,
    data,
    error,
    isError,
  };
};

export const useGetStatusesService = (initialParams: PageSize, type: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-statuses-data'],
      queryFn: async ({ pageParam }) => {
        return StatusesProfileService.getStatuses({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          type: type,
          name: initialParams.name,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateStatusesService = ({ id, onError, onSuccess }: UpdateStatusesServiceProps) => {
  const {
    mutateAsync: updateStatuses,
    isPending: isUpdateStatusesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-statuses-request'],
    mutationFn: (req: StatusesRequest) => StatusesProfileService.updateStatuses(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateStatuses = useCallback(
    async (req: StatusesRequest) => {
      try {
        updateStatuses(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateStatuses]
  );

  return {
    onUpdateStatuses,
    isUpdateStatusesLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteStatusesService = ({ id, onError, onSuccess }: DeleteStatusesServiceProps) => {
  const {
    mutateAsync: deleteStatuses,
    isPending: isDeleteStatusesLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-statuses-request'],
    mutationFn: () => StatusesProfileService.deleteStatuses(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteStatuses = useCallback(async () => {
    try {
      deleteStatuses();
    } catch (err) {
      console.error(err);
    }
  }, [deleteStatuses]);

  return {
    onDeleteStatuses,
    isDeleteStatusesLoading,
    data,
    error,
    isError,
  };
};
