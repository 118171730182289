import React from "react";
import { Box, Grid } from "@mui/material";
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from "react-i18next";

interface CardProps {
    data: unknown[];
    type: string;
    hasChild?: boolean;
}

const Card: React.FC<CardProps> = ({data, type, hasChild}) => {
    const { t } = useTranslation();

    return (
        <Box>
            {data && data?.map((data: any) => (
                <Box className="mt-4">
                    <Grid container xs={12} 
                        sx={hasChild ? {background: '#F1F6F9 !important'} : {background: '#fff'}}
                        style={type === 'package' ? { background: 'transparent', border: 'none', padding: 0 } : {}}
                        className={`items-center justify-between rounded-md border-l-2 p-2 ${type === 'item' ? 'border-primary-color bg-white' : 'border-[#F65F3D] bg-white'}`}
                    >
                        <Grid xs={6} className="p-2 ">
                            <Typography className="font-Saira text-start line-clamp-1 font-[600] text-[18px] mb-2 leading-[18px]" >
                                {data?.title}
                            </Typography>
                            <Typography className="font-Saira text-start line-clamp-1 font-[400] italic text-[16px] leading-[16px]" >
                                {data[`${type}Code`]}
                            </Typography>
                        </Grid>
                        <Grid xs={1} className="p-2 mx-2 "></Grid>
                        <Grid xs={3} className="p-2 flex ml-2 items-end justify-end ">
                            <Typography className="font-Saira text-start font-[700] text-[16px] leading-[21px]" >
                                {data?.unitPrice}
                            </Typography>
                            <Typography className="font-[400] text-[12px] italic ml-1 mr-2">{t("each")}</Typography>
                            <Divider className="bg-primary-color w-[2px]" orientation="vertical" flexItem />
                            <Typography className="font-Saira text-start text-[#17ae36] font-[700] text-[16px] leading-[21px] ml-2" >
                                {data?.totalAmount}
                            </Typography>
                            {/* <Typography className="font-[400] text-black text-[12px] ml-1 italic">SAR</Typography> */}
                        </Grid>
                        {type === 'package' && data?.services?.length > 0 && 
                            <div className="w-[100%]"><Card data={data?.services} type="service" /></div>
                        }
                        {type === 'package' && data?.items?.length > 0 && 
                            <div className="w-[100%]"><Card data={data?.items} type="item" /></div>
                        }
                        {type === 'service' && data?.items?.length > 0 && 
                            <div className="w-[100%]"><Card data={data?.items} type="item" hasChild={true} /></div>
                        }
                    </Grid>
                </Box>
            ))}
        </Box>
    );
};

export default Card;