// RoleProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { RoleProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignPrivilegeRequest,
  AssignUnassignResponse,
  ChangeStatusRoleResponse,
  DeleteRoleResponse,
  RoleRequest,
  RoleResponse,
} from './interface';
import { useCallback } from 'react';

type UseRoleServiceProps = {
  onSuccess: (data: RoleResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateRoleServiceProps = {
  onSuccess: (data: RoleResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteRoleServiceProps = {
  onSuccess: (data: DeleteRoleResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusRoleServiceProps = {
  onSuccess: (data: ChangeStatusRoleResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};

export const useRoleService = ({ onError, onSuccess }: UseRoleServiceProps) => {
  const {
    mutateAsync: createRole,
    isPending: isCreateRoleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-roles-request'],
    mutationFn: (req: RoleRequest) => RoleProfileService.createRole(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateRole = useCallback(
    async (roleData: RoleRequest) => {
      try {
        createRole(roleData);
      } catch (err) {
        console.error(err);
      }
    },
    [createRole]
  );

  return {
    onCreateRole,
    isCreateRoleLoading,
    data,
    error,
    isError,
  };
};

export const useGetRoleService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-privileges-data'],
      queryFn: async ({ pageParam }) => {
        return RoleProfileService.getRole({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetRoleDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-roles-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return RoleProfileService.getRolesDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateRoleService = ({ id, onError, onSuccess }: UpdateRoleServiceProps) => {
  const {
    mutateAsync: updateRole,
    isPending: isUpdateRoleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-roles-request'],
    mutationFn: (req: RoleRequest) => RoleProfileService.updateRole(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateRole = useCallback(
    async (req: RoleRequest) => {
      try {
        updateRole(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateRole]
  );

  return {
    onUpdateRole,
    isUpdateRoleLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteRoleService = ({ id, onError, onSuccess }: DeleteRoleServiceProps) => {
  const {
    mutateAsync: deleteRole,
    isPending: isDeleteRoleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-role-request'],
    mutationFn: () => RoleProfileService.deleteRole(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteRole = useCallback(async () => {
    try {
      deleteRole();
    } catch (err) {
      console.error(err);
    }
  }, [deleteRole]);

  return {
    onDeleteRole,
    isDeleteRoleLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusRoleService = ({ active, id, onError, onSuccess }: ChangeStatusRoleServiceProps) => {
  const {
    mutateAsync: changeStatusRole,
    isPending: isChangeStatusRoleLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-role-request'],
    mutationFn: (req: ChangeStatusRequest) => RoleProfileService.changeStatusRole(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusRole = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusRole(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusRole]
  );

  return {
    onChangeStatusRole,
    isChangeStatusRoleLoading,
    data,
    error,
    isError,
  };
};

export const useGetRolePrivilegeService = (initialParams: PageSize, roleId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-roles-privileges-data'],
      queryFn: async ({ pageParam }) => {
        return RoleProfileService.getRolePrivilege({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          roleId: roleId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignPrivilegeService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignPrivilege,
    isPending: isAssignPrivilegeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-privileges-request'],
    mutationFn: (req: AssignUnassignPrivilegeRequest) => RoleProfileService.assignPrivilege(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignPrivilege = useCallback(
    async (assignPrivilegeData: AssignUnassignPrivilegeRequest) => {
      try {
        assignPrivilege(assignPrivilegeData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignPrivilege]
  );

  return {
    onAssignPrivilege,
    isAssignPrivilegeLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignPrivilegeService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignPrivilege,
    isPending: isUnassignPrivilegeLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-privileges-request'],
    mutationFn: (req: AssignUnassignPrivilegeRequest) => RoleProfileService.unassignPrivilege(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignPrivilege = useCallback(
    async (unassignPrivilegeData: AssignUnassignPrivilegeRequest) => {
      try {
        unassignPrivilege(unassignPrivilegeData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignPrivilege]
  );

  return {
    onUnassignPrivilege,
    isUnassignPrivilegeLoading,
    data,
    error,
    isError,
  };
};
