import axios, { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';
import { ACCESS_TOKEN, APP_BASE_URL, SESSION_EXPIRE_MESSAGE, STATUS_CODE_440 } from '../constant/Config';
import { ProjectError } from './ProjectError';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import type { AxiosRequestConfig } from 'axios';

export const axiosClient = axios.create({
  baseURL: APP_BASE_URL,
  timeout: 60000,
  validateStatus: (status) => status >= 200 && status < 300,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});
// eslint-disable-next-line no-underscore-dangle, @typescript-eslint/naming-convention
// declare const __DEV__: boolean;

axiosClient.interceptors.request.use(
  async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    let accessToken: string = '';

    // if (typeof __DEV__ !== 'undefined' && __DEV__) {
    //   const token = await AsyncStorage.getItem(ACCESS_TOKEN);

    //   if (token) {
    //     accessToken = token as string;
    //   }
    // } else {
    const webToken = localStorage.getItem(ACCESS_TOKEN);

    if (webToken) {
      accessToken = webToken as string;
    }
    // }

    if (accessToken) {
      config.headers = {
        'Authorization': `Bearer ${accessToken}`,
      } as unknown as AxiosHeaders;

      return config;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  async (response) => {
    if (response && response.config.method === 'post' && response.config.url?.includes('/api/auth/signin')) {
      // if (typeof __DEV__ !== 'undefined' && __DEV__) {
      //   await AsyncStorage.setItem(ACCESS_TOKEN, String(response.data?.data?.accessToken));
      // } else {
      localStorage.setItem(ACCESS_TOKEN, String(response.data?.data?.accessToken));
      // }
    }

    if (response.data?.statusCode === STATUS_CODE_440 || response?.data?.message === SESSION_EXPIRE_MESSAGE) {
      throw new ProjectError({
        statusCode: STATUS_CODE_440,
        message: 'Session is expired, Login again first to use this application',
        name: SESSION_EXPIRE_MESSAGE,
      });
    }

    return response;
  },
  (error) => {
    console.error('===', error);

    if (error?.response?.status === 401) {
      ['userInfo', 'selectedSubItem', 'ACCESS_TOKEN'].forEach((item) => localStorage.removeItem(item));
      window.location.href = '/login';
    }

    if (!error?.response && error?.code !== 'ERR_CANCELED') {
      setTimeout(() => {
        const notif = document.createElement('div');
        const closeButton = document.createElement('button');

        closeButton.textContent = 'x';
        Object.assign(closeButton.style, {
          cursor: 'pointer',
          position: 'absolute',
          top: '-10px',
          right: '-5px',
          color: '#D32F2F',
          background: '#fff',
          borderRadius: '50%',
          padding: '3px 5px',
          fontFamily: 'Saira, sans-serif',
        });

        notif.textContent = 'Something went wrong, check network connection';
        notif.appendChild(closeButton);

        Object.assign(notif.style, {
          position: 'fixed',
          top: '30px',
          right: '10px',
          backgroundColor: '#D32F2F',
          color: '#fff',
          padding: '10px 20px',
          borderRadius: '7px',
          zIndex: '10000',
          fontFamily: 'Saira, sans-serif',
          fontSize: '14px',
          lineHeight: '16px',
          fontWeight: '600',
        });
        document.body.appendChild(notif);
        (document.getElementsByClassName('MuiBackdrop-root') as HTMLCollectionOf<HTMLElement>)[0].style.display =
          'none';
        closeButton.addEventListener('click', () => notif.remove());
        setTimeout(() => notif.remove(), 5000);
      }, 5000);
    }

    return Promise.reject(error.response);
  }
);
