import { Box } from "@mui/material";
import { ReactComponent as WorkOrder } from "assets/Modal/workorder.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import CardWithPagination from "common/Cards/CardWithPagination";
import { useTranslation } from 'react-i18next';
import Loader from "layout/Loader";
import WorkOrderModalComponent from "./WorkOrderModal";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import WorkOrderSearchHeader from "./WorkOrderSearchHeader";
// import Loader from "layout/Loader";

interface WorkOrderComponentProps {
  data: any;
  loading: boolean;
  pageNo: number;
  pageSize: number;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  showRowView: (row: any) => void;
  formData: object;
  openModal: () => void;
  closeModal: () => void;
  openForm: boolean;
  actions1: { label: string; onClick: (value: any) => void; icon?: React.ReactNode }[];
  searchFormData: any;
  handleChangeFilter: (event: any) => void;
  handleChangeSearch: (field: string, value: any) => void;
  handleSubmitSearch: () => void;
  searchErrorMessages: any;
  filter: string[];
  clearSearch: () => void;
  search: any;
  loadingSearch: boolean;
  stationData: any;
}

const WorkOrderComp: React.FC<WorkOrderComponentProps> = ({
  data,
  loading,
  handleChangePage,
  handleChangeRows,
  pageNo,
  pageSize,
  showRowView,
  formData,
  openModal,
  closeModal,
  openForm,
  actions1,
  searchFormData,
  handleChangeFilter,
  handleChangeSearch,
  handleSubmitSearch,
  searchErrorMessages,
  filter,
  clearSearch,
  search,
  loadingSearch,
  stationData,
}) => {
  const { t } = useTranslation();

  return (
    <Box>
      <ComponentHeaderTwo
        showBtn={true}
        showSaveBtn={false}
        onClick={openModal}
        onSubmit={() => { }}
        heading={openForm ? t('workOrderDetails') : t('workOrder')}
        icon={<WorkOrder height={28} width={28} />}
        btnText={openForm ? "back" : "create"}
        btnType={openForm ? "back" : "create"}
        isBtnEnabled={false}
        showCreateBtn={false}
      // btnType="create"
      />

      {openForm ?
        <WorkOrderModalComponent
          openModal={openModal}
          closeModal={closeModal}
          formData={formData}
          openForm={openForm}
        />
        : <Box>
            {loading ? <Loader /> :
              <CardWithPagination 
                data={data.data?.content || []}
                cardsData={data?.data || {}}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                showRowView={showRowView}
                actions={actions1}
                rowClick={checkPrivileges("ROLE_WORKORDER_UPDATE")}
                type="workOrder"
                {...{ pageNo, pageSize, loadingSearch }}
                headerComponent={
                  <WorkOrderSearchHeader 
                    searchFormData={searchFormData}
                    handleChangeFilter={handleChangeFilter}
                    handleChangeSearch={handleChangeSearch}
                    handleSubmitSearch={handleSubmitSearch}
                    searchErrorMessages={searchErrorMessages}
                    clearSearch={clearSearch}
                    stationData={stationData.data?.content || []}
                    {...{filter, search}}
                  />
                }
              />
            }
          </Box>
      }

    </Box>

  )
}

export default WorkOrderComp;