// TaxProcedureProfileService.tsx
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';
import { TaxProcedureProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  ChangeStatusTaxProcedureResponse,
  DeleteTaxProcedureResponse,
  GetDefaultTaxProcedureResponse,
  TaxProcedureRequest,
  TaxProcedureResponse,
} from './interface';
import { useCallback } from 'react';

type UseTaxProcedureServiceProps = {
  onSuccess: (data: TaxProcedureResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateTaxProcedureServiceProps = {
  onSuccess: (data: TaxProcedureResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteTaxProcedureServiceProps = {
  onSuccess: (data: DeleteTaxProcedureResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusTaxProcedureServiceProps = {
  onSuccess: (data: ChangeStatusTaxProcedureResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useTaxProcedureService = ({ onError, onSuccess }: UseTaxProcedureServiceProps) => {
  const {
    mutateAsync: createTaxProcedure,
    isPending: isCreateTaxProcedureLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-taxprocedure-request'],
    mutationFn: (req: TaxProcedureRequest) => TaxProcedureProfileService.createTaxProcedure(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateTaxProcedure = useCallback(
    async (TaxprocedureData: TaxProcedureRequest) => {
      try {
        createTaxProcedure(TaxprocedureData);
      } catch (err) {
        console.error(err);
      }
    },
    [createTaxProcedure]
  );

  return {
    onCreateTaxProcedure,
    isCreateTaxProcedureLoading,
    data,
    error,
    isError,
  };
};

export const useGetTaxProcedureService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-taxprocedure-data'],
      queryFn: async ({ pageParam }) => {
        return TaxProcedureProfileService.getTaxProcedure({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetTaxProcedureDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-taxprocedure-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return TaxProcedureProfileService.getTaxProcedureDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateTaxProcedureService = ({ id, onError, onSuccess }: UpdateTaxProcedureServiceProps) => {
  const {
    mutateAsync: updateTaxProcedure,
    isPending: isUpdateTaxProcedureLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-taxprocedure-request'],
    mutationFn: (req: TaxProcedureRequest) => TaxProcedureProfileService.updateTaxProcedure(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateTaxProcedure = useCallback(
    async (req: TaxProcedureRequest) => {
      try {
        updateTaxProcedure(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateTaxProcedure]
  );

  return {
    onUpdateTaxProcedure,
    isUpdateTaxProcedureLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteTaxProcedureService = ({ id, onError, onSuccess }: DeleteTaxProcedureServiceProps) => {
  const {
    mutateAsync: deleteTaxProcedure,
    isPending: isDeleteTaxProcedureLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-taxprocedure-request'],
    mutationFn: () => TaxProcedureProfileService.deleteTaxProcedure(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteTaxProcedure = useCallback(async () => {
    try {
      deleteTaxProcedure();
    } catch (err) {
      console.error(err);
    }
  }, [deleteTaxProcedure]);

  return {
    onDeleteTaxProcedure,
    isDeleteTaxProcedureLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusTaxProcedureService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusTaxProcedureServiceProps) => {
  const {
    mutateAsync: changeStatusTaxProcedure,
    isPending: isChangeStatusTaxProcedureLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-taxprocedure-request'],
    mutationFn: (req: ChangeStatusRequest) => TaxProcedureProfileService.changeStatusTaxProcedure(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusTaxProcedure = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusTaxProcedure(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusTaxProcedure]
  );

  return {
    onChangeStatusTaxProcedure,
    isChangeStatusTaxProcedureLoading,
    data,
    error,
    isError,
  };
};

export const useGetDefaultTaxProcedureService = (id: number) => {
  const { data, isLoading, error, refetch } = useQuery<GetDefaultTaxProcedureResponse, unknown>({
    queryKey: ['get-default-tax-procedures-data'],
    queryFn: async () => {
      return TaxProcedureProfileService.getDefaultTaxProcedure(id);
    },
    enabled: false,
  });

  return {
    data,
    isLoading,
    error,
    refetch,
  };
};
