import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
// import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import { ReactComponent as CheckBoxUnchecked } from '../../assets/Modal/Rectangle 34624559.svg';
import { ReactComponent as CheckBoxchecked } from '../../assets/Modal/checked-box.svg';
import { useTranslation } from "react-i18next";


interface CommonCheckboxProps {
  label: string;
  fontSize?: string;
  color?: 'primary' | 'secondary' | 'default' | 'success';
  // size?: 'small' | 'medium';
  required?: boolean;
  error?:string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const CommonCheckbox: React.FC<CommonCheckboxProps> = ({
  label,
  color = 'primary',
  // size = 'medium',
  required,
  error,
  checked = false,
  onChange,
  // fontSize
}) => {
  const { t,i18n } = useTranslation();
  const isArabic:boolean=i18n.language==="ar"
  const textAlignments:any=isArabic?"start":"end"
  return (
    <>
      <FormControlLabel
        labelPlacement={textAlignments}
        className='pt-[13px] pl-[2px] pb-[4px] flex items-center'
        label={
          <span className="font-Saira font-[600] text-[14px]  mt-0 text-txt-color" style={{ color: error && !checked ? "red" : "black" }}>
            {t(label)}
            {required && <span className='ml-[5px]'>*</span>}
          </span>
        }
        control={
          <Checkbox
            color={color}
            // size={size}
            checked={checked}
            onChange={onChange}
            icon={<CheckBoxUnchecked width={'36px'} height={'36px'} />}
            checkedIcon={<CheckBoxchecked width={'36px'} height={'36px'} />}
            
            sx={{
              // marginTop:"5px",
              color: error ? "red" : "#5F6770",
             '& .MuiIconButton-root': { 
              borderRadius: '40px',
                overflow: 'hidden',
            },
              }}
          />
        } 
      />
        {error && 
          <div className='text-[#FF0000] font-[500] text-[12px] font-Saira italic text-left ml-[10px]'>
            {t(error)}
          </div>
        }
      <span style={{ display: 'block', height: '10px' }}></span>
    </>
  );
};

export default CommonCheckbox;
