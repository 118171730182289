import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import PackageGroupComponent from 'components/PackageGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { PackageGroupRequest } from 'subModule/src/services/packagegroup/interface';
import { useChangeStatusPackageGroupService, usePackageGroupService, useDeletePackageGroupService, useGetPackageGroupService, useUpdatePackageGroupService, useGetPackageGroupPackageService } from 'subModule/src/services/packagegroup/usePackageGroup';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
import { useTranslation } from 'react-i18next';
import { useAssignPackageService, useUnassignPackageService, useAssignAllPackageService, useUnassignAllPackageService } from 'subModule/src/services/packagegroup/usePackageGroup';
import { SelectChangeEvent } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfo } from 'features/user/userSlice';
import { checkPrivileges } from 'Helpers/CheckPrivileges';

interface FormData {
  title: string,
  secondaryTitle: string,
  id: number,
  active: boolean
}
interface SearchFormData {
  [key: string]: string;
}

  const requiredFields = ['title'];

  export default function PackageGroup () {
    const [showSecField, setShowSecField] = useState<boolean>(false);
    const [openForm, setOpenForm] = useState(false);
    const [openDeleteModal, setDeleteModal] = useState(false);
    const [enableEdit, setEnableEdit] = useState(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(20);
    const [statusModal, setStatusModal] = useState(false);
    const [statusText, setStatusText] = useState<string>('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [search, setSearch] = useState('');
    const [isBtnEnabled, setIsBtnEnabled] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingSearch, setLoadingSearch] = useState<boolean>(false);
    const [prevFormData, setPrevFormData] = useState<object>({});
    const [rowView, setRowView] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>({});
    const [packageGroupId, setPackageGroupId] = useState<number>(0);
    const [tab, setTab] = useState<number>(0);
    // const [data] = useState(rows);
    const [formData, setFormData] = useState<FormData>({
        title: '',
        secondaryTitle: '',
        id: 0,
        active: true
    });
    const [searchFormData, setSearchFormData] = useState<SearchFormData>({
      title: '',
      packageCode: ''
    });
  const [filter, setFilter] = useState<string[]>(['title']);
  const [searchErrorMessages, setSearchErrorMessages] = useState<Partial<SearchFormData>>({});
  const [searchPackage, setSearchPackage] = useState<any>({});
  const [loadingPackage,setLoadingPackage]=useState<boolean>(false)

    const { data: tableData,  isLoading, error,  refetch } = searchTerm ? useGetPackageGroupService({ pageNumber: pageNo, size: pageSize, title: searchTerm }) : useGetPackageGroupService({ pageNumber: pageNo, size: pageSize });

    const { data: packageAssignData, isLoading: packageAssignIsLoading, error: packageAssignError, refetch:packageAssignRefetch } = useGetPackageGroupPackageService({pageNumber: pageNo, size: pageSize,...searchPackage}, packageGroupId);

    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const loggedInUserData = useSelector(selectUserInfo);
  const primaryLanguage = loggedInUserData?.primaryLanguage;
  const secondaryLanguage = loggedInUserData?.secondaryLanguage;

    let allPackagesAssigned:unknown = packageAssignData?.pages[0].data?.content.map(packages => packages.linked).every(linked => linked === true);
    
    useEffect(() => {
      if (!isLoading && !error && !rowView && !openForm && !loadingSearch && !loadingPackage) { // Only refetch if not already loading or in error state
        refetch();
      }
      if(tableData) {
        setLoading(false);
        if((tableData?.pages[0] as any).status && !(tableData?.pages[0] as any).success) {
          enqueueSnackbar(<AlertMessages statusCode={(tableData?.pages[0] as any)?.status} />, { variant: 'error' })
        }
      }
      if(tab === 0 && enableEdit) {
        setIsBtnEnabled(false);
      }
      if(!packageAssignIsLoading && !packageAssignError && packageGroupId && packageGroupId !== 0 && tab === 1 && !loadingPackage) {
        const loadData = async() => {
          setLoadingPackage(true);
          await packageAssignRefetch();
          setLoadingPackage(false);
        }
        loadData();
      }
    }, [pageNo, pageSize, refetch, isLoading, error, tableData, openForm, packageGroupId, tab]);

    useEffect(() => {
      if(enableEdit && JSON.stringify(formData) !== JSON.stringify(prevFormData) || openForm && JSON.stringify(formData) !== JSON.stringify(prevFormData)) {
        setIsBtnEnabled(true);
      } else if(enableEdit && JSON.stringify(formData) === JSON.stringify(prevFormData)) {
        setIsBtnEnabled(false);
      }
    }, [enableEdit, formData]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if (searchTerm !== null && !isLoading && !error && loadingSearch) {
          await refetch();
          setLoadingSearch(false);
        }
      }, 1000);
      return () => clearTimeout(getData)
    }, [searchTerm, loadingSearch]);

    useEffect(() => {
      const getData = setTimeout(async() => {
        if(searchPackage&&!packageAssignIsLoading&&loadingPackage&&tab===1){
          await packageAssignRefetch()
          setLoadingPackage(false);
        }
      }, 300);
      return () => clearTimeout(getData)
    }, [searchPackage]);
    
    const handleChangePage = (pageNo : number) => {  
      setLoading(true);
      setPageNo(pageNo + 1);
      setPageSize(pageSize);
    }
    const handleChangeRows = (pageSize : number) => {
      setLoading(true);
      setPageSize(pageSize);
      setPageNo(1);
    }


    const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});

    const openModal = () => {
      if(rowView) {
        setRowView(false);
        setPackageGroupId(0);
        setPageNo(1);
        setTab(0);
      } else {
        setIsBtnEnabled(true);
        setOpenForm(!openForm);
        setShowSecField(false)
        openModalSettings();
        setSearch('');
        setSearchTerm('');
        setShowSecField(false)
      }
    };
    const openModalSettings = () => {
      setEnableEdit(false);
      setErrorMessages({});
      setFormData({
        title: '',
        secondaryTitle: '',
        id: 0,
        active: true
      });
    }

    const handleSecField = () => {
      setShowSecField((prevValue) => !prevValue);
    }

      const handleChange = (field: string, value: any) => {
        const  val = value;

        setFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
      
        if (requiredFields.includes(field) && !val) {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      
    
      const validateFormData = (data: FormData) => {
        const errors: Partial<FormData> = {};

        if (!data.title?.trim()) {
          errors.title = 'Title is required.';
        }
    
        return errors;
      };

      const { onCreatePackageGroup } = usePackageGroupService({
        onSuccess: (msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            setOpenForm(false);
            refetch();
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('packageGroup') + ' ' + t('created') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
          console.error(err.stack);
        }
      }})

      const { onUpdatePackageGroup } = useUpdatePackageGroupService({
        onSuccess: (data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(!rowView) {
              setOpenForm(false);
              setEnableEdit(false);
              refetch();
            }
            if(rowView) { setPrevFormData(response?.data); setSelectedRow(response?.data); }
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('packageGroup') + ' ' + t('updated') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err) => {
          console.error('Login error======', err, enableEdit);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        id: formData.id,
      });

      const handleSubmit = () => {
        const errors = validateFormData(formData);
        setErrorMessages(errors);
   
        if (Object.keys(errors).length === 0) {
          setIsBtnEnabled(false);
          setLoading(true);
          const packageGroupData: PackageGroupRequest = {
            ...formData, 
            title: formData.title,
            secondaryTitle: formData.secondaryTitle,
          };
     
          if (enableEdit) {
            onUpdatePackageGroup(
                packageGroupData
            );
          } else {
            onCreatePackageGroup(packageGroupData);
          }
        }
      };

      const onEdit = (row:any) => {
        setEnableEdit(true);
        setIsBtnEnabled(false);
        setFormData(row);
        setPrevFormData(row);
        setOpenForm(true);
        setSearch('');
        setSearchTerm('');
      }

      const onDelete = (row:any) => {
        setFormData(row)
        setDeleteModal(true)
      }

      const { onDeletePackageGroup } = useDeletePackageGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('packageGroup') + ' ' + t('deleted') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch(); // This will use the updated pageNo and pageSize
          if(tableData?.pages[0].data.content.length === 1) {
            handleChangePage(pageNo - 1)
          }
        },
        onError: (err) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      const handleDelete = () => {
        setDeleteModal(false)
        setLoading(true);
        onDeletePackageGroup()
      }
      const cancelDelete = () => {
        setDeleteModal(false)
      }

      const clearSearch = () => {
        setSearch('');
        setSearchTerm('');
        if(searchTerm) { handleSearch(''); }
      }
      const handleSearch = (search: string) => {
        setLoadingSearch(true);
        setSearchTerm(search);
        setPageNo(1);
        setPageSize(pageSize);
      };
    
      const handleSearchClick = () => {
        const trimmedSearch = search.trim();
        if (trimmedSearch !== '') {
          handleSearch(trimmedSearch);
        }
      };
    
      const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value.trim());
        if (!event.target.value.trim()) handleSearch('');
      };
      
      const onChangeStatus = (row: any) => {
        row.active ? setStatusText('deactivate') : setStatusText('activate');
        setFormData(row);
        setStatusModal(true);
      }
      const cancelChangeStatus = () => {
        setStatusModal(false);
      }
      const submitChangeStatus = () => {
        setStatusModal(false);
        setLoading(true);
        onChangeStatusPackageGroup({});
      }
      const { onChangeStatusPackageGroup } = useChangeStatusPackageGroupService({
        id: formData.id,
        onSuccess: (msg) => {
          if(msg.success) {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} text={t('packageGroup') + ' ' + t(statusText) + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            enqueueSnackbar(<AlertMessages statusCode={msg.status} />, { variant: 'error' });
          }
          refetch();
        },
        onError: (err) => {
          console.error("===", err)
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
        active: formData.active ? "inactive" : "active"
      });

      const showRowView = (row: any) => {
        setEnableEdit(true);
        setOpenForm(false);
        setFormData(row);
        setErrorMessages({});
        setRowView(true);
        setPackageGroupId(row.id);
        setSelectedRow(row);
        setSearch('');
        setSearchTerm('');
      }
      const handleChangeTabs = (tabId: number) => {
        if(tabId === 0) {
          setTab(0);
          setEnableEdit(true);
          setOpenForm(false);
          setFormData(selectedRow);
          setErrorMessages({});
          setIsBtnEnabled(false);
        } else if(tabId === 1) {
          setTab(1);
          setPageNo(1);
          setEnableEdit(false);
          setErrorMessages({});
          clearSearchAssign()
        }
      }
      const assignUnassignSingleEntity = (isChecked: boolean, row: any) => {
        setLoadingPackage(false);
        if(isChecked) {
          if(tab === 1) {
            onAssignPackage({ packageGroupId: packageGroupId, packageId: row.id });
          }
        } else {
          if(tab === 1) {
            onUnassignPackage({ packageGroupId: packageGroupId, packageId: row.id });
          }
        }
      }
      const assignUnassignAllEntities = (isChecked: boolean) => {
        setLoadingPackage(false);
        if(isChecked) {
          if(tab === 1) {
            onAssignAllPackages({packageGroupId: packageGroupId});
          }
        } else {
          if(tab === 1) {
            onUnassignAllPackages({packageGroupId: packageGroupId});
          }
        }
      }
      const { onAssignPackage } = useAssignPackageService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            if(packageAssignData?.pages[0].data.content.length === packageAssignData?.pages[0].data.content.filter(packages => packages.linked).length) {
              allPackagesAssigned = true;
            }
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignPackage } = useUnassignPackageService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            if(packageAssignData?.pages[0].data.content.length !== packageAssignData?.pages[0].data.content.filter(packages => packages.linked).length) {
              allPackagesAssigned = false;
            }
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('package') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });
      const { onAssignAllPackages } = useAssignAllPackageService({
        onSuccess: async(msg) => {
          const response: any = {};
          Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
          if(response.success) {
            allPackagesAssigned = true;
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('packages') + ' ' + t('assigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err:any) => {
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
          if (err instanceof Error) {
            console.error(err.stack);
          }
      }})
      const { onUnassignAllPackages } = useUnassignAllPackageService({
        onSuccess: async(data) => {
          const response: any = {};
          Object.keys(data).forEach((key, index) => { response[key] = Object.values(data)[index]; });
          if(response.success) {
            allPackagesAssigned = false;
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} text={t('All') + ' ' + t('packages') + ' ' + t('unassigned') + ' ' + t('successfully')} />, { variant: 'success' });
          } else {
            await packageAssignRefetch();
            setLoadingPackage(false);
            enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
          }
        },
        onError: (err: any) => {
          console.log("err====,  ",err);
          enqueueSnackbar(<AlertMessages statusCode={0} />, { variant: 'error' });
        },
      });

      // child Search
      const clearSearchAssign = () => {
        setFilter(['title']);
        setSearchFormData({
          title: '',
          packageCode: ''
        });
        setSearchErrorMessages({});
        if(Object.keys(searchPackage).length) {
          setLoadingPackage(true);
          setSearchPackage({})
        }
      }
      const handleChangeFilter = (event: SelectChangeEvent<typeof filter>) => {
        const { target: { value }, } = event;
        setFilter(typeof value === 'string' ? value.split(',') : value,);
        setSearchFormData(prevFormData => 
          Object.fromEntries(Object.keys(prevFormData).map(key => 
            [key, value.includes(key) ? prevFormData[key] : '']
          ))
        );
        if(!value.length && Object.keys(searchPackage).length) {
          clearSearchAssign();
        }
      };
      const handleChangeSearch = (field: string, value: any) => {
        const val = value;
    
        setSearchFormData((prevData) => ({
          ...prevData,
          [field]: val,
        }));
    
        if (filter.includes(field) && !val) {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: 'This field is required.',
          }));
        } else {
          setSearchErrorMessages((prevErrors) => ({
            ...prevErrors,
            [field]: undefined,
          }));
        }
      };
      const validateSearchFormData = (data: SearchFormData) => {
        const errors: Partial<SearchFormData> = {};
    
        filter.forEach(fieldName => {
          if (!data[fieldName]) { 
            errors[fieldName] = 'Required';
          }
        });
    
        return errors;
      };
      const handleSubmitSearch = () => {
        const errors = validateSearchFormData(searchFormData);
        setSearchErrorMessages(errors);
    
        if (Object.keys(errors).length === 0) {
          setLoadingPackage(true);
          const filteredSearch: Record<string, string> = {};
    
          Object.entries(searchFormData).forEach(([key, value]) => {
              if (value) {
                filteredSearch[key] = typeof value === 'string' ? value.trim() : value;
              }
          });
          setSearchPackage(filteredSearch);
          setPageNo(1);
          setPageSize(pageSize);
        }
      };
      const adjustActions = (actions: any[]) => {
        return checkPrivileges('ROLE_PACKAGEGROUP_UPDATE') 
          ? (checkPrivileges('ROLE_PACKAGEGROUP_ARCHIVE') 
              ? actions 
              : actions.filter(action => action.label !== 'delete')) 
          : (checkPrivileges('ROLE_PACKAGEGROUP_ARCHIVE') 
              ? actions.filter(action => action.label !== 'edit') 
              : []);
      }

    return(
        <Box>
            <PackageGroupComponent
                openModal={openModal}
                closeModal={openModal}
                handleSecField={handleSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                handleSearchClick={handleSearchClick}
                handleInputChange={handleInputChange}
                handleDelete={handleDelete}
                cancelDelete={cancelDelete}
                handleChange={handleChange} 
                handleSubmit={handleSubmit}
                formData={formData}
                handleChangePage={handleChangePage}
                handleChangeRows={handleChangeRows}
                onChangeStatus={onChangeStatus}
                cancelChangeStatus={cancelChangeStatus}
                submitChangeStatus={submitChangeStatus}
                rowView={rowView}
                showRowView={showRowView}
                handleChangeTabs={handleChangeTabs}
                assignUnassignSingleEntity={assignUnassignSingleEntity}
                assignUnassignAllEntities={assignUnassignAllEntities}
                allPackagesAssigned={allPackagesAssigned}
                packageAssignData={packageAssignData?.pages[0] || {}}
                clearSearch={clearSearch}
                columns={checkPrivileges("ROLE_PACKAGEGROUP_ACTIVE") ? [
                    { id: 'title', label: 'title', numeric: false },
                    { id: 'active', label: 'status', numeric: false },
                    { id: 'actions', label: 'actions', numeric: false },
                ] : [
                  { id: 'title', label: 'title', numeric: false },
                  { id: 'actions', label: 'actions', numeric: false },
              ]}
                data={tableData?.pages[0] || {}}
                {...{ openForm, openDeleteModal, enableEdit,showSecField, pageNo, pageSize, statusModal, statusText, search,isBtnEnabled,loading,loadingSearch,filter,searchPackage,loadingPackage }}
                errorMessages={errorMessages}
                actions1 = {adjustActions([
                  { label: "edit", onClick: onEdit, icon: <EditIcon /> },
                  { label: "delete", onClick: onDelete, icon: <DeleteIcon /> },
                ])}
                searchFormData={searchFormData}
                handleChangeFilter={handleChangeFilter}
                handleChangeSearch={handleChangeSearch}
                handleSubmitSearch={handleSubmitSearch}
                searchErrorMessages={searchErrorMessages}
                clearSearchAssign={clearSearchAssign}
            />
        </Box>
    )
  }