// UserService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangePasswordRequest,
  ChangePasswordResponse,
  ChangeStatusUserResponse,
  DeleteUserResponse,
  GetUserResponse,
  UpdatePasswordRequest,
  UserRequest,
  UserResponse,
} from './interface';

export class UserModuleProfile extends BaseService {
  async createUser(req: UserRequest) {
    return this.post<UserResponse, UserRequest>(`${this.apiUrl}/api/users`, req);
  }

  async getUser(params: PaginationParams) {
    return this.get<GetUserResponse>(`${this.apiUrl}/api/users/paged`, params);
  }

  async updateUser(id: number, req: UserRequest) {
    return this.put<UserResponse, UserRequest>(`${this.apiUrl}/api/users/${id}`, req);
  }

  async deleteUser(id: number) {
    return this.delete<DeleteUserResponse>(`${this.apiUrl}/api/users/${id}`);
  }

  async changeStatusUser(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusUserResponse, ChangeStatusRequest>(`${this.apiUrl}/api/users/${active}/${id}`, req);
  }

  async changePassword(req: ChangePasswordRequest) {
    return this.put<ChangePasswordResponse, ChangePasswordRequest>(`${this.apiUrl}/api/password/changepassword`, req);
  }

  async updatePassword(req: UpdatePasswordRequest) {
    return this.put<ChangePasswordResponse, UpdatePasswordRequest>(`${this.apiUrl}/api/password/updatepassword`, req);
  }
}
