import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import {  Grid } from '@mui/material';
import DynamicSelect from 'common/Select/Select';
import BasicDatePicker from 'common/Pickers/Pickers';

interface BankDepositModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  bankAccountData:any;
  stationData:any;
  openForm: boolean;
  errorMessages:{ [key: string]: string };
  enableEdit?:boolean;
  isBtnEnabled: boolean;
}

const BankDepositModalComponent: React.FC<BankDepositModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  bankAccountData,
  stationData,
  openForm,
  enableEdit,
  errorMessages,
  isBtnEnabled
}) => {
  const bankaccountsOptions = bankAccountData.map((item: { id: number, bankName: string }) => ({ 
    value: item.id, 
    label: item.bankName
  }));
  const stationOptions = stationData.map((item: { id: number, name: string }) => ({
    value: item.id, 
    label: item.name
  }));
    const handleScroll = () => {
        // Handle scroll logic here
    
      };
    
      const handleSearch = () => {
        // Handle search logic here
      }

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={enableEdit ? "updateBankDeposit" : "addBankDeposit"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type='number'
                label="accountNumber"
                placeholder="Write your account number here"
                handleChange={(e) => handleChange('number', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.numberError}
                value={formData?.number || ''}
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label="receiptNumber"
                placeholder="Write receipt number here"
                handleChange={(e) => handleChange('receiptNumber', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.receiptNumber}
                value={formData?.receiptNumber || ''}
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? stationOptions.find((option: any) => option.value === formData?.stationId) : null}
                value={stationOptions.find((option: any) => option.value === formData?.stationId)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="color"
                options={stationOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('stationId', e)}
                error={errorMessages?.stationIdError}
                label="station"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <DynamicSelect
                defaultValue={enableEdit ? bankaccountsOptions.find((option: any) => option.value === formData?.bankAccountId) : null}
                value={bankaccountsOptions.find((option: any) => option.value === formData?.bankAccountId)}
                isDisabled={false}
                isLoading={false}
                isClearable={true}
                isRtl={false}
                // fullWidth={false}
                isSearchable={true}
                name="color"
                options={bankaccountsOptions}
                onScroll={handleScroll}
                onInputChange={handleSearch}
                // isMulti
                isRequired={true}
                onChange={(e) => handleChange('bankAccountId', e)}
                error={errorMessages?.bankAccountIdError}
                label="bankAccount"
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3} >
              <BasicDatePicker
                type = "datePicker"
                label="date"
                fullWidth
                onChange={(e) => handleChange('date', e)}
                required={true}
                error={errorMessages?.date}
                value={enableEdit ? formData?.date.split('T')[0] : null}
              />
            </Grid>
            <Grid item  xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                type="number"
                label="amount"
                placeholder="Write amount here"
                handleChange={(e) => handleChange('amount', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.amountError}
                value={formData?.amount || ''}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default BankDepositModalComponent;
