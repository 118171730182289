// ModelEngineService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusModelEngineResponse,
  DeleteModelEngineResponse,
  GetModelEngineResponse,
  GetModelsByMakeResponse,
  ModelEngineRequest,
  ModelEngineResponse,
} from './interface';

export class ModelEngineProfile extends BaseService {
  async createModelEngine(req: ModelEngineRequest) {
    return this.post<ModelEngineResponse, ModelEngineRequest>(`${this.apiUrl}/api/modelengines`, req);
  }

  async getModelEngine(params: PaginationParams) {
    return this.get<GetModelEngineResponse>(`${this.apiUrl}/api/modelengines/paged`, params);
  }

  async updateModelEngine(id: number, req: ModelEngineRequest) {
    return this.put<ModelEngineResponse, ModelEngineRequest>(`${this.apiUrl}/api/modelengines/${id}`, req);
  }

  async deleteModelEngine(id: number) {
    return this.delete<DeleteModelEngineResponse>(`${this.apiUrl}/api/modelengines/${id}`);
  }

  async changeStatusModelEngine(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusModelEngineResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/modelengines/${active}/${id}`,
      req
    );
  }

  async getModelsByMake(params: PaginationParams, id: number) {
    return this.get<GetModelsByMakeResponse>(`${this.apiUrl}/api/models/bymakeid/${id}`, params);
  }
}
