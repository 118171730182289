// BayProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { BayProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { BayRequest, BayResponse, ChangeStatusBayResponse, DeleteBayResponse } from './interface';
import { useCallback } from 'react';

type UseBayServiceProps = {
  onSuccess: (data: BayResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateBayServiceProps = {
  onSuccess: (data: BayResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteBayServiceProps = {
  onSuccess: (data: DeleteBayResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusBayServiceProps = {
  onSuccess: (data: ChangeStatusBayResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useBayService = ({ onError, onSuccess }: UseBayServiceProps) => {
  const {
    mutateAsync: createBay,
    isPending: isCreateBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-bay-request'],
    mutationFn: (req: BayRequest) => BayProfileService.createBay(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateBay = useCallback(
    async (bayData: BayRequest) => {
      try {
        createBay(bayData);
      } catch (err) {
        console.error(err);
      }
    },
    [createBay]
  );

  return {
    onCreateBay,
    isCreateBayLoading,
    data,
    error,
    isError,
  };
};

export const useGetBaysService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Bay-data'],
      queryFn: async ({ pageParam }) => {
        // Use the provided initialPageNo and initialPageSize
        return BayProfileService.getBays({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateBayService = ({ id, onError, onSuccess }: UpdateBayServiceProps) => {
  const {
    mutateAsync: updateBay,
    isPending: isUpdateBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-bay-request'],
    mutationFn: (req: BayRequest) => BayProfileService.updateBay(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateBay = useCallback(
    async (req: BayRequest) => {
      try {
        updateBay(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateBay]
  );

  return {
    onUpdateBay,
    isUpdateBayLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteBayService = ({ id, onError, onSuccess }: DeleteBayServiceProps) => {
  const {
    mutateAsync: deleteBay,
    isPending: isDeleteBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-bay-request'],
    mutationFn: () => BayProfileService.deleteBay(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteBay = useCallback(async () => {
    try {
      deleteBay();
    } catch (err) {
      console.error(err);
    }
  }, [deleteBay]);

  return {
    onDeleteBay,
    isDeleteBayLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusBayService = ({ active, id, onError, onSuccess }: ChangeStatusBayServiceProps) => {
  const {
    mutateAsync: changeStatusBay,
    isPending: isChangeStatusBayLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-bay-request'],
    mutationFn: (req: ChangeStatusRequest) => BayProfileService.changeStatusBay(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusBay = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusBay(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusBay]
  );

  return {
    onChangeStatusBay,
    isChangeStatusBayLoading,
    data,
    error,
    isError,
  };
};
