import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from 'react-i18next';
import { TimeField } from '@mui/x-date-pickers';


interface BasicDatePickerProps {
  label?: string;
  type?: 'datePicker' | 'timePicker' | 'timefield'; // Type can only be 'datePicker' or 'timePicker'
  fullWidth?: boolean;
  onChange?: (date: unknown) => void;
  required?: boolean;
  disable?:any;
  error?: string | undefined; // Added error prop
  value?: Date | string | null | undefined;
}

const BasicDatePicker: React.FC<BasicDatePickerProps> = ({
  label,
  type,
  fullWidth,
  onChange,
  required,
  disable,
  error, // Accept error prop
  value,
}) => {
  const { t,i18n } = useTranslation();
  const isArabic:boolean=i18n.language==="ar"
  const textAlignments:string=isArabic?"text-right":"text-left"
  return (
    <div>
      <div className={`${textAlignments} relative top-[0px] ${!disable ? 'opacity-100' : 'opacity-30'}`}>
        {label && (
          <label className='font-Saira font-[600] text-[14px] text-txt-color' style={{ color: error && !disable ? 'red' : '#343434' }}>
            {t(label)}
            {required && !disable && <span className='ml-[5px]'>*</span>}
          </label>
        )}
      </div>
      <div>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {type === 'datePicker' ? (
            <DemoContainer sx={{ marginTop: '-10px' }} components={['DatePicker']}>
              <DatePicker
                className='hover:border-primary-color'
                sx={{
                  overflow: 'hidden',
                  backgroundColor: '#fff',
                  '& .MuiInputBase-root': {
                    height: '38px',
                    borderRadius: '8px',
                  },
                  width: fullWidth ? '100%' : '280px',
                  borderColor: error ? 'red' : 'black',
                  borderRadius: '8px',
                  '&:focus': {
                    borderColor: 'black',
                    borderWidth:'1px'
                  },
                  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black',
                    borderWidth:'1px'
                  },
                }}
                onChange={onChange}
                format='YYYY-MM-DD'
                disabled={disable}
                value={value ? dayjs(value) : null}
              />
            </DemoContainer>
          ) : type === 'timefield' ? (
            <DemoContainer components={['TimeField']}>
              <TimeField
                className='hover:border-black'
                sx={{
                  overflow: 'hidden',
                  backgroundColor: '#fff',
                  '& .MuiInputBase-root': {
                    height: '36px',
                    borderRadius: '8px',
                  },
                  width: fullWidth ? '100%' : '280px',
                  borderColor: error ? 'red' : 'black',
                  borderRadius: '8px',
                }}
                onChange={onChange}
                format="HH:mm"
                value={value ? dayjs(value) : null}
              />
            </DemoContainer>
          ) : (
            <DemoContainer components={['TimePicker']}>
              <TimePicker
                className='hover:border-black'
                sx={{
                  overflow: 'hidden',
                  backgroundColor: '#fff',
                  '& .MuiInputBase-root': {
                    height: '36px',
                    borderRadius: '8px',
                  },
                  width: fullWidth ? '100%' : '280px',
                  border: '1px solid grey',
                  borderRadius: '8px',
                }}
                onChange={onChange}
                views={['hours', 'minutes']}
                format="HH:mm"
                value={value ? dayjs(value) : null}
              />
            </DemoContainer>
          )}
        </LocalizationProvider>
        {/* Display error message if error exists */}
        {error && !disable &&
          <div className='text-[#FF0000] font-[500] text-[12px] font-Saira italic text-left' 
            style={{ position: 'absolute' }}
          >
              {t(error)}
          </div>
        }
        <span style={{ display: 'block', height: '10px' }}></span>
      </div>
    </div>
  );
};

export default BasicDatePicker;
