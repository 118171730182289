// InvoiceService.tsx
import { BaseService } from '../../core/BaseService';
import { PaginationParams } from '../../type';
import { GetInvoiceResponse } from './interface';

export class InvoiceProfile extends BaseService {
  async getInvoice(params: PaginationParams) {
    return this.get<GetInvoiceResponse>(`${this.apiUrl}/api/invoices/paged`, params);
  }
}
