import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';

interface StyledFieldProps {
    label?: string;
    value?: string;
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    required?: boolean;
    fullWidth?: boolean;
    error?: string;
    rowsNo?: number;
}

const StyledInputArea: FC<StyledFieldProps> = ({
    value,
    label,
    handleChange,
    required,
    fullWidth,
    rowsNo = 3,
    error,
}) => {
    const { t,i18n } = useTranslation();
    const isArabic:boolean=i18n.language==="ar"
    const textAlignments:any=isArabic?"right":"left"

    return (
        <div>
            <Typography
                className={`font-Saira font-[600] text-[14px] ${required && error?'text-red-400':'text-txt-color'} line-clamp-1`}
                style={{ textAlign: textAlignments }}
            >
                {label && t(label)}
                {required && ' *'}
            </Typography>
            <textarea
                id="message"
                onChange={handleChange}
                rows={rowsNo}
                className={`block px-2.5 pt-1.5 font-Saira font-[500] text-[14px] ${fullWidth ? 'w-full' : 'w-96'
                    } overflow-hidden rounded-[8px] bg-white text-black border border-[#C4C4C4] focus:border focus:border-black`}
                value={value || ''}
                style={{ minHeight: `${rowsNo * 39}px`, outline:'none' }}
                required={required}
            />
            <HelperText className='font-Saira font-[600] text-[14px] text-red-400' error={Boolean(error)}>
                {required && error ? t(error) : null}
            </HelperText>
            <span style={{ display: 'block', height: '10px' }}></span>
        </div>
    );
};

const HelperText = styled('p') <{ error?: boolean }>`
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 0.875rem;
  color: ${(props) => (props.error ? 'red' : '')};
  text-align: left;
  position: absolute;
`;

export default StyledInputArea;
