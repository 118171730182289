// ClientGroupService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignClientRequest,
  AssignUnassignResponse,
  ChangeStatusClientGroupResponse,
  ClientGroupRequest,
  ClientGroupResponse,
  DeleteClientGroupResponse,
  GetClientGroupClientResponse,
  GetClientGroupResponse,
} from './interface';

export class ClientGroupProfile extends BaseService {
  async createClientGroup(req: ClientGroupRequest) {
    return this.post<ClientGroupResponse, ClientGroupRequest>(`${this.apiUrl}/api/usergroups/client`, req);
  }

  async getClientGroup(params: PaginationParams) {
    return this.get<GetClientGroupResponse>(`${this.apiUrl}/api/usergroups/client/paged`, params);
  }

  async updateClientGroup(id: number, req: ClientGroupRequest) {
    return this.put<ClientGroupResponse, ClientGroupRequest>(`${this.apiUrl}/api/usergroups/client/${id}`, req);
  }

  async deleteClientGroup(id: number) {
    return this.delete<DeleteClientGroupResponse>(`${this.apiUrl}/api/usergroups/client/${id}`);
  }

  async changeStatusClientGroup(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusClientGroupResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/usergroups/client/${active}/${id}`,
      req
    );
  }

  async getClientGroupClient(params: PaginationParams & { clientGroupId: number }) {
    return this.get<GetClientGroupClientResponse>(`${this.apiUrl}/api/users/client/byclientgroupid`, params);
  }

  async assignClient(req: AssignUnassignClientRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignClientRequest>(
      `${this.apiUrl}/api/usergroups/client/assign`,
      req
    );
  }

  async unassignClient(req: AssignUnassignClientRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignClientRequest>(
      `${this.apiUrl}/api/usergroups/client/unassign`,
      req
    );
  }

  async assignAllClients(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/usergroups/client/assignall`,
      req
    );
  }

  async unassignAllClients(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/usergroups/client/unassignall`,
      req
    );
  }
}
