// ItemGroupProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { ItemGroupProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignItemRequest,
  AssignUnassignResponse,
  ChangeStatusItemGroupResponse,
  DeleteItemGroupResponse,
  ItemGroupRequest,
  ItemGroupResponse,
} from './interface';
import { useCallback } from 'react';

type UseItemGroupServiceProps = {
  onSuccess: (data: ItemGroupResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateItemGroupServiceProps = {
  onSuccess: (data: ItemGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteItemGroupServiceProps = {
  onSuccess: (data: DeleteItemGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type ChangeStatusItemGroupServiceProps = {
  onSuccess: (data: ChangeStatusItemGroupResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

type AssignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignServiceProps = {
  onSuccess: (data: AssignUnassignResponse) => void;
  onError: (err: unknown) => void;
};
type AssignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};
type UnassignAllServiceProps = {
  onSuccess: (data: AssignUnassignAllResponse) => void;
  onError: (err: unknown) => void;
};

export const useItemGroupService = ({ onError, onSuccess }: UseItemGroupServiceProps) => {
  const {
    mutateAsync: createItemGroup,
    isPending: isCreateItemGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-item-groups-request'],
    mutationFn: (req: ItemGroupRequest) => ItemGroupProfileService.createItemGroup(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateItemGroup = useCallback(
    async (itemGroupData: ItemGroupRequest) => {
      try {
        createItemGroup(itemGroupData);
      } catch (err) {
        console.error(err);
      }
    },
    [createItemGroup]
  );

  return {
    onCreateItemGroup,
    isCreateItemGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemGroupService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-item-groups-data'],
      queryFn: async ({ pageParam }) => {
        return ItemGroupProfileService.getItemGroup({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      enabled: false,
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useGetItemGroupDropdownService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-itemgroups-dropdown-data'],
      queryFn: async ({ pageParam }) => {
        return ItemGroupProfileService.getItemGroupDropdown({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateItemGroupService = ({ id, onError, onSuccess }: UpdateItemGroupServiceProps) => {
  const {
    mutateAsync: updateItemGroup,
    isPending: isUpdateItemGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-item-groups-request'],
    mutationFn: (req: ItemGroupRequest) => ItemGroupProfileService.updateItemGroup(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateItemGroup = useCallback(
    async (req: ItemGroupRequest) => {
      try {
        updateItemGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateItemGroup]
  );

  return {
    onUpdateItemGroup,
    isUpdateItemGroupLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteItemGroupService = ({ id, onError, onSuccess }: DeleteItemGroupServiceProps) => {
  const {
    mutateAsync: deleteItemGroup,
    isPending: isDeleteItemGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-item-groups-request'],
    mutationFn: () => ItemGroupProfileService.deleteItemGroup(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteItemGroup = useCallback(async () => {
    try {
      deleteItemGroup();
    } catch (err) {
      console.error(err);
    }
  }, [deleteItemGroup]);

  return {
    onDeleteItemGroup,
    isDeleteItemGroupLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusItemGroupService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusItemGroupServiceProps) => {
  const {
    mutateAsync: changeStatusItemGroup,
    isPending: isChangeStatusItemGroupLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-item-groups-request'],
    mutationFn: (req: ChangeStatusRequest) => ItemGroupProfileService.changeStatusItemGroup(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusItemGroup = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusItemGroup(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusItemGroup]
  );

  return {
    onChangeStatusItemGroup,
    isChangeStatusItemGroupLoading,
    data,
    error,
    isError,
  };
};

export const useGetItemGroupItemService = (initialParams: PageSize, itemGroupId: number) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-itemgroups-items-data'],
      queryFn: async ({ pageParam }) => {
        return ItemGroupProfileService.getItemGroupItem({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
          itemCode: initialParams.itemCode,
          itemGroupId: itemGroupId,
        });
      },
      initialPageParam: 1,
      getNextPageParam: (res) => {
        return !res?.last ? res?.number + 1 : undefined;
      },
      enabled: false,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useAssignItemService = ({ onError, onSuccess }: AssignServiceProps) => {
  const {
    mutateAsync: assignItem,
    isPending: isAssignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => ItemGroupProfileService.assignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignItem = useCallback(
    async (assignItemData: AssignUnassignItemRequest) => {
      try {
        assignItem(assignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignItem]
  );

  return {
    onAssignItem,
    isAssignItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignItemService = ({ onError, onSuccess }: UnassignServiceProps) => {
  const {
    mutateAsync: unassignItem,
    isPending: isUnassignItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-items-request'],
    mutationFn: (req: AssignUnassignItemRequest) => ItemGroupProfileService.unassignItem(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignItem = useCallback(
    async (unassignItemData: AssignUnassignItemRequest) => {
      try {
        unassignItem(unassignItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignItem]
  );

  return {
    onUnassignItem,
    isUnassignItemLoading,
    data,
    error,
    isError,
  };
};

export const useAssignAllItemService = ({ onError, onSuccess }: AssignAllServiceProps) => {
  const {
    mutateAsync: assignAllItems,
    isPending: isAssignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['assign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ItemGroupProfileService.assignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onAssignAllItems = useCallback(
    async (assignAllItemData: AssignUnassignAllRequest) => {
      try {
        assignAllItems(assignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [assignAllItems]
  );

  return {
    onAssignAllItems,
    isAssignAllItemLoading,
    data,
    error,
    isError,
  };
};

export const useUnassignAllItemService = ({ onError, onSuccess }: UnassignAllServiceProps) => {
  const {
    mutateAsync: unassignAllItems,
    isPending: isUnassignAllItemLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['unassign-all-items-request'],
    mutationFn: (req: AssignUnassignAllRequest) => ItemGroupProfileService.unassignAllItems(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUnassignAllItems = useCallback(
    async (unassignAllItemData: AssignUnassignAllRequest) => {
      try {
        unassignAllItems(unassignAllItemData);
      } catch (err) {
        console.error(err);
      }
    },
    [unassignAllItems]
  );

  return {
    onUnassignAllItems,
    isUnassignAllItemLoading,
    data,
    error,
    isError,
  };
};
