// VerticalTabs.tsx
import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  value: number;
  index: number;
}

function TabPanel({ children, value, index, ...other }: TabPanelProps) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

interface TabItem {
  id: number;
  title: string;
  child: React.ReactNode; // React component as child
}

interface VerticalTabsProps {
  tabs: TabItem[];
  selectedIndex: number;
  handleChangeTabs?: (tabId: number) => void;
  disableTabNo?: number[];
}

const VerticalTabs: React.FC<VerticalTabsProps> = ({ tabs, selectedIndex, handleChangeTabs, disableTabNo }) => {
  const [value, setValue] = React.useState(selectedIndex);
  const {t} = useTranslation();
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    handleChangeTabs?.(newValue);
  };

  return (
    <Box
      className='flex mt-[10px]'
    >
      <div
        className='rounded-lg bg-primary-color w-[200px] min-w-[200px] h-[380px] mr-[10px] pt-[7px] pb-[10rem] sticky top-[80px]'
      >
        {/* <div
          className='min-h-[93px] flex items-center justify-center text-white border-b border-b-white'
        >
          <h1 className='font-Saira font-[500] text-[16px]'> 
            {tabs[value].title}
          </h1>
        </div> */}
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          // sx={{
          //   '& .MuiTabs-indicator': {
          //     backgroundColor: 'orange',
          //   },
          // }}
          className='border-r border-[#FFFFFF] w-[100%] py-0 px-0 text-white'
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              disabled={disableTabNo?.includes(tab.id)}
              label={t(tab.title)}
              className={`font-Saira font-[500] hover:bg-secondary-color text-[12px] border-r-2 border-solid border-orange-500 text-white ${value === tab.id ? 'bg-secondary-color' : disableTabNo?.includes(tab.id) ? 'bg-disabled-color' : 'bg-primary-color'} border-b border-b-white`}
            />
          ))}
        </Tabs>
      </div>
      <Box
        className='grow'
      >
        {tabs.map((tab) => (
          <TabPanel key={tab.id} value={value} index={tab.id}>
            <Box className='w-[100%]'>{tab.child}</Box>
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
};

export default VerticalTabs;
