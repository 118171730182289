import SimpleModal from 'common/Form/Form';
import StyledField from 'common/TextField/TexField1';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface BankAccountModalComponentProps {
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleSubmit: () => void;
  formData: any;
  handleSecField: (field: string) => void;
  showSecAccountTitleField: boolean;
  showSecBankNameField: boolean;
  primaryLanguage: string;
  secondaryLanguage: string;
  openForm: boolean;
  errorMessages: { [key: string]: string };
  enableEdit?: boolean;
  isBtnEnabled: boolean;
}

const BankAccountModalComponent: React.FC<BankAccountModalComponentProps> = ({
  closeModal,
  handleChange,
  handleSubmit,
  formData,
  openForm,
  showSecAccountTitleField,
  showSecBankNameField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  enableEdit,
  errorMessages,
  isBtnEnabled
}) => {
  // const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
  //   // Handle scroll logic here
  const { t } = useTranslation();
  // };
  // const handleSearch = (inputValue: string) => {
  //   // Handle search logic here
  // }

  return (
    <div>
      <SimpleModal
        visable={openForm}
        title={enableEdit ? "updateBankAccount" : "addBankAccount"}
        isButtonVisable={true}
        isVisableBtn={isBtnEnabled}
        btnclosetext="Back"
        buttonText={enableEdit ? "update" : "submit"}
        attBtnNotshow={false}
        notVisableBackbtn={true}
        showPortList={handleSubmit} // Assuming this is the Submit button
        modalControle={closeModal}
        // attributeChangesMessage="Attribute changes message"
        // showPort={true}
        formSubmit={handleSubmit}
        content={
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('accountTitle')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                // placeholder="Write your Title here"
                handleChange={(e) => handleChange('accountTitle', e.target.value)}
                required={true}
                search='language'
                handleSecField={() => handleSecField('accountTitle')}
                fullWidth
                error={errorMessages?.accountTitle}
                value={formData?.accountTitle || ''}
              />
              { showSecAccountTitleField &&
                <Grid item xs={12} sm={12} md={12} lg={12} >
                <div style={{ position: 'relative' }}>
                  <div
                    style={{
                      position: 'absolute',
                      top: -8,
                      left: 0,
                      zIndex: 1,
                      display: showSecAccountTitleField ? 'flex' : 'none',
                      backgroundColor: "white",
                      height: '5rem',
                      width: '100%',
                      justifyContent:'center',
                      borderRadius: '8px',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                    }}
                  >
                    <div className='w-full mx-2 mt-1'>
                  <StyledField
                    label={`${!secondaryLanguage ? t('secondary') : ''} ${t('accountTitle')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                    handleChange={(e) => handleChange('secondaryAccountTitle', e.target.value)}
                    required={false}
                    fullWidth
                    // error={errorMessages?.secondaryAccountTitle}
                    value={formData?.secondaryAccountTitle || ''}
                  />
                     </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label={`${!primaryLanguage ? t('primary') : ''} ${t('bankName')} ${primaryLanguage ? ` (${primaryLanguage})` : ''}`}
                placeholder="Write your Title here"
                handleChange={(e) => handleChange('bankName', e.target.value)}
                required={true}
                search='language'
                handleSecField={() => handleSecField('bankName')}
                fullWidth
                error={errorMessages?.bankName}
                value={formData?.bankName || ''}
              />
              {showSecBankNameField &&
              <Grid item xs={12} sm={12} md={12} lg={12} >
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    top: -8,
                    left: 0,
                    zIndex: 1,
                    display: showSecBankNameField ? 'flex' : 'none',
                    backgroundColor: "white",
                    height: '5rem',
                    width: '100%',
                    justifyContent:'center',
                    borderRadius: '8px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'
                  }}
                >
                  <div className='w-full mx-2 mt-1'>
                <StyledField
                  label={`${!secondaryLanguage ? t('secondary') : ''} ${t('bankName')} ${secondaryLanguage ? ` (${secondaryLanguage})` : ''}`}
                  placeholder="Write your Secondary Title here"
                  handleChange={(e) => handleChange('bankNameSecondary', e.target.value)}
                  required={false}
                  fullWidth
                  // error={errorMessages?.secondaryTitle}
                  value={formData?.bankNameSecondary || ''}
                />
                    </div>
                    </div>
                  </div>
                </Grid>
              }
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label="accountNumber"
                placeholder="Write your account number here"
                handleChange={(e) => handleChange('accountNumber', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.accountNumber}
                value={formData?.accountNumber || ''}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}  >
              <StyledField
                label="IBAN"
                placeholder="Write your IBAN here"
                handleChange={(e) => handleChange('iban', e.target.value)}
                required={true}
                fullWidth
                error={errorMessages?.iban}
                value={formData?.iban || ''}
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default BankAccountModalComponent;
