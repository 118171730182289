import React, { useState, useEffect, useRef } from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { List } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerContainer = styled('div')({
  overflow: 'auto',
  flexGrow: 1,
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

interface NavigationItem {
  checked: boolean;
  href?: string;
  icon: React.ReactNode;
  title: string;
  tooltip: string;
  haveMenu?: boolean;
  menuItems?: { label: string; onClick: () => void }[];
  subItems?: Record<string, NavigationItem>;
}

interface DrawerComponentProps {
  open: boolean;
  navigation: Record<string, NavigationItem>;
  drawerHeader: React.ReactNode;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({ open, navigation, drawerHeader }) => {
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [, setSelectedSubItemId] = useState<string | null>(null);
  const navigate = useNavigate();
  const drawerContainerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition] = useState<number>(0);
  const [openSubmenu, setOpenSubmenu] = useState<string | null>(null);
  const { t } = useTranslation();
  const location = useLocation();
  const [submenuPosition, setSubmenuPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const [selectedSubItem, setSelectedSubItem] = useState<string | null>(
    localStorage.getItem('selectedSubItem') || null
  );

  useEffect(() => {
    const currentPath = location.pathname;
    const matchingItemId = Object.keys(navigation).find(
      (key) => {
        const item = navigation[key as keyof typeof navigation];
        if (item.subItems) {
          const subItemKeys = Object.keys(item.subItems);
          const subItemSelected = subItemKeys.some(subKey => item.subItems![subKey].href === currentPath);
          if (subItemSelected) {
            return true;
          }
        }
        return item.href === currentPath;
      }
    );
    setSelectedItemId(matchingItemId || null);
  }, [location, navigation]);

  useEffect(() => {
    if (selectedItemId && drawerContainerRef.current) {
      const container = drawerContainerRef.current;
      const selectedElement = container.querySelector(`[data-key="${selectedItemId}"]`);
      if (selectedElement) {
        const containerRect = container.getBoundingClientRect();
        const selectedRect = selectedElement.getBoundingClientRect();
        const scrollTop = selectedRect.top - containerRect.top + scrollPosition;
        container.scrollTo({ top: scrollTop, behavior: 'smooth' });
      }
    }
  }, [selectedItemId, scrollPosition]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        drawerContainerRef.current &&
        !drawerContainerRef.current.contains(event.target as Node)
      ) {
        setOpenSubmenu(null);
      }
    };

    document.body.addEventListener('click', handleOutsideClick);

    return () => {
      document.body.removeEventListener('click', handleOutsideClick);
    };
  }, []);


  const handleSubmenuToggle = (key: string, event: React.MouseEvent<HTMLDivElement>) => {
    setOpenSubmenu(openSubmenu === key ? null : key);
    setSelectedSubItemId(null);
    if (drawerContainerRef.current) {
      const selectedElement = event.currentTarget;
      const rect = selectedElement.getBoundingClientRect();
      const containerRect = drawerContainerRef.current.getBoundingClientRect();
      setSubmenuPosition({
        top: rect.top - containerRect.top + rect.height,
        left: rect.right,
      });
    }
  };

  const handleSubItemClick = (parentKey: string, key: string, href?: string) => {
    setSelectedSubItem(key);
    setOpenSubmenu(null);
    if (href) {
      navigate(href);
    }
    localStorage.setItem('selectedSubItem', key);
  };

  const handleMouseEnter = (key: string, event: React.MouseEvent<HTMLDivElement>) => {
    if (!openSubmenu || openSubmenu !== key) {
      handleSubmenuToggle(key, event);
    }
  };

  const handleMouseLeave = () => {
    setOpenSubmenu(null);
  };

  const trimText = (text: string, maxLength: number) => {
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <Drawer variant="permanent" open={open}>
      {drawerHeader && drawerHeader}
      <Divider />
      <DrawerContainer className={`bg-layoutbg-color border-t-4 py-2 ${openSubmenu ? 'overflow-hidden' : 'overflow-auto'}`} ref={drawerContainerRef}>
        {Object.keys(navigation).map((key) => {
          const item = navigation[key as keyof typeof navigation];
          const hasSubmenu = item.subItems && Object.keys(item.subItems).length > 0;
          const overflowClass = hasSubmenu ? 'overflow-hidden' : '';

          return (
            <div key={item.title} onMouseEnter={(event) => handleMouseEnter(key, event)} onMouseLeave={handleMouseLeave}>
              {Object.values(item.subItems!).every(item => item.checked === false) ? null : <ListItem disablePadding className={`block ${overflowClass}`} data-key={key}>
                <Tooltip
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [-35, -14],
                          },
                        },
                      ],
                    },
                  }}
                  title={t(item.tooltip)} placement='right'>
                  <ListItemButton
                    className={`group mb-1 hover:bg-primary-color hover:text-white
          ${selectedItemId === key || openSubmenu === key ? 'bg-primary-color text-white hover:bg-primary-color' : ''}
          `}
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      // width:"105%",
                    }}
                  >
                    <ListItemIcon
                      className={` ${selectedItemId === key || openSubmenu === key ? 'invert' : ''} `}
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primaryTypographyProps={{ className: "font-Saira font-[500] text-[14px]" }} className={`opacity-${open ? 1 : 0}`} primary={t(item.title)} />
                    {hasSubmenu && open && (<ArrowRightIcon className={`${selectedItemId === key ? 'text-[#FC5F3A]' : ''}`} />)}
                  </ListItemButton>
                </Tooltip>
              </ListItem>}
              {hasSubmenu && openSubmenu === key && (
                <div style={{ position: 'fixed', top: submenuPosition.top, left: submenuPosition.left, zIndex: 999, marginTop: 13 }}>
                  <List className='bg-primary-color w-[10rem] pt-2 pb-1 rounded-tr-lg rounded-b-lg max-h-80 overflow-y-scroll'>
                    {Object.values(item.subItems!).map((subItem) => (
                      subItem.checked && <ListItem
                        key={subItem.title}
                        disablePadding
                        onClick={() => handleSubItemClick(key, subItem.title, subItem.href)}

                        className={`block overflow-hidden`}
                      >
                        <Tooltip title={t(subItem.tooltip)} placement='right'>
                          <ListItemButton
                            className={`text-white mb-1 ml-1 py-1 pr-10 hover:bg-[#FC5F3A] ${selectedSubItem === subItem.title ? 'bg-[#FC5F3A]' : ''
                              }`}
                            onClick={() => handleSubItemClick(key, subItem.title, subItem.href)}
                            aria-haspopup="true"
                            sx={{
                              px: 2.5,
                            }}
                          >
                            <ListItemText primaryTypographyProps={{ className: "font-Saira font-[500] text-[14px]" }} primary={trimText(t(subItem.title), 15)} />
                          </ListItemButton>
                        </Tooltip>
                      </ListItem>
                    ))}
                  </List>
                </div>
              )}
            </div>
          );
        })}
      </DrawerContainer>
    </Drawer>
  );
};

export default DrawerComponent;
