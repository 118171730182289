// ItemsService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  AssignPriceListRequest,
  AssignUnassignAllRequest,
  AssignUnassignAllResponse,
  AssignUnassignModelRequest,
  AssignUnassignResponse,
  AssignUnassignVendorRequest,
  AssignUnitRequest,
  ChangeStatusItemsResponse,
  DeleteItemsResponse,
  GetItemModelResponse,
  GetItemPriceListResponse,
  GetItemUnitResponse,
  GetItemVendorResponse,
  GetItemsDropdownResponse,
  GetItemsResponse,
  ItemsRequest,
  ItemsResponse,
  UnassignPriceListRequest,
  UnassignUnitRequest,
} from './interface';

export class ItemsProfile extends BaseService {
  async createItems(req: ItemsRequest) {
    return this.post<ItemsResponse, ItemsRequest>(`${this.apiUrl}/api/items`, req);
  }

  async getItems(params: PaginationParams) {
    return this.get<GetItemsResponse>(`${this.apiUrl}/api/items/paged`, params);
  }

  async getItemsDropdown(params: PaginationParams & { itemGroupId?: number }) {
    return this.get<GetItemsDropdownResponse>(`${this.apiUrl}/api/items/get`, params);
  }

  async updateItems(id: number, req: ItemsRequest) {
    return this.put<ItemsResponse, ItemsRequest>(`${this.apiUrl}/api/items/${id}`, req);
  }

  async deleteItems(id: number) {
    return this.delete<DeleteItemsResponse>(`${this.apiUrl}/api/items/${id}`);
  }

  async changeStatusItems(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusItemsResponse, ChangeStatusRequest>(`${this.apiUrl}/api/items/${active}/${id}`, req);
  }

  async getItemUnit(params: PaginationParams & { itemId: number }) {
    return this.get<GetItemUnitResponse>(`${this.apiUrl}/api/units/byitemid`, params);
  }

  async assignUnit(req: AssignUnitRequest) {
    return this.post<AssignUnassignResponse, AssignUnitRequest>(`${this.apiUrl}/api/items/unit/assign`, req);
  }

  async unassignUnit(req: UnassignUnitRequest) {
    return this.put<AssignUnassignResponse, UnassignUnitRequest>(`${this.apiUrl}/api/items/unit/unassign`, req);
  }

  async getItemModel(params: PaginationParams & { itemId: number }) {
    return this.get<GetItemModelResponse>(`${this.apiUrl}/api/models/byitemid`, params);
  }

  async assignModel(req: AssignUnassignModelRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignModelRequest>(`${this.apiUrl}/api/items/model/assign`, req);
  }

  async unassignModel(req: AssignUnassignModelRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignModelRequest>(`${this.apiUrl}/api/items/model/unassign`, req);
  }

  async assignAllModels(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/items/model/assignall`,
      req
    );
  }

  async unassignAllModels(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/items/model/unassignall`,
      req
    );
  }

  async getItemVendor(params: PaginationParams & { itemId: number }) {
    return this.get<GetItemVendorResponse>(`${this.apiUrl}/api/vendors/byitemid`, params);
  }

  async assignVendor(req: AssignUnassignVendorRequest) {
    return this.post<AssignUnassignResponse, AssignUnassignVendorRequest>(
      `${this.apiUrl}/api/items/vendor/assign`,
      req
    );
  }

  async unassignVendor(req: AssignUnassignVendorRequest) {
    return this.put<AssignUnassignResponse, AssignUnassignVendorRequest>(
      `${this.apiUrl}/api/items/vendor/unassign`,
      req
    );
  }

  async assignAllVendors(req: AssignUnassignAllRequest) {
    return this.post<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/items/vendor/assignall`,
      req
    );
  }

  async unassignAllVendors(req: AssignUnassignAllRequest) {
    return this.put<AssignUnassignAllResponse, AssignUnassignAllRequest>(
      `${this.apiUrl}/api/items/vendor/unassignall`,
      req
    );
  }

  async getItemPriceList(params: PaginationParams & { itemId: number }) {
    return this.get<GetItemPriceListResponse>(`${this.apiUrl}/api/pricelists/byitemid`, params);
  }

  async assignPriceList(req: AssignPriceListRequest) {
    return this.post<AssignUnassignResponse, AssignPriceListRequest>(`${this.apiUrl}/api/items/pricelist/assign`, req);
  }

  async unassignPriceList(req: UnassignPriceListRequest) {
    return this.put<AssignUnassignResponse, UnassignPriceListRequest>(
      `${this.apiUrl}/api/items/pricelist/unassign`,
      req
    );
  }
}
