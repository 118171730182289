import { useState } from 'react';
// import Box from '@mui/material/Box';
import LoginComponent from 'components/Login/Index';
import { useNavigate } from 'react-router-dom';
import { useAuthService } from 'subModule/src/services/userProfile/useProfile';
import { useDispatch } from 'react-redux';
import { setUserInfo } from 'features/user/userSlice';
import { useSnackbar } from 'notistack';
import AlertMessages from 'common/Alert/AlertMessages';
interface FormData {
  email: string;
  password: string;
}

const requiredFields = ['email', 'password'];

export default function Login() {
  const [openForm, setOpenForm] = useState(false);
  const [isVisableAddBtn, setIsVisibleAddBtn] = useState(true);
  // const [loginError, setLoginError] = useState('');
  const [logedInUserInfo, setLogedInUserInfo] = useState({username : "", email:""});
  

  const [formData, setFormData] = useState<FormData>({
    email: '',
    password: '',
  });
  const [errorMessages, setErrorMessages] = useState<Partial<FormData>>({});
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  // Use the useAuthService hook
  const { onLogIn, isLoginLoading, data, error, isError } = useAuthService({
    onSuccess: (msg) => {
      console.log('Login successful=======', data, isLoginLoading, error, isError, logedInUserInfo);
      const response: any = {};
      Object.keys(msg).forEach((key, index) => { response[key] = Object.values(msg)[index]; });
      if(response.success) {
        dispatch(setUserInfo({ username: response.data.username, email: response.data.email, ...response.data }));
        localStorage.setItem('selectedSubItem', 'users');
        navigate('/users');
        setLogedInUserInfo({ username:response.data.username, email:response.data.email, ...response.data })   
      } else {
        enqueueSnackbar(<AlertMessages statusCode={response.status} />, { variant: 'error' });
      }
    },
    onError: (err: any) => {
      if (err) {
        const errorMessage = err?.data?.message || 'An error occurred';
        enqueueSnackbar(errorMessage, { variant: 'error' });
      }
    },
  });

  const openModal = () => {
    setOpenForm(!openForm);
    setIsVisibleAddBtn(false);
    setErrorMessages({});
    setFormData({
      email: '',
      password: '',
    });
  };

  const handleChange = (field: string, value: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  
    if (requiredFields.includes(field) && !value) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`,
      }));
    } else {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [field]: undefined,
      }));
    }
  };  

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit();
    } else if (e.key === 'Tab') {
      // Handle Tab key press (e.g., move focus to next input)
    }
  };

  const handleSubmit = () => {
    const emptyFields: string[] = [];
    requiredFields.forEach(field => {
      if (!formData[field as keyof FormData]) {
        emptyFields.push(field);
      }
    });
    if (emptyFields.length > 0) {
      const newErrorMessages: Partial<FormData> = {};
      emptyFields.forEach(field => {
        newErrorMessages[field as keyof FormData] = `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
      });
      setErrorMessages(newErrorMessages);
      return;
    }
    onLogIn(formData.email, formData.password);
  };

  return (
    <>
      <LoginComponent
        openModal={openModal}
        closeModal={openModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleKeyDown={handleKeyDown}
        formData={formData}
        {...{ openForm, isVisableAddBtn }}
        errorMessages={errorMessages}
      />
    </>
  );
}
