import { Box, Grid, Typography } from "@mui/material";
import BackendPaginationTable from "common/Table/TableWithPagination";
import { ReactComponent as Services  } from "assets/SideBarIcons/services.svg";
import ComponentHeaderTwo from "common/ComponentHeaderTwo/ComponentHeaderTwo";
import ServiceModal from "./ServiceModal";
import FormDialog from "common/Modal/Modal";
import { useTranslation } from 'react-i18next';
import StyledInput from "common/SearchField/Search";
import VerticalTabs from 'common/ComponentSidebar/ComponentSideBar';
import Loader from "layout/Loader";
import ServicePriceListItemsTable from "./ServicePriceListItemsTable";
import ServiceStationSearchHeader from "./ServiceStationSearchHeader";
import { checkPrivileges } from "Helpers/CheckPrivileges";
import SearchHeaderMenu from "common/Misc/SearchHeaderMenu";
interface ServiceComponentProps {
  handleSecField: () => void;
  showSecField: boolean;
  primaryLanguage:string;
  secondaryLanguage:string;
  openModal: () => void;
  closeModal: () => void;
  handleChange: (field: string, value: any) => void;
  handleChangePriceList: (field: string, value: any) => void;
  handleChangePage: (field: number) => void;
  handleChangeRows: (field: number) => void;
  handleSubmit: () => void;
  handleDelete:  () => void;
  cancelDelete:  () => void;
  onChangeStatus: (row: any) => void;
  cancelChangeStatus: () => void;
  submitChangeStatus: () => void;
  statusModal: boolean;
  statusText: string;
  formData: any;
  priceListFormData: any;
  taxProceduresData: any;
  itemGroupsData: any;
  openForm: boolean;
  columns: any[]; 
  data: any; 
  errorMessages: any,
  priceListErrorMessages: any,
  actions1: { label: string; onClick: ( value: any) => void; icon?: React.ReactNode }[];
  openDeleteModal: boolean;
  enableEdit?:boolean;
  pageNo:number;
  pageSize:number;
  itemAssignData: any;
  vehicleTypeAssignData: any;
  stationAssignData: any;
  modelAssignData: any;
  priceListAssignData: any;
  showRowView: (row: any) => void;
  rowView: boolean;
  handleChangeTabs: (tabId: number) => void;
  assignUnassignSingleEntity: (isChecked: boolean, row: any) => void;
  assignUnassignAllEntities: (isChecked: boolean) => void;
  allItemsAssigned: unknown;
  allVehicleTypesAssigned: unknown;
  allStationsAssigned: unknown;
  allModelsAssigned: unknown;
  isBtnEnabled: boolean;
  loading: boolean;
  search: string;
  handleSearchClick: () => void;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loadingSearch: boolean;
  loadNext: (field: string, scroll: any) => void;
  loadPrev: (field: string, scroll: any) => void;
  handleDropdownSearch: (field: string, search: string) => void;
  loadingDropdownSearch: boolean;
  searchAssign: string;
  loadingSearchAssign: boolean;
  searchAssignFormData: any;
  filterAssign: string[];
  searchMultiAssign: any;
  handleChangeAssignFilter: (event: any) => void;
  handleChangeSearchAssign: (field: string, value: any) => void;
  handleSubmitSearchAssign: () => void;
  searchAssignErrorMessages: any;
  clearSearchAssign: () => void;
  tab: number;
  defaultPriceList: string;
  loggedInUserCurrency: string;
  clearSearch: () => void;
  defaultPriceListId: number | null | undefined;
  refetch: any;
}

const ServiceComponent: React.FC <ServiceComponentProps> = ({
  showSecField,
  handleSecField,
  primaryLanguage,
  secondaryLanguage,
  openModal,
  closeModal,
  handleChange,
  handleChangePriceList,
  handleSubmit,
  formData,
  priceListFormData,
  taxProceduresData,
  itemGroupsData,
  openForm,
  columns,
  data,
  errorMessages,
  priceListErrorMessages,
  actions1,
  openDeleteModal,
  handleDelete,
  cancelDelete,
  enableEdit,
  handleChangePage,
  handleChangeRows,
  onChangeStatus,
  cancelChangeStatus,
  submitChangeStatus,
  pageNo,
  statusModal,
  statusText,
  pageSize,
  itemAssignData,
  vehicleTypeAssignData,
  stationAssignData,
  modelAssignData,
  priceListAssignData,
  showRowView,
  rowView,
  handleChangeTabs,
  assignUnassignSingleEntity,
  assignUnassignAllEntities,
  allItemsAssigned,
  allVehicleTypesAssigned,
  allStationsAssigned,
  allModelsAssigned,
  isBtnEnabled,
  loading,
  search,
  handleSearchClick,
  handleInputChange,
  loadingSearch,
  loadNext,
  loadPrev,
  handleDropdownSearch,
  loadingDropdownSearch,
  searchAssign,
  loadingSearchAssign,
  searchAssignFormData,
  filterAssign,
  searchMultiAssign,
  handleChangeAssignFilter,
  handleChangeSearchAssign,
  handleSubmitSearchAssign,
  searchAssignErrorMessages,
  clearSearchAssign,
  tab,
  defaultPriceList,
  loggedInUserCurrency,
  clearSearch,
  defaultPriceListId,
  refetch
}) => {
  const { t } = useTranslation();

  const itemColumns = [
    { id: 'title', label: 'name', numeric: false },
    { id: 'itemCost', label: 'itemCost', numeric: false },
    { id: 'itemCode', label: 'itemCode', numeric: false },
  ]
  const vehicleTypeColumns = [
    { id: 'title', label: 'title', numeric: false },
  ]
  const stationColumns = [
    { id: 'name', label: 'name', numeric: false },
    { id: 'phoneNumber1', label: 'phoneNumber1', numeric: false },
    { id: 'phoneNumber2', label: 'phoneNumber2', numeric: false },
    { id: 'email', label: 'email', numeric: false }
  ]
  const modelColumns = [
    { id: 'title', label: 'title', numeric: false },
    { id: 'makeTitle', label: 'make', numeric: false },
  ]
  const priceListColumns = [
    { id: 'title', label: 'title', numeric: false },
    { id: 'price', label: 'price', numeric: false },
  ]

  const childTabContent = (columns:any, data:any, tableData:any, allEntitiesAssigned:unknown, assignPrivilege: string, unassignPrivilege: string) => {
    return (
      <BackendPaginationTable 
        columns={columns}
        data={data}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={tableData}
        showCheckbox={true}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        assignUnassignAllEntities={assignUnassignAllEntities}
        allEntitiesAssigned={allEntitiesAssigned}
        loadingSearch={loadingSearchAssign}
        assignPrivilege={checkPrivileges(assignPrivilege)}
        unassignPrivilege={checkPrivileges(unassignPrivilege)}
        headerComponent={tab === 3 ? <ServiceStationSearchHeader 
          searchAssignFormData={searchAssignFormData}
          handleChangeAssignFilter={handleChangeAssignFilter}
          handleChangeSearchAssign={handleChangeSearchAssign}
          handleSubmitSearchAssign={handleSubmitSearchAssign}
          searchAssignErrorMessages={searchAssignErrorMessages}
          clearSearchAssign={clearSearchAssign}
          searchAssign={searchMultiAssign}
          {...{filterAssign}}
        /> : (
          <Grid container spacing={2}>  
            <Grid item xs={12} md={7} lg={5}>
              <StyledInput
                fullWidth
                placeholder={tab === 3 || tab === 1 ? t("name") : t("title")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={searchAssign}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
        )}
      />
    );
  }
  const tabs = [
    { 
      id: 0, title: `edit`, 
        child: <ServiceModal
        handleSecField={handleSecField}
        showSecField={showSecField}
        secondaryLanguage={secondaryLanguage}
        primaryLanguage={primaryLanguage}
        enableEdit={enableEdit}
        openModal={openModal}
        closeModal={closeModal}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        formData={formData}
        taxProceduresData={taxProceduresData.data?.content || []}
        itemGroupsData={itemGroupsData.data?.content || []}
        openForm={true}
        errorMessages={errorMessages}
        isBtnEnabled={isBtnEnabled}
        loadNext={loadNext}
        loadPrev={loadPrev}
        handleDropdownSearch={handleDropdownSearch}
        {...{loadingDropdownSearch, defaultPriceList, loggedInUserCurrency}}
      />
    },
    { id: 1, title: `items`, child: childTabContent(itemColumns, itemAssignData.data?.content || [], itemAssignData?.data || {}, allItemsAssigned, 'ROLE_SERVICE_ITEM_ASSIGN', 'ROLE_SERVICE_ITEM_UNASSIGN')},
    { id: 2, title: `vehicletypes`, child: childTabContent(vehicleTypeColumns, vehicleTypeAssignData.data?.content || [], vehicleTypeAssignData?.data || {}, allVehicleTypesAssigned, 'ROLE_SERVICE_VEHICLETYPE_ASSIGN', 'ROLE_SERVICE_VEHICLETYPE_UNASSIGN')},
    { id: 3, title: `stations`, child: childTabContent(stationColumns, stationAssignData.data?.content || [], stationAssignData?.data || {}, allStationsAssigned, 'ROLE_SERVICE_STATION_ASSIGN', 'ROLE_SERVICE_STATION_UNASSIGN')},
    { id: 4, title: `models`, child: childTabContent(modelColumns, modelAssignData.data?.content || [], modelAssignData?.data || {}, allModelsAssigned, 'ROLE_SERVICE_MODEL_ASSIGN', 'ROLE_SERVICE_MODEL_UNASSIGN')},
    { id: 5, title: `priceList`, 
      child: <ServicePriceListItemsTable 
        columns={priceListColumns}
        data={priceListAssignData.data?.content || []}
        pageNo={pageNo}
        pageSize={pageSize}
        tableData={priceListAssignData?.data || {}}
        showActions={true}
        enableSorting={true}
        showHeader={true}
        showSearch={true}
        showFilter={true}
        showCreateButton={false}
        actions={[]}
        handleChangePage={handleChangePage}
        handleChangeRows={handleChangeRows}
        assignUnassignSingleEntity={assignUnassignSingleEntity}
        loadingSearch={loadingSearchAssign}
        priceListFormData={priceListFormData}
        handleChangePriceList={handleChangePriceList}
        priceListErrorMessages={priceListErrorMessages}
        assignPrivilege={checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN')}
        unassignPrivilege={checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN')}
        {...{defaultPriceListId}}
        headerComponent={(
          <Grid container spacing={2}>  
            <Grid item xs={12} md={7} lg={5}>
              <StyledInput
                fullWidth
                placeholder={t("title")}
                handleSearchClick={handleSearchClick}
                handleChange={handleInputChange}
                value={searchAssign}
                clearSearch={clearSearch}
              />
            </Grid>
          </Grid>
        )}
      />
    }
  ];
  const disableTabs = tabs.map(tab => tab.id).filter(tabId => {
    switch (tabId) {
      case 0:
        return !checkPrivileges('ROLE_SERVICE_UPDATE');
      case 1:
        return !checkPrivileges('ROLE_SERVICE_ITEM_ASSIGN') && !checkPrivileges('ROLE_SERVICE_ITEM_UNASSIGN');
      case 2:
        return !checkPrivileges('ROLE_SERVICE_VEHICLETYPE_ASSIGN') && !checkPrivileges('ROLE_SERVICE_VEHICLETYPE_UNASSIGN');
      case 3:
        return !checkPrivileges('ROLE_SERVICE_STATION_ASSIGN') && !checkPrivileges('ROLE_SERVICE_STATION_UNASSIGN');
      case 4:
        return !checkPrivileges('ROLE_SERVICE_MODEL_ASSIGN') && !checkPrivileges('ROLE_SERVICE_MODEL_UNASSIGN');
      case 5:
        return !checkPrivileges('ROLE_SERVICE_PRICELIST_ASSIGN') && !checkPrivileges('ROLE_SERVICE_PRICELIST_UNASSIGN');
      default:
        return false;
    }
  });
  const checkDisabledTabs = () => {
    let updatedDisableTabs = [...disableTabs];
    if (formData?.itemGroup === 1) {
      if (!updatedDisableTabs.includes(1)) {
        updatedDisableTabs.push(1);
      }
    } else {
      updatedDisableTabs = updatedDisableTabs.filter(tabId => tabId !== 1);
    }
  
    if (formData?.applyToAllStations) {
      if (!updatedDisableTabs.includes(3)) {
        updatedDisableTabs.push(3);
      }
    }
  
    return updatedDisableTabs;
  }

    return(

        <Box>
          <ComponentHeaderTwo
            showBtn={true}
            showSaveBtn={openForm}
            onClick={openModal}
            onSubmit={handleSubmit}
            heading={(openForm && !enableEdit) ? "addService" : (enableEdit && openForm || rowView) ? "editService" : t('services')}
            icon={<Services height={28} width={28} />}
            btnText={openForm || rowView ? "back" : "create"}
            btnType={openForm || rowView ? "back" : "create"}
            isBtnEnabled={isBtnEnabled}
            showCreateBtn={checkPrivileges("ROLE_SERVICE_CREATE")}
            // btnType="create"
          />

            {openForm ? 
              <ServiceModal
                handleSecField={handleSecField}
                showSecField={showSecField}
                secondaryLanguage={secondaryLanguage}
                primaryLanguage={primaryLanguage}
                enableEdit={enableEdit}
                openModal={openModal}
                closeModal={closeModal}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                formData={formData}
                taxProceduresData={taxProceduresData?.data?.content || []}
                itemGroupsData={itemGroupsData?.data?.content || []}
                openForm={openForm}
                errorMessages={errorMessages}
                isBtnEnabled={isBtnEnabled}
                loadNext={loadNext}
                loadPrev={loadPrev}
                handleDropdownSearch={handleDropdownSearch}
                {...{loadingDropdownSearch, defaultPriceList, loggedInUserCurrency}}
              />
            : rowView ?
              <VerticalTabs tabs={tabs} selectedIndex={0} handleChangeTabs={handleChangeTabs} disableTabNo={checkDisabledTabs()} />
            : <Box>
                {loading ? <Loader /> :
                  <BackendPaginationTable
                    columns={actions1.length ? columns : columns.filter(action => action.id !== 'actions')}
                    data={data.data?.content || []}
                    pageNo={pageNo}
                    pageSize={pageSize}
                    tableData={data?.data || {}}
                    showCheckbox={false}
                    showActions={true}
                    enableSorting={true}
                    showHeader={true}
                    showSearch={true}
                    showFilter={true}
                    showCreateButton={true}
                    actions={actions1}
                    handleChangePage={handleChangePage}
                    handleChangeRows={handleChangeRows}
                    onChangeStatus={onChangeStatus}
                    rowClick={disableTabs.length === tabs.length ? false : true}
                    showRowView={showRowView}
                    loadingSearch={loadingSearch}
                    headerComponent={
                    <Grid container spacing={2}>  
                      <Grid item xs={12} md={7} lg={5}>
                        <StyledInput
                          fullWidth
                          placeholder={t("title")}
                          handleSearchClick={handleSearchClick}
                          handleChange={handleInputChange}
                          value={search}
                          clearSearch={clearSearch}
                        />
                      </Grid>
                      <Grid item xs={5} lg={7} className="mt-[5px] text-end">
                        <SearchHeaderMenu typeName="services" refetch={refetch} />
                      </Grid>
                    </Grid>
                    }
                  />
                }
              </Box>
            }

      {openDeleteModal && 
            <FormDialog
                open={openDeleteModal}
                onClose={cancelDelete}
                title="Delete Service"
                // icon={<div><Home/></div>}
                content={  
                    <Grid container >
                  <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                    Are you sure you want to Delete?
                  </Typography>
                  </Grid>
                  </Grid>
                  }
                  saveFunction={handleDelete}
                submitButtonText="Delete"
              />}
{statusModal && 
          <FormDialog
            open={statusModal}
            onClose={cancelChangeStatus}
            title="Change Status"
            // icon={<div><Home/></div>}
            content={  
              <Grid container >
                <Grid item  xs={12} sm={12} md={12} lg={12}  >
                  <Typography>
                  {t(`Are you sure you want to ${statusText}`)}?
                  </Typography>
                </Grid>
              </Grid>
            }
            saveFunction={submitChangeStatus}
            submitButtonText="OK"
          />
        }

        </Box>

    )
}

export default ServiceComponent;