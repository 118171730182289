// BankDepositProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { BankDepositProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import {
  BankDepositRequest,
  BankDepositResponse,
  ChangeStatusBankDepositResponse,
  DeleteBankDepositResponse,
} from './interface';
import { useCallback } from 'react';

type UseBankDepositServiceProps = {
  onSuccess: (data: BankDepositResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateBankDepositServiceProps = {
  onSuccess: (data: BankDepositResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteBankDepositServiceProps = {
  onSuccess: (data: DeleteBankDepositResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusBankDepositServiceProps = {
  onSuccess: (data: ChangeStatusBankDepositResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useBankDepositService = ({ onError, onSuccess }: UseBankDepositServiceProps) => {
  const {
    mutateAsync: createBankDeposit,
    isPending: isCreateBankDepositLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-bankdeposit-request'],
    mutationFn: (req: BankDepositRequest) => BankDepositProfileService.createBankDeposit(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateBankDeposit = useCallback(
    async (bankDepositData: BankDepositRequest) => {
      try {
        createBankDeposit(bankDepositData);
      } catch (err) {
        console.error(err);
      }
    },
    [createBankDeposit]
  );

  return {
    onCreateBankDeposit,
    isCreateBankDepositLoading,
    data,
    error,
    isError,
  };
};

export const useGetBankDepositsService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-bankdeposits-data'],
      queryFn: async ({ pageParam }) => {
        return BankDepositProfileService.getBankDeposits({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          receiptNumber: initialParams.receiptNumber,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateBankDepositService = ({ id, onError, onSuccess }: UpdateBankDepositServiceProps) => {
  const {
    mutateAsync: updateBankDeposit,
    isPending: isUpdateBankDepositLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-bankdeposit-request'],
    mutationFn: (req: BankDepositRequest) => BankDepositProfileService.updateBankDeposit(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateBankDeposit = useCallback(
    async (req: BankDepositRequest) => {
      try {
        updateBankDeposit(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateBankDeposit]
  );

  return {
    onUpdateBankDeposit,
    isUpdateBankDepositLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteBankDepositService = ({ id, onError, onSuccess }: DeleteBankDepositServiceProps) => {
  const {
    mutateAsync: deleteBankDeposit,
    isPending: isDeleteBankDepositLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-bankdeposit-request'],
    mutationFn: () => BankDepositProfileService.deleteBankDeposit(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteBankDeposit = useCallback(async () => {
    try {
      deleteBankDeposit();
    } catch (err) {
      console.error(err);
    }
  }, [deleteBankDeposit]);

  return {
    onDeleteBankDeposit,
    isDeleteBankDepositLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusBankDepositService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusBankDepositServiceProps) => {
  const {
    mutateAsync: changeStatusBankDeposit,
    isPending: isChangeStatusBankDepositLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-bankdeposit-request'],
    mutationFn: (req: ChangeStatusRequest) => BankDepositProfileService.changeStatusBankDeposit(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusBankDeposit = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusBankDeposit(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusBankDeposit]
  );

  return {
    onChangeStatusBankDeposit,
    isChangeStatusBankDepositLoading,
    data,
    error,
    isError,
  };
};
