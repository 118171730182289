// VendorProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { VendorProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { ChangeStatusVendorResponse, DeleteVendorResponse, VendorRequest, VendorResponse } from './interface';
import { useCallback } from 'react';

type UseVendorServiceProps = {
  onSuccess: (data: VendorResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateVendorServiceProps = {
  onSuccess: (data: VendorResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteVendorServiceProps = {
  onSuccess: (data: DeleteVendorResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusVendorServiceProps = {
  onSuccess: (data: ChangeStatusVendorResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useVendorService = ({ onError, onSuccess }: UseVendorServiceProps) => {
  const {
    mutateAsync: createVendor,
    isPending: isCreateVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-vendor-request'],
    mutationFn: (req: VendorRequest) => VendorProfileService.createVendor(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateVendor = useCallback(
    async (vendorData: VendorRequest) => {
      try {
        createVendor(vendorData);
      } catch (err) {
        console.error(err);
      }
    },
    [createVendor]
  );

  return {
    onCreateVendor,
    isCreateVendorLoading,
    data,
    error,
    isError,
  };
};

export const useGetVendorService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-vendor-data'],
      queryFn: async ({ pageParam }) => {
        // Use the provided initialPageNo and initialPageSize
        return VendorProfileService.getVendor({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateVendorService = ({ id, onError, onSuccess }: UpdateVendorServiceProps) => {
  const {
    mutateAsync: updateVendor,
    isPending: isUpdateVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-vendor-request'],
    mutationFn: (req: VendorRequest) => VendorProfileService.updateVendor(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateVendor = useCallback(
    async (req: VendorRequest) => {
      try {
        updateVendor(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateVendor]
  );

  return {
    onUpdateVendor,
    isUpdateVendorLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteVendorService = ({ id, onError, onSuccess }: DeleteVendorServiceProps) => {
  const {
    mutateAsync: deleteVendor,
    isPending: isDeleteVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-vendor-request'],
    mutationFn: () => VendorProfileService.deleteVendor(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteVendor = useCallback(async () => {
    try {
      deleteVendor();
    } catch (err) {
      console.error(err);
    }
  }, [deleteVendor]);

  return {
    onDeleteVendor,
    isDeleteVendorLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusVendorService = ({ active, id, onError, onSuccess }: ChangeStatusVendorServiceProps) => {
  const {
    mutateAsync: changeStatusVendor,
    isPending: isChangeStatusVendorLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-vendor-request'],
    mutationFn: (req: ChangeStatusRequest) => VendorProfileService.changeStatusVendor(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusVendor = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusVendor(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusVendor]
  );

  return {
    onChangeStatusVendor,
    isChangeStatusVendorLoading,
    data,
    error,
    isError,
  };
};
