// TaxProcedureService.tsx
import { BaseService } from '../../core/BaseService';
import { ChangeStatusRequest, PaginationParams } from '../../type';
import {
  ChangeStatusTaxProcedureResponse,
  DeleteTaxProcedureResponse,
  GetDefaultTaxProcedureResponse,
  GetTaxProcedureDropdownResponse,
  GetTaxProcedureResponse,
  TaxProcedureRequest,
  TaxProcedureResponse,
} from './interface';

export class TaxProcedureProfile extends BaseService {
  async createTaxProcedure(req: TaxProcedureRequest) {
    return this.post<TaxProcedureResponse, TaxProcedureRequest>(`${this.apiUrl}/api/taxprocedures`, req);
  }

  async getTaxProcedure(params: PaginationParams) {
    return this.get<GetTaxProcedureResponse>(`${this.apiUrl}/api/taxprocedures/paged`, params);
  }

  async getTaxProcedureDropdown(params: PaginationParams) {
    return this.get<GetTaxProcedureDropdownResponse>(`${this.apiUrl}/api/taxprocedures/get`, params);
  }

  async updateTaxProcedure(id: number, req: TaxProcedureRequest) {
    return this.put<TaxProcedureResponse, TaxProcedureRequest>(`${this.apiUrl}/api/taxprocedures/${id}`, req);
  }

  async deleteTaxProcedure(id: number) {
    return this.delete<DeleteTaxProcedureResponse>(`${this.apiUrl}/api/taxprocedures/${id}`);
  }

  async changeStatusTaxProcedure(id: number, active: string, req: ChangeStatusRequest) {
    return this.put<ChangeStatusTaxProcedureResponse, ChangeStatusRequest>(
      `${this.apiUrl}/api/taxprocedures/${active}/${id}`,
      req
    );
  }

  async getDefaultTaxProcedure(id: number) {
    return this.get<GetDefaultTaxProcedureResponse>(`${this.apiUrl}/api/taxprocedures/default/${id}`);
  }
}
