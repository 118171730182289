// BusinessProfileService.tsx
import { useInfiniteQuery, useMutation } from '@tanstack/react-query';
import { BusinessProfileService } from '../../core/services';
import { ChangeStatusRequest, PageSize } from '../../type';
import { BusinessRequest, BusinessResponse, ChangeStatusBusinessResponse, DeleteBusinessResponse } from './interface';
import { useCallback } from 'react';

type UseBusinessServiceProps = {
  onSuccess: (data: BusinessResponse) => void;
  onError: (err: unknown) => void;
};
type UpdateBusinessServiceProps = {
  onSuccess: (data: BusinessResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};

type DeleteBusinessServiceProps = {
  onSuccess: (data: DeleteBusinessResponse) => void;
  onError: (err: unknown) => void;
  id: number;
};
type ChangeStatusBusinessServiceProps = {
  onSuccess: (data: ChangeStatusBusinessResponse) => void;
  onError: (err: unknown) => void;
  id: number;
  active: string;
};

export const useBusinessService = ({ onError, onSuccess }: UseBusinessServiceProps) => {
  const {
    mutateAsync: createBusiness,
    isPending: isCreateBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['create-business-request'],
    mutationFn: (req: BusinessRequest) => BusinessProfileService.createBusiness(req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onCreateBusiness = useCallback(
    async (businessData: BusinessRequest) => {
      try {
        createBusiness(businessData);
      } catch (err) {
        console.error(err);
      }
    },
    [createBusiness]
  );

  return {
    onCreateBusiness,
    isCreateBusinessLoading,
    data,
    error,
    isError,
  };
};

export const useGetBusinessService = (initialParams: PageSize) => {
  const { data, isLoading, error, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } =
    useInfiniteQuery({
      queryKey: ['get-Business-data'],
      queryFn: async ({ pageParam }) => {
        // Use the provided initialPageNo and initialPageSize
        return BusinessProfileService.getBusiness({
          page: initialParams.pageNumber ?? pageParam,
          size: initialParams.size,
          title: initialParams.title,
        });
      },
      enabled: false,
      initialPageParam: 1,
      getNextPageParam: (res) => (!res?.last ? res?.number + 1 : undefined),
      retry: 1,
      retryDelay: 36000000,
    });
  const isDataFetching = isFetching || isFetchingNextPage;

  return {
    data,
    isLoading,
    error,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isFetching,
    isDataFetching,
  };
};

export const useUpdateBusinessService = ({ id, onError, onSuccess }: UpdateBusinessServiceProps) => {
  const {
    mutateAsync: updateBusiness,
    isPending: isUpdateBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['update-business-request'],
    mutationFn: (req: BusinessRequest) => BusinessProfileService.updateBusiness(id, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onUpdateBusiness = useCallback(
    async (req: BusinessRequest) => {
      try {
        updateBusiness(req);
      } catch (err) {
        console.error(err);
      }
    },
    [updateBusiness]
  );

  return {
    onUpdateBusiness,
    isUpdateBusinessLoading,
    data,
    error,
    isError,
  };
};

export const useDeleteBusinessService = ({ id, onError, onSuccess }: DeleteBusinessServiceProps) => {
  const {
    mutateAsync: deleteBusiness,
    isPending: isDeleteBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['delete-business-request'],
    mutationFn: () => BusinessProfileService.deleteBusiness(id),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onDeleteBusiness = useCallback(async () => {
    try {
      deleteBusiness();
    } catch (err) {
      console.error(err);
    }
  }, [deleteBusiness]);

  return {
    onDeleteBusiness,
    isDeleteBusinessLoading,
    data,
    error,
    isError,
  };
};

export const useChangeStatusBusinessService = ({
  active,
  id,
  onError,
  onSuccess,
}: ChangeStatusBusinessServiceProps) => {
  const {
    mutateAsync: changeStatusBusiness,
    isPending: isChangeStatusBusinessLoading,
    data,
    error,
    isError,
  } = useMutation({
    mutationKey: ['change-status-business-request'],
    mutationFn: (req: ChangeStatusRequest) => BusinessProfileService.changeStatusBusiness(id, active, req),
    onSuccess: onSuccess,
    onError: onError,
  });

  const onChangeStatusBusiness = useCallback(
    async (req: ChangeStatusRequest) => {
      try {
        changeStatusBusiness(req);
      } catch (err) {
        console.error(err);
      }
    },
    [changeStatusBusiness]
  );

  return {
    onChangeStatusBusiness,
    isChangeStatusBusinessLoading,
    data,
    error,
    isError,
  };
};
